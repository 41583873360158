<template>
	<!-- Modal -->
    <div class="modal fade text-left" id="Modal_Import" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel160" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">Import {{ title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="">Excel File</label>
                        <input type="file" accept=".xlsx, .xls, .csv" id="file" ref="file" v-on:change="handleFileUpload()"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                    <button type="button" @click="submitFile()" class="btn btn-primary" data-dismiss="modal">Import {{ title }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: 'ModalImport',
	props: ['title', 'url'],
	data() {
		return {
			file: ''
		}
	},
	mounted() {
		// let _ = this
	},
	methods: {
		handleFileUpload() {
			this.file = this.$refs.file.files[0];
		},
		submitFile() {
			let _ = this
			let formData = new FormData();
			var extension = _.file.name.split('.').pop()
			if ( extension == 'xls' || extension == 'xlsx' ) {
				formData.append('excel', this.file);
				_.axios.post('/'+_.url+'/import', formData)
					.then(resp => {
						console.log(resp)
						_.$emit('success')
						_.$toast.success('Berhasil Import Data')
					})
					.catch(err => {
						if ( err.response.data.message ) {
							_.$toast.warning(err.response.data.message)	
						} else {
							_.$toast.warning('Isi dokumen tidak sesuai format')						
						}
					})
				_.$refs.file.value = null;
			} else {
				_.$toast.warning('Tipe File hanya menerima xls atau xlsx')
			}
		}
	}
}
</script>