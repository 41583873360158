<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">Edit Profil</h2>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body text-center">
                            <div class="profile-avatar">
                                <img :src="temp_photo?temp_photo:'/assets/img/icon/profile.png'" class="img-fluid"
                                    alt="">
                                <div class="edit-avatar">
                                    <label for="file-upload" class="btn-edit">
                                        <i class="fa fa-pencil"></i>
                                    </label>
                                    <input id="file-upload" class="input-file-avatar" type="file" v-on:change="Addphoto">
                                </div>
                            </div>
                        </div>

                        <ValidationObserver tag="div" v-slot="{ invalid }" class="card-body">
                            <div class="row">
                                <div class="col-12 col-sm-7">
                                    <form @submit.prevent="save">
                                        <ValidationProvider name="nama" v-slot="{ errors }" rules="required">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Nama Lengkap</label>
                                                <input type="text" v-model="model.name" id="name" class="form-control"
                                                    placeholder="Nama lengkap">
                                                <small class="text-muted" v-if="errors[0]">{{ capitalize(errors[0]) }}</small>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider name="email" v-slot="{ errors }" rules="required|unique">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Email</label>
                                                <input type="email" v-model="model.email" id="email" class="form-control"
                                                    placeholder="Alamat email">
                                                <small class="text-muted" v-if="errors[0]">{{ capitalize(errors[0]) }}</small>
                                            </div>
                                        </ValidationProvider>
                                        
                                        <ValidationProvider name="model.phone" v-slot="{ errors }" rules="required">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Nomor Telepon</label>
                                                <vue-tel-input v-model="model.phone" :style="( warningPhone ? 'border-color: red !important' : '' )" @blur="checkPhone" id="telephone" :required="true" :validCharactersOnly="true" :onlyCountries="['ID']" defaultCountry="ID"></vue-tel-input>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider name="model.address" v-slot="{ errors }" rules="required">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Alamat</label>
                                                <textarea v-model="model.address" class="form-control" cols="30" rows="5"></textarea>
                                            </div>
                                        </ValidationProvider>
                                        <hr>

                                        <ValidationProvider name="model.password" v-slot="{ errors }" :rules="getRulesPassword">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Password</label>
                                                <div class="input-group">
                                                    <input class="form-control border-right-0" :type="showPassword ? 'text' : 'password'" v-model="model.password"
                                                        id="example-search-input">
                                                    <span class="input-group-append">
                                                        <div class="input-group-text">
                                                            <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue">
                                                                <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                                            </a>
                                                        </div>
                                                    </span>
                                                </div>
                                                <small class="text-muted">Password minimal 6 karakter dengan kombinasi huruf
                                                    dan angka</small>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider name="model.password_confirmation" v-slot="{ errors }" :rules="getRulesPasswordConfirmation">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Konfirmasi Password</label>
                                                <div class="input-group">
                                                    <input class="form-control border-right-0" :type="showPasswordConf ? 'text' : 'password'"
                                                        id="example-search-input" v-model="model.password_confirmation">
                                                    <span class="input-group-append">
                                                        <div class="input-group-text">
                                                            <a href="javascript:;" @click="showPasswordConf = !showPasswordConf" class="text-blue">
                                                                <i :class="showPasswordConf ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                                            </a>
                                                        </div>
                                                    </span>
                                                </div>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0].replace('model.password_confirmation', 'Konfirmasi password') }}</small>
                                            </div>
                                        </ValidationProvider>

                                        <button type="submit" :disabled="invalid" class="btn btn-primary">Simpan</button>
                                    </form>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
import { EventBus } from '@/plugins/eventBus.js'
import { VueTelInput } from 'vue-tel-input'
import { extend } from 'vee-validate';

export default {
	name: 'EditProfile',
    components: {
        VueTelInput
    },
    data() {
        return {
            temp_photo: null,
            model: {
                name: '',
                email: '',
                photo: '',
                password: '',
                password_confirmation: '',
                phone: '',
                address: ''
            },
            showPassword: false,
            showPasswordConf: false,
            warningPhone: false
        }
    },
    mounted() {
        let _ = this
        if ( _.$store.state.user.profile_picture != 'null' ) {
            _.temp_photo = _.$store.state.user.profile_picture
        }

        _.model = {
            name: _.$store.state.user.name,
            email: _.$store.state.user.email,
            phone: _.$store.state.user.phone,
            address: _.$store.state.user.address
        }

        extend('password', {
          params: ['target'],
          validate(value, { target }) {
            console.log(value)
            console.log(target)
            return _.model.password === _.model.password_confirmation;
          },
          message: 'Konfirmasi password tidak sesuai'
        });

        extend("unique", {
          validate: async value => {
            var res;
            await _.axios.get('/user/check_email?email=' + value + '&id=' + _.$store.state.user.id)
                .then(resp => {
                    if ( resp.data.data.count === 0 ) {
                        res = true
                    } else {
                        res = false
                    }
                })
                .catch(err=> {
                    console.log(err.response)
                    res = false
                })

            return res
          },

          // same prop as returned in the `data` object.
          message: 'Email sudah digunakan'
        });
    },
    computed: {
        getRulesPassword() {
            return this.$store.state.user.has_update_profile == '0' ? 'required|alpha_num|alphaandnumeric|min:6' : 'alpha_num|alphaandnumeric|min:6'
        },
        getRulesPasswordConfirmation() {
            return this.$store.state.user.has_update_profile == '0' ? 'required|alpha_num|alphaandnumeric|min:6|password' : 'alpha_num|alphaandnumeric|min:6|password'  
        }
    },
    methods: {
        save() {
            let _ = this

            _.FData =  new FormData()
            for (let i in _.model){
                _.FData.append(i, _.model[i])
            }  

            _.axios.post('/user/update_profile', _.FData)
                .then(resp => {
                    console.log(resp.data.data.user)
                    _.$store.dispatch('login', resp.data.data)
                    EventBus.$emit('changeProfilePicture') 
                    _.$toast.success(resp.data.message)
                })
                .catch(err => {
                    console.log(err.response)
                    _.$toast.warning(err.response.data.message)
                })
        },
        Addphoto (ev) {             
            const file = ev.target.files[0];
            this.model.photo = ev.target.files[0]; 
            this.temp_photo = URL.createObjectURL(file);     
        },
        checkPhone() {
            let _ = this
            if ( _.model.phone == 'null' ) {
                _.warningPhone = true
            } else {
                _.warningPhone = false
            }
        },
        capitalize(s) {
          if (typeof s !== 'string') return ''
          return s.charAt(0).toUpperCase() + s.slice(1)
        }
    }
}
</script>