<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
                    <button type="submit" class="btn btn-primary waves-effect waves-light" data-toggle="modal" data-target="#Modal_FilterKontrak" style="margin-left: 3px;">
                        <i class="fa fa-filter fa-l" aria-hidden="true"></i> Filter Laporan
                    </button>
                    &emsp;
                    <button v-can="'laporan/solusi/generate'" @click="cetak()" class="btn btn-outline-primary waves-effect waves-light" 
                        v-if="table.dataTable.lists.data" 
                        :disabled="table.dataTable.lists.data.length == 0">
                        <i class="fa fa-file-excel-o fa-l" aria-hidden="true"></i> Cetak Excel
                    </button>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th>Tanggal</th>
                        <th>No. Kontrak</th>
                        <th>Judul Kontrak</th>
                        <th>UPP</th>
                        <th>Nama Kendala</th>
                        <th>Nama Solusi</th>
                        <th>Uraian</th>
                        <th></th>
                    </tr>
                </thead>
			</template>          
		</List>
        <div class="modal fade text-left show" id="Modal_FilterKontrak" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Filter Kontrak</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-1" v-for="(f, idx) in filter" :key="idx">
                            <div class="col-8 col-sm-10">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="filter_by">Filter Berdasarkan</label>
                                            <select class="form-control" v-model="f.by" :disabled="f.by != '-'">
                                                <option v-for="(data, index) in filterOption" :key="index" :value="data.by">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-if="f.by == 'id_unit'">
                                        <div class="form-group">
                                            <label for="filter_by">UPP</label>                                                                                        
                                            <select class="form-control" v-model="filterby.id_unit">
                                                <option v-for="(unit, index) in optionsUnit.filter(unit => unit.id > 1)" :key="index" :value="unit.id">{{unit.name}}</option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'tgl_awal'">
                                        <div class="form-group">
                                            <label for="filter_by">Tanggal Awal</label>
                                            <div class="position-relative has-icon-left">                                                
                                                <date-picker
                                                    v-model="filterby.tgl_awal"
                                                    format="DD/MM/YYYY"
                                                    type="date"
                                                    value-type="YYYY-MM-DD"
                                                    placeholder="Tanggal awal (dd/mm/yyyy)"  
                                                    style="width: 100%"
                                                ></date-picker>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'tgl_akhir'">
                                        <div class="form-group">
                                            <label for="filter_by">Tanggal Akhir</label>
                                            <div class="position-relative has-icon-left">                                                
                                                <date-picker
                                                    v-model="filterby.tgl_akhir"
                                                    format="DD/MM/YYYY"
                                                    type="date"
                                                    value-type="YYYY-MM-DD"
                                                    placeholder="Tanggal akhir (dd/mm/yyyy)"   
                                                    style="width: 100%"
                                                ></date-picker>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else>
                                        <div class="form-group">
                                            <label for="filter_by">-</label>
                                            <input type="text" class="form-control" disabled>                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 col-sm-2 d-flex align-items-center">
                                <button class="btn-transparent" @click="addFilter()" v-if="idx == filter.length - 1">
                                    <svg class="img-fluid mr-2" width="20" height="21" viewBox="0 0 20 21">
                                        <path fill="#17536A" fill-rule="nonzero" d="M10 .134c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zm0 18.75a8.75 8.75 0 1 1 0-17.5 8.75 8.75 0 0 1 0 17.5zm3.75-9.375h-3.125V6.384a.625.625 0 0 0-1.25 0v3.125H6.25a.625.625 0 0 0 0 1.25h3.125v3.125a.625.625 0 0 0 1.25 0v-3.125h3.125a.625.625 0 0 0 0-1.25z"></path>
                                    </svg>
                                </button>
                                <button class="btn-transparent" @click="deleteFilter(idx)" v-if="filter.length > 1">
                                    <svg class="img-fluid mr-2" width="20" height="21" viewBox="0 0 20 21">
                                        <path fill="#DA3E2C" fill-rule="nonzero" d="M17.314 3.314C13.547-.726 7.219-.946 3.18 2.82c-4.04 3.767-4.26 10.094-.494 14.134 3.767 4.039 10.095 4.26 14.134.493 4.039-3.766 4.26-10.094.494-14.133zM3.6 16.1A8.75 8.75 0 1 1 16.399 4.166 8.75 8.75 0 0 1 3.601 16.101zm9.414-3.651l-2.132-2.286 2.286-2.13a.625.625 0 0 0-.853-.915L10.031 9.25 7.9 6.965a.625.625 0 0 0-.915.852l2.132 2.286-2.286 2.13a.625.625 0 0 0 .853.915l2.285-2.131 2.131 2.285a.625.625 0 0 0 .915-.852z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="reset()">Reset</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="getList()">Filter</button>
                    </div>
                </div>
            </div>
        </div> 
	</section>
</template>

<script>
import List from '@/components/List.vue';

export default {
	name: 'UserIndex',
	components: {
        List
	},
	data() {
		return {
			table: {
				title: "Generate Laporan Solusi",
				breadcumb: "Laporan",
                dataTable: {
                    lists: [],
                    columns: [
                        'col_created_at',
                        'no_kontrak_lap',
                        'judul_kontrak',
                        'unit_name',
                        'col_kendala_name',
                        'name',
                        'uraian'
                    ]
                }
            },
            model: {},
            filterby: {
                tgl_awal: '',
                tgl_akhir: '',
                id_unit: ''
            },
            filter: [
                {
                    'by': '-'
                }
            ],
            filterOption: 
            [
                {
                    'name': '-',
                    'by': '-'
                },
                {
                    'name': 'Tanggal Awal',
                    'by': 'tgl_awal'
                },
                {
                    'name': 'Tanggal Akhir',
                    'by': 'tgl_akhir'
                },
                {
                    'name': 'UPP',
                    'by': 'id_unit'
                }
            ],
            optionsUnit: [],
        }
	},
    mounted() {
        let _ = this
        _.getList()
        _.getUnit()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/kontrak/solusi/index_laporan' + '?page=' + page 
                + '&id_unit=' + _.filterby.id_unit 
                + '&date_start=' + _.filterby.tgl_awal
                + '&date_end=' + _.filterby.tgl_akhir
                ).then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },        
        cetak() {
			let _ = this
            _.axios.get('/kontrak/solusi/export-data?id_unit=' + _.filterby.id_unit + '&date_start=' + _.filterby.tgl_awal + '&date_end=' + _.filterby.tgl_akhir
                ,{responseType: 'arraybuffer'})
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'LaporanSolusi.xlsx'
                    link.click()
                })
                .catch(err => {
                    console.log(err.response)
                })
	    },
        getUnit() {
            let _ = this
            _.axios.get('/unit/all')
                .then(resp => {
                    _.optionsUnit = resp.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        reset() {
            let _ = this 
            _.filterby.tgl_awal = ''
            _.filterby.tgl_akhir = ''
            _.filterby.id_unit = ''
            _.filter = [
                {
                    'by': '-'
                }
            ]
        },
        addFilter(){
            let _ = this
            _.filter.push({
                name	: '-',
                by      : '-'
			})
        },
        deleteFilter(x) {
            let _ = this
            switch (_.filter[x].by) {
                case 'tgl_awal':
                    _.filterby.tgl_awal = ''
                    break;

                case 'tgl_akhir':
                    _.filterby.tgl_akhir = ''
                    break;

                case 'id_unit':
                    _.filterby.id_unit = ''
                    break;            
            
                default:
                    break;
            }            
            _.filter.splice(x, 1);            
        }
    }
}
</script>