<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
					<button v-can="'upload/ai_aki/upload'" type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_TambahKontraktor">
		                <i class="fa fa-upload fa-l" aria-hidden="true"></i> Upload AI & AKI
		            </button>
                    &emsp;
                    <button type="button" data-toggle="modal" data-target="#Modal_filter_progress" class="btn btn-outline-primary waves-effect waves-light">
                        Filter AI & AKI
                    </button>  
                    &nbsp;
                    <button @click="exportExcel" type="button" class="btn btn-outline-primary" style="margin-left: 4px;" data-toggle="modal" data-target="#Modal_Import">
                        <div v-if="!loadStateExport" class="mx-auto text-center" style="width:100%">
                            <i class="fa fa-spinner fa-spin"></i>
                        </div>
                        <span v-else>Cetak Excel</span>
                    </button>
				</div>
			</template>

			<template v-slot:search>
				<div class="input-group form-search-rounded">
                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari ..">
                    <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                        </div>
                    </span>
                </div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th @click="sortBy('no_prk')">Nomor PRK
                            <i class="fa sort" :class="currentSort == 'no_prk' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('program')">Program Utama/Proyek 
                            <i class="fa sort" :class="currentSort == 'program' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>

                        <th @click="sortBy('uraian_text')">Uraian
                            <i class="fa sort" :class="currentSort == 'program' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>

                        <th @click="sortBy('no_ski')">NO SKI
                            <i class="fa sort" :class="currentSort == 'program' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>

                        <th @click="sortBy('tgl')">Tanggal
                            <i class="fa sort" :class="currentSort == 'program' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>

                         <th @click="sortBy('nilai_kontrak')">Nilai <br> Anggaran/Kontrak
                            <i class="fa sort" :class="currentSort == 'program' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>

                        <th @click="sortBy('realisasi')">Realisasi <br> Pembayaran s/d {{ beforeYear }}
                            <i class="fa sort" :class="currentSort == 'program' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>

                        <th @click="sortBy('ai_thn')">AI Tahun {{ nowYear }}
                            <i class="fa sort" :class="currentSort == 'program' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>

                        <th @click="sortBy('aki_thn')">AKI Tahun {{ nowYear }}
                            <i class="fa sort" :class="currentSort == 'program' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                    </tr>
                </thead>
			</template>
		</List>
        <div class="modal fade text-left" id="Modal_filter_progress" aye="mdaman" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title">Filter</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col col-md-6">
                                <div class="form-group">
                                    <label for="">Filter Berdasarkan</label>
                                    <select class="form-control">
                                        <option :value="idx" v-for="idx of 5" :key="idx">{{ idx }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col col-md-6">
                                <div class="form-group">
                                    <label for="">-</label>
                                    <select class="form-control">
                                        <option :value="idx" v-for="idx of 4" :key="idx">{{ idx }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Reset</button>
                        <button type="button" class="btn btn-primary">
                            <span>Filter</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left" id="Modal_TambahKontraktor" tabindex="-1" role="dialog" aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Upload AI & AKI</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ValidationObserver ref="bgUangMukaForm">
                        <div class="modal-body">
                            <ValidationProvider name="Nomor SKI" v-slot="{ errors }" rules="required">
                                <div class="form-group">
                                    <label for="">Nomor SKI</label>
                                    <input type="text" v-model="model.no_ski" class="form-control" placeholder="Masukan nomor SKI" aria-describedby="helpId" required="">
                                    <small>{{ errors[0] }} </small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="Tanggal" rules="required" v-slot="{ errors }">
                                <div class="form-group"> 
                                    <label for="">Tanggal</label>
                                    <div class="position-relative has-icon-right">
                                        <date-picker
                                            v-model="model.tanggal"
                                            format="DD/MM/YYYY"
                                            type="date"
                                            :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Masukan tanggal (dd/mm/yyyy)"
                                            style="width: 100%;"
                                            >
                                        </date-picker>
                                    </div>
                                    <small>{{ errors[0] }} </small>
                                </div>
                            </ValidationProvider>
                            <div class="form-group">
                                <label for="filter_by">File AI & AKI</label>
                                <vue-dropzone ref="bgUangMukaDropzoneBG" id="ai&aki"                               
                                    :duplicateCheck="true" 
                                    @vdropzone-files-added="bgUangMukaBGFileHandler" 
                                    @vdropzone-removed-file="bgUangMukaBGDeleteFile"
                                    :options="kontraktual_dropzoneOptions">
                                </vue-dropzone>
                                <button v-if="Boolean(model.file[0])" @click="bgUangMukaBGRemoveFile" class="btn btn-danger" style="margin-top :10px;"> Reset File </button>
                                <ValidationProvider name="File" rules="required" v-slot="{ errors }">
                                    <input type="hidden" v-model="model.file">
                                    <p style="color : #EA5455;">{{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" @click="save()" class="btn btn-primary">Upload AI & AKI</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>

	</section>
</template>

<script>
// import { EventBus } from '@/plugins/eventBus.js'
import List from '@/components/List.vue'
import $ from 'jquery'
import ld from 'lodash'
// import ModalDetail from './ModalDetail.vue'
// import ModalCCTV from './ModalCCTV.vue'

export default {
	name: 'CCTVIndex',
	components: {
		List,
        // ModalDetail,
        // ModalCCTV
	},
	data() {
		return {
			table: {
				title: "AI & AKI List",
				breadcumb: "Upload AI & AKI",
                dataTable: {
                    lists: [],
                    columns: ['no_prk', 'program',
                              'uraian_text', 'no_ski','tgl', 'nilai_kontrak','nilai_realisasi', 'nilai_ai', 'nilai_aki']
                }
			},
            search: null,
            detail: [],
            model: {
               file:[]
            },
            currentSort: 'id',
            currentSortDir: 'desc',
            kontraktual_dropzoneOptions: {                
                url: this.$baseUrl + '/kontrak/progress',
                thumbnailWidth: 150,
                maxFiles: 1,
                acceptedFiles : '.xlsx,',
                autoProcessQueue: false,
                addRemoveLinks: true,
                uploadMultiple: true,
                headers: { "Authorization": "Bearer " + this.$store.state.token },
                paramName: 'image',
                maxFilesize: 10000,
                init: function(){
                    this.on("error", function(file, message) { 
                        alert(message);
                        this.removeFile(file); 
                    });
                }                            
            },
            loadStateExport: true,
            beforeYear:null,
            nowYear:null
		}
	},
    mounted() {
        let _ = this
        _.beforeYear = new Date().getFullYear();
        _.beforeYear = _.beforeYear - 1;
        _.nowYear = new Date().getFullYear();
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        bgUangMukaBGFileHandler (file){
            let _ = this
            if (file[0].size/1000000 < _.kontraktual_dropzoneOptions.maxFilesize){
                $.each(file ,function(index,val){
                    _.model.file.push(val)
                })
            }
        },
        bgUangMukaBGDeleteFile (file){
            let _ = this
            // alert("jall");
            if(file.lastModified){
                _.model.file = ld.filter(_.model.file, function(o){
                    return o.name != file.name;
                })
            }else{
                _.modal.deletedFileBg.push(file.name)
            }            
        },
        bgUangMukaBGRemoveFile() {
            let _ = this
            _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true)
            _.model.file = []
        },
        getList(page) {
            let _ = this
            _.axios.get('/v2/master-ai-aki?key=' + _.search + '&page=' + page + '&orderby=' + _.currentSort + '&sort=' + _.currentSortDir)
                .then(resp=>{
                    console.log(resp.data.data)
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
             var validation = _.$refs.bgUangMukaForm
                validation.validate().then(async success => {
                    if(!success){
                        return;
                    }
                    // console.log(_.model);
                    var formData = new FormData();
                    $.each(_.model, function(index, val) {
                        if(index == 'file'){                      
                            $.each(_.model.file, function(index, val) {
                                formData.append('file['+index+']',val)
                            })   
                        }else{
                            formData.append(index, val)
                        }
                    })
                    console.log(formData);

                     await _.axios.post('v2/master-ai-aki/import', formData)
                        .then(resp=> {
                            console.log(resp)
                            _.model = {
                                no_ski:'',
                                tanggal:'',
                                file:[],
                            },
                            validation.reset()
                            window.$("#Modal_TambahKontraktor").modal('hide')
                            _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true)
                            _.$toast.success('Data Berhasil Disimpan')
                            _.getList()
                        })
                        .catch(err=> {
                            console.log(err)
                            // _.$router.push('/kontrak/'+_.model.id)
                            _.$toast.warning('Data Master Gagal Disimpan')
                        })
                })
        },
        destroy(x) {
            let _ = this
            this.$swal.fire({
                title: 'Hapus Data Ini?',
                text: "Anda mungkin tidak akan mengembalikannya lagi!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#44A8BD',
                cancelButtonColor: '#D95E6A',
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'Batal'
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/cctv/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    this.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        sortBy(x) {
            if (x === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortDir = 'asc'
            }
            this.currentSort = x;
            this.getList()
        },

        async exportExcel () {
            this.loadStateExport = false
            try {
                const datas = (await this.axios.get('/v2/master-ai-aki/ai-upload-export', { responseType: 'blob' })).data
                const url = window.URL.createObjectURL(new Blob([datas]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Download-AI-dan-AKI-.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateExport = true
            }
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
    }
}
</script>