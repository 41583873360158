<template>

	<section>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcrumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
		<div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body card-dashboard">
                            <ValidationObserver ref="form">
                            <div class="row" v-if="role_id != 3">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Permohonan</label>
                                        <select v-model="data.id_permohonan" class="form-control">
                                            <option value="">Pilih Permohonan</option>
                                            <option v-for="(lp, i) in list_permohonan" :value="lp.id" :key="i">{{ lp.keterangan }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Upload Dokumen</label>
                                        <vue-dropzone ref="dokumenDz" id="dropzone" :duplicateCheck="true" :options="dropzoneOptions" @vdropzone-files-added="fileHandler">
                        
                                        </vue-dropzone>
                                        <br>
                                        <button v-if="Boolean(data.dokumen[0])" @click="removeFile" class="btn btn-danger"> Reset File </button>
                                        <ValidationProvider name="Dokumen" rules="required" v-slot="v">
                                            <input type="hidden" v-model="data.dokumen">
                                            <br>
                                            <small style="color : #EA5455;">{{ Boolean(data.dokumen[0])? '' : v.errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <ValidationProvider name="Deskripsi" rules="required" v-slot="{ errors }">
                                        <label for="">Keterangan</label>
                                        <textarea v-model="data.keterangan" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''"
                                            placeholder="Isi Keterangan Disini"></textarea>
                                        <small style="color : #EA5455;">{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            </ValidationObserver>
                            <div class="d-flex flex-wrap justify-content-end align-items-center">
                                <router-link :to="'/kontrak/'+$route.params.id" type="button" class="btn btn-outline-primary mr-1 btn-lg"
                                    data-dismiss="modal">
                                    Batal</router-link>
                                <a type="button" class="btn btn-primary btn-lg" v-if="role_id == 3" :disabled="isLoading"
                                    @click="permohonanSave">
                                    <i v-if="isLoading" class="fa fa-spinner fa-spin"></i> 
                                    <span v-if="!isLoading">Simpan</span>
                                </a>
                                <a type="button" class="btn btn-primary btn-lg" v-else :disabled="isLoading"
                                    @click="rekomendasiSave">
                                    <i v-if="isLoading" class="fa fa-spinner fa-spin"></i> 
                                    <span v-if="!isLoading">Simpan</span>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
import $ from 'jquery'

export default {
	name: 'UserIndex',
	data() {
		return {
			role_id : localStorage.getItem('role_id'),
            title: "Detail Kontrak",
            model : {},
            breadcrumb : [],
            optionsPekerjaan: [],
            optionsUnit: [],
            data : {
                dokumen : [],
                keterangan : '',
                id_permohonan: ''
            },
            list_permohonan: [],
            dropzoneOptions: {
              url: 'http://localhost:8000/api/kontrak',
              acceptedFiles : '.doc,.docx,application/msword,.pdf',
              thumbnailWidth: 150,
              autoProcessQueue: false,
              uploadMultiple: true,
              paramName: 'dokumen'
            },
            isLoading: false
		}
	},
    mounted() {
        let _ = this
        _.get()
        _.getPermohonanList()
    },
    methods: {
        
        async get() {
            let _ = this
             await _.axios.get('/kontrak?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    _.breadcrumb = [
                        {
                            name: 'Detail Kontrak', 
                            link: '/kontrak/'+_.$route.params.id,
                            active: false
                        },   
                        {
                            name: _.model.no_kontrak, 
                            link: '/kontrak/'+_.$route.params.id,
                            active: false
                        }, 
                        {
                            name: 'Upload Dokumen '+(_.role_id == 3? 'Permohonan' : 'Rekomendasi'), 
                            link: '',
                            active: true
                        },           
                    ]
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        fileHandler (file){
            let _ = this
            $.each(file ,function(index,val){
                _.data.dokumen.push(val)
            })
        },
        removeFile() {
            let _ = this
            _.$refs.dokumenDz.removeAllFiles(true)
            _.data.dokumen = []
        },
        async permohonanSave(){
            let _ = this
            
            var validation = _.$refs.form
                validation.validate().then(async success => {
                    if(!success){
                        return;
                    }
                    _.isLoading = true
                    var formData = new FormData();
                    formData.append('id_kontrak', _.$route.params.id)
                    $.each(_.data, function(index, val) {
                        if(index != 'dokumen'){
                            formData.append(index, val)
                        }
                        else{
                            $.each(_.data.dokumen, function(index, val) {
                                formData.append('dokumen['+index+']',val)
                            })
                        }
                    })

                    await _.axios.post('/kontrak/permohonan', formData,{
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                        .then(resp=> {
                            console.log(resp)
                           _.data = {
                                keterangan : "",
                                dokumen : []
                            }
                            _.removeFile()
                            validation.reset()
                            _.$router.push('/kontrak/'+_.model.id)
                            _.$toast.success('Dokumen Permohonan Disimpan')
                            _.get()
                        })
                        .catch(err=> {
                            console.log(err)
                            _.$router.push('/kontrak/'+_.model.id)
                            _.$toast.warning('Dokumen Permohonan Gagal Disimpan')
                        })
                    _.isLoading = false
                })
           
        },
        async rekomendasiSave(){
            let _ = this
            
            var validation = _.$refs.form
                validation.validate().then(async success => {
                    if(!success){
                        return;
                    }
                    _.isLoading = true
                    var formData = new FormData();
                    formData.append('id_kontrak', _.$route.params.id)
                    $.each(_.data, function(index, val) {
                        if(index != 'dokumen'){
                            formData.append(index, val)
                        }
                        else{
                            $.each(_.data.dokumen, function(index, val) {
                                formData.append('dokumen['+index+']',val)
                            })
                        }
                    })

                    await _.axios.post('/kontrak/rekomendasi', formData,{
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                        .then(resp=> {
                            console.log(resp)
                           _.data = {
                                keterangan : "",
                                dokumen : []
                            }
                            _.removeFile()
                            validation.reset()
                            _.$router.push('/kontrak/'+_.model.id)
                            _.$toast.success('Dokumen Rekomendasi Berhasil Disimpan')
                            _.get()
                        })
                        .catch(err=> {
                            console.log(err)
                            _.$router.push('/kontrak/'+_.model.id)
                            _.$toast.warning('Dokumen Rekomendasi Gagal Disimpan')
                        })
                    _.isLoading = false
                })
           
        },

        getPermohonanList() {
            let _ = this
            _.axios.get( '/kontrak/permohonan?id_kontrak=' + _.$route.params.id )
                .then(resp => {
                    _.list_permohonan = resp.data.data.rows.filter(p => { return !p.id_rekomendasi })
                })
        }
    },
    watch: {
       
    }
}
</script>