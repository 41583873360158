<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
					<button v-can="'cctv/create'" type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_TambahBidang">
		                <i class="fa fa-plus fa-l" aria-hidden="true"></i> Tambah CCTV
		            </button>
				</div>
			</template>

			<template v-slot:search>
				<div class="input-group form-search-rounded">
                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari cctv disini..">
                    <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                        </div>
                    </span>
                </div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th @click="sortBy('name')">Nama CCTV
                            <i class="fa sort" :class="currentSort == 'name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('address')">Lokasi 
                            <i class="fa sort" :class="currentSort == 'address' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button v-can="'cctv/detail'" type="button" @click="toDetail(data)" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                        <i class="fa fa-eye"></i>
                    </button>
                    <router-link v-can="'cctv/update'" :to="'cctv/edit/' + data.id" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                        <i class="fa fa-pencil"></i>
                    </router-link>
                    <button v-can="'cctv/delete'" type="button" @click="destroy(data)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>

        <!-- Modal -->
        <div class="modal fade text-left" id="Modal_TambahBidang" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Tambah CCTV</h5>
                        <button type="button" ref="btnModalClose" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ValidationObserver v-slot="{ invalid }" ref="observer">
                        <div class="modal-body">
                            <ValidationProvider name="model.name" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Nama CCTV</label>
                                    <input type="text" v-model="model.name" class="form-control" placeholder="Masukan nama cctv" aria-describedby="helpId" required="">
                                </div>
                            </ValidationProvider>

                            <ValidationProvider name="model.lokasi" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Lokasi CCTV</label>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" v-model="lokasiInp[0].latlng" placeholder="Lokasi cctv (Latitude,Longitude)">
                                            <input type="hidden" v-model="model.lokasi" class="form-control" placeholder="Masukan lokasi cctv" aria-describedby="helpId" required="">  
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:;" @click="addMarker(0)"
                                                class="btn w-100 waves-effect waves-light" :class="(!lokasiInp[0].pinned)? 'btn-success-trans': 'btn-warning-trans'">
                                                <span v-if="!lokasiInp[0].pinned">
                                                    Pin Lokasi
                                                </span>
                                                <span v-else><i class="fa fa-times"></i> Batal </span>
                                            </a>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="w-100" id="map" ref="map" style="height:300px"></div>
                                </div>
                            </ValidationProvider>

                            <div class="form-group">
                                <label for="">URL CCTV</label>
                                <input type="text" v-model="model.url" class="form-control" placeholder="Masukan url cctv" aria-describedby="helpId" required="">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" :disabled="invalid" @click="save()" class="btn btn-primary">Tambah CCTV</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>

        <!-- <ModalDetail v-if="detail != null" :detail="detail"/> -->
        <ModalCCTV />
	</section>
</template>

<script>
import { EventBus } from '@/plugins/eventBus.js'
import List from '@/components/List.vue'
// import ModalDetail from './ModalDetail.vue'
import ModalCCTV from './ModalCCTV.vue'

export default {
	name: 'CCTVIndex',
	components: {
		List,
        // ModalDetail,
        ModalCCTV
	},
	data() {
		return {
			table: {
				title: "CCTV",
				breadcumb: "",
                dataTable: {
                    lists: [],
                    columns: ['name', 'address']
                }
			},
            search: null,
            detail: [],
            model: {
                name: '',
                lokasi: [],
                url: ''
            },
            currentSort: 'id',
            currentSortDir: 'desc',
            map: '',
            marker : [],
            lokasiInp: [{
                    latlng : '',
                    pinned : false
            }]
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.initMap()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/cctv?key=' + _.search + '&page=' + page + '&orderby=' + _.currentSort + '&sort=' + _.currentSortDir)
                .then(resp=>{
                    console.log(resp.data.data)
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this

            if ( _.model.lokasi.length == 0 ) {
                _.$toast.warning('Pin lokasi terlebih dahulu')
                return;
            }

            _.axios.post('/cctv', _.model)
                .then(resp => {
                    if (resp.status) {
                        _.getList()
                        _.$toast.success('Data Berhasil Disimpan')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Disimpan')
                    }
                    _.model = {
                        status: false,
                        name: null
                    }
                    _.cancelPin(0)
                    _.lokasiInp[0].latlng = ""
                    _.$refs.observer.reset();
                    _.$refs.btnModalClose.click();
                })
                .catch(err=>{
                    console.log(err.response)
                    _.model = {
                        status: false,
                        name: null
                    }
                    _.$refs.observer.reset();
                    _.$toast.warning('Data CCTV Sudah Digunakan')
                    _.$refs.btnModalClose.click();
                })
        },
        destroy(x) {
            let _ = this
            this.$swal.fire({
                title: 'Hapus Data Ini?',
                text: "Anda mungkin tidak akan mengembalikannya lagi!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#44A8BD',
                cancelButtonColor: '#D95E6A',
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'Batal'
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/cctv/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    this.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        sortBy(x) {
            if (x === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortDir = 'asc'
            }
            this.currentSort = x;
            this.getList()
        },
        initMap() {
            let _ = this
            var myLatlng = {lat: -6.409493537558435, lng: 106.79552258660475}

            _.map = new window.google.maps.Map(
               _.$refs["map"], {zoom: 12, center: myLatlng})
        },
        async addMarker(index) {
            let _ = this
            var mylatlng = {lat : parseFloat(_.lokasiInp[index].latlng.split(",")[0]), lng : parseFloat(_.lokasiInp[index].latlng.split(",")[1])}
            if(!_.lokasiInp[index].pinned){
                var geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({
                        'latLng': mylatlng
                    }, function(results, status) {
                        if (status == window.google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                let mark = new window.google.maps.Marker({
                                                map : _.map,
                                                animation: window.google.maps.Animation.DROP,
                                                position: {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                                            });
                                var infoWindow = new window.google.maps.InfoWindow({
                                    content: results[0].formatted_address,
                                });
                                _.marker['mark'+index] = mark
                                infoWindow.open(_.map, _.marker['mark'+index]);

                                _.model.lokasi[0] = {
                                    address: results[0].formatted_address,
                                    gmap_url : 'https://www.google.co.id/maps/place/'+results[0].formatted_address.replace(/,/g,"").replace(/ /g,"+"),
                                    lat : results[0].geometry.location.lat(),
                                    lng : results[0].geometry.location.lng()
                                }
                                _.lokasiInp[index].pinned = true
                            }
                        }
                    });
           
            }
            else{
                _.cancelPin(index)
            }
            
        },
        cancelPin(ix) {
            let _ = this
            _.model.lokasi = []
            _.marker['mark'+ix].setMap(null)
           delete _.marker['mark'+ix]
            _.lokasiInp[ix].latlng = ""
            _.lokasiInp[ix].pinned = false
        },
        toDetail(data) {
            let _ = this
            _.detail = data
            window.location = 'cctv/detail/' + data.id + '?url=' + data.url
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        detail: function(newValue) {
            if ( newValue ) {
                EventBus.$emit('detailCCTV', newValue);
            }
        }
    }
}
</script>