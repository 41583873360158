<template>
	<div class="row">
        <div class="col-12 col-lg-6 col-xl-7 d-none d-lg-block">
            <div class="full-img">
                <img src="/assets/img/gistet-tambun.jpg" class="img-fluid" alt="">
                <div class="logo-block">
                    <img src="/assets/img/logo-text-white-min.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>

        <ValidationObserver v-slot="{ invalid }" class="col-12 col-md-8 col-lg-6 col-xl-5 ml-auto mr-auto">
            <div class="form-auth-block">
                <div class="form-logo d-block d-lg-none">
                    <img src="/assets/img/logo.png" class="img-fluid" alt="">
                    <div class="sub-logo">Monitoring <span>Digital</span></div>
                </div>
                <form @submit.prevent="login">
                    <h4 class="title-heading-form text-primary">Selamat Datang</h4>    
                    <p class="subtitle-form">Masukan alamat email dan password untuk Login</p>

                    <ValidationProvider name="email" rules="required|email">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }" slot-scope="{ errors }">
                            <input type="email" v-model="email" class="form-control form-custom-bg" id="user-email" placeholder="Email" required="">
                            <small v-if="errors[0]" id="" class="text-muted">Harap masukan email</small>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider name="password" rules="required">
                        <div class="input-group form-group" :class="{ 'validation-warning': errors[0] }" slot-scope="{ errors }">
                            <input :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control py-2 border-right-0 border form-custom-bg" id="user-password" placeholder="Password" required="">
                            <span class="input-group-append">
                                <div class="input-group-text form-custom-bg">
                                    <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue">
                                        <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span>
                            <div class="invalid-feedback" style="display: block !important">
                              <small v-if="errors[0]" id="" class="text-muted">Harap masukan password</small>
                              <small :class="{ 'val-warning': authFalse }" v-if="email.length > 0 && password.length > 0 && authFalse" id="" class="text-muted">Email/password salah</small>
                            </div>
                        </div>
                    </ValidationProvider>

                    <button type="submit" :disabled="invalid" class="btn btn-primary waves-effect waves-light w-100 mt-2">Login</button>

                    <p class="font-600 mt-2 text-center">
                        <router-link to="/lupa-password" class="text-primary">Lupa Password?</router-link>
                    </p>
                </form>
            </div>
        </ValidationObserver>
    </div>
</template>

<style scoped>
    .val-warning {
        font-family: "Myriad Pro";
        font-size: 14px;
        font-weight: 300;
        color: #ed1c24 !important;
    }
</style>

<script>
export default {
	name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            authFalse: false,
            showPassword: false
        }
    },
	methods: {
		login() {
            let _ = this
			_.axios.post('/auth/login', { email: _.email, password: _.password})
				.then((resp )=>{
					_.$store.dispatch('login', resp.data)
                    
                    if ( resp.data.success ) {
                        if ( resp.data.user.status == 1 ) {
                            if (localStorage.getItem('role_id') == 1 || localStorage.getItem('role_id') == 3){
                                window.location = '/kontrak'
                            } else {
                                window.location = '/'
                            }   
                        } else {
                            this.$toast.warning('Pengguna Tidak Aktif')
                        }
                    } else {
                        _.authFalse = true
                    }
				})
				.catch(err=>{
                    console.log(err.message)
                    _.authFalse = true
				})
		}
	}
}
</script>