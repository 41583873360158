<template>
  <div class="row">
        <div class="col-md-12">
            <section>
                <List
                :title="table.title"
                :breadcumb="table.breadcumb"
                :show-pagination="false"
                maxHeight="none"
                :dataTable="table.dataTable">

                    <template v-slot:button>
                        <div>
                            <router-link to="/kontrak-mtu/manage">
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_Tambah_Bank" v-can="'kontrak_mtu/create'">
                                    Tambah Kontrak MTU
                                </button>
                            </router-link>
                            <button type="button" data-toggle="modal" data-target="#Modal_Filter" class="btn btn-outline-primary" style="margin-left: 4px;">
                                Filter Kontrak
                            </button>
                            <button @click="exportExcel" type="button" class="btn btn-outline-primary" style="margin-left: 4px;" data-toggle="modal" data-target="#Modal_Import" v-can="'kontraktor/create'">
                                <div v-if="!loadStateExport" class="mx-auto text-center" style="width:100%">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>
                                <span v-else>Cetak Excel</span>
                            </button>
                        </div>
                    </template>

                    <template v-slot:search>
                        <div class="input-group form-search-rounded">
                            <input v-model="payloadList.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari..">
                            <span class="input-group-append">
                                <div class="input-group-text bg-transparent">
                                    <a class="text-blue"><i class="fa fa-search"></i></a>
                                </div>
                            </span>
                        </div>
                    </template>

                    <template v-slot:action="{data}">
                        <div class="d-flex align-items-center" style="gap: 10px">
                            <router-link :to="'kontrak-mtu/detail/' + data.id" class="btn btn-icon-small btn-success-trans waves-effect waves-light" v-can="'kontrak_mtu/detail'">
                                <i class="fa fa-eye"></i>
                            </router-link>
                            <router-link :to="'/kontrak-mtu/manage?edit='+data.id" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;" v-can="'kontrak_mtu/update'">
                                <i class="fa fa-pencil"></i>
                            </router-link>
                            <button type="button" @click="destroy(data.id)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;" v-can="'kontrak_mtu/delete'">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </template>

                    <template v-slot:columnHeader>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th @click="sortBy('judul_kontrak')">Judul Kontrak
                                    <i class="fa sort" :class="currentSort == 'judul_kontrak' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                </th>
                                <th @click="sortBy('no_kontrak')">No. Kontrak
                                    <i class="fa sort" :class="currentSort == 'no_kontrak' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                </th>
                                <th @click="sortBy('jenis_kontrak')">Jenis Kontrak
                                    <i class="fa sort" :class="currentSort == 'jenis_kontrak' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                </th>
                                <th @click="sortBy('status')">Status Kontrak
                                    <i class="fa sort" :class="currentSort == 'status' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                </th>
                                <th @click="sortBy('col_status')">Status
                                    <i class="fa sort" :class="currentSort == 'col_status' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                </th>
                                <th @click="sortBy('progress_bayar')">Progress Bayar Overall
                                    <i class="fa sort" :class="currentSort == 'progress_bayar' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                </th>
                                <th>Action
                                </th>
                            </tr>
                        </thead>
                    </template>
                </List>
            </section>
        </div>
        <div 
            class="modal fade text-left" 
            id="Modal_Filter" 
            aye="mdaman" 
            tabindex="-1" 
            role="dialog"
            aria-labelledby="myModalLabel160" 
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title">Filter</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <ValidationProvider name="Jenis Kontrak" rules="" v-slot="{ errors }">
                                            <label for="">Jenis Kontrak</label>
                                            <select v-model="payloadList.jenis_kontrak" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                <option :value="null">Pilih Jenis Kontrak</option>
                                                <option v-for="(opt, idx) in jenisPekerjaan" :key="idx" :value="opt.value">{{ opt.text }}</option>
                                            </select>
                                            <small>{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <ValidationProvider name="Status Kontrak" rules="" v-slot="{ errors }">
                                            <label for="">Status Kontrak</label>
                                            <select v-model="payloadList.status" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                <option :value="null">Pilih Status Kontrak</option>
                                                <option v-for="(opt, idx) in typeStatus" :key="idx" :value="opt.value">{{ opt.text }}</option>
                                            </select>
                                            <small>{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="reset" @click="resetFilter()" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                            <button v-if="submitFilterState" @click="loadList()" type="button" :disabled="invalid" class="btn btn-primary">
                                <span>Simpan</span>
                            </button>
                            <button v-else type="button" disabled class="btn btn-primary">
                                <div class="mx-auto text-center" style="width:100%">
                                    <i class="fa fa-spinner fa-spin text-white"></i>
                                </div>
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import List from '@/components/List.vue'
import ld from 'lodash'
// import ModalImport from '@/components/ModalImport.vue'

export default {
    name: 'KontrakMTULists',
    components: {
      List,
    //   ModalImport
    },
    data () {
      return {
        table: {
            title: "Project List",
            breadcumb: "Monitoring Kontrak MTU",
            dataTable: {
                lists: [],
                columns: ['judul_kontrak', 'no_kontrak_mtu', 'jenis_kontrak','status', 'col_status', 'progress_bayar']
            }
        },
        payloadList: {
            search: null,
            jenis_kontrak: null,
            status: null
        },
        jenisPekerjaan : [
            {
                text: 'GIS',
                value: 'GIS'
            },
            {
                text: 'Trafo',
                value: 'Trafo'
            },
            {
                text: 'AIS',
                value: 'AIS'
            },
            {
                text: 'Konduktor',
                value: 'Konduktor'
            },
            {
                text: 'Tower',
                value: 'Tower'
            },
        ],
        typeStatus : [
            {
                text: 'On Going',
                value: 0
            },
            {
                text: 'ST 1',
                value: 1
            },
            {
                text: 'ST 2',
                value: 2
            },
        ],
        loadStateExport: true,
        submitFilterState: true,
        isEdit: false,
        idActive: null,
        search: null,
        detail: [],
        model: {
            name: ''
        },
        currentSort: 'id',
        currentSortDir: 'desc'
      }
    },
    watch: {
        'payloadList.search': ld.debounce(function() {
            this.loadList ()
        }, 300)
    },
    mounted () {
      this.loadList ()
    },
    methods : {
      async loadList () {
        try {
            const datas = (await this.axios.get('/v2/kontrak-mtu', { params: this.payloadList })).data
            this.table.dataTable.lists = {
                data : datas.data.rows.map((e) => {
                    e.no_kontrak_mtu = e.no_kontrak
                    e.progress_fisik = `${e.progress_fisik} %`
                    e.progress_bayar = `${e.progress_bayar} %`
                    return e
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.model.name = ''
            window.$('#Modal_Filter').modal('hide')
        }
      },
      async exportExcel () {
            this.loadStateExport = false
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/excel', { responseType: 'blob' })).data
                const url = window.URL.createObjectURL(new Blob([datas]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', new Date().getMilliseconds() +'-monitoring-kontrak-mtu.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateExport = true
            }
        },
        destroy (id) {
            this.$swal.fire({
                title: 'Hapus Data Ini?',
                text: "Anda mungkin tidak akan mengembalikannya lagi!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#44A8BD',
                cancelButtonColor: '#D95E6A',
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'Batal'
                }).then((result) => {
                    if (result.value) {
                        this.axios.delete('/v2/kontrak-mtu/'+id)
                        .then((res) => {
                            if (res.status) {
                                this.loadList ()
                                this.$swal.fire({
                                    title: 'Terhapus!',
                                    text: "Data berhasil dihapus",
                                    icon: 'success',
                                    confirmButtonColor: '#37BA71',
                                    confirmButtonText: 'OK'
                                })
                            } else {
                                this.$swal.fire({
                                    title: 'Failed!',
                                    text: "Data Tidak dihapus",
                                    icon: 'warning',
                                    confirmButtonColor: '#37BA71',
                                    confirmButtonText: 'OK'
                                })
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            this.$swal.fire({
                                title: 'Failed!',
                                text: "Data Tidak Terhapus",
                                icon: 'warning',
                                confirmButtonColor: 'red',
                                confirmButtonText: 'OK'
                            })
                        })
                    }
                })
        },
        resetFilter () {
            for (const [key] of Object.entries(this.payloadList)) {
                this.payloadList[key] = null
            }
            this.loadList ()
        },
        sortBy(x) {
            let _ = this
            if (x === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortDir = 'asc'
            }
            this.currentSort = x;

            let sorted = []
            if (_.currentSortDir == 'asc'){
                sorted = _.table.dataTable.lists.data.sort(function(a,b){
                    {
                        if (_.currentSort == 'col_kontrak_progress_num'){
                            return parseFloat(a[_.currentSort]) - parseFloat(b[_.currentSort])
                        } else {
                            if(a[_.currentSort] < b[_.currentSort]) { return -1; }
                            if(a[_.currentSort] > b[_.currentSort]) { return 1; }
                            return 0;
                        }
                    } 
                })
            } else {
                sorted = _.table.dataTable.lists.data.sort(function(a,b){
                    {
                        if (_.currentSort == 'col_kontrak_progress_num'){
                            return parseFloat(b[_.currentSort]) - parseFloat(a[_.currentSort])
                        } else {
                            if(a[_.currentSort] > b[_.currentSort]) { return -1; }
                            if(b[_.currentSort] > a[_.currentSort]) { return 1; }
                            return 0;
                        }
                    } 
                })
            }

            _.table.dataTable.lists.data = sorted
        },
    }
}
</script>

<style>

</style>