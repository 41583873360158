<template>
    <div class="modal fade text-left" id="Modal_LihatCCTV" tabindex="-1" role="dialog" aria-labelledby="myModalLabel160"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">Live CCTV</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <br>
                    <vplayer :playerOptions = "vOption"></vplayer>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { vplayer } from 'vue-hls-player'
	export default {
        name: 'ModalCCTV',
        components: {
            vplayer
        },
        data() {
            return {
                vOption: {
                    type: "application/x-mpegURL",
                    src: "http://45.118.114.26:80/camera/Ruaspramukacihapit.m3u8",
                    controls: ''
                }
            }
        }
	}
</script>