<template>
  <div>
    <section class="d-block" name="DokumenLainLain" v-can="'kontrak/detail/dokumenLainLain/read'">
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-sm-8 col-lg-6 mb-1">
          <h4 class="card-title mb-0">
            Dokumen Lain-Lain
            <button
              type="button"
              @click="showModalDokumenLainLain('add')"
              class="btn btn-primary btn-block-mobile"
              v-can="'kontrak/detail/dokumenLainLain/create'">
              Tambah Dokumen Lain-Lain
            </button>
          </h4>
        </div>

        <div class="col-12 col-sm-4 mb-1">
          <div class="input-group form-search-rounded">
            <input
              class="form-control py-2 border-right-0 border"
              type="search"
              v-model="dokumenLainLainQuery"
              placeholder="Cari dokumen lain lain.."
            />
            <span class="input-group-append">
              <div class="input-group-text bg-transparent">
                <a href="#" class="text-blue"
                  ><i class="fa fa-search"></i
                ></a>
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="table-responsive no-sort w-100">
        <table class="table tabel-status-document w-100">
          <thead>
            <tr>
              <th>Nama</th>
              <th><i class="feather icon-download-cloud fa-l"></i> File</th>
              <th>Tanggal</th>
              <th class="w10">Aksi</th>
            </tr>
          </thead>
          <tbody v-if="dokumenLainLainDatas.length > 0">
            <tr v-for="data in dokumenLainLainDatas" :key="data.id">
              <td>{{ data.name }}</td>
              <td>
                <div v-if="data.files == null">-</div>
                <div v-else>
                  <p
                    v-for="(item, index) in data.files"
                    :key="item.name"
                  >
                    <a @click="pdfViewer(item.url)" href="javascript:;"
                      >• File {{ index + 1 }} [Lihat Detail]</a
                    >
                  </p>
                </div>
              </td>
              <td>{{ data.col_date }}</td>
              <td>
                <div class="btn-action-block">
                  <button
                    type="button"
                    class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                    @click="dokumenLainLainDetail = data, showModalDokumenLainLain('edit');"
                    v-can="'kontrak/detail/dokumenLainLain/update'">
                    <i class="fa fa-pencil"></i>
                  </button>
                  &emsp;
                  <button
                    type="button"
                    class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                    @click="destroyDokumenLainLain(data.id)"
                    v-can="'kontrak/detail/dokumenLainLain/delete'">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6" class="text-center">Tidak ada data.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr class="my-2" />
    </section>

    <div
      class="modal fade text-left"
      id="modalDokumenLainLain"
      role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">
              {{ dokumenLainLainDetail.id ? 'Edit' : 'Tambah' }} Dokumen Lain-Lain
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="dokumenLainLainFormObserver">
              <div class="form-group">
                <ValidationProvider
                  name="Nama"
                  rules="required"
                  v-slot="{ errors }">
                  <label for="">Nama</label>
                  <input
                    type="text"
                    v-model="dokumenLainLainForm.name"
                    class="form-control"
                    placeholder="Masukan nama"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    aria-describedby="helpId"
                  />
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }">
                  <label for="">Tanggal</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="dokumenLainLainForm.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukkan tanggal"
                      style="width: 100%" />
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="filter_by">Upload File</label>
                <vue-dropzone
                  ref="dokumenLainLainDropzone"
                  id="dokumenLainLainDropzone"
                  :duplicateCheck="true"
                  @vdropzone-files-added="onDokumenLainLainFilesAdded"
                  @vdropzone-removed-file="onDokumenLainLainFilesRemoved"
                  :options="dokumenLainLainDropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="dokumenLainLainDetail.files.length > 0"
                  @click="removeAllDokumenLainLainFile"
                  class="btn btn-danger"
                  style="margin-top: 10px">
                  Reset File
                </button>
                <ValidationProvider name="Dokumen" v-slot="{ errors }">
                  <input type="hidden" v-model="dokumenLainLainForm.files" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal">
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDokumenLainLain()"
              :disabled="loading">
              <i v-if="loading" class="fa fa-spinner fa-spin"></i>
              <span v-if="!loading">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import ld from "lodash";

export default {
  data() {
    return {
      dokumenLainLains: [],
      dokumenLainLainDetail: {
        id_kontrak: this.$route.params.id,
        name: '',
        date: '',
        files: [],
        deletedFile: [],
      },
      dokumenLainLainQuery: "",
      dokumenLainLainForm: {
        name: '',
        date: '',
        files: []
      },
      dokumenLainLainDropzoneOptions: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: ".doc,.docx,application/msword,.pdf",
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        headers: { 
          Authorization: "Bearer " + this.$store.state.token 
        },
        paramName: "image",
        maxFilesize: 10000,
        init: function () {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
      },
      loading: false,
    }
  },
  computed: {
    dokumenLainLainDatas: function() {
      let _ = this
      return _.dokumenLainLains.filter(function( x ) {
        return x.name.toLowerCase().indexOf(_.dokumenLainLainQuery.toLowerCase()) >= 0
      });
    }
  },
  methods: {
    getDokumenLainLain() {
      let _ = this
      _.axios.get("v2/document-others?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.dokumenLainLains = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    showModalDokumenLainLain(type) {
      let _ = this;
      _.removeAllDokumenLainLainFile();
      if (type == "edit") {
        _.dokumenLainLainForm = {
          id: _.dokumenLainLainDetail.id,
          id_kontrak: _.dokumenLainLainDetail.id_kontrak,
          name: _.dokumenLainLainDetail.name,
          date: _.dokumenLainLainDetail.date,
          files: [],
          deletedFile: [],
        };
        if (_.dokumenLainLainDetail.files) {
          _.dokumenLainLainDetail.files.forEach(function (el) {
            let file = { size: 10240, name: el.name, type: "application/word" };
            let url = el.url;
            _.$refs.dokumenLainLainDropzone.manuallyAddFile(file, url);
          });
        }
      } else if (type == "add") {
        _.resetDokumenLainLainForm()
        _.dokumenLainLainDetail = {
          id_kontrak: this.$route.params.id,
          name: '',
          date: '',
          files: [],
          deletedFile: [],
        }
      }
      window.$("#modalDokumenLainLain").modal("show");
    },
    destroyDokumenLainLain(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios.delete("v2/document-others/" + x)
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.getDokumenLainLain()
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },
    onDokumenLainLainFilesAdded(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.dokumenLainLainDropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.dokumenLainLainForm.files.push(val);
        });
      }
    },
    onDokumenLainLainFilesRemoved(file) {
      let _ = this;
      if (file.lastModified) {
        _.dokumenLainLainForm.files = ld.filter(
          _.dokumenLainLainForm.files,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.dokumenLainLainForm.deletedFile.push(file.name);
      }
    },
    removeAllDokumenLainLainFile() {
      let _ = this;
      _.$refs.dokumenLainLainDropzone.removeAllFiles(true);
      _.dokumenLainLainForm.files = [];
    },
    resetDokumenLainLainForm() {
      this.dokumenLainLainForm = {
        id_kontrak: this.$route.params.id,
        name: '',
        date: '',
        files: [],
        deletedFile: [],
      }
    },
    saveDokumenLainLain() {
      let _ = this;
      var validation = _.$refs.dokumenLainLainFormObserver;
      validation.validate()
        .then(async (success) => {
          if (!success) {
            return;
          }
          
          var formData = new FormData();
          $.each(_.dokumenLainLainForm, function (index, val) {
            if (index == "files") {
              $.each(_.dokumenLainLainForm.files, function (index, val) {
                formData.append("files[" + index + "]", val);
              });
            } else if (index == "deletedFile") {
              $.each(_.dokumenLainLainForm.deletedFile, function (index, val) {
                formData.append("deletedFile[" + index + "]", val);
              });
            } else {
              formData.append(index, val);
            }
          });

          _.loading = true;

          await _.axios.post("v2/document-others" + (_.dokumenLainLainForm.id ? '/' + _.dokumenLainLainForm.id : ''), formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(() => {
              _.$toast.success("Dokumen Lain-Lain Berhasil" + (_.dokumenLainLainForm.id ? ' Diubah' : ' Ditambahkan'))
              _.resetDokumenLainLainForm()
              _.removeAllDokumenLainLainFile();
              validation.reset();
              window.$("#modalDokumenLainLain").modal("hide")
              _.getDokumenLainLain()
            })
            .catch((err) => {
              console.log(err);
              window.$("#modalDokumenLainLain").modal("hide")
              _.$toast.warning("Dokumen Lain-Lain Gagal" + (_.dokumenLainLainForm.id ? ' Diubah' : ' Ditambahkan'));
            });
          _.loading = false;
        });
    },
    pdfViewer(file) {
      this.$emit('showDetail', file);
    },
  },
  mounted() {
    this.getDokumenLainLain()
  }
}
</script>