<template>
  <div>
    <div class="content-header">
      <div class="content-header-left mb-2">
        <div class="breadcrumbs-top">
          <h2 class="content-header-title mb-0">Detail Kontrak</h2>
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="role_id == 2 ? '/' : '/kontrak-mtu'">
                  <i class="feather icon-home"></i>
                </router-link>
              </li>

              <li
                class="breadcrumb-item"
                :class="data.active == true ? 'active' : ''"
                style="text-transform: capitalize"
                v-for="(data, index) in breadcumb"
                :key="index"
              >
                <router-link :to="data.link">{{ data.name }}</router-link>
              </li>
            </ol>
          </div>
        </div>
        <div class="d-flex mt-2" style="gap: 10px">
          <router-link
            :to="'/kontrak/' + $route.params.id"
            style="font-size: 20px"
          >
            <i class="feather icon-arrow-left"></i>
          </router-link>
          <h2 class="content-header-title mb-0">Tambah Progress</h2>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="basic-datatable">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body card-dashboard">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h5 class="m-0"><strong>Detail Progress</strong></h5>
                    <div class="d-flex align-items-center" style="gap: 10px">
                      <!-- <h5 class="m-0 text-blue"><strong>Buat baru</strong></h5> -->
                      <!-- <button type="button" data-toggle="modal" data-target="#Modal_Kendala" class="btn btn-primary waves-effect waves-light btn-icon-small float-right">
                                        <i class="fa fa-plus"></i>
                                    </button> -->
                    </div>
                  </div>
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                      <div class="row mt-2">
                        <div class="col-12 col-md-6">
                          <div class="form-group">
                            <ValidationProvider
                              name="Periode Progress"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <label for="">Periode Progress</label>
                              <input
                                v-model="model.name"
                                type="text"
                                disabled
                                class="form-control text-capitalize"
                                :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                              />
                              <small>{{ errors[0] }}</small>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="form-group">
                            <ValidationProvider
                              name="Jumlah Pekerja"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <label for="">Jumlah Pekerja </label>
                              <input
                                v-model="model.workers"
                                type="number"
                                class="form-control"
                                :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                              />
                              <small>{{ errors[0] }}</small>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="form-group">
                            <label for="">Foto</label>
                            <vue-dropzone
                              ref="myVueDropzone"
                              id="dropzone"
                              :duplicateCheck="true"
                              @vdropzone-files-added="DocumentFileHandler"
                              @vdropzone-removed-file="DocumentDeleteFile"
                              :options="kontraktual_dropzoneOptions"
                            ></vue-dropzone>
                            <button
                              v-if="Boolean(model.document > 0)"
                              @click="DocumentRemoveFile"
                              class="btn btn-danger"
                              style="margin-top: 10px"
                            >
                              Reset File
                            </button>
                            <ValidationProvider
                              name="Document"
                              rules=""
                              v-slot="{ errors }"
                            >
                              <input type="hidden" v-model="model.document" />
                              <p style="color: #ea5455">{{ errors[0] }}</p>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <ValidationProvider
                            name="Uraian"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="uraian">Uraian atau Deskripsi Singkat Pekerjaan</label>
                              <!-- <textarea
                                v-model="model.description"
                                class="form-control"
                                id="uraian"
                                cols="30"
                                rows="5"
                              ></textarea> -->
                              <quill-editor v-model="model.description" :options="editorOption"/>
                              <small>{{ errors[0] }}</small>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-12 mb-2">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <h5 class="m-0">
                              <strong>Detail Progress</strong>
                            </h5>
                          </div>
                        </div>

                        <!-- <div class="col-12">
                                            <table-v2 :options="tableUpdateProgress" :no-number="true">
                                                <template #header>
                                                    <thead>
                                                        <tr>
                                                            <th>Unit Pekerjaan</th>
                                                            <th>Satuan</th>
                                                            <th>Qty Kontrak</th>
                                                            <th>Bobot (%)</th>
                                                            <th>Kumulatif Quantitiy<br>Sampai Saat Ini</th>
                                                            <th>Progress (%)</th>
                                                            <th>Tingkat<br>Penyelesaian (%)</th>
                                                        </tr>
                                                    </thead>
                                                </template>
                                                <template #customeColSpan>
                                                    <tr style="background: #EAF5F8 !important">
                                                        <td colspan="5">
                                                            <span style="font-size: 15px" class="text-blue"><strong>Grand Total</strong></span>
                                                        </td>
                                                        <td colspan="2">
                                                            <span style="font-size: 15px" class="text-blue"><strong>56%</strong></span>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </table-v2>
                                        </div> -->

                        <div class="col-12">
                          <div class="table-responsive no-sort tableFixHead">
                            <table class="table tabel-status-document w-100">
                              <thead>
                                <tr>
                                  <th>Unit Pekerjaan</th>
                                  <th>Satuan</th>
                                  <th>Qty Kontrak</th>
                                  <th>Bobot (%)</th>
                                  <th>
                                    Kumulatif Quantity<br />Sampai Saat Ini
                                  </th>
                                  <th>Progress (%)</th>
                                  <th>Tingkat<br />Penyelesaian (%)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(data, index) in model.progress"
                                  :key="index"
                                >
                                  <td>{{ data.name }}</td>
                                  <td>{{ data.unit }}</td>
                                  <td>{{ data.quantity }}</td>
                                  <td>{{ Number(data.weight.toFixed(2)) }}%</td>
                                  <!-- :disabled="data.quantity == 0 || data.quantity == null" -->
                                  <td>
                                    <ValidationProvider
                                        :name="`Cummulative_Quantity_${index}`"
                                        v-slot="{ errors }"
                                        :rules="{ regex:/^[0-9]+,?[0-9]*/, max_value:data.quantity,}"
                                    >
                                      <input
                                        v-model="
                                          model.progress[index]
                                            .cumulative_quantity
                                        "
                                        @keyup="
                                          calculate(
                                            index,
                                            model.progress[index]
                                              .cumulative_quantity
                                          )
                                        "
                                        class="form-control"
                                      />
                                    <small v-show="errors[0]">Nilai kumulatif quantity sampai saat ini tidak boleh lebih dari {{data.quantity}} dan karakter selain Koma.</small>
                                    </ValidationProvider>
                                  </td>
                                  <td>{{ Number(data.progress.toFixed(2))}}%</td>
                                  <td>{{ Number(data.completion_rate.toFixed(2)) }}%</td>
                                </tr>

                                <!-- <tr style="background: #EAF5F8 !important">
                                                            <td colspan="5">
                                                                <span style="font-size: 15px" class="text-blue"><strong>Grand Total</strong></span>
                                                            </td>
                                                            <td colspan="2">
                                                                <span style="font-size: 15px" class="text-blue"><strong>{{totalProgress}}%</strong></span>
                                                            </td>
                                                        </tr> -->
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="table-responsive no-sort tableFixHead">
                            <table class="table tabel-status-document w-100">
                              <tbody>
                                <tr style="background: #eaf5f8 !important">
                                  <td class="class-td">
                                    <span
                                      style="font-size: 15px"
                                      class="text-blue"
                                      ><strong>Grand Total</strong></span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      style="font-size: 15px"
                                      class="text-blue"
                                      ><strong
                                        >{{ Number(totalProgress.toFixed(2)) }}%</strong
                                      ></span
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="col-12 mb-2 mt-2">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <h5 class="m-0"><strong>Kendala dan Rencana Tindak Lanjut</strong></h5>
                          </div>
                        </div>
                        <div class="col-12">
                          <ValidationProvider
                            name="Kendala"
                            v-slot="{ errors }"
                            rules=""
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <!-- <textarea
                                v-model="model.problem"
                                class="form-control"
                                id="uraian"
                                cols="30"
                                rows="5"
                              ></textarea> -->
                              <quill-editor v-model="model.problem" :options="editorOption"/>
                              <small>{{ errors[0] }}</small>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div
                          class="col-12 d-flex align-items-center justify-content-center"
                          style="gap: 10px"
                        >
                          <div>
                            <button
                              @click="back()"
                              type="button"
                              class="btn btn-outline-primary"
                            >
                              Batal
                            </button>
                          </div>
                          <div>
                            <button type="submit" class="btn btn-primary">
                              Simpan
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// import TableV2 from '@/components/TableV2.vue'
import $ from "jquery";
import ld from "lodash";

export default {
  name: "TambahProgressIndex",
  progress_quantity:true,
  components: {},
  data() {
    return {
      editorOption: {
          modules: {
              toolbar: [
                  ['bold', 'italic', 'underline'],                        
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
              ]
          }
      },
      breadcumb: [],
      role_id: localStorage.getItem("role_id"),
      dropzoneOptions: {
        url: this.$baseUrl + "/kontrak",
        acceptedFiles: "image/*",
        thumbnailWidth: 150,
        autoProcessQueue: false,
        uploadMultiple: true,
        addRemoveLinks: true,
        paramName: "image",
      },
      totalProgress: 0,
      tableUpdateProgress: {
        list: [],
        columns: [
          "name_resume",
          "no_prk",
          "nilai_kontrak",
          "col_ready_at",
          "status",
          "progress_fisik",
          "progress_bayar",
        ],
      },
      model: {
        documentRow: [],
      },
      progressForm: {
        document: [],
      },
      kontraktual_dropzoneOptions: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: ".jpg,.jpeg,.png",
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        headers: { Authorization: "Bearer " + this.$store.state.token },
        paramName: "image",
        maxFilesize: 10000,
        init: function () {
          // this.on("error", function(file, message) {
          //     alert(message);
          //     this.removeFile(file);
          // });
        },
      },
    };
  },
  methods: {
    back() {
      window.history.go(-1);
    },
    async get() {
      let _ = this;
      await _.axios
        .get("/kontrak?id=" + _.$route.params.id)
        .then((resp) => {
          let data_bread = resp.data.data.rows;
          _.breadcumb = [
            {
              name: "Monitoring Project",
              link: "/kontrak-mtu",
              active: false,
            },
            {
              name: "Project List",
              link: "/kontrak",
              active: false,
            },
            {
              name: data_bread.no_kontrak,
              link: "/kontrak/" + this.$route.params.id,
              active: false,
            },
            {
              name: this.$route.query.edit
                ? "Edit Progress"
                : "Tambah Progress",
              link: "",
              active: true,
            },
          ];
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    async getPeriode() {
      let _ = this;
      await _.axios
        .get(
          "/v2/kontrak/curva-s/periode-progress?id_kontrak=" +
            _.$route.params.id
        )
        .then((resp) => {
          _.model = resp.data.data;
          _.model.week = _.model.periode.week;
          _.model.id_kontrak = _.$route.params.id;
          _.model.name = _.model.periode.name;
          _.model.start = _.model.periode.start;
          _.model.end = _.model.periode.end;
          _.model.year = _.model.periode.year;
          _.model.month = _.model.periode.month;
          _.model.description =
            _.model.note && _.model.note.description
              ? _.model.note.description
              : "";
          _.model.workers =
            _.model.note && _.model.note.workers ? _.model.note.workers : "";
          _.model.problem =
            _.model.note && _.model.note.problem ? _.model.note.problem : null;
          _.model.document = [];
          _.model.documentRow = [];

          if (_.model.note && _.model.note.documents) {
            _.model.note.col_documents.forEach(function (el) {
              _.model.documentRow.push({
                name: el.name,
              });
              let file = { size: 10240, name: el.name, type: "image/png" };
              let url = el.url;
              _.$refs.myVueDropzone.manuallyAddFile(file, url);
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    async calculate(index, cumulative_quantity) {
      let _ = this;


      let tampData = _.model.progress[index];

      if (tampData.quantity == null || tampData.quantity == 0) {
        _.model.progress[index].completion_rate = 0;
        _.model.progress[index].updated = true;
      } else if (tampData.weight == null || tampData.weight == 0) {
        _.model.progress[index].progress = 0;
        _.model.progress[index].updated = true;
      } else {
        _.model.progress[index].completion_rate =
          cumulative_quantity / tampData.quantity ;
        _.model.progress[index].progress =
          _.model.progress[index].completion_rate * tampData.weight;
        _.model.progress[index].completion_rate = _.model.progress[index].completion_rate * 100;
        _.model.progress[index].updated = true;
      }

      let totalProgress = 0;
      _.model.progress.map((v) => {
        totalProgress = totalProgress + v.progress;
      });
      this.totalProgress = totalProgress;
    },

    async onSubmit() {
      let _ = this;
      let mapProgress = [];
      _.model.progress.forEach((element) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!element.hasOwnProperty("updated")) {
          element.updated = _.model.note != null ? true : false;
        }
        mapProgress.push({
          id: element.id,
          cumulative_quantity: element.cumulative_quantity,
          quantity: element.quantity - element.cumulative_quantity,
          progress: element.progress,
          completion_rate: element.completion_rate,
          updated: element.updated,
        });
      });

      let dataPost = {
        id_kontrak: _.$route.params.id,
        name: _.model.name,
        year: _.model.year,
        month: _.model.month,
        week: _.model.week,
        start: _.model.start,
        end: _.model.end,
        workers: _.model.workers,
        description: _.model.description,
        problem: _.model.problem,
        progress: mapProgress,
        documents: _.model.document,
        documents_raw: JSON.stringify(_.model.documentRow),
      };

      var formData = new FormData();
      $.each(dataPost, function (index, val) {
        if (index == "documents") {
          $.each(dataPost.documents, function (index, val) {
            formData.append("documents[" + index + "]", val);
          });
        } else if (index == "progress") {
          $.each(dataPost.progress, function (index, val) {
            for (var key in val) {
              formData.append("progress[" + index + "][" + key + "]", val[key]);
            }
          });
        } else {
          formData.append(index, val);
        }
      });

      await _.axios
        .post("v2/kontrak/curva-s", formData)
        .then(() => {
          _.$toast.success("Progress Berhasil Disimpan");
          setTimeout(() => {
            this.$router.push("/kontrak/" + _.$route.params.id);
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    DocumentFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.model.document.push(val);
        });
      }
    },
    DocumentDeleteFile(file) {
      let _ = this;
      console.log(file);
      if (file.lastModified) {
        _.model.document = ld.filter(_.model.document, function (o) {
          return o.name != file.name;
        });
      } else {
        _.model.documentRow = ld.filter(_.model.documentRow, function (o) {
          return o.name != file.name;
        });
      }
    },
    DocumentRemoveFile() {
      let _ = this;
      _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true);
      _.model.document = [];
    },
  },

  async mounted() {
    let _ = this;
    await _.get();
    await _.getPeriode();
  },

  watch: {
    "model.progress": function (newValue) {
      let totalProgress = 0;
      newValue.map((v) => {
        totalProgress = totalProgress + v.progress;
      });
      this.totalProgress = totalProgress;
    },
  },
};
</script>

<style scoped>
  .class-td{
    width: 71.5%
  }
</style>>

