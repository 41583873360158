<template>
    <div class="modal fade text-left" id="Modal_KontraktorData" tabindex="-1" role="dialog" aria-labelledby="myModalLabel160" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">Data Kontraktor</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="">Nama Kontraktor</label>
                        <input type="text" v-model="detail.name" class="form-control" aria-describedby="helpId" readonly>
                    </div>

                    <div class="form-group">
                        <label for="">Alamat</label>
                        <textarea cols="30" rows="5" v-model="detail.alamat" class="form-control" aria-describedby="helpId" readonly></textarea>
                    </div>

                    <div class="form-group">
                        <label for="">Nomor Telepon</label>
                        <input type="text" v-model="detail.no_telp" class="form-control" aria-describedby="helpId" readonly>
                    </div>

                    <div class="form-group">
                        <label for="">Status</label>
                        <div class="custom-control custom-switch custom-control-inline mt-1">
                            <input type="checkbox" v-model="detail.status" class="custom-control-input" id="customSwitch2" disabled>
                            <label class="custom-control-label" for="customSwitch2">
                            </label>
                            <span class="switch-label">{{ detail.status ? 'Aktif' : 'Tidak Aktif' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        name: 'ModalDetail',
        props: ['detail'],
	}
</script>