<template>
  <div class="row">
    <div class="col-md-12">
      <section>
        <ListMonitoringProject
          :title="table.title"
          :breadcumb="table.breadcumb"
          :dataTable="table.dataTable"
        >
          <template v-slot:button>
            <div>
              <router-link
                to="kontrak/add"
                class="btn btn-primary d-inline"
                v-can="'kontrak/create'"
              >
                <i class="fa fa-plus fa-l" aria-hidden="true"></i> Tambah
                Kontrak
              </router-link>
              <button
                type="submit"
                class="btn btn-outline-primary waves-effect waves-light"
                data-toggle="modal"
                data-target="#Modal_FilterKontrak"
                style="margin-left: 3px"
              >
                <i class="fa fa-filter fa-l" aria-hidden="true"></i> Filter
                Kontrak
              </button>
              &emsp;
              <button
                type="button"
                class="btn btn-outline-primary waves-effect waves-light"
                data-toggle="modal"
                data-target="#Modal_Import"
                v-can="'kontrak/create'"
              >
                <i class="fa fa-file-excel-o fa-l" aria-hidden="true"></i>
                Import Excel
              </button>
            </div>
          </template>

          <template v-slot:search>
            <div class="input-group form-search-rounded">
              <input
                class="form-control py-2 border-right-0 border"
                v-model="search"
                type="search"
                id="example-search-input"
                placeholder="Cari kontrak disini.."
              />
              <span class="input-group-append">
                <div class="input-group-text bg-transparent">
                  <i class="fa fa-search"></i>
                </div>
              </span>
            </div>
          </template>

          <template v-slot:columnHeader>
            <thead>
              <tr>
                <th>No.</th>
                <th @click="sortBy('no_kontrak')">
                  No. Kontrak
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'no_kontrak'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('judul_kontrak')">
                  Judul Kontrak
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'judul_kontrak'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('col_kontraktor_name')">
                  Nama Kontraktor
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'col_kontraktor_name'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('tgl_awal')">
                  Tanggal Awal
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'tgl_awal'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('tgl_akhir')">
                  Tanggal Akhir
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'tgl_akhir'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('col_unit')">
                  UPP
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'col_unit'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('col_jenis_pekerjaan')">
                  Jenis Pekerjaan
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'col_jenis_pekerjaan'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('col_status_kontraktual')">
                  Status Pekerjaan
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'col_status_kontraktual'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('progress_fisik')">
                  Progress
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'progress_fisik'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('col_status_serahterima')">
                  Status Serah Terima
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'col_status_serahterima'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th @click="sortBy('col_status_operasi')">
                  Status Operasi
                  <i
                    class="fa sort"
                    :class="
                      currentSort == 'col_status_operasi'
                        ? currentSortDir == 'asc'
                          ? 'fa-sort-down'
                          : currentSortDir == 'desc'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                        : 'fa-sort'
                    "
                  ></i>
                </th>
                <th width="10%">Aksi</th>
              </tr>
            </thead>
          </template>

          <template v-slot:action="{ data }">
            <div class="btn-action-block">
              <router-link
                :to="'kontrak/' + data.id"
                class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                v-can="'kontrak/detail'"
              >
                <i class="fa fa-eye"></i>
              </router-link>
              <router-link
                :to="'kontrak/edit/' + data.id"
                type="button"
                class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                style="margin-left: 2px"
                v-can="'kontrak/update'"
              >
                <i class="fa fa-pencil"></i>
              </router-link>
              <button
                type="button"
                @click="destroy(data.id)"
                v-can="'kontrak/delete'"
                class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                id="confirm-text"
                style="margin-left: 2px"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </template>

          <template v-if="parseInt(role_id) === 1" #warning>
            <div class="row mb-2">
              <div class="col-12">
                <ul class="nav custom-nav-box">
                  <li class="nav-item">
                    <a
                      @click="notifActive = 'kontraktual'"
                      :class="{
                        'nav-link': true,
                        active: notifActive === 'kontraktual',
                      }"
                      >Kontraktual ({{ notifKontraktual.total }})</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      @click="notifActive = 'bg'"
                      :class="{
                        'nav-link': true,
                        active: notifActive === 'bg',
                      }"
                      >Bank Garansi ({{ notifBg.total }})</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      @click="notifActive = 'anggaran'"
                      :class="{
                        'nav-link': true,
                        active: notifActive === 'anggaran',
                      }"
                      >Anggaran ({{ notifAnggaran.total }})</a
                    >
                  </li>
                </ul>
              </div>
            </div>

            <div class="row">
              <div class="col-12" v-if="notifActive === 'bg'">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="row no-gutters">
                        <router-link
                          to="/warning/uang-muka?filter=Akan Berakhir"
                          class="col-4"
                          style="padding: 8px"
                          v-if="
                            parseInt(notifBgUangMuka.data['Akan Berakhir']) !==
                            0
                          "
                        >
                          <div
                            :class="{
                              'alert custom-alert alert-warning': true,
                            }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{ notifBgUangMuka.data["Akan Berakhir"] }}
                                </div>
                                <div class="alert-text">
                                  Jaminan uang muka akan berakhir
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-warning': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          to="/warning/uang-muka?filter=Berakhir"
                          class="col-4"
                          style="padding: 8px"
                          v-if="
                            parseInt(notifBgUangMuka.data['Berakhir']) !== 0
                          "
                        >
                          <div
                            :class="{ 'alert custom-alert alert-danger': true }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{ notifBgUangMuka.data["Berakhir"] }}
                                </div>
                                <div class="alert-text">
                                  Jaminan uang muka berakhir
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-danger': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          to="/laporan-bg-pelaksanaan"
                          class="col-4"
                          style="padding: 8px"
                          v-if="parseInt(notifBg.data['Akan Berakhir']) !== 0"
                        >
                          <div
                            :class="{
                              'alert custom-alert alert-warning': true,
                            }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{ notifBg.data["Akan Berakhir"] }}
                                </div>
                                <div class="alert-text">
                                  Jaminan pelaksanaan akan berakhir
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-warning': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          to="/warning/pelaksanaan?filter=Berakhir"
                          class="col-4"
                          style="padding: 8px"
                          v-if="parseInt(notifBg.data['Berakhir']) !== 0"
                        >
                          <div
                            :class="{ 'alert custom-alert alert-danger': true }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{ notifBg.data["Berakhir"] }}
                                </div>
                                <div class="alert-text">
                                  Jaminan pelaksanaan berakhir
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-danger': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          to="/warning/pelaksanaan?filter=Perlu Penyesuaian"
                          class="col-4"
                          style="padding: 8px"
                          v-if="
                            parseInt(notifBg.data['penyesuaiaan nilai']) !== 0
                          "
                        >
                          <div
                            :class="{ 'alert custom-alert alert-danger': true }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{ notifBg.data["penyesuaiaan nilai"] }}
                                </div>
                                <div class="alert-text">
                                  Jaminan pelaksanaan perlu penyesuaian
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-danger': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          to="/warning/pemeliharaan?filter=Akan Berakhir"
                          class="col-4"
                          style="padding: 8px"
                          v-if="
                            parseInt(
                              notifBgPemeliharaan.data['Akan Berakhir']
                            ) !== 0
                          "
                        >
                          <div
                            :class="{
                              'alert custom-alert alert-warning': true,
                            }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{
                                    notifBgPemeliharaan.data["Akan Berakhir"]
                                  }}
                                </div>
                                <div class="alert-text">
                                  Jaminan pemeliharaan akan berakhir
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-warning': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          to="/warning/pemeliharaan?filter=Berakhir"
                          class="col-4"
                          style="padding: 8px"
                          v-if="
                            parseInt(notifBgPemeliharaan.data['Berakhir']) !== 0
                          "
                        >
                          <div
                            :class="{ 'alert custom-alert alert-danger': true }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{ notifBgPemeliharaan.data["Berakhir"] }}
                                </div>
                                <div class="alert-text">
                                  Jaminan pemeliharaan berakhir
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-danger': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          to="/warning/pemeliharaan?filter=Perlu Penyesuaian"
                          class="col-4"
                          style="padding: 8px"
                          v-if="
                            parseInt(
                              notifBgPemeliharaan.data['penyesuaiaan nilai']
                            ) !== 0
                          "
                        >
                          <div
                            :class="{ 'alert custom-alert alert-danger': true }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{
                                    notifBgPemeliharaan.data[
                                      "penyesuaiaan nilai"
                                    ]
                                  }}
                                </div>
                                <div class="alert-text">
                                  Jaminan pemeliharaan perlu penyesuaian
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-danger': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="notifActive === 'kontraktual'">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="row no-gutters">
                        <router-link
                          to="/chart/kontraktual/pmbk?filter=Akan Berakhir"
                          class="col-4"
                          style="padding: 8px"
                          v-if="
                            parseInt(notifKontraktual.data['Akan Habis']) !== 0
                          "
                        >
                          <div
                            :class="{
                              'alert custom-alert alert-warning': true,
                            }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{ notifKontraktual.data["Akan Habis"] }}
                                </div>
                                <div class="alert-text">
                                  Kontrak Akan Berakhir
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-warning': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          to="/chart/kontraktual/pmbk?filter=Berakhir"
                          class="col-4"
                          style="padding: 8px"
                          v-if="parseInt(notifKontraktual.data['Habis']) !== 0"
                        >
                          <div
                            :class="{ 'alert custom-alert alert-danger': true }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{ notifKontraktual.data["Habis"] }}
                                </div>
                                <div class="alert-text">Kontrak Berakhir</div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-danger': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="notifActive === 'anggaran'">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="row">
                        <router-link
                          to="/laporan-project-ai"
                          class="col-4"
                          style="padding: 8px"
                          v-if="
                            parseInt(
                              notifAnggaran.data['Perlu Penyesuaian AI']
                            ) !== 0
                          "
                        >
                          <div
                            :class="{
                              'alert custom-alert alert-warning': true,
                            }"
                          >
                            <div class="row">
                              <div class="col-10">
                                <div class="alert-highlight">
                                  {{
                                    notifAnggaran.data["Perlu Penyesuaian AI"]
                                  }}
                                </div>
                                <div class="alert-text">
                                  Project Perlu Penyesuaian AI
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <i
                                  :class="{
                                    'feather icon-alert-triangle h4 m-0 text-warning': true,
                                  }"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="parseInt(role_id) === 1" #leaderboard>
            <div class="card">
              <div class="card-content">
                <div class="card-body card-dashboard">
                  <div
                    class="pt-2 pb-2 d-flex align-items-center justify-content-between"
                  >
                    <h4 class="d-inline m-0 card-title">
                      <strong>Leaderboard</strong>
                    </h4>
                  </div>
                  <div
                    v-if="!leaderBoardLoadState"
                    class="wrap-table-leaderboard"
                  >
                    <el-table
                      stripe
                      empty-text="No Data"
                      :data="tableData"
                      style="width: 100%"
                    >
                      <el-table-column :fixed="fixedColTable">
                        <template slot-scope="scope">
                          <p class="text-center">
                            <strong>{{ scope.row.number }}. </strong>
                          </p>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :fixed="fixedColTable"
                        prop="ranking"
                        label="Ranking"
                        width="270"
                      >
                        <template #header>
                          <p style="display: inline">
                            <strong>Ranking</strong>
                          </p>
                        </template>
                        <template slot-scope="scope">
                          <div class="row d-flex align-items-center">
                            <div class="col-3">
                              <el-avatar
                                :size="40"
                                :src="
                                  scope.row.ranking.photo ||
                                  'https://inspektorat.kotawaringinbaratkab.go.id/public/uploads/user/default-user-imge.jpeg'
                                "
                              ></el-avatar>
                            </div>
                            <div class="col-9">
                              <p class="text-primary">
                                <strong>{{ scope.row.name }}</strong>
                              </p>
                              <p class="text-mutted">
                                {{ scope.row.ranking.position }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :fixed="fixedColTable"
                        prop="current_total"
                        label="Total Point"
                        width="100"
                      >
                        <template #header>
                          <p style="display: inline">
                            <strong>Total Point</strong>
                          </p>
                        </template>
                        <template slot-scope="scope">
                          <div
                            class="d-flex align-items-center"
                            style="gap: 10px"
                          >
                            <svg
                              v-if="scope.row.status === 'up'"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="14" cy="13" r="6.25" fill="#3099AC" />
                              <g filter="url(#filter0_d_300_2518)">
                                <path
                                  d="M24 13C24 10.3478 22.9464 7.8043 21.0711 5.92893C19.1957 4.05357 16.6522 3 14 3C11.3478 3 8.8043 4.05357 6.92893 5.92893C5.05357 7.8043 4 10.3478 4 13C4 15.6522 5.05357 18.1957 6.92893 20.0711C8.8043 21.9464 11.3478 23 14 23C16.6522 23 19.1957 21.9464 21.0711 20.0711C22.9464 18.1957 24 15.6522 24 13ZM14.625 17.375C14.625 17.5408 14.5592 17.6997 14.4419 17.8169C14.3247 17.9342 14.1658 18 14 18C13.8342 18 13.6753 17.9342 13.5581 17.8169C13.4408 17.6997 13.375 17.5408 13.375 17.375V10.1337L10.6925 12.8175C10.5751 12.9349 10.416 13.0008 10.25 13.0008C10.084 13.0008 9.92486 12.9349 9.8075 12.8175C9.69014 12.7001 9.62421 12.541 9.62421 12.375C9.62421 12.209 9.69014 12.0499 9.8075 11.9325L13.5575 8.1825C13.6156 8.1243 13.6845 8.07812 13.7605 8.04661C13.8364 8.0151 13.9178 7.99888 14 7.99888C14.0822 7.99888 14.1636 8.0151 14.2395 8.04661C14.3155 8.07812 14.3844 8.1243 14.4425 8.1825L18.1925 11.9325C18.3099 12.0499 18.3758 12.209 18.3758 12.375C18.3758 12.541 18.3099 12.7001 18.1925 12.8175C18.0751 12.9349 17.916 13.0008 17.75 13.0008C17.584 13.0008 17.4249 12.9349 17.3075 12.8175L14.625 10.1337V17.375Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_d_300_2518"
                                  x="0"
                                  y="0"
                                  width="28"
                                  height="28"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dy="1" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_300_2518"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_300_2518"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>

                            <svg
                              v-if="scope.row.status === 'down'"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                r="6.25"
                                transform="matrix(1 0 0 -1 14 13)"
                                fill="#EC2F39"
                              />
                              <g filter="url(#filter0_d_300_2533)">
                                <path
                                  d="M24 13C24 15.6522 22.9464 18.1957 21.0711 20.0711C19.1957 21.9464 16.6522 23 14 23C11.3478 23 8.8043 21.9464 6.92893 20.0711C5.05357 18.1957 4 15.6522 4 13C4 10.3478 5.05357 7.8043 6.92893 5.92893C8.8043 4.05357 11.3478 3 14 3C16.6522 3 19.1957 4.05357 21.0711 5.92893C22.9464 7.8043 24 10.3478 24 13ZM14.625 8.625C14.625 8.45924 14.5592 8.30027 14.4419 8.18306C14.3247 8.06585 14.1658 8 14 8C13.8342 8 13.6753 8.06585 13.5581 8.18306C13.4408 8.30027 13.375 8.45924 13.375 8.625V15.8663L10.6925 13.1825C10.5751 13.0651 10.416 12.9992 10.25 12.9992C10.084 12.9992 9.92486 13.0651 9.8075 13.1825C9.69014 13.2999 9.62421 13.459 9.62421 13.625C9.62421 13.791 9.69014 13.9501 9.8075 14.0675L13.5575 17.8175C13.6156 17.8757 13.6845 17.9219 13.7605 17.9534C13.8364 17.9849 13.9178 18.0011 14 18.0011C14.0822 18.0011 14.1636 17.9849 14.2395 17.9534C14.3155 17.9219 14.3844 17.8757 14.4425 17.8175L18.1925 14.0675C18.3099 13.9501 18.3758 13.791 18.3758 13.625C18.3758 13.459 18.3099 13.2999 18.1925 13.1825C18.0751 13.0651 17.916 12.9992 17.75 12.9992C17.584 12.9992 17.4249 13.0651 17.3075 13.1825L14.625 15.8663V8.625Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_d_300_2533"
                                  x="0"
                                  y="0"
                                  width="28"
                                  height="28"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dy="1" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_300_2533"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_300_2533"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>

                            <svg
                              v-if="scope.row.status === 'same'"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                r="6.25"
                                transform="matrix(1 0 0 -1 14 13)"
                                fill="#505050"
                              />
                              <g filter="url(#filter0_d_300_2578)">
                                <path
                                  d="M24 13C24 7.477 19.523 3 14 3C8.477 3 4 7.477 4 13C4 18.523 8.477 23 14 23C19.523 23 24 18.523 24 13ZM18.252 11.5H9.752C9.55309 11.5 9.36232 11.421 9.22167 11.2803C9.08102 11.1397 9.002 10.9489 9.002 10.75C9.002 10.5511 9.08102 10.3603 9.22167 10.2197C9.36232 10.079 9.55309 10 9.752 10H18.252C18.4509 10 18.6417 10.079 18.7823 10.2197C18.923 10.3603 19.002 10.5511 19.002 10.75C19.002 10.9489 18.923 11.1397 18.7823 11.2803C18.6417 11.421 18.4509 11.5 18.252 11.5ZM18.252 16H9.752C9.55309 16 9.36232 15.921 9.22167 15.7803C9.08102 15.6397 9.002 15.4489 9.002 15.25C9.002 15.0511 9.08102 14.8603 9.22167 14.7197C9.36232 14.579 9.55309 14.5 9.752 14.5H18.252C18.4509 14.5 18.6417 14.579 18.7823 14.7197C18.923 14.8603 19.002 15.0511 19.002 15.25C19.002 15.4489 18.923 15.6397 18.7823 15.7803C18.6417 15.921 18.4509 16 18.252 16Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_d_300_2578"
                                  x="0"
                                  y="0"
                                  width="28"
                                  height="28"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dy="1" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_300_2578"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_300_2578"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>

                            <a
                              ><strong
                                >{{ scope.row.current_total }}
                              </strong></a
                            >
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_masuk_ke_ipm"
                        label="Masuk Aplikasi"
                      >
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_masuk_ke_ipm }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_input_kendala"
                        label="Input Kendala"
                      >
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_input_kendala }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_input_solusi"
                        label="Input Solusi"
                      >
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_input_solusi }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_update_amandemen"
                        label="Update Amandemen"
                      >
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_update_amandemen }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_update_bank_garansi"
                        label="Update Bank Garansi"
                      >
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_update_bank_garansi }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_update_bap"
                        label="Update BAP"
                      >
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_update_bap }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_update_po_se"
                        label="Update PO SE"
                      >
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_update_po_se }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_input_status_operasi"
                        label="Update Status Operasi"
                      >
                        <template #header>
                          <span style="display: inline"
                            ><strong>Update Status<br />Operasi</strong></span
                          >
                        </template>
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_input_status_operasi }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_update_status_kontraktual"
                        label="Input Status Kontraktual"
                      >
                        <template #header>
                          <span style="display: inline"
                            ><strong
                              >Input Status<br />Kontraktual</strong
                            ></span
                          >
                        </template>
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_update_status_kontraktual }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_update_status_kontraktual"
                        label="Update Status Kontraktual"
                      >
                        <template #header>
                          <span style="display: inline"
                            ><strong
                              >Update Status<br />Kontraktual</strong
                            ></span
                          >
                        </template>
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_update_status_kontraktual }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="160"
                        prop="total_update_status_serah_terima"
                        label="Update Status Serah Terima"
                      >
                        <template #header>
                          <span style="display: inline"
                            ><strong
                              >Update Status <br />serah Terima</strong
                            ></span
                          >
                        </template>
                        <template slot-scope="scope">
                          <a style="display: block; text-align: center"
                            ><strong
                              >{{ scope.row.total_update_status_serah_terima }}
                            </strong></a
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="wrap-leader-see-all">
                      <router-link
                        to="/leaderboard"
                        class="btn d-inline text-primary"
                        ><strong>Lihat Semua</strong></router-link
                      >
                    </div>
                  </div>
                  <div class="mx-auto text-center" style="width: 400px" v-else>
                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- <template v-slot:loadingState>
                        <div class="col text-center mt-1" v-if="isLoading">
                            <i class="fa fa-spinner fa-spin"></i> 
                        </div>
                        <div class="col text-center mt-1" v-else>
                            <a v-if="!isLastPage" @click="page += 1, getList(page)">Muat lebih banyak <span><i class="fa fa-arrow-down"></i></span></a>
                        </div>
                    </template> -->
        </ListMonitoringProject>
        <div
          class="modal fade text-left show"
          id="Modal_FilterKontrak"
          tabindex="-1"
          role="dialog"
        >
          <div
            class="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header modal-primary white">
                <h5 class="modal-title" id="myModalLabel160">Filter Kontrak</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-1" v-for="(f, idx) in filter" :key="idx">
                  <div class="col-8 col-sm-10">
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="filter_by">Filter Berdasarkan</label>
                          <select
                            class="form-control"
                            v-model="f.by"
                            :disabled="f.by != '-'"
                          >
                            <option
                              v-for="(data, index) in filterOption"
                              :key="index"
                              :value="data.by"
                            >
                              {{ data.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6" v-if="f.by == 'tgl_awal'">
                        <div class="form-group">
                          <label for="filter_by">Tanggal Awal</label>
                          <div class="position-relative has-icon-left">
                            <date-picker
                              v-model="filterby.tgl_awal"
                              format="DD/MM/YYYY"
                              type="date"
                              value-type="YYYY-MM-DD"
                              placeholder="Tanggal awal kontrak"
                              style="width: 100%"
                            ></date-picker>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-else-if="f.by == 'tgl_akhir'"
                      >
                        <div class="form-group">
                          <label for="filter_by">Tanggal Akhir</label>
                          <div class="position-relative has-icon-left">
                            <date-picker
                              v-model="filterby.tgl_akhir"
                              format="DD/MM/YYYY"
                              type="date"
                              value-type="YYYY-MM-DD"
                              placeholder="Tanggal akhir kontrak"
                              style="width: 100%"
                            ></date-picker>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-else-if="f.by == 'id_pekerjaan'"
                      >
                        <div class="form-group">
                          <label for="filter_by">Jenis Pekerjaan</label>
                          <select
                            class="form-control"
                            v-model="filterby.id_pekerjaan"
                          >
                            <option
                              v-for="(work, index) in optionsPekerjaan"
                              :key="index"
                              :value="work.id"
                            >
                              {{ work.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-else-if="f.by == 'id_kontraktor'"
                      >
                        <div class="form-group">
                          <label for="filter_by">Kontraktor</label>
                          <select
                            class="form-control"
                            v-model="filterby.id_kontraktor"
                          >
                            <option
                              v-for="(kontraktor, index) in optionsKontraktor"
                              :key="index"
                              :value="kontraktor.name"
                            >
                              {{ kontraktor.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-else-if="f.by == 'id_unit'"
                      >
                        <div class="form-group">
                          <label for="filter_by">UPP</label>
                          <select
                            class="form-control"
                            v-model="filterby.id_unit"
                          >
                            <option
                              v-for="(unit, index) in optionsUnit.filter(
                                (unit) => unit.id > 1
                              )"
                              :key="index"
                              :value="unit.id"
                            >
                              {{ unit.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-else-if="f.by == 'status_pekerjaan'"
                      >
                        <div class="form-group">
                          <label for="filter_by">Status Pekerjaan</label>
                          <select
                            class="form-control"
                            v-model="filterby.status_pekerjaan"
                          >
                            <option
                              v-for="(data, index) in optionsStatusPekerjaan"
                              :key="index"
                              :value="data.name"
                            >
                              {{ data.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-else-if="f.by == 'status_serahterima'"
                      >
                        <div class="form-group">
                          <label for="filter_by">Status Serah Terima</label>
                          <select
                            class="form-control"
                            v-model="filterby.status_serahterima"
                          >
                            <option
                              v-for="(data, index) in optionsStatusSerahTerima"
                              :key="index"
                              :value="data.name"
                            >
                              {{ data.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-else-if="f.by == 'status_operasi'"
                      >
                        <div class="form-group">
                          <label for="filter_by">Status Operasi</label>
                          <select
                            class="form-control"
                            v-model="filterby.status_operasi"
                          >
                            <option
                              v-for="(data, index) in optionsStatusOperasi"
                              :key="index"
                              :value="data.name"
                            >
                              {{ data.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6"
                        v-else-if="f.by == 'bap_berjalan'"
                      >
                        <div class="form-group">
                          <label for="filter_by">BAP</label>
                          <select
                            class="form-control"
                            v-model="filterby.bap_berjalan"
                          >
                            <option
                              v-for="(data, index) in optionsBAPBerjalan"
                              :key="index"
                              :value="data.name"
                            >
                              {{ data.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6" v-else>
                        <div class="form-group">
                          <label for="filter_by">-</label>
                          <input type="text" class="form-control" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-2 d-flex align-items-center">
                    <button
                      class="btn-transparent"
                      @click="addFilter()"
                      v-if="idx == filter.length - 1"
                    >
                      <svg
                        class="img-fluid mr-2"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                      >
                        <path
                          fill="#17536A"
                          fill-rule="nonzero"
                          d="M10 .134c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zm0 18.75a8.75 8.75 0 1 1 0-17.5 8.75 8.75 0 0 1 0 17.5zm3.75-9.375h-3.125V6.384a.625.625 0 0 0-1.25 0v3.125H6.25a.625.625 0 0 0 0 1.25h3.125v3.125a.625.625 0 0 0 1.25 0v-3.125h3.125a.625.625 0 0 0 0-1.25z"
                        ></path>
                      </svg>
                    </button>
                    <button
                      class="btn-transparent"
                      @click="deleteFilter(idx)"
                      v-if="filter.length > 1"
                    >
                      <svg
                        class="img-fluid mr-2"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                      >
                        <path
                          fill="#DA3E2C"
                          fill-rule="nonzero"
                          d="M17.314 3.314C13.547-.726 7.219-.946 3.18 2.82c-4.04 3.767-4.26 10.094-.494 14.134 3.767 4.039 10.095 4.26 14.134.493 4.039-3.766 4.26-10.094.494-14.133zM3.6 16.1A8.75 8.75 0 1 1 16.399 4.166 8.75 8.75 0 0 1 3.601 16.101zm9.414-3.651l-2.132-2.286 2.286-2.13a.625.625 0 0 0-.853-.915L10.031 9.25 7.9 6.965a.625.625 0 0 0-.915.852l2.132 2.286-2.286 2.13a.625.625 0 0 0 .853.915l2.285-2.131 2.131 2.285a.625.625 0 0 0 .915-.852z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-primary waves-effect waves-light"
                  @click="reset()"
                >
                  Reset
                </button>
                <button
                  type="button"
                  class="btn btn-primary waves-effect waves-light"
                  data-dismiss="modal"
                  @click="filterData()"
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <ModalImport
          title="Kontrak"
          url="kontrak"
          @success="(allData = []), getList()"
        />

        <ModalUpdateProfile @toEditPage="toEditPage" />
        <button
          type="button"
          v-show="false"
          ref="showModalUpdate"
          class="btn btn-primary"
          style="margin-left: 4px"
          data-toggle="modal"
          data-target="#ModalUpdateProfile"
        ></button>
        <button
          type="button"
          v-show="false"
          ref="hideModalUpdate"
          class="btn btn-outline-primary"
          data-dismiss="modal"
        ></button>
      </section>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import DatePicker from "vue2-datepicker";
import ListMonitoringProject from "@/components/ListMonitoringProject.vue";
import ModalImport from "@/components/ModalImport.vue";
import ModalUpdateProfile from "@/components/ModalUpdateProfile.vue";
// import { debounce } from "debounce";
import moment from "moment";

export default {
  name: "UserIndex",
  components: {
    ListMonitoringProject,
    DatePicker,
    ModalImport,
    ModalUpdateProfile,
  },
  data() {
    return {
      table: {
        title: "Project List",
        breadcumb: "Monitoring Project",
        dataTable: {
          lists: [],
          columns: [
            "no_kontrak",
            "judul_kontrak",
            "col_kontraktor_name",
            "col_tgl_awal",
            "col_tgl_akhir",
            "col_unit",
            "col_jenis_pekerjaan",
            "col_status_kontraktual_html",
            "col_kontrak_progress",
            "col_status_serahterima_html",
            "col_status_operasi_html",
          ],
        },
      },
      notifActive: "kontraktual",
      notifBg: { total: 0, data: {} },
      notifBgUangMuka: { total: 0, data: {} },
      notifBgPemeliharaan: { total: 0, data: {} },
      notifKontraktual: { total: 0, data: {} },
      notifAnggaran: { total: 0, data: {} },
      tanggal_awal: "",
      search: null,
      detail: [],
      model: {
        status: false,
      },
      currentSort: "updated_at",
      currentSortDir: "desc",
      filterby: {
        tgl_awal: "",
        tgl_akhir: "",
        id_pekerjaan: "",
        id_unit: "",
        status_pekerjaan: "",
        status_serahterima: "",
        status_operasi: "",
        bap_berjalan: "",
        id_kontraktor: "",
      },
      filter: [
        {
          by: "-",
        },
      ],
      filterOption: [
        {
          name: "-",
          by: "-",
        },
        {
          name: "Tanggal Awal",
          by: "tgl_awal",
        },
        {
          name: "Tanggal Akhir",
          by: "tgl_akhir",
        },
        {
          name: "Jenis Pekerjaan",
          by: "id_pekerjaan",
        },
        {
          name: "UPP",
          by: "id_unit",
        },
        {
          name: "Status Pekerjaan",
          by: "status_pekerjaan",
        },
        {
          name: "Status Serah Terima",
          by: "status_serahterima",
        },
        {
          name: "Status Operasi",
          by: "status_operasi",
        },
        {
          name: "BAP",
          by: "bap_berjalan",
        },
        {
          name: "Kontraktor",
          by: "id_kontraktor",
        },
      ],
      optionsPekerjaan: [],
      optionsUnit: [],
      optionsKontraktor: [],
      optionsStatusPekerjaan: [
        { name: "Belum Efektif" },
        { name: "On Going" },
        { name: "Serah Terima I" },
        { name: "Serah Terima II" },
        { name: "Kontrak Berakhir" },
        { name: "Kontrak Akan Berakhir" },
      ],
      optionsStatusSerahTerima: [
        { name: "On Going" },
        { name: "Proses STOP" },
        { name: "STOP" },
        { name: "Proses STAP" },
        { name: "STAP" },
        { name: "Proses STP" },
        { name: "STP" },
      ],
      optionsStatusOperasi: [
        { name: "On Going" },
        { name: "RLB" },
        { name: "Energize" },
        { name: "SLO" },
      ],
      optionsBAPBerjalan: [{ name: "Berjalan" }, { name: "Tidak Berjalan" }],
      allData: [],
      page: 1,
      isLoading: false,
      isLastPage: false,
      beforeFilter: [],
      fixedColTable: true,
      leaderBoardLoadState: true,
      leaderboardExpand: false,
      payloadLb: {
        month: null,
        week: null,
        limit: 5,
      },
      tableData: [],
      role_id: localStorage.getItem("role_id"),
    };
  },
  mounted() {
    let _ = this;

    _.$nextTick(() => {
      if (localStorage.getItem("has_update_profile") == "0") {
        _.$refs.showModalUpdate.click();
      }
    });
    _.loadLeaderboard();
    _.getList();
    _.getPekerjaan();
    _.getKontraktor();
    _.getUnit();
    _.loadNotifBG();
    _.loadNotifBGUangMuka();
    _.loadNotifBGPemeliharaan();
    _.loadNotifKontraktual();
    _.loadNotifAnggaran();

    // $(window).scroll(function() {
    // 	if($(window).scrollTop() + $(window).height() == $(document).height()) {
    // 		_.page += 1
    //         if(!_.isLastPage){
    //             _.getList(_.page)
    //         }
    // 	}
    // });
  },
  beforeDestroy() {
    $(window).unbind("scroll");
    localStorage.removeItem("dashboard_filter");
  },
  methods: {
    async loadNotifBG() {
      try {
        const datas = (await this.axios.get("/v2/dashboard/bg-pelaksanaan"))
          .data;
        for (const [key, val] of Object.entries(datas.data.data)) {
          if (key === "total") {
            this.notifBg.total = val;
          } else {
            this.notifBg.data[key] = val;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadNotifBGUangMuka() {
      try {
        const datas = (await this.axios.get("/v2/dashboard/bg-uang-muka")).data;
        for (const [key, val] of Object.entries(datas.data.data)) {
          if (key === "total") {
            this.notifBg.total += val;
          } else {
            this.notifBgUangMuka.data[key] = val;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadNotifBGPemeliharaan() {
      try {
        const datas = (await this.axios.get("/v2/dashboard/bg-pemeliharaan"))
          .data;
        for (const [key, val] of Object.entries(datas.data.data)) {
          if (key === "total") {
            this.notifBg.total += val;
          } else {
            this.notifBgPemeliharaan.data[key] = val;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadNotifKontraktual() {
      try {
        const datas = (await this.axios.get("/v2/dashboard/kontraktual")).data;
        for (const [key, val] of Object.entries(datas.data.data)) {
          if (key === "total") {
            this.notifKontraktual.total = val;
          } else {
            this.notifKontraktual.data[key] = val;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadNotifAnggaran() {
      try {
        const datas = (await this.axios.get("/v2/dashboard/penyesuaiaan-ai"))
          .data;
        for (const [key, val] of Object.entries(datas.data.data)) {
          if (key === "total") {
            this.notifAnggaran.total = val;
          } else {
            this.notifAnggaran.data[key] = val;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async loadLeaderboard() {
      this.leaderBoardLoadState = true;
      let params = this.payloadLb;
      try {
        const datas = (
          await this.axios.get("/v2/dashboard/leaderboard", { params: params })
        ).data;
        const arr = datas.data;
        this.tableData = arr.map((e, idx) => {
          e.ranking = {
            fullname: e.name,
            photo: e.photo,
            position: e.position,
          };
          e.number = idx + 1;
          return e;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.leaderBoardLoadState = false;
      }
    },
    toEditPage() {
      let _ = this;
      _.$router.push("/user/edit_profile");
    },
    getList() {
      let _ = this;
      _.isLoading = true;
      _.allData = [];
      _.axios
        .get("/kontrak?orderby=updated_at&sort=desc&no_paginate=1")
        .then((resp) => {
          _.table.dataTable.lists = resp.data.data.rows;
          _.allData = resp.data.data.rows;
          _.beforeFilter = _.allData;
          _.isLoading = false;

          if (localStorage.getItem("dashboard_filter")) {
            if (localStorage.getItem("dashboard_filter") == "slo") {
              _.filter[0].by = "status_operasi";
              _.filterby.status_operasi = "SLO";
            } else if (
              localStorage.getItem("dashboard_filter") == "serah_terima_1"
            ) {
              _.filter[0].by = "status_pekerjaan";
              _.filterby.status_pekerjaan = "Serah Terima I";
            } else if (
              localStorage.getItem("dashboard_filter") == "serah_terima_2"
            ) {
              _.filter[0].by = "status_pekerjaan";
              _.filterby.status_pekerjaan = "Serah Terima II";
            } else if (
              localStorage.getItem("dashboard_filter") == "kontrak_berakhir"
            ) {
              _.filter[0].by = "status_pekerjaan";
              _.filterby.status_pekerjaan = "Kontrak Berakhir";
            } else if (
              localStorage.getItem("dashboard_filter") ==
              "kontrak_akan_berakhir"
            ) {
              _.filter[0].by = "status_pekerjaan";
              _.filterby.status_pekerjaan = "Kontrak Akan Berakhir";
            } else if (localStorage.getItem("dashboard_filter") == "energize") {
              _.filter[0].by = "status_operasi";
              _.filterby.status_operasi = "Energize";
            } else if (
              localStorage.getItem("dashboard_filter") == "bap_berjalan"
            ) {
              _.filter[0].by = "bap_berjalan";
              _.filterby.bap_berjalan = "Berjalan";
            }
            _.filterData();
          }
        })
        .catch((err) => {
          console.log(err.response);
          _.isLoading = false;
        });
    },
    filterData() {
      let _ = this;
      let filtered = [];
      _.table.dataTable.lists = _.beforeFilter;
      if (_.filterby.tgl_awal != "" || _.filterby.tgl_akhir != "") {
        if (_.filterby.tgl_awal != "" && _.filterby.tgl_akhir != "") {
          filtered = _.table.dataTable.lists.filter(
            (x) =>
              moment(x.tgl_awal) >= moment(_.filterby.tgl_awal) &&
              moment(x.tgl_akhir) <= moment(_.filterby.tgl_akhir)
          );
          _.table.dataTable.lists = filtered;
        } else if (_.filterby.tgl_awal != "") {
          filtered = _.table.dataTable.lists.filter(
            (x) => moment(x.tgl_awal) >= moment(_.filterby.tgl_awal)
          );
          _.table.dataTable.lists = filtered;
        } else if (_.filterby.tgl_akhir != "") {
          filtered = _.table.dataTable.lists.filter(
            (x) => moment(x.tgl_akhir) <= moment(_.filterby.tgl_akhir)
          );
          _.table.dataTable.lists = filtered;
        }
      }

      if (_.filterby.id_pekerjaan != "") {
        filtered = _.table.dataTable.lists.filter(
          (x) => x.id_pekerjaan == _.filterby.id_pekerjaan
        );
        _.table.dataTable.lists = filtered;
      }

      if (_.filterby.id_unit != "") {
        filtered = _.table.dataTable.lists.filter(
          (x) => x.id_unit == _.filterby.id_unit
        );
        _.table.dataTable.lists = filtered;
      }

      if (_.filterby.status_pekerjaan != "") {
        if (_.filterby.status_pekerjaan == "Kontrak Berakhir") {
          filtered = _.table.dataTable.lists.filter(
            (x) =>
              moment(x.tgl_akhir).format("YYYY-MM-DD") <
                moment().format("YYYY-MM-DD") &&
              x.col_status_kontraktual == "On Going"
          );
        } else if (_.filterby.status_pekerjaan == "Kontrak Akan Berakhir") {
          filtered = _.table.dataTable.lists.filter(
            (x) =>
              moment(x.tgl_akhir).format("YYYY-MM-DD") >=
                moment().format("YYYY-MM-DD") &&
              moment(x.tgl_akhir).format("YYYY-MM-DD") <=
                moment().add(40, "days").format("YYYY-MM-DD")
          );
        } else {
          filtered = _.table.dataTable.lists.filter(
            (x) => x.col_status_kontraktual == _.filterby.status_pekerjaan
          );
        }
        _.table.dataTable.lists = filtered;
      }

      if (_.filterby.status_serahterima != "") {
        filtered = _.table.dataTable.lists.filter(
          (x) => x.col_status_serahterima == _.filterby.status_serahterima
        );
        _.table.dataTable.lists = filtered;
      }

      if (_.filterby.id_kontraktor != "") {
        filtered = _.table.dataTable.lists.filter(
          (x) => x.col_kontraktor_name == _.filterby.id_kontraktor
        );
        _.table.dataTable.lists = filtered;
      }

      if (_.filterby.status_operasi != "") {
        filtered = _.table.dataTable.lists.filter(
          (x) => x.col_status_operasi == _.filterby.status_operasi
        );
        _.table.dataTable.lists = filtered;
      }

      if (_.filterby.bap_berjalan != "") {
        if (_.filterby.bap_berjalan == "Berjalan") {
          filtered = _.table.dataTable.lists.filter(
            (x) => x.col_nilai_outstanding > 0
          );
        } else {
          filtered = _.table.dataTable.lists.filter(
            (x) => x.col_nilai_outstanding <= 0
          );
        }
        _.table.dataTable.lists = filtered;
      }

      _.allData = _.table.dataTable.lists;
    },
    destroy(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  _.allData = [];
                  _.getList();
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },
    sortBy(x) {
      let _ = this;
      if (x === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.currentSortDir = "asc";
      }
      this.currentSort = x;

      let sorted = [];
      if (_.currentSortDir == "asc") {
        sorted = _.table.dataTable.lists.sort(function (a, b) {
          {
            if (_.currentSort == "tgl_awal" || _.currentSort == "tgl_akhir") {
              return moment(a[_.currentSort]) - moment(b[_.currentSort]);
            } else if (_.currentSort == "progress_fisik") {
              return (
                parseFloat(a[_.currentSort]) - parseFloat(b[_.currentSort])
              );
            } else {
              if (a[_.currentSort] < b[_.currentSort]) {
                return -1;
              }
              if (a[_.currentSort] > b[_.currentSort]) {
                return 1;
              }
              return 0;
            }
          }
        });
      } else {
        sorted = _.table.dataTable.lists.sort(function (a, b) {
          {
            if (_.currentSort == "tgl_awal" || _.currentSort == "tgl_akhir") {
              return moment(b[_.currentSort]) - moment(a[_.currentSort]);
            } else if (_.currentSort == "progress_fisik") {
              return (
                parseFloat(b[_.currentSort]) - parseFloat(a[_.currentSort])
              );
            } else {
              if (a[_.currentSort] > b[_.currentSort]) {
                return -1;
              }
              if (b[_.currentSort] > a[_.currentSort]) {
                return 1;
              }
              return 0;
            }
          }
        });
      }

      _.table.dataTable.lists = sorted;
    },
    addFilter() {
      let _ = this;
      _.filter.push({
        name: "-",
        by: "-",
      });
    },
    deleteFilter(x) {
      let _ = this;
      switch (_.filter[x].by) {
        case "tgl_awal":
          _.filterby.tgl_awal = "";
          break;

        case "tgl_akhir":
          _.filterby.tgl_akhir = "";
          break;

        case "id_pekerjaan":
          _.filterby.id_pekerjaan = "";
          break;

        case "id_unit":
          _.filterby.id_unit = "";
          break;

        case "status_pekerjaan":
          _.filterby.status_pekerjaan = "";
          break;

        case "status_serahterima":
          _.filterby.status_serahterima = "";
          break;

        case "status_operasi":
          _.filterby.status_operasi = "";
          break;
        case "bap_berjalan":
          _.filterby.bap_berjalan = "";
          break;

        default:
          break;
      }
      _.filter.splice(x, 1);
    },
    getPekerjaan() {
      let _ = this;
      _.axios
        .get("/work/all")
        .then((resp) => {
          _.optionsPekerjaan = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getKontraktor() {
      let _ = this;
      _.axios
        .get("v2/misc/kontraktor")
        .then((resp) => {
          _.optionsKontraktor = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getUnit() {
      let _ = this;
      _.axios
        .get("/unit/all")
        .then((resp) => {
          _.optionsUnit = resp.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    reset() {
      let _ = this;

      _.filterby.tgl_awal = "";
      _.filterby.tgl_akhir = "";
      _.filterby.id_pekerjaan = "";
      _.filterby.id_unit = "";
      _.filterby.status_pekerjaan = "";
      _.filterby.status_serahterima = "";
      _.filterby.status_operasi = "";
      _.filterby.bap_berjalan = "";
      _.filter = [
        {
          by: "-",
        },
      ];

      _.table.dataTable.lists = _.beforeFilter;
      localStorage.removeItem("dashboard_filter");
    },
  },
  watch: {
    leaderboardExpand(val) {
      if (val === true) {
        this.loadLeaderboard();
      }
    },
    search: function () {
      let _ = this;
      _.table.dataTable.lists = _.allData;
      let searched_data = _.table.dataTable.lists.filter(function (data) {
        return (
          data.judul_kontrak.toLowerCase().indexOf(_.search.toLowerCase()) >
            -1 ||
          data.no_kontrak.toLowerCase().indexOf(_.search.toLowerCase()) > -1 ||
          data.col_kontraktor_name
            .toLowerCase()
            .indexOf(_.search.toLowerCase()) > -1
        );
      });
      _.table.dataTable.lists = searched_data;
    },
  },
};
</script>

<style scoped>
.wrap-leader-see-all {
  width: 100%;
  height: 75px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.wrap-table-leaderboard {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
