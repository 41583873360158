<template>
  <section>
    <List
      :title="table.title"
      :breadcumb="table.breadcumb"
      :dataTable="table.dataTable">

      <template v-slot:button>
				<div>
					<button @click="isEdit = false" type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_Tambah_Bank" v-can="'kontraktor/create'">
              <i class="fa fa-plus fa-l" aria-hidden="true"></i> Tambah Bank
          </button>

          <button type="button" class="btn btn-primary" style="margin-left: 4px;" data-toggle="modal" data-target="#Modal_Import" v-can="'kontraktor/create'">
              <i class="fa fa-cloud-download fa-l" aria-hidden="true"></i> Import Bank
          </button>
				</div>
			</template>

      <template v-slot:search>
				<div class="input-group form-search-rounded">
            <input class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari bank disini..">
            <span class="input-group-append">
                <div class="input-group-text bg-transparent">
                    <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                </div>
            </span>
        </div>
			</template>

      <template v-slot:columnHeader>
				<thead>
            <tr>
                <th>No.</th>
                <th @click="sortBy('name')">Nama 
                    <i class="fa sort" :class="currentSort == 'name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                </th>
                <th width="10%">Aksi</th>
            </tr>
        </thead>
			</template>

      <template v-slot:action="{ data }">
          <div class="btn-action-block">
              <div @click="loadEdit(data)" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                  <i class="fa fa-pencil"></i>
              </div>
              <button @click="destroy(data)" type="button" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                  <i class="fa fa-trash"></i>
              </button>
          </div>
      </template>       
    </List>

    <!-- Modal -->
    <div class="modal fade text-left" id="Modal_Tambah_Bank" tabindex="-1" role="dialog" aria-labelledby="myModalLabel160" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">{{ isEdit ? 'Edit ' : 'Tambah ' }} Bank</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="observer">
                    <div class="modal-body">
                        <ValidationProvider name="model.name" v-slot="{ errors }" rules="required">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="">Nama Bank</label>
                                <input type="text" v-model="model.name" class="form-control" placeholder="Masukan nama bank" aria-describedby="helpId" required="">
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                        <button type="button" :disabled="invalid" @click="submit()" class="btn btn-primary" data-dismiss="modal">{{ isEdit ? 'Simpan' : 'Tambah Bank' }}</button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
    <ModalImport
      title="Bank"
      url="v2/master_bank"
      @success="loadList()"/>
  </section>
</template>

<script>
import List from '@/components/List.vue'
import ModalImport from '@/components/ModalImport.vue'

export default {
    name: 'BankPageList',
    components: {
      List,
      ModalImport
    },
    data () {
      return {
        table: {
          title: "Bank",
          breadcumb: "Master Data",
                  dataTable: {
                      lists: [],
                      columns: ['name']
                  }
        },
        isEdit: false,
        idActive: null,
        search: null,
        detail: [],
        model: {
            name: ''
        },
        currentSort: 'id',
        currentSortDir: 'desc'
      }
    },
    mounted () {
      this.loadList ()
    },
    methods : {
      async loadList () {
        try {
          const datas = (await this.axios.get('/v2/master_bank/all')).data
          this.table.dataTable.lists = {
            data : datas.data.rows
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.model.name = ''
        }
      },
      async submit () {
        try {
          if (this.isEdit) {
            const datas = (await this.axios.put('/v2/master_bank/' + this.idActive, this.model)).data
            if (!datas.status) throw "Can't Edit Bank"
            this.$refs.observer.reset();
            this.loadList ()
          } else {
            const datas = (await this.axios.post('/v2/master_bank', this.model)).data
            if (!datas.status) throw "Can't Add Bank"
            this.$refs.observer.reset();
            this.loadList ()
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.isEdit = false
        }
      },
      destroy (data) {
        this.$swal.fire({
              title: 'Hapus Data Ini?',
              text: "Anda mungkin tidak akan mengembalikannya lagi!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#44A8BD',
              cancelButtonColor: '#D95E6A',
              confirmButtonText: 'Ya, Hapus!',
              cancelButtonText: 'Batal'
              }).then(async (result) => {
                if (result.value) {
                  try {
                    const datas = (await this.axios.delete('/v2/master_bank/'+data.id)).data
                    if (!datas.status) throw "Can't Delete"
                    this.loadList ()
                  } catch (error) {
                    console.log(error)
                  }
                }
              })
      },
      async loadDetail () {
        try {
          const datas = (await this.axios.get('/v2/master_bank/' + this.idActive)).data
          if (!datas.status) throw "Can't get detail"
          this.model.name = datas.data.name
        } catch (error) {
          console.log(error)
        }
      },
      loadEdit (data) {
        window.$('#Modal_Tambah_Bank').modal('show')
        this.idActive = data.id
        this.isEdit = true
        this.loadDetail ()
      },
      sortBy(x) {
          if (x === this.currentSort) {
              this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
          } else {
              this.currentSortDir = 'asc'
          }
          this.currentSort = x;
          this.loadList()
      }
    }
}
</script>

<style>

</style>