<template>
    <div class="modal fade text-left" id="Modal_JabatanData" tabindex="-1" role="dialog" aria-labelledby="myModalLabel160" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">Data Jabatan</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="">Nama Jabatan</label>
                        <input type="text" v-model="detail.name" class="form-control" aria-describedby="helpId" readonly>
                    </div>

                    <div class="form-group">
                        <label for="">Role</label>
                        <input type="text" v-model="detail.type_text" class="form-control" aria-describedby="helpId" readonly>
                    </div>

                    <div class="form-group">
                        <label for="">Atasan</label>
                        <select v-model="detail.index" class="form-control" disabled>
                            <option value="">Pilih Atasan</option>
                            <option :value="pos.id" v-for="(pos, ix) in position.filter(x => x.id == detail.index)" :key="ix">{{ pos.name }}</option>
                        </select>
                    </div>
                    
                    <div class="form-group">
                        <label for="">Status</label>
                        <div class="custom-control custom-switch custom-control-inline mt-1">
                            <input type="checkbox" v-model="detail.status" class="custom-control-input" id="customSwitch1" disabled>
                            <label class="custom-control-label" for="customSwitch1">
                            </label>
                            <span class="switch-label">{{ detail.status ? 'Aktif' : 'Tidak Aktif' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        name: 'ModalDetail',
        props: ['detail'],
        data() {
            return {
                position: []
            }
        },
        mounted() {
            let _ = this
            _.all()
        },
        methods: {
            all() {
                let _ = this
                _.axios.get('/position/all')
                    .then(resp=>{
                        _.position = resp.data.data
                    })
                    .catch(err=>{
                        console.log(err.response)
                    })
            }
        }
	}
</script>