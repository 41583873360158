<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
					<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_TambahUser" v-can="'user/create'">
		                <i class="fa fa-plus fa-l" aria-hidden="true"></i> Tambah User
		            </button>

		            <a :href="`${base_url}/export`" class="btn btn-outline-primary" style="margin-left: 4px;" v-can="'user/export'">
		                <i class="fa fa-file-excel-o fa-l" aria-hidden="true"></i> Export User
		            </a>

                    <button type="button" class="btn btn-primary" style="margin-left: 4px;" data-toggle="modal" data-target="#Modal_ImportUser" v-can="'user/create'">
                        <i class="fa fa-cloud-download fa-l" aria-hidden="true"></i> Import User
                    </button>
				</div>
			</template>

			<template v-slot:search>
				<div class="input-group form-search-rounded">
                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari user disini..">
                    <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                        </div>
                    </span>
                </div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th @click="sortBy('name')">Nama 
                            <i class="fa sort" :class="currentSort == 'name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('unit_name')">Unit
                            <i class="fa sort" :class="currentSort == 'unit_name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('jabatan_name')">Jabatan
                            <i class="fa sort" :class="currentSort == 'jabatan_name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('bidang_name')">Bidang
                            <i class="fa sort" :class="currentSort == 'bidang_name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('subbidangs_name')">Sub Bidang
                            <i class="fa sort" :class="currentSort == 'subbidangs_name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('role_name')">Role
                            <i class="fa sort" :class="currentSort == 'role_name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('status')">Status
                            <i class="fa sort" :class="currentSort == 'status' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button v-can="'user/detail'" type="button" data-toggle="modal" data-target="#Modal_UserData" @click="detail = data" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                        <i class="fa fa-eye"></i>
                    </button>
                    <router-link v-can="'user/update'" :to="'user/edit/' + data.id" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                        <i class="fa fa-pencil"></i>
                    </router-link>
                    <button v-can="'user/delete'" type="button" @click="destroy(data.id)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>

        <ModalAddUser @success="getList"/>

        <ModalImportUser @successImport="getList"/>

        <!-- Modal Data User-->
        <ModalDetail v-if="detail != null" :detail="detail"/>
	</section>
</template>

<script>
import List from '@/components/List.vue'
import ModalDetail from './ModalDetail.vue'
import ModalAddUser from './ModalAddUser.vue'
import ModalImportUser from './ModalImportUser.vue'

export default {
	name: 'UserIndex',
	components: {
		List,
        ModalDetail,
        ModalAddUser,
        ModalImportUser
	},
	data() {
		return {
            base_url: process.env.VUE_APP_BASE_URL,
			table: {
				title: "User List",
				breadcumb: "User & Role Management",
                dataTable: {
                    lists: [],
                    columns: ['name', 'unit_name', 'jabatan_name', 'bidang_name', 'subbidangs_name', 'role_name', 'status_text']
                }
			},
            search: null,
            detail: [],
            currentSort: 'id',
            currentSortDir: 'desc'
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/user?search=' + _.search + '&page=' + page + '&orderby=' + _.currentSort + '&sort=' + _.currentSortDir)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            this.$swal.fire({
                title: 'Hapus Data Ini?',
                text: "Anda mungkin tidak akan mengembalikannya lagi!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#44A8BD',
                cancelButtonColor: '#D95E6A',
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'Batal'
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/user/delete', { data: { id: x }})
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    this.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        sortBy(x) {
            if (x === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortDir = 'asc'
            }
            this.currentSort = x;
            this.getList()
        }
    },
    watch: {
        search: function() {
            this.getList()
        }
    }
}
</script>