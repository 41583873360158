<template>
	<div>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }} </h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" v-if="breadcumb">
                                <a>{{ breadcumb }}</a>
                            </li>

                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col mb-1">
                                            <div class="btn-responsive">
                                                <slot name="button"></slot>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-4 mb-1">
                                            <slot name="search"></slot>
                                        </div>
                                    </div>

                                    <div class="table-responsive tableFixHead" :style="{maxHeight: maxHeight}">
                                        <table class="table mb-0">
                                            <slot name="columnHeader"></slot>
                                            <tbody>
                                                <tr v-for="(data, key, i) in dataTable.lists.data" :key="key">
                                                    <td>{{ ((dataTable.lists.current_page - 1) * dataTable.lists.per_page + (key > 9 ? i : key) + 1) || key + 1 }}</td>
                                                    <td v-for="(column, kcolumn) in dataTable.columns" :key="kcolumn">                                                        
                                                        <span v-if="column == 'no_kontrak'">
                                                            <router-link :to="'kontrak/' + data.id" v-html="data[column]"></router-link>
                                                        </span>
                                                        <span v-else-if="column == 'no_kontrak_lap'">
                                                            <router-link :to="'kontrak/' + data.id_kontrak" v-html="data[column]"></router-link>
                                                        </span>
                                                        <span v-else-if="column == 'judul_kontrak'">
                                                            <strong>
                                                                <router-link style="font-size: 15px" :to="'kontrak/' + data.id" v-html="data[column]"></router-link>
                                                            </strong>
                                                        </span>
                                                        <span class="text-right" v-else-if="column == 'nilai_kontrak'">
                                                             <p class="text-right">{{data[column] | currency}}</p>
                                                        </span>
                                                        <span v-else-if="column == 'idr'">
                                                            {{data[column] | currency}}
                                                        </span>
                                                        <span v-else-if="column == 'nilai'">
                                                            {{data[column] | currency}}
                                                        </span>
                                                        <span style="text-align:right;" v-else-if="column == 'nilai_realisasi'">
                                                            <p class="text-right">{{data[column]}}</p>
                                                        </span>

                                                        <span style="text-align:right;" v-else-if="column == 'nilai_ai'">
                                                            <p class="text-right">{{data[column]}}</p>
                                                        </span>

                                                        <span style="text-align:right;" v-else-if="column == 'nilai_aki'">
                                                            <p class="text-right">{{data[column]}}</p>
                                                        </span>
                                                        <span v-else style="font-size: 15px" v-html="!data[column] ? '-' : data[column]"></span>
                                                    </td>
                                                    <td v-if="noAction !== true">
                                                        <slot name="action" v-bind:data="data"></slot>
                                                    </td>
                                                </tr>
                                            </tbody>           
                                        </table>
                                        <div class="col text-center mt-1">
                                            <p v-if="dataTable.lists.total == 0">Tidak ada data.</p>
                                        </div>                                                                           
                                    </div>
                                    <PaginateList :data="dataTable.lists" v-if="dataTable.lists.total != 0 && showPagination"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
import PaginateList from '@/components/PaginateList'

export default {
	name: 'List',
    props: {
        showPagination : {
            type: Boolean,
            default : true
        },
        title: {
            type: String,
            default: '',
        },
        noAction: {
            type: Boolean,
            default: false,
        },
        breadcumb: {
            type: String,
            default: '',
        },
        dataTable: {
            type: Object,
            default () {
                return {}
            }
        },
        isBreadcrumb: {
            type: [Boolean, String, Number],
            default: false,
        },
        maxHeight: {
            type: [Number, String],
            default: '500px'
        }
    },
    components: {
        PaginateList
    },
    data() {
        return {
            role_id: localStorage.getItem('role_id')
        }
    }
}
</script>