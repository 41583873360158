<template>
	<div class="row">
        <div class="col-12 col-lg-6 col-xl-7 d-none d-lg-block">
            <div class="full-img">
                <img src="/assets/img/gistet-tambun.jpg" class="img-fluid" alt="">
                <div class="logo-block">
                    <img src="/assets/img/logo-text-white-min.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>

        <ValidationObserver class="col-12 col-md-8 col-lg-6 col-xl-5 ml-auto mr-auto" ref="observer">
            <div class="form-auth-block">
                <div class="form-logo d-block d-lg-none">
                    <img src="/assets/img/logo.png" class="img-fluid" alt="">
                    <div class="sub-logo">Monitoring <span>Digital</span></div>
                </div>

                <h4 class="title-heading-form text-primary">Lupa Password?</h4>    
                <p class="subtitle-form">Jangan khawatir. Masukan email untuk dikirimkan kode OTP sebagai verifikasi membuat password baru.</p>

                <ValidationProvider name="email" immediate rules="required|email">
                    <div class="form-group" :class="{ 'validation-warning': errors[0] || errMessage!='' }" slot-scope="{ errors }">
                        <input type="email" v-model="email" class="form-control form-custom-bg" id="user-email" placeholder="Email" required="">
                        <small v-if="errors[0]" id="" class="text-muted">{{ capitalize(errors[0]) }}</small>
                        <small v-if="errMessage!='' && errors.length == 0" id="" class="text-muted">{{ capitalize(errMessage) }}</small>
                    </div>
                </ValidationProvider>

                <button type="submit" @click="submitForm" class="btn btn-primary waves-effect waves-light w-100">Kirim Link</button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

export default {
	name: 'ForgotPassword',
    data() {
        return {
            email: '',
            errMessage: ''
        }
    },
    mounted() {
        let _ = this
        _.$refs.observer.reset();
    },
    methods: {
        submitForm() {
            let _ = this
            // _.$router.push('/otp')
            
            _.axios.post('/auth/forgot', { email: _.email })
                .then(resp => {
                    if ( resp.status == 200 ) {
                        _.$router.push({path: '/otp', query: { email: _.email }})
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if ( _.email == '' ) {
                        _.errMessage = 'email harus diisi'
                    } else {
                        _.errMessage = err.response.data.message
                    }
                })
        },
        capitalize(s) {
          if (typeof s !== 'string') return ''
          return s.charAt(0).toUpperCase() + s.slice(1)
        }
    },
    watch: {
        'email': function() {
            let _ = this
            _.errMessage = ''
        }
    }
}
</script>