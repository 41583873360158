<template>
  <div v-if="!loadStatePage">
      <div class="content-header">
          <div class="content-header-left mb-2">
              <div class="breadcrumbs-top">
                  <h2 class="content-header-title mb-0">Detail Kontrak</h2>
                  <div class="breadcrumb-wrapper">
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                              <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                  <i class="feather icon-home"></i>
                              </router-link>
                          </li>

                          <li class="breadcrumb-item">
                              <router-link to="/kontrak">Monitoring Project</router-link>
                          </li>
                          <li class="breadcrumb-item">
                              <a href="#">Project List</a>
                          </li>
                          <li class="breadcrumb-item">
                              <a href="#">{{ detail.kontrak.no_kontrak }}</a>
                          </li>
                          <li class="breadcrumb-item">
                              <a href="#">Detail Kontrak</a>
                          </li>
                          <li class="breadcrumb-item active">Detail Progress</li>
                      </ol>
                  </div>
              </div>
              <div class="d-flex mt-2" style="gap: 10px">
                  <router-link to="/approval" style="font-size: 20px">
                    <i class="feather icon-arrow-left"></i>
                  </router-link>
                  <h2 class="content-header-title mb-0">Detail Progress</h2>
              </div>
          </div>
      </div>
      <div class="content-body">
          <section id="basic-datatable">
              <div class="row">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-content">
                              <div class="card-body card-dashboard">
                                <span v-html="detail.status_text"></span>
                                <div class="row mb-2 mt-2">
                                  <div class="col col-md-6 d-flex flex-column" style="gap: 30px">
                                      <div>
                                        <p class="d-block text-black"><strong>Periode Progress</strong></p>
                                        <span class="text-mutted">{{ detail.col_type }}</span>
                                      </div>
                                      <div>
                                        <p class="d-block text-black"><strong>Uraian</strong></p>
                                        <span class="text-mutted" v-html="detail.description">
                                        </span>
                                      </div>
                                      <div>
                                        <p class="d-block text-black"><strong>Jumlah Pekerja</strong></p>
                                        <span class="text-mutted">{{ detail.pekerja }}</span>
                                      </div>
                                      <div v-if="detail && detail.image && detail.image.length">
                                        <p class="d-block text-black"><strong>Foto</strong></p>
                                        <div class="wrapper-ap-gallery">
                                          <img v-for="(img, idx) in detail.image" :key="idx" :src="img.url" alt="img">
                                        </div>
                                      </div>
                                      <div>
                                        <p class="d-block text-black"><strong>Inputed By : {{ detail && detail.user && detail.user.position && detail.user.position.name }}</strong></p>
                                        <div class="wrapper-ap-gallery">
                                          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png" alt="industry img">
                                        </div>
                                        <div class="d-flex flex-column">
                                          <span class="text-black">{{ detail && detail.user && detail.user.name }}</span>
                                          <span class="text-black">{{ detail && detail.user && detail.user.phone }}</span>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col col-md-6 d-flex flex-column" style="gap: 30px">
                                      <div v-if="detail.histories.length">
                                        <p class="d-block text-black"><strong>History Catatan</strong></p>
                                        <div class="history-catatan-list">
                                          <div v-for="(h, idx) in detail.histories" :key="idx" :class="{ 'history-row-list': true, 'bg-row-gray' : idx % 2 === 0, 'bg-row-gray' : idx % 2 !== 0, }">
                                              <div class="avatar-40px">
                                                <img :src="h.raw_json.image[0].url || 'x'" alt="avatar">
                                              </div>
                                              <div class="d-flex flex-column justify-content-center">
                                                <span class="h5 m-0 text-black">
                                                  {{ h.raw_json.name }}
                                                </span>
                                                <span class="d-inline text-primary"><small>{{ h.raw_json.col_created_at }} {{ h.raw_json.created_at | hhMS }}</small></span>
                                              </div>
                                              <div class="ml-2">
                                                <span v-html="h.raw_json.status_text"></span>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col col-md-6">
                                    <button @click="notApprove()" class="btn btn-danger" style="width: 100%">
                                      <span class="text-white"><strong>Not Aproved</strong></span>
                                    </button>
                                  </div>
                                  <div class="col col-md-6">
                                    <button @click="approve()" class="btn btn-primary" style="width: 100%">
                                      <span class="text-white"><strong>Aproved</strong></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
  </div>
  <div class="mx-auto text-center" style="width:100%" v-else>
      <h2><i class="fa fa-spinner fa-spin"></i></h2>
  </div>
</template>

<script>
export default {
    name: 'DetailProgressApprove',
    data () {
        return {
            role_id: localStorage.getItem('role_id'),
            detail: null,
            loadStatePage: true,
            idData: null
        }
    },
    filters : {
      hhMS (val) {
        if (!val) return '-'
        return val.substring(10, val.length)
      }
    },
    mounted () {
      this.idData = this.$route.params.id
      this.loadDetail ()
    },
    methods : {
      async loadDetail () {
        this.loadStatePage = true
        try {
          const datas = (await this.axios.get('/v2/progress/' + this.idData)).data
          if (!datas.status) throw 'Error'
          this.detail = datas.data
        } catch (error) {
          console.log(error)
        } finally {
          this.loadStatePage = false
        }
      },
      async approve () {
        try {
          const datas = (await this.axios.put(`/v2/progress/${this.idData}/status`, { approved_by: 1 })).data
          if (!datas.status) throw "Error"
          this.loadDetail ()
        } catch (error) {
          console.log(error)
        }
      },
      async notApprove () {
        try {
          const datas = (await this.axios.put(`/v2/progress/${this.idData}/status`, { approved_by: 0 })).data
          if (!datas.status) throw "Error"
          this.loadDetail ()
        } catch (error) {
          console.log(error)
        }
      }
    }
}
</script>

<style scoped>
.wrapper-ap-gallery {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.wrapper-ap-gallery > img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.history-catatan-list {
  display: flex;
  flex-direction: column;
}
.history-row-list {
  height: 65px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 10px;

}
.bg-row-gray {
  background: #F9F9F9;
}
.bg-row-white {
  background: #fff;
}

.avatar-40px {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.06));
}
.avatar-40px > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
</style>