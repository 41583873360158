<template>
	<section>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-12 col-sm-6 mb-1">
                                            <div class="btn-responsive">
                                                
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-4 mb-1">
                                           <div class="input-group form-search-rounded">
                                                <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari dokumen permohonan..">
                                                <span class="input-group-append">
                                                    <div class="input-group-text bg-transparent">
                                                        <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>  
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th @click="sortBy('keterangan')">Nama
                                                        <i class="fa sort" :class="currentSort == 'keterangan' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                                    </th>
                                                    <th @click="sortBy('created_at')">Tanggal
                                                        <i class="fa sort" :class="currentSort == 'created_at' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                                    </th>
                                                    <th @click="sortBy('Status')">Status
                                                        <i class="fa sort" :class="currentSort == 'created_at' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                                                    </th>
                                                        
                                                        
                                                    <th width="20%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataTable.lists.length > 0">
                                                <tr v-for="(data, key) in dataTable.lists" :key="key">
                                                    <td>{{ key+1 }}</td>
                                                   
                                                    <td>{{ data.keterangan }}</td>
                                                    <td>{{ moment(data.created_at).format("DD MMMM YYYY")}}</td>
                                                    <td>{{ data.status }}</td>
                                                    <td>
                                                         <p v-for="(item,index) in data.dokumen" :key="item.name">
                                                            <a @click="pdfViewer(item.url)" href="javascript:;">• File {{ index+1 }} [Lihat Detail]</a>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>  
                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="5" class="text-center">Tidak ada data.</td>
                                                </tr>
                                            </tbody>         
                                        </table>
                                                                                                          
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-10"></div>
                                        <div class="col-sm-12 col-md-2 ">
                                            <div class="dataTables_paginate paging_simple_numbers float-right" id="DataTables_Table_0_paginate" style="position:relative;right:0">
                                                <ul class="pagination"><li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                                                    <a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link"> </a>
                                                    </li>
                                                    <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                                                    <li class="paginate_button page-item next disabled" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link"> </a></li>
                                                </ul>
                                            </div> 
                                        </div>                                                                   
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="modal fade text-left" id="Modal_PDFViewer" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">View File</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="pdfFile">
                        <embed :src="pdfFile" type="application/pdf" width="100%" height="600px" />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
import moment from 'moment'

export default {
    
	name: 'LihatRekomendasi',
	components: {
	},
	data() {
		return {
			
			title: "Dokumen File",
			breadcumb: [
                                  
            ],
            dataTable: {
                lists: [],
            },
            tanggal_awal : '',
            search: null,
            detail: [],
            moment : moment,
            currentSort: 'id',
            currentSortDir: 'desc',
            role_id: localStorage.getItem('role_id'),
            pdfFile: ''
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        window.$("#Modal_PDFViewer").on('hidden.bs.modal', function () {
            // do something...
            _.pdfFile = ""
        })
    },
    methods: {
        pdfViewer(file) {
            let _ = this
            _.pdfFile = file
            // Modal_PDFViewer
            window.$("#Modal_PDFViewer").modal('show')
        },
        getList(page) {
            let _ = this
            _.breadcumb = [
                        {
                            name: 'Monitoring Project', 
                            link: '',
                            active: false
                        },
                        {
                            name: 'Project List',
                            link: '/kontrak',
                            active: false
                        },   
                        {
                            name: 'Detail Kontrak', 
                            link: '/kontrak/'+_.$route.params.id,
                            active: false
                        }, 
                        {
                            name: 'Dokumen Permohonan', 
                            link: '',
                            active: true
                        },           
                    ],
            _.axios.get('/kontrak/permohonan?key=' + _.search + '&page=' + page + '&orderby=' + _.currentSort + '&sort=' + _.currentSortDir 
                    + '&id_kontrak=' + _.$route.params.id
                )
                .then(resp=>{
                    _.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
       
        sortBy(x) {
            if (x === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortDir = 'asc'
            }
            this.currentSort = x;
            this.getList()
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
    }
}
</script>