<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
					<button v-can="'progress_mtu_status/create'" type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_TambahProgressMTUStatus">
                    <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_TambahProgressMTUStatus"> -->
		                <i class="fa fa-plus fa-l" aria-hidden="true"></i> Tambah Progress MTU Status
		            </button>
				</div>
			</template>

            <template v-slot:search>
				<div class="input-group form-search-rounded">
                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari status progress MTU disini..">
                    <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                        </div>
                    </span>
                </div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th>Nama</th>
                        <th>Jenis Pekerjaan</th>
                        <th>Level</th>
                        <th>Persentase</th>
                        <th>Nilai Kontrak</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button v-can="'progress_mtu_status/detail'" type="button" data-toggle="modal" data-target="#Modal_DataProgressMTUStatus" @click="detail = JSON.parse(JSON.stringify(data))" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                    <!-- <button type="button" data-toggle="modal" data-target="#Modal_DataProgressMTUStatus" @click="detail = data" class="btn btn-icon-small btn-success-trans waves-effect waves-light"> -->
                        <i class="fa fa-eye"></i>
                    </button>
                    <button v-can="'progress_mtu_status/update'" type="button" data-toggle="modal" data-target="#Modal_EditProgressMTUStatus" @click="detail = JSON.parse(JSON.stringify(data))" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                    <!-- <button type="button" data-toggle="modal" data-target="#Modal_EditProgressMTUStatus" @click="detail = data" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;"> -->
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button v-can="'progress_mtu_status/delete'" type="button" @click="destroy(data)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                    <!-- <button type="button" @click="destroy(data)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;"> -->
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>

        <!-- Modal -->
        <div class="modal fade text-left" id="Modal_TambahProgressMTUStatus" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Tambah Progress MTU Status</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ValidationObserver v-slot="{ invalid }" ref="observer">
                        <div class="modal-body">
                            <ValidationProvider name="nama status" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Nama Status</label>
                                    <input type="text" v-model="model.name" class="form-control" placeholder="Masukan nama status">
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="jenis pekerjaan" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Jenis Pekerjaan</label>
                                    <select class="form-control" v-model="model.jenis_pekerjaan" @change="model.slug = ''">
                                        <option value="">Pilih Jenis Pekerjaan</option>
                                        <option v-for="(opt, idx) in jenisPekerjaan" :key="idx" :value="opt.value">{{ opt.text }}</option>
                                    </select>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="level" v-slot="{ errors }" rules="required|min_value:1">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Level</label>
                                    <input type="number" min="1" v-model="model.level" class="form-control" placeholder="Masukan level">
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="persentase" v-slot="{ errors }" rules="required|min_value:1|max_value:100">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Persentase</label>
                                    <input type="number" min="1" v-model="model.percentage" class="form-control" placeholder="Masukan persentase">
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="nilai kontrak" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Nilai Kontrak</label>
                                    <select class="form-control" v-model="model.slug">
                                        <option value="">Pilih Nilai Kontrak</option>
                                        <option v-for="(opt, idx) in nilaiKontrakOptions.filter(function(x) {
                                            return x.jenis_pekerjaan.indexOf(model.jenis_pekerjaan) != -1;
                                        })" :key="idx" :value="opt.slug">{{ opt.name }}</option>
                                    </select>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" :disabled="invalid" @click="save()" class="btn btn-primary" data-dismiss="modal">Tambah Status</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div class="modal fade text-left" id="Modal_EditProgressMTUStatus" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Edit Progress MTU Status</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ValidationObserver v-slot="{ invalid }" ref="observerEdit">
                        <div class="modal-body">
                            <ValidationProvider name="nama status" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Nama Status</label>
                                    <input type="text" v-model="detail.name" class="form-control" placeholder="Masukan nama status">
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="jenis pekerjaan" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Jenis Pekerjaan</label>
                                    <select class="form-control" v-model="detail.jenis_pekerjaan">
                                        <option value="">Pilih Jenis Pekerjaan</option>
                                        <option v-for="(opt, idx) in jenisPekerjaan" :key="idx" :value="opt.value">{{ opt.text }}</option>
                                    </select>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="level" v-slot="{ errors }" rules="required|min_value:1">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Level</label>
                                    <input type="number" min="1" v-model="detail.level" class="form-control" placeholder="Masukan level">
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="persentase" v-slot="{ errors }" rules="required|min_value:1|max_value:100">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Persentase</label>
                                    <input type="number" min="1" v-model="detail.percentage" class="form-control" placeholder="Masukan persentase">
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="nilai kontrak" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Nilai Kontrak</label>
                                    <select class="form-control" v-model="detail.slug">
                                        <option value="">Pilih Nilai Kontrak</option>
                                        <option v-for="(opt, idx) in nilaiKontrakOptions" :key="idx" :value="opt.slug">{{ opt.name }}</option>
                                    </select>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" :disabled="invalid" @click="update()" class="btn btn-primary" data-dismiss="modal">Edit Status</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div class="modal fade text-left" id="Modal_DataProgressMTUStatus" tabindex="-1" role="dialog"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Data Progress MTU Status</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">Nama Status</label>
                            <input type="text" v-model="detail.name" class="form-control" placeholder="Masukan nama status" readonly>
                        </div>
                        <div class="form-group">
                            <label for="">Jenis Pekerjaan</label>
                            <select class="form-control" v-model="detail.jenis_pekerjaan" disabled>
                                <option value="">Pilih Jenis Pekerjaan</option>
                                <option v-for="(opt, idx) in jenisPekerjaan" :key="idx" :value="opt.value">{{ opt.text }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">Level</label>
                            <input type="number" min="1" v-model="detail.level" class="form-control" placeholder="Masukan level" readonly>
                        </div>
                        <div class="form-group">
                            <label for="">Persentase</label>
                            <input type="number" min="1" v-model="detail.percentage" class="form-control" placeholder="Masukan persentase" readonly>
                        </div>
                        <div class="form-group">
                            <label for="">Nilai Kontrak</label>
                            <select class="form-control" v-model="detail.slug" disabled>
                                <option value="">Pilih Nilai Kontrak</option>
                                <option v-for="(opt, idx) in nilaiKontrakOptions" :key="idx" :value="opt.slug">{{ opt.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	components: {
		List,
	},
	data() {
		return {
			table: {
				title: "Progress MTU Status",
				breadcumb: "Master Data",
                dataTable: {
                    lists: [],
                    columns: ['name', 'jenis_pekerjaan', 'level', 'percentage', 'slug']
                }
			},
            search: null,
            detail: {
                name: null,
                jenis_pekerjaan: '',
                level: null,
                percentage: null,
                slug: '',
            },
            model: {
                name: null,
                jenis_pekerjaan: '',
                level: null,
                percentage: null,
                slug: '',
            },
            jenisPekerjaan : [
                {
                    text: 'GIS',
                    value: 'GIS'
                },
                {
                    text: 'Trafo',
                    value: 'Trafo'
                },
                {
                    text: 'AIS',
                    value: 'AIS'
                },
                {
                    text: 'Konduktor',
                    value: 'Konduktor'
                },
                {
                    text: 'Tower',
                    value: 'Tower'
                },
            ],
            nilaiKontrakOptions: [],
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getNilaiKontrak()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page = 1) {
            let _ = this
            _.axios.get('/v2/progress-mtu-status?key=' + _.search + '&page=' + page + '&per_page=10')
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            _.axios.post('/v2/progress-mtu-status', _.model)
                .then(resp => {
                    if (resp.status) {
                        _.getList()
                        _.$toast.success('Data Berhasil Disimpan')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Disimpan')
                    }
                    _.model = {
                        color: null,
                        name: null
                    }
                    _.$refs.observer.reset();
                })
                .catch(err=>{
                    console.log(err.response)
                    _.model = {
                        color: null,
                        name: null
                    }
                    _.$refs.observer.reset();
                    _.$toast.warning('Data Progress MTU Status Sudah Digunakan')
                })
        },
        update() {
            let _ = this
            _.axios.put('/v2/progress-mtu-status/' + _.detail.id, _.detail)
                .then(resp => {
                    if (resp.status) {
                        _.getList()
                        _.$toast.success('Data Berhasil Diubah')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Diubah')
                    }
                    _.$refs.observerEdit.reset();
                })
                .catch(err=>{
                    console.log(err.response)
                    _.$refs.observerEdit.reset();
                    _.$toast.warning(err.response.data.message)
                })
        },
        destroy(x) {
            let _ = this
            this.$swal.fire({
                title: 'Hapus Data Ini?',
                text: "Anda mungkin tidak akan mengembalikannya lagi!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#44A8BD',
                cancelButtonColor: '#D95E6A',
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'Batal'
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/v2/progress-mtu-status/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    this.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                                _.$toast.warning(err.response.data.message)
                            })                        
                    }
            })
            
        },
        getNilaiKontrak() {
            let _ = this
            _.axios.get('/v2/misc/nilai-kontrak')
                .then(resp=>{
                    _.nilaiKontrakOptions = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
    },
    watch: {
        search: function() {
            this.getList()
        }
    }
}
</script>