<template>
  <div>
    <div class="content-header">
        <div class="content-header-left mb-2">
            <div class="breadcrumbs-top">
                <h2 class="content-header-title mb-0">{{ titlePage }} UIP JBB</h2>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                <i class="feather icon-home"></i>
                            </router-link>
                        </li>

                        <li class="breadcrumb-item">
                            <a>Laporan</a>
                        </li>

                        <li class="breadcrumb-item active">{{ titlePage }} UIP JBB</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body card-dashboard">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row align-items-center justify-content-between">
                                            <div class="col mb-1">
                                                <div class="btn-responsive">
                                                   <div class="d-flex align-items-center">
                                                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_Filter">
                                                            Filter Laporan
                                                        </button>

                                                        <button @click="exportExcel" type="button" class="btn btn-outline-primary" style="margin-left: 4px;" data-toggle="modal" data-target="#Modal_Import" v-can="'laporan/bay_stp/excel'">
                                                            <div v-if="!loadStateExport" class="mx-auto text-center" style="width:100%">
                                                                <i class="fa fa-spinner fa-spin"></i>
                                                            </div>
                                                            <span v-else>Cetak Excel</span>
                                                        </button>
                                                        <div class="form-check ml-2">
                                                            <input type="radio" class="form-check-input" id="energize" name="status" @change="() => $router.push('/laporan/kontrak/energize?'+queryPage)">
                                                            <label class="form-check-label text-primary" for="energize">Proyek telah Energize</label>
                                                        </div>
                                                        <div class="form-check ml-2">
                                                            <input type="radio" class="form-check-input" id="slo" name="status" :checked="true">
                                                            <label class="form-check-label text-primary" for="slo">Bay telah Energize</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-4 mb-1">
                                                <div class="input-group form-search-rounded">
                                                    <input v-model="payloadList.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari disini..">
                                                    <span class="input-group-append">
                                                        <div class="input-group-text bg-transparent">
                                                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div v-if="loadStateList" class="col-12">
                                                <div class="wrap-table-leaderboard">
                                                    <el-table stripe empty-text="No Data"  :data="tableData.list" style="width: 100%" class="table-ipm-theme">
                                                        <el-table-column width="90" fixed>
                                                            <template #header>
                                                                <span class="text-primary text-center d-block"><strong>No</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <a style="display: block; text-align: center; cursor: normal">
                                                                    {{ e.row.no || 0 }}
                                                                </a>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="200" fixed>
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>UPP</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <router-link :to="'#'" style="display: block;">
                                                                    <span class="no-decoration">{{ e.row.unit_name }}</span>
                                                                </router-link>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="350">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Judul Kontrak</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <router-link :to="'/kontrak/'+e.row.id" style="display: block;">
                                                                    <span class="no-decoration">{{ e.row.judul_kontrak }}</span>
                                                                </router-link>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="230">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>No Kontrak</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <a style="display: block; color: black !important;">{{ e.row.no_kontrak }}</a>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="350">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Nama Scope</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <router-link :to="'/kontrak/'+e.row.id" style="display: block;">
                                                                    <span class="no-decoration">{{ e.row.judul_scope }}</span>
                                                                </router-link>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="170">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Tanggal Energize</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <a style="display: block; color: black !important;">{{ e.row.date | dateParse }}</a>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="170">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Jenis Pekerjaan</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <a style="display: block; color: black !important;">
                                                                    <strong class="text-blue">{{ e.row.jenis_pekerjaan }}</strong>
                                                                </a>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="170">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Status Operasi</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <div :class="{'badge badge-success badge-md' : true}">{{ e.row.status }}</div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="170">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Lihat Dokumen</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <div class="d-flex justify-content-center">
                                                                    <button @click="doc_url = e.row.document_link" type="button" data-toggle="modal" data-target="#Modal_PDFViewer" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                                                                        <i class="fa fa-file-pdf-o"></i>
                                                                    </button>
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                            </div>
                                            <div v-else class="col-12">
                                                <div class="mx-auto text-center" style="width:100%">
                                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2" style="text-align: end">
                                        <paginate
                                            :page-count="tableData.total_page"
                                            :click-handler="paginateCb"
                                            :prev-text="'Prev'"
                                            :next-text="'Next'"
                                            container-class="pagination-v2">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- <div 
        class="modal fade text-left" 
        id="Modal_Filter" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Filter</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <ValidationProvider name="Start Date" rules="" v-slot="{ errors }">
                                        <label for="">Start Date</label>
                                        <date-picker
                                            format="DD/MM/YYYY"
                                            type="date"
                                            v-model="payloadList.start"
                                            placeholder="Tanggal Mulai"
                                            input-class="form-control"
                                            value-type="YYYY-MM-DD"
                                            style="width: 100%;"
                                            >
                                        </date-picker>
                                        <small>{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <ValidationProvider name="End Date" rules="" v-slot="{ errors }">
                                        <label for="">End Date</label>
                                        <date-picker
                                            format="DD/MM/YYYY"
                                            type="date"
                                            v-model="payloadList.end"
                                            placeholder="Tanggal Mulai"
                                            input-class="form-control"
                                            value-type="YYYY-MM-DD"
                                            style="width: 100%;"
                                            >
                                        </date-picker>
                                        <small>{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="reset" @click="resetFilter()" class="btn btn-outline-primary" data-dismiss="modal">Reset</button>
                        <button v-if="true" @click="loadList()" type="button" :disabled="invalid" class="btn btn-primary">
                            <span>Filter</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div> -->
    <div class="modal fade text-left show" id="Modal_Filter" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">Filter Kontrak</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mb-1" v-for="(f, idx) in filter" :key="idx">
                        <div class="col-8 col-sm-10">
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="filter_by">Filter Berdasarkan</label>
                                        <select class="form-control" v-model="f.by" :disabled="f.by != '-'">
                                            <option v-for="(data, index) in filterOption" :key="index" :value="data.by">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6" v-if="f.by == 'id_unit'">
                                    <div class="form-group">
                                        <label for="filter_by">UPP</label>                                                                                        
                                        <select class="form-control" v-model="payloadList.id_unit">
                                            <option v-for="(unit, index) in optionsUnit.filter(unit => unit.id > 1)" :key="index" :value="unit.id">{{unit.name}}</option>
                                        </select>                                                                             
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6" v-else-if="f.by == 'tgl_awal'">
                                    <div class="form-group">
                                        <label for="filter_by">Tanggal Awal</label>
                                        <div class="position-relative has-icon-left">                                                
                                            <date-picker
                                                v-model="payloadList.start"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="Tanggal awal (dd/mm/yyyy)"  
                                                style="width: 100%"
                                            ></date-picker>
                                        </div>                                            
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6" v-else-if="f.by == 'tgl_akhir'">
                                    <div class="form-group">
                                        <label for="filter_by">Tanggal Akhir</label>
                                        <div class="position-relative has-icon-left">                                                
                                            <date-picker
                                                v-model="payloadList.end"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="Tanggal akhir (dd/mm/yyyy)"   
                                                style="width: 100%"
                                            ></date-picker>
                                        </div>                                            
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6" v-else>
                                    <div class="form-group">
                                        <label for="filter_by">-</label>
                                        <input type="text" class="form-control" disabled>                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-sm-2 d-flex align-items-center">
                            <button class="btn-transparent" @click="addFilter()" v-if="idx == filter.length - 1">
                                <svg class="img-fluid mr-2" width="20" height="21" viewBox="0 0 20 21">
                                    <path fill="#17536A" fill-rule="nonzero" d="M10 .134c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zm0 18.75a8.75 8.75 0 1 1 0-17.5 8.75 8.75 0 0 1 0 17.5zm3.75-9.375h-3.125V6.384a.625.625 0 0 0-1.25 0v3.125H6.25a.625.625 0 0 0 0 1.25h3.125v3.125a.625.625 0 0 0 1.25 0v-3.125h3.125a.625.625 0 0 0 0-1.25z"></path>
                                </svg>
                            </button>
                            <button class="btn-transparent" @click="deleteFilter(idx)" v-if="filter.length > 1">
                                <svg class="img-fluid mr-2" width="20" height="21" viewBox="0 0 20 21">
                                    <path fill="#DA3E2C" fill-rule="nonzero" d="M17.314 3.314C13.547-.726 7.219-.946 3.18 2.82c-4.04 3.767-4.26 10.094-.494 14.134 3.767 4.039 10.095 4.26 14.134.493 4.039-3.766 4.26-10.094.494-14.133zM3.6 16.1A8.75 8.75 0 1 1 16.399 4.166 8.75 8.75 0 0 1 3.601 16.101zm9.414-3.651l-2.132-2.286 2.286-2.13a.625.625 0 0 0-.853-.915L10.031 9.25 7.9 6.965a.625.625 0 0 0-.915.852l2.132 2.286-2.286 2.13a.625.625 0 0 0 .853.915l2.285-2.131 2.131 2.285a.625.625 0 0 0 .915-.852z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="reset()">Reset</button>
                    <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="loadList()">Filter</button>
                </div>
            </div>
        </div>
    </div> 
    <div class="modal fade text-left" id="Modal_PDFViewer" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel160" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width:95%; max-height: 95%;" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">View File</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-if="doc_url" style="height: 80vh;">
                    <embed :src="doc_url+'?#zoom=125'" type="application/pdf" width="100%" height="100%"/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// import TableV2 from '@/components/TableV2.vue'
import DatePicker from 'vue2-datepicker';
import paginate from 'vuejs-paginate'
import ld from 'lodash'

export default {
    name: 'LaporanEnergizeBAYPage',
    components: { DatePicker,paginate },
    data() {
        return {
            role_id: localStorage.getItem('role_id'),
            loadStateList: false,
            queryPage: '',
            doc_url: null,
            titlePage: '',
            loadStateExport: true,
            payloadList: {
                per_page: 10,
                page: 1,
                search: null,
                start: null,
                end: null,
                id_unit:null,
                status: '',
            },
            tableData: {
                current_page: 0,
                total_page: 0,
                list: [],
            },
            filter: [
                {
                    'by': '-'
                }
            ],
            filterOption: 
            [
                {
                    'name': '-',
                    'by': '-'
                },
                {
                    'name': 'Tanggal Awal',
                    'by': 'tgl_awal'
                },
                {
                    'name': 'Tanggal Akhir',
                    'by': 'tgl_akhir'
                },
                {
                    'name': 'UPP',
                    'by': 'id_unit'
                }
            ],
            optionsUnit: [],
        }
    },
    watch: {
        'payloadList.search' : ld.debounce (function () {
            this.payloadList.page = 1
            this.loadList ()
        }, 300),
        '$route.params.status' : function () {
            this.$router.go()
        }
    },
    mounted () {
        this.payloadList.start = this.$route.query?.start ?? null
        this.payloadList.end = this.$route.query?.end ?? null
        this.parseQueryPage ()
        this.titlePage = 'BAY Telah Energize'
        this.payloadList.status = 'Energize'
        this.loadList ()
        this.getUnit()
    },
    methods: {
        getUnit() {
            let _ = this
            _.axios.get('/unit/all')
                .then(resp => {
                    _.optionsUnit = resp.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        parseQueryPage () {
            const query = this.$route.query
            let parsed = ''
            for (const [key, val] of Object.entries(query)) {
                parsed += `${key}=${val}&`
            }
            this.queryPage = parsed.substring(0, parsed.length - 1)
        },
        async loadList () {
            this.loadStateList = false
            try {
                const datas = (await this.axios.get('/v2/reports/kontrak/status-operasi/bay', { params: this.payloadList })).data
                if (!datas.status) throw datas
                this.tableData.list = datas.data.data.map((e, key, i) => {
                    e.no = ((datas.data.current_page - 1) * parseInt(datas.data.per_page) + (key > 9 ? i : key) + 1) || key + 1
                    return e
                })
                const total_page = parseInt(datas.data.total) / this.payloadList.per_page
                this.tableData.total_page = total_page < 1 ? 1 : Math.ceil(total_page)
                this.tableData.current_page = datas.data.current_page
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateList = true
                window.$('#Modal_Filter').modal('hide')
            }
        },
        async exportExcel () {
            this.loadStateExport = false
            try {
                const datas = (await this.axios.get('/v2/reports/kontrak/status-operasi/bay/excel?status=Energize', { responseType: 'blob' })).data
                const url = window.URL.createObjectURL(new Blob([datas]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'laporan-kontrak-bay-'+this.payloadList.status+'.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateExport = true
            }
        },
        paginateCb(page) {
            this.payloadList.page = page
            this.loadList ()
        },
        resetFilter () {
            this.payloadList = { ...this.payloadList, start: null, end: null }
            this.loadList ()
        },
        reset() {
            let _ = this 
            _.payloadList.start = null
            _.payloadList.end = null
            _.payloadList.id_unit = ''
            _.filter = [
                {
                    'by': '-'
                }
            ]
        },
        addFilter(){
            let _ = this
            _.filter.push({
                name	: '-',
                by      : '-'
			})
        },
        deleteFilter(x) {
            let _ = this
            switch (_.filter[x].by) {
                case 'tgl_awal':
                    _.payloadList.start = ''
                    break;

                case 'tgl_akhir':
                    _.payloadList.end = ''
                    break;

                case 'id_unit':
                    _.payloadList.id_unit = ''
                    break;            
            
                default:
                    break;
            }            
            _.filter.splice(x, 1);            
        }

    }
}
</script>

<style>

</style>