<template>
	<div>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ table.title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in table.breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-12 col-sm-6 mb-1">
                                            <div class="btn-responsive">                                                
                                            </div>
                                        </div>

                                        <!-- <div class="col-12 col-sm-4 mb-1">
                                            <div class="input-group form-search-rounded">
                                                <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari jabatan disini..">
                                                <span class="input-group-append">
                                                    <div class="input-group-text bg-transparent">
                                                        <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                    </div>
                                                </span>
                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="table-responsive" style="overflow-x: unset">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Jabatan</th>
                                                    <th>Unit</th>
                                                    <th>Bidang</th>
                                                    <th>Sub Bidang</th>
                                                    <th width="10%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, key) in table.dataTable.lists" :key="key">
                                                    <td v-for="(column, kcolumn) in table.dataTable.columns" :key="kcolumn" v-html="data[column]"></td>
                                                    <td>
                                                        <div class="btn-action-block">
                                                            <button type="button" @click="redirect(data.role_id)" class="btn btn-icon-small btn-warning-trans waves-effect waves-light">
                                                                <i class="fa fa-pencil"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-show="table.dataTable.lists.total == 0">
                                                    <td :colspan="table.dataTable.columns.length + 1" class="text-center">There is no data.</td>
                                                </tr>
                                            </tbody>           
                                        </table>   
                                        <!-- <PaginateList v-show="table.dataTable.lists.total != 0"/> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
// import PaginateList from '@/components/PaginateList'
export default {
    name: 'TypeIndex',
    components: {
        // PaginateList
    },
	data() {
		return {
			table: {
                title: "Role",
				breadcumb: [
                    {
                        name: 'User & Role Management', 
                        link: '/role',
                        active: false
                    },
                    {
                        name: this.$route.params.type,
                        link: '',
                        active: false
                    },
                    {
                        name: 'Jabatan', 
                        link: '',
                        active: true
                    },                                  
                ],
                dataTable: {
                    lists: [],
                    columns: ['name', 'unit_text', 'bidang_text', 'subbidang_text']
                }
			},
            // search: null,
            type: this.$route.params.type == 'pengawas' ? 1 : 2,
            role_id: localStorage.getItem('role_id')
		}
	},
    mounted() {
        let _ = this
        _.getList()
    },
    methods: {
        getList() {
            let _ = this
            _.axios.get('/position?type='+ _.type +'&search=' + _.search)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        redirect(x) {
            let _ = this
            localStorage.setItem('type', this.$route.params.type)
            localStorage.setItem('role', x)
            _.$router.push('/akses')
        }
    },
    watch: {
        search: function() {
            this.getList()
        }
    }
}
</script>