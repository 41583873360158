<template>
  <div class="content-body">
      <div class="row">
        <div class="col-12 mb-2">
            <!-- Notf Section -->
            <div class="row mb-2">
                <div class="col-12">
                    <ul class="nav custom-nav-box">
                        <li class="nav-item">
                            <a @click="notifActive = 'kontraktual'" :class="{ 'nav-link': true, 'active': notifActive === 'kontraktual'}">Kontraktual ({{ notifKontraktual.total }})</a>
                        </li>
                        <li class="nav-item">
                            <a @click="notifActive = 'bg'" :class="{ 'nav-link': true, 'active': notifActive === 'bg'}">Bank Garansi ({{notifBg.total}})</a>
                        </li>
                        <li class="nav-item">
                            <a @click="notifActive = 'anggaran'" :class="{ 'nav-link': true, 'active': notifActive === 'anggaran'}">Anggaran ({{ notifAnggaran.total }})</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="col-12" v-if="notifActive === 'bg'">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="row no-gutters">
                                    <router-link to="/warning/uang-muka?filter=Akan Berakhir" class="col-4" style="padding: 8px" v-if="parseInt(notifBgUangMuka.data['Akan Berakhir']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-warning': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifBgUangMuka.data['Akan Berakhir'] }}</div>
                                                    <div class="alert-text">Jaminan uang muka akan berakhir</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-warning': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="/warning/uang-muka?filter=Berakhir" class="col-4" style="padding: 8px" v-if="parseInt(notifBgUangMuka.data['Berakhir']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-danger': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifBgUangMuka.data['Berakhir'] }}</div>
                                                    <div class="alert-text">Jaminan uang muka berakhir</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-danger': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="/laporan-bg-pelaksanaan" class="col-4" style="padding: 8px" v-if="parseInt(notifBg.data['Akan Berakhir']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-warning': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifBg.data['Akan Berakhir'] }}</div>
                                                    <div class="alert-text">Jaminan pelaksanaan akan berakhir</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-warning': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="/warning/pelaksanaan?filter=Berakhir" class="col-4" style="padding: 8px" v-if="parseInt(notifBg.data['Berakhir']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-danger': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifBg.data['Berakhir'] }}</div>
                                                    <div class="alert-text">Jaminan pelaksanaan berakhir</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-danger': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="/warning/pelaksanaan?filter=Perlu Penyesuaian" class="col-4" style="padding: 8px" v-if="parseInt(notifBg.data['penyesuaiaan nilai']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-danger': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifBg.data['penyesuaiaan nilai'] }}</div>
                                                    <div class="alert-text">Jaminan pelaksanaan perlu penyesuaian</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-danger': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="/warning/pemeliharaan?filter=Akan Berakhir" class="col-4" style="padding: 8px" v-if="parseInt(notifBgPemeliharaan.data['Akan Berakhir']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-warning': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifBgPemeliharaan.data['Akan Berakhir'] }}</div>
                                                    <div class="alert-text">Jaminan pemeliharaan akan berakhir</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-warning': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="/warning/pemeliharaan?filter=Berakhir" class="col-4" style="padding: 8px" v-if="parseInt(notifBgPemeliharaan.data['Berakhir']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-danger': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifBgPemeliharaan.data['Berakhir'] }}</div>
                                                    <div class="alert-text">Jaminan pemeliharaan berakhir</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-danger': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="/warning/pemeliharaan?filter=Perlu Penyesuaian" class="col-4" style="padding: 8px" v-if="parseInt(notifBgPemeliharaan.data['penyesuaiaan nilai']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-danger': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifBgPemeliharaan.data['penyesuaiaan nilai'] }}</div>
                                                    <div class="alert-text">Jaminan pemeliharaan perlu penyesuaian</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-danger': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="notifActive === 'kontraktual'">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="row no-gutters">
                                    <router-link to="/chart/kontraktual/pmbk?filter=Akan Habis&list=warning" class="col-4" style="padding: 8px" v-if="parseInt(notifKontraktual.data['Akan Habis']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-warning': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifKontraktual.data['Akan Habis'] }}</div>
                                                    <div class="alert-text">Kontrak Akan Berakhir</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-warning': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <router-link to="/chart/kontraktual/pmbk?filter=Habis&list=warning" class="col-4" style="padding: 8px" v-if="parseInt(notifKontraktual.data['Habis']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-danger': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifKontraktual.data['Habis'] }}</div>
                                                    <div class="alert-text">Kontrak Berakhir</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-danger': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="notifActive === 'anggaran'">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="row">
                                    <router-link to="/laporan-project-ai" class="col-4" style="padding: 8px" v-if="parseInt(notifAnggaran.data['Perlu Penyesuaian AI']) !== 0">
                                        <div :class="{ 'alert custom-alert alert-warning': true}">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="alert-highlight">{{ notifAnggaran.data['Perlu Penyesuaian AI'] }}</div>
                                                    <div class="alert-text">Project Perlu Penyesuaian AI</div>
                                                </div>
                                                <div class="col-2 d-flex align-items-center">
                                                   <i :class="{ 'feather icon-alert-triangle h4 m-0 text-warning': true }"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- End Notif Section -->

        </div>
        <div class="col-12">
            <!-- Transmisi -->
            <div class="row mb-2 d-none">
                <div class="col-6 d-flex align-items-center">
                    <h6 class="text-dark text-bold-600 font-medium-3">Transmisi & Gardu Induk</h6>
                </div>
                <div class="col-6 d-flex align-items-center justify-content-end" style="gap: 10px">
                    <date-picker
                        format="DD/MM/YYYY"
                        type="date"
                        v-model="payloadDate.date_start"
                        placeholder="Tanggal Mulai"
                        input-class="form-control"
                        value-type="YYYY-MM-DD"
                        style="width: 100%;"
                        >
                    </date-picker>
                    <date-picker
                        format="DD/MM/YYYY"
                        placeholder="Tanggal Akhir"
                        type="date"
                        v-model="payloadDate.date_end"
                        input-class="form-control"
                        value-type="YYYY-MM-DD"
                        style="width: 100%;"
                        >
                    </date-picker>
                    <button 
                        @click="fetchFilterDate"
                        class="btn btn-primary d-flex justify-content-center" 
                        data-toggle="modal" 
                    >
                    Filter
                    </button>
                    <button @click="resetChartPK" class="btn btn-danger d-flex justify-content-center">Reset</button>
                </div>
            </div>

            <div class="row">
                <!-- <div class="col-lg-6 col-md-12">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <h4 class="card-title">Project Kinerja</h4>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartPK"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStatePK" class="card-body">
                                <apexchart type="bar" height="300" :options="barChartPK" :series="seriesPK"></apexchart>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- End Transmisi -->
        </div>
        <div class="col-sm-12">
            <!-- Kontraktual -->
            <div class="row mb-2">
                <div class="col-6 d-flex align-items-center">
                    <h6 class="text-dark text-bold-600 font-medium-3">Status Kontrak UIP JBB</h6>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <div>
                                <h4 class="card-title mb-50">Semua Project</h4>
                            </div>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartSP"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStateSP" class="card-body">
                                <apexchart type="donut"  height="250" :options="donutChartSP" :series="seriesSP"></apexchart>
                                <div class="row mb-1 mx-2" v-for="(l, idx) in labelDonutSP" :key="idx">
                                    <div class="col-9 d-flex align-items-center">
                                        <span class="custom-bullet" :style="{'background-color': l.color}"></span>
                                        <span class="custom-chart-legend">{{ l.label }}</span>
                                    </div>
                                    <div class="col-3 text-right">
                                        <span class="text-primary font-700">{{ l.series }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <div>
                                <h4 class="card-title mb-50">Status Kontrak</h4>
                                <h6 class="card-subtitle">
                                    Total Kontrak
                                    <span class="text-primary text-bold-700">{{ seriesSK[0].data.reduce((a,b) => a + b) }}</span>
                                </h6>
                            </div>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartSK"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="loadStateSK" class="card-content">
                            <div class="card-body">
                                <apexchart type="bar" height="250" :options="barChartSK" :series="seriesSK"></apexchart>
                            </div>
                        </div>
                        <div v-else class="card-body">
                            <div class="wrap-spiner-chart">
                                <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <div>
                                <h4 class="card-title mb-50">Status Masa Aktif Kontrak</h4>
                                <h6 class="card-subtitle">
                                    <div class="d-flex align-items-center" style="gap: 7px">
                                        <div class="d-flex align-items-center" style="gap: 7px">
                                            Belum Efektif
                                            <span class="text-primary text-bold-700">{{ infoSMAK.belum_efektif }}</span>
                                        </div>
                                        <div class="d-flex align-items-center" style="gap: 7px">
                                            ST 1
                                            <span class="text-primary text-bold-700">{{ infoSMAK.st_1 }}</span>
                                        </div>
                                    </div>
                                </h6>
                            </div>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartSMAK"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="loadStateSMAK" class="card-content">
                            <div class="card-body">
                                <apexchart type="bar" height="250" :options="barChartSMAK" :series="seriesSMAK"></apexchart>
                            </div>
                        </div>
                        <div v-else class="card-body">
                            <div class="wrap-spiner-chart">
                                <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row mb-2">
                        <div class="col-6 d-flex align-items-center">
                            <h6 class="text-dark text-bold-600 font-medium-3">Proyek On Going</h6>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                    
                    <!-- Total Nilai Kontrak -->
                    <router-link :to="'/summary/nilai-kontrak?p=1'" class="col-12 col-sm-6 col-lg-3 d-flex align-items-stretch">
                        <div class="card w-100 text-center">
                            <div v-if="loadStateKontrakCard" class="card-content">
                                <div class="card-body ic-statistic">
                                    <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                        <img src="../../../public/assets/img/icon/ic-money-1.png" class="img-fluid" alt="">
                                    </div>

                                    <div class="ic-title small mb-50">{{ kontrakCard.nilaiKontrak | currency }}</div>
                                    <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate" data-toggle="tooltip"
                                        data-placement="bottom" data-original-title="Total Nilai Kontrak">Nilai
                                        Kontrak <br>Proyek On Going</p>
                                </div>
                            </div>
                            <div v-else class="card-content">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <!-- Nilai Kontrak Terbayar -->
                    <router-link :to="'/summary/nilai-kontrak?p=2'" class="col-12 col-sm-6 col-lg-3 d-flex align-items-stretch">
                        <div class="card w-100 text-center">
                            <div v-if="loadStateKontrakCard" class="card-content">
                                <div class="card-body ic-statistic">
                                    <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                        <img src="../../../public/assets/img/icon/ic-money-2.png" class="img-fluid" alt="">
                                    </div>

                                    <div class="ic-title small mb-50">{{ kontrakCard.nilaiTerbayar | currency }}</div>
                                    <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate" data-toggle="tooltip"
                                        data-placement="bottom" data-original-title="Nilai Kontrak Terbayar">Nilai Terbayar <br>Proyek On Going</p>
                                </div>
                            </div>
                            <div v-else class="card-content">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <!-- Kapasitas MVA -->
                    <router-link :to="'/summary/kapasitas-mva?p=1'" class="col-12 col-sm-6 col-lg-3 d-flex align-items-stretch">
                        <div class="card w-100 text-center">
                            <div v-if="loadStateKontrakCard" class="card-content">
                                <div class="card-body ic-statistic">
                                    <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                        <img src="../../../public/assets/img/icon/ic-speedometer-1.png" class="img-fluid" alt="">
                                    </div>

                                    <div class="ic-title large mb-50">{{ kontrakCard.mva | numFormat }}</div>
                                    <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate" data-toggle="tooltip"
                                        data-placement="bottom" data-original-title="Kapasitas MVA">Kapasitas MVA<br>Proyek On Going</p>
                                </div>
                            </div>
                            <div v-else class="card-content">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <!-- Kapasitas KMS -->
                    <router-link :to="'/summary/kapasitas-transmisi?p=1'" class="col-12 col-sm-6 col-lg-3 d-flex align-items-stretch">
                        <div class="card w-100 text-center">
                            <div v-if="loadStateKontrakCard" class="card-content">
                                <div class="card-body ic-statistic">
                                    <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                        <img src="../../../public/assets/img/icon/ic-speedometer-2.png" class="img-fluid" alt="">
                                    </div>

                                    <div class="ic-title large mb-50">{{ kontrakCard.kapasitasTransmisi | numFormat }}</div>
                                    <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate" data-toggle="tooltip"
                                        data-placement="bottom" data-original-title="Kapasitas KMS">Kapasitas Transmisi <br>Proyek On Going</p>
                                </div>
                            </div>
                            <div v-else class="card-content">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </router-link>

                </div>
                </div>
            </div>
            <!-- End Kontraktual -->
        </div>
        <div class="col-md-12">
            <!-- Status Operasi Section -->
            <div class="row mb-2">
                <div class="col-6 d-flex align-items-center">
                    <h6 class="text-dark text-bold-600 font-medium-3">Status Operasi</h6>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <div>
                                <h4 class="card-title mb-50">Operasi per BAY</h4>
                            </div>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartOB"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStateOB" class="card-body">
                                <apexchart type="bar" height="250" :options="barChartOB" :series="seriesOB"></apexchart>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <div>
                                <h4 class="card-title mb-50">Operasi per Project</h4>
                            </div>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartOP"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStateOP" class="card-body">
                                <apexchart type="bar" height="250" :options="barChartOP" :series="seriesOP"></apexchart>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- <div class="col-6">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <div>
                                <h4 class="card-title mb-50">Project Prioritas</h4>
                            </div>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartPP"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStatePP" class="card-body">
                                <apexchart type="bar" height="250" :options="barChartPP" :series="seriesPP"></apexchart>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-6">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <div>
                                <h4 class="card-title mb-50">STOP, STAP, STP</h4>
                            </div>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartSSS"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStateSSS" class="card-body">
                                <apexchart type="bar" height="250" :options="barChartSSS" :series="seriesSSS"></apexchart>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Section Status Operasi -->
        </div>
        <div class="col-12">
            <!-- Section Statistik -->
            <div class="row">

                <!-- Kapasitas MVA -->
                <router-link to="/summary/kapasitas-mva/all" class="col-12 col-sm-6 col-lg-3 d-flex align-items-stretch">
                        <div class="card w-100 text-center">
                            <div v-if="loadStateSPMVA" class="card-content">
                                <div class="card-body ic-statistic">
                                    <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                        <img src="../../../public/assets/img/icon/ic-speedometer-1.png" class="img-fluid" alt="">
                                    </div>

                                    <div class="ic-title large mb-50">{{ SPCard.mva | numFormat }}</div>
                                    <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate" data-toggle="tooltip"
                                        data-placement="bottom" data-original-title="Kapasitas MVA">Kapasitas MVA</p>
                                </div>
                            </div>
                            <div v-else class="card-content">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                </router-link>

                <!-- Kapasitas KMS -->
                <router-link to="/summary/kapasitas-transmisi/all" class="col-12 col-sm-6 col-lg-3 d-flex align-items-stretch">
                    <div class="card w-100 text-center">
                        <div v-if="loadStateSPKT" class="card-content">
                            <div class="card-body ic-statistic">
                                <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                    <img src="../../../public/assets/img/icon/ic-speedometer-2.png" class="img-fluid" alt="">
                                </div>

                                <div class="ic-title large mb-50">{{ SPCard.kt | numFormat }}</div>
                                <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate" data-toggle="tooltip"
                                    data-placement="bottom" data-original-title="Kapasitas KMS">Kapasitas Transmisi</p>
                            </div>
                        </div>
                        <div v-else class="card-content">
                            <div class="wrap-spiner-chart">
                                <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                            </div>
                        </div>
                    </div>
                </router-link>

            </div>
            <!-- End Statistic -->
        </div>
        <div class="col-md-12">
            <div class="pt-2 pb-2 d-flex align-items-center justify-content-between">
                <h4 class="d-inline m-0 card-title"><strong>Leaderboard</strong></h4>
                <button v-if="leaderboardExpand" data-toggle="modal" data-target="#Modal_filter_leaderboard" class="btn btn-primary d-inline">
                    Filter Leaderboard
                </button>  
            </div>
            <div v-if="!leaderBoardLoadState" class="wrap-table-leaderboard">
                <el-table stripe empty-text="No Data"  :data="tableData" style="width: 100%">
                    <el-table-column
                        :fixed="fixedColTable"
                        >
                        <template slot-scope="scope">
                            <p class="text-center"><strong>{{ scope.row.number }}. </strong></p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :fixed="fixedColTable"
                        prop="ranking"
                        label="Ranking"
                        width="270">
                        <template #header>
                            <p style="display: inline"><strong>Ranking</strong></p>
                        </template>
                        <template slot-scope="scope">
                            <div class="row d-flex align-items-center">
                                <div class="col-3">
                                    <el-avatar :size="40" :src="scope.row.ranking.photo || 'https://inspektorat.kotawaringinbaratkab.go.id/public/uploads/user/default-user-imge.jpeg'"></el-avatar>
                                </div>
                                <div class="col-9">
                                    <p class="text-primary"><strong>{{ scope.row.name }}</strong></p>
                                    <p class="text-mutted">{{ scope.row.ranking.position }}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :fixed="fixedColTable"
                        prop="current_total"
                        label="Total Point"
                        width="100"
                        >
                        <template #header>
                            <p style="display: inline"><strong>Total Point</strong></p>
                        </template>
                        <template slot-scope="scope">
                            <div class="d-flex align-items-center" style="gap: 10px">
                                <svg v-if="scope.row.status === 'up'" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="14" cy="13" r="6.25" fill="#3099AC"/>
                                    <g filter="url(#filter0_d_300_2518)">
                                    <path d="M24 13C24 10.3478 22.9464 7.8043 21.0711 5.92893C19.1957 4.05357 16.6522 3 14 3C11.3478 3 8.8043 4.05357 6.92893 5.92893C5.05357 7.8043 4 10.3478 4 13C4 15.6522 5.05357 18.1957 6.92893 20.0711C8.8043 21.9464 11.3478 23 14 23C16.6522 23 19.1957 21.9464 21.0711 20.0711C22.9464 18.1957 24 15.6522 24 13ZM14.625 17.375C14.625 17.5408 14.5592 17.6997 14.4419 17.8169C14.3247 17.9342 14.1658 18 14 18C13.8342 18 13.6753 17.9342 13.5581 17.8169C13.4408 17.6997 13.375 17.5408 13.375 17.375V10.1337L10.6925 12.8175C10.5751 12.9349 10.416 13.0008 10.25 13.0008C10.084 13.0008 9.92486 12.9349 9.8075 12.8175C9.69014 12.7001 9.62421 12.541 9.62421 12.375C9.62421 12.209 9.69014 12.0499 9.8075 11.9325L13.5575 8.1825C13.6156 8.1243 13.6845 8.07812 13.7605 8.04661C13.8364 8.0151 13.9178 7.99888 14 7.99888C14.0822 7.99888 14.1636 8.0151 14.2395 8.04661C14.3155 8.07812 14.3844 8.1243 14.4425 8.1825L18.1925 11.9325C18.3099 12.0499 18.3758 12.209 18.3758 12.375C18.3758 12.541 18.3099 12.7001 18.1925 12.8175C18.0751 12.9349 17.916 13.0008 17.75 13.0008C17.584 13.0008 17.4249 12.9349 17.3075 12.8175L14.625 10.1337V17.375Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_300_2518" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="1"/>
                                    <feGaussianBlur stdDeviation="2"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_300_2518"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_300_2518" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>

                                <svg v-if="scope.row.status === 'down'" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle r="6.25" transform="matrix(1 0 0 -1 14 13)" fill="#EC2F39"/>
                                    <g filter="url(#filter0_d_300_2533)">
                                    <path d="M24 13C24 15.6522 22.9464 18.1957 21.0711 20.0711C19.1957 21.9464 16.6522 23 14 23C11.3478 23 8.8043 21.9464 6.92893 20.0711C5.05357 18.1957 4 15.6522 4 13C4 10.3478 5.05357 7.8043 6.92893 5.92893C8.8043 4.05357 11.3478 3 14 3C16.6522 3 19.1957 4.05357 21.0711 5.92893C22.9464 7.8043 24 10.3478 24 13ZM14.625 8.625C14.625 8.45924 14.5592 8.30027 14.4419 8.18306C14.3247 8.06585 14.1658 8 14 8C13.8342 8 13.6753 8.06585 13.5581 8.18306C13.4408 8.30027 13.375 8.45924 13.375 8.625V15.8663L10.6925 13.1825C10.5751 13.0651 10.416 12.9992 10.25 12.9992C10.084 12.9992 9.92486 13.0651 9.8075 13.1825C9.69014 13.2999 9.62421 13.459 9.62421 13.625C9.62421 13.791 9.69014 13.9501 9.8075 14.0675L13.5575 17.8175C13.6156 17.8757 13.6845 17.9219 13.7605 17.9534C13.8364 17.9849 13.9178 18.0011 14 18.0011C14.0822 18.0011 14.1636 17.9849 14.2395 17.9534C14.3155 17.9219 14.3844 17.8757 14.4425 17.8175L18.1925 14.0675C18.3099 13.9501 18.3758 13.791 18.3758 13.625C18.3758 13.459 18.3099 13.2999 18.1925 13.1825C18.0751 13.0651 17.916 12.9992 17.75 12.9992C17.584 12.9992 17.4249 13.0651 17.3075 13.1825L14.625 15.8663V8.625Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_300_2533" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="1"/>
                                    <feGaussianBlur stdDeviation="2"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_300_2533"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_300_2533" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>


                                <svg v-if="scope.row.status === 'same'" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle r="6.25" transform="matrix(1 0 0 -1 14 13)" fill="#505050"/>
                                    <g filter="url(#filter0_d_300_2578)">
                                    <path d="M24 13C24 7.477 19.523 3 14 3C8.477 3 4 7.477 4 13C4 18.523 8.477 23 14 23C19.523 23 24 18.523 24 13ZM18.252 11.5H9.752C9.55309 11.5 9.36232 11.421 9.22167 11.2803C9.08102 11.1397 9.002 10.9489 9.002 10.75C9.002 10.5511 9.08102 10.3603 9.22167 10.2197C9.36232 10.079 9.55309 10 9.752 10H18.252C18.4509 10 18.6417 10.079 18.7823 10.2197C18.923 10.3603 19.002 10.5511 19.002 10.75C19.002 10.9489 18.923 11.1397 18.7823 11.2803C18.6417 11.421 18.4509 11.5 18.252 11.5ZM18.252 16H9.752C9.55309 16 9.36232 15.921 9.22167 15.7803C9.08102 15.6397 9.002 15.4489 9.002 15.25C9.002 15.0511 9.08102 14.8603 9.22167 14.7197C9.36232 14.579 9.55309 14.5 9.752 14.5H18.252C18.4509 14.5 18.6417 14.579 18.7823 14.7197C18.923 14.8603 19.002 15.0511 19.002 15.25C19.002 15.4489 18.923 15.6397 18.7823 15.7803C18.6417 15.921 18.4509 16 18.252 16Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_300_2578" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="1"/>
                                    <feGaussianBlur stdDeviation="2"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_300_2578"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_300_2578" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>


                                <a><strong>{{ scope.row.current_total }} </strong></a>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_masuk_ke_ipm"
                        label="Masuk Aplikasi"
                        >
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_masuk_ke_ipm }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_input_kendala"
                        label="Input Kendala"
                        >
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_input_kendala }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_input_solusi"
                        label="Input Solusi"
                        >
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_input_solusi }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_update_amandemen"
                        label="Update Amandemen"
                        >
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_amandemen }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_update_bank_garansi"
                        label="Update Bank Garansi"
                        >
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_bank_garansi }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_update_bap"
                        label="Update BAP"
                        >
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_bap }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_update_po_se"
                        label="Update PO SE"
                        >
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_po_se }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_update_status_kontraktual"
                        label="Input Status Kontraktual"
                        >
                        <template #header>
                            <span style="display: inline"><strong>Input Progress</strong></span>
                        </template>
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_status_kontraktual }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_update_status_kontraktual"
                        label="Update Status Kontraktual"
                        >
                        <template #header>
                            <span style="display: inline"><strong>Update Status<br>Kontraktual</strong></span>
                        </template>
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_status_kontraktual }} </strong></a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="160"
                        prop="total_update_status_serah_terima"
                        label="Update Status Serah Terima"
                        >
                        <template #header>
                            <span style="display: inline"><strong>Update Status <br>Serah
                                Terima</strong></span>
                        </template>
                        <template slot-scope="scope">
                            <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_status_serah_terima }} </strong></a>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wrap-leader-see-all">
                    <router-link to="/leaderboard" class="btn d-inline text-primary"><strong>Lihat Semua</strong></router-link>
                </div>
            </div>
            <div class="mx-auto text-center" style="width:400px" v-else>
                <h2><i class="fa fa-spinner fa-spin"></i></h2>
            </div>
        </div>
        <div class="col-sm-12 mt-4">
           <!-- LAINNYA -->
            <div class="row mb-2">
                <div class="col-12">
                    <h6 class="text-dark text-bold-600 font-medium-3">Lainnya</h6>
                </div>
            </div>

            <div class="row">
                <!-- Pemetaan Masa Berlaku Kontrak -->
                <!-- <div class="col-lg-6 col-md-12">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <h4 class="card-title">Pemetaan Masa Berlaku Kontrak</h4>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartPMBK"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStatePMBK" class="card-body">
                                <apexchart type="pie" height="250" :options="pieChartPMBK" :series="seriesPMBK"></apexchart>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- Pemetaan Tipe Project -->
                <div class="col-lg-6 col-md-12">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <h4 class="card-title">Pemetaan Tipe Project</h4>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartPTP"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStatePTP" class="card-body">
                                <apexchart type="pie" height="250" :options="pieChartPTP" :series="seriesPTP"></apexchart>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Pemetaan Gardu Induk -->
                <div class="col-lg-6 col-md-12">
                    <div class="card card-statistic">
                        <div class="card-header">
                            <h4 class="card-title">Pemetaan Gardu Induk</h4>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="reload" @click="loadChartPGI"><i class="feather icon-rotate-cw"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content">
                            <div v-if="loadStatePGI" class="card-body">
                                <apexchart type="pie" height="250" :options="pieChartPGI" :series="seriesPGI"></apexchart>
                            </div>
                            <div v-else class="card-body">
                                <div class="wrap-spiner-chart">
                                    <div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- LAINNYA -->
        </div>
        <div class="col-md-12">
            <!-- Embed -->
            <div class="card" v-for="(data, index) in embed" :key="index">
                <div class="card-header">
                    <h4 class="card-title">{{data.name}}</h4>
                </div>
                <div class="card-content">
                    <div class="card-body card-dashboard">
                        <div class="table-responsive">
                            <div v-html="data.code"></div>
                        </div>                        
                        <img v-if="data.code != '' && data.script != ''" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" :onload="data.script">
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment'
export default {
    name: 'HomeV2',
    components: { DatePicker },
    data () {
        return {
            notifActive: 'kontraktual',
            notifBg: { total: 0, data: {} },
            notifBgUangMuka: { total: 0, data: {} },
            notifBgPemeliharaan: { total: 0, data: {} },
            notifKontraktual: { total: 0, data: {} },
            notifAnggaran: { total: 0, data: {} },
            rangeSP: [],
            rangeSMAK: [],
            rangeSK: [],
            kontrakCard: {
                nilaiKontrak: 0,
                nilaiTerbayar: 0,
                mva: 0,
                kapasitasTransmisi: 0
            },
            SPCard: {
                mva: 0,
                kt: 0,
            },
            loadStateKontrakCard: false,
            loadStateSPMVA: false,
            loadStateSPKT: true,
            loadState : true,
            loadStatePK: false,
            loadStateSP: false,
            loadStateSMAK: false,
            loadStateSK: false,
            loadStateOB: false,
            loadStateOP: false,
            loadStatePP: false,
            loadStateSSS: false,
            loadStatePMBK: false,
            loadStatePTP: false,
            loadStatePGI: false,
            leaderBoardLoadState: true,
            fixedColTable: true,
            tableData: [],
            payloadDate: {
                date_start: null,
                date_end: null
            },
            payloadLb : {
                month: null,
                week: null,
                limit: 5
            },
            payloadPK: {
                type: 'chart',
                date_start: null,
                date_end: null,
            },
            payloadSP: {
                type: 'chart',
                date_start: null,
                date_end: null,
            },
            payloadSK: {
                type: 'chart',
                date_start: null,
                date_end: null,
            },
            payloadSMAK: {
                type: 'chart',
                date_start: null,
                date_end: null,
            },
            payloadKC: {
                date_start: null,
                date_end: null,
            },
            payloadOB: {
                type: 'chart',
                date_start: null,
                date_end: null,
            },
            payloadOP: {
                type: 'chart',
                date_start: null,
                date_end: null,
            },
            payloadPP: {
                type: 'chart',
                date_start: null,
                date_end: null,
            },
            payloadSSS: {
                type: 'chart',
                date_start: null,
                date_end: null,
            },
            payloadPMBK: {
                date_start: null,
                date_end: null,
            },
            payloadPTP: {
                date_start: null,
                date_end: null,
            },
            payloadPGI: {
                date_start: null,
                date_end: null,
            },
            
            // Data Object Scope

            counter: {
                total_nilai_kontrak: 0,
                nilai_kontrak_terbayar: 0
            },
            embed: [],
            leaderboardExpand: false,
            seriesPK: [
                {
                    data: [48, 40, 40]
                }
            ],
            barChartPK: {
                chart: {
                    type: 'bar',
                    height: 300,
                    toolbar: {
                        show: false
                    },
                    events: {
                        click: this.barEventClick
                    },
                },
                grid: {
                show: false
                },
                colors: ['#3099AC', '#F3CA27', '#F3CA27'],
                dataLabels: {
                formatter: this.renderBottomArrow,
                offsetY: -40,
                style: {
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    colors: ['#3099AC', '#F3CA27', '#F3CA27']
                },
                background: {
                    enabled: true,
                    padding: '10',
                    borderRadius: 6
                }
                },
                xaxis: {
                categories: ['UPP 1', 'UPP 2', 'UPP 3'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
                },
                yaxis: {
                max: function (max) {
                    return max + (max * 0.2)
                },
                forceNiceScale: true,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                title: {
                    text: 'Project',
                    rotate: 0,
                    offsetX: 32,
                    offsetY: -135,
                    style: {
                    color: '#626262',
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                    },
                },
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 6,
                    columnWidth: '32%',
                    distributed: true,
                    dataLabels: {
                    position: 'top'
                    }
                },
                },
                legend: {
                show: false,
                itemMargin: {
                    horizontal: 2
                },
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesSP: [100, 53],
            labelDonutSP: [],
            donutChartSP: { 
                chart: {
                    type: 'donut',
                    height: 183,
                    events: {
                        dataPointSelection: this.donutEventClick
                    }
                },
                dataLabels: {
                enabled: false
                },
                labels: ['Ongoing STP', 'Ongoing'],
                colors: ['#3099AC', '#F3CA27', '#F3CA27'],
                plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: true,
                    offsetX: 0,
                    offsetY: 0,
                    customScale: 1,
                    dataLabels: {
                        offset: 0,
                        minAngleToShowLabel: 10
                    },
                    donut: {
                    size: '65%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '15px',
                            fontFamily: 'Myriad Pro',
                            fontWeight: 400,
                            color: '#626262',
                            offsetY: -10,
                            formatter: function (val) {
                                return val
                            }
                        },
                        value: {
                            show: true,
                            fontSize: '15px',
                            fontFamily: 'Myriad Pro',
                            fontWeight: 700,
                            color: '#3099AC',
                            offsetY: -5,
                            formatter: function (val) {
                                return val
                            }
                        },
                        total: {
                        show: true,
                        showAlways: true,
                        label: 'Total',
                        fontSize: '15px',
                        fontFamily: 'Myriad Pro',
                        fontWeight: 400,
                        color: '#626262',
                        formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                            return a + b
                            }, 0)
                        }
                        }
                    }
                    },
                }
                },
                legend: {
                show: false
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesSMAK: [
                {
                    data: [30, 20, 40]
                }
            ],
            infoSMAK: {},
            barChartSMAK: {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    },
                    events: {
                        click: this.barEventClick
                    },
                },
                grid: {
                    show: false
                },
                colors: ['#3099AC', '#F3CA27', '#D6515D'],
                dataLabels: {
                formatter: this.renderBottomArrow,
                offsetY: -40,
                style: {
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    colors: ['#3099AC', '#F3CA27', '#D6515D']
                },
                background: {
                    enabled: true,
                    padding: '10',
                    borderRadius: 6
                }
                },
                xaxis: {
                categories: ['Hidup', 'Akan Habis', 'Berakhir'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
                },
                yaxis: {
                max: function (max) {
                    return max + (max * 0.2)
                },
                forceNiceScale: true,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                title: {
                    text: 'Jumlah',
                    rotate: 0,
                    offsetX: 25,
                    offsetY: -105,
                    style: {
                    color: '#626262',
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                    },
                },
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 6,
                    columnWidth: '40%',
                    distributed: true,
                    dataLabels: {
                    position: 'top'
                    }
                },
                },
                legend: {
                show: false,
                itemMargin: {
                    horizontal: 2
                },
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesSK: [
                {
                    data: [25, 48, 30, 20]
                }
            ],
            barChartSK : {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    },
                    events: {
                        click: this.barEventClick
                    },
                },
                grid: {
                show: false
                },
                colors: ['#D6515D', '#3099AC', '#F3CA27', '#6FC1EA'],
                dataLabels: {
                formatter: this.renderBottomArrow,
                offsetY: -40,
                style: {
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    colors: ['#D6515D', '#3099AC', '#F3CA27', '#6FC1EA']
                },
                background: {
                    enabled: true,
                    padding: '10',
                    borderRadius: 6
                }
                },
                xaxis: {
                categories: [['Belum', 'Efektif'], 'Ongoing', 'ST 1', 'ST 2'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    rotate: 0
                }
                },
                yaxis: {
                max: function (max) {
                    return max + (max * 0.2)
                },
                forceNiceScale: true,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                title: {
                    text: 'Jumlah',
                    rotate: 0,
                    offsetX: 25,
                    offsetY: -100,
                    style: {
                    color: '#626262',
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                    },
                },
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 6,
                    columnWidth: '40%',
                    distributed: true,
                    dataLabels: {
                    position: 'top'
                    }
                },
                },
                legend: {
                show: false,
                itemMargin: {
                    horizontal: 2
                },
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesOB: [
                {
                    data: [48, 40]
                }
            ],
            barChartOB: {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    },
                    events: {
                        click: this.barEventClickSO
                    },
                },
                grid: {
                show: false
                },
                colors: ['#3099AC', '#F3CA27'],
                dataLabels: {
                formatter: this.renderBottomArrow,
                offsetY: -40,
                style: {
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    colors: ['#3099AC', '#F3CA27']
                },
                background: {
                    enabled: true,
                    padding: '10',
                    borderRadius: 6
                }
                },
                xaxis: {
                categories: ['Energize per BAY', 'SLO per BAY'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    rotate: 0
                }
                },
                yaxis: {
                max: function (max) {
                    return max + (max * 0.2)
                },
                forceNiceScale: true,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                title: {
                    text: 'Jumlah',
                    rotate: 0,
                    offsetX: 25,
                    offsetY: -110,
                    style: {
                    color: '#626262',
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                    },
                },
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 6,
                    columnWidth: '23%',
                    distributed: true,
                    dataLabels: {
                    position: 'top'
                    }
                },
                },
                legend: {
                show: false,
                itemMargin: {
                    horizontal: 2
                },
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesOP: [
                {
                    data: [48, 40]
                }
            ],
            barChartOP: {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    },
                    events: {
                        click: this.barEventClickSO
                    },
                },
                grid: {
                show: false
                },
                colors: ['#3099AC', '#F3CA27'],
                dataLabels: {
                formatter: this.renderBottomArrow,
                offsetY: -40,
                style: {
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    colors: ['#3099AC', '#F3CA27']
                },
                background: {
                    enabled: true,
                    padding: '10',
                    borderRadius: 6
                }
                },
                xaxis: {
                categories: ['Energize per Project', 'SLO per Project'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    rotate: 0
                }
                },
                yaxis: {
                max: function (max) {
                    return max + (max * 0.2)
                },
                forceNiceScale: true,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                title: {
                    text: 'Jumlah',
                    rotate: 0,
                    offsetX: 25,
                    offsetY: -110,
                    style: {
                    color: '#626262',
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                    },
                },
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 6,
                    columnWidth: '20%',
                    distributed: true,
                    dataLabels: {
                    position: 'top'
                    }
                },
                },
                legend: {
                show: false,
                itemMargin: {
                    horizontal: 2
                },
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesPP: [
                {
                    data: [48, 25, 40]
                }
            ],
            barChartPP: {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    },
                    events: {
                        click: this.barEventClick
                    },
                },
                grid: {
                show: false
                },
                colors: ['#3099AC', '#F3CA27', '#6FC1EA'],
                dataLabels: {
                formatter: this.renderBottomArrow,
                offsetY: -40,
                style: {
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    colors: ['#3099AC', '#F3CA27', '#6FC1EA']
                },
                background: {
                    enabled: true,
                    padding: '10',
                    borderRadius: 6
                }
                },
                xaxis: {
                categories: ['Ongoing', 'Energize', 'SLO'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    rotate: 0
                }
                },
                yaxis: {
                max: function (max) {
                    return max + (max * 0.2)
                },
                forceNiceScale: true,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                title: {
                    text: 'Jumlah',
                    rotate: 0,
                    offsetX: 25,
                    offsetY: -110,
                    style: {
                    color: '#626262',
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                    },
                },
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 6,
                    columnWidth: 32,
                    distributed: true,
                    dataLabels: {
                    position: 'top'
                    }
                },
                },
                legend: {
                show: false,
                itemMargin: {
                    horizontal: 2
                },
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesSSS: [
                {
                    data: [48, 25, 40]
                }
            ],
            barChartSSS: {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    },
                    events: {
                        click: this.barEventClickSO
                    },
                },
                grid: {
                show: false
                },
                colors: ['#5B62BB', '#6FC1EA', '#3099AC'],
                dataLabels: {
                formatter: this.renderBottomArrow,
                offsetY: -40,
                style: {
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    colors: ['#5B62BB', '#6FC1EA', '#3099AC']
                },
                background: {
                    enabled: true,
                    padding: '10',
                    borderRadius: 6
                }
                },
                xaxis: {
                categories: ['STOP', 'STAP', 'STP'],
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    rotate: 0
                }
                },
                yaxis: {
                max: function (max) {
                    return max + (max * 0.2)
                },
                forceNiceScale: true,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                title: {
                    text: 'Jumlah',
                    rotate: 0,
                    offsetX: 25,
                    offsetY: -110,
                    style: {
                    color: '#626262',
                    fontSize: '11px',
                    fontFamily: 'Myriad Pro',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                    },
                },
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 6,
                    columnWidth: '23%',
                    distributed: true,
                    dataLabels: {
                    position: 'top'
                    }
                },
                },
                legend: {
                show: false,
                itemMargin: {
                    horizontal: 2
                },
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesPMBK: [],
            pieChartPMBK: {
                chart: {
                    type: 'pie',
                    height: 300,
                    events: {
                        dataPointSelection: this.pieEventClick
                    }
                },
                dataLabels: {
                enabled: false
                },
                colors: ['#6FC1EA', '#F3CA27', '#D6515D', '#39AD74', '#6C72C2', '#E98B38'],
                labels: ['Hidup', 'Akan Berakhir', 'Mati'],
                legend: {
                width: 160,
                itemMargin: {
                    vertical: 8
                },
                formatter: function (seriesName, opts) {
                    return [seriesName, " <span class='font-700 float-right'>", opts.w.globals.series[opts.seriesIndex], "</span>"]
                }
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesPTP: [],
            pieChartPTP: {
                chart: {
                    type: 'pie',
                    height: 300,
                    events: {
                        dataPointSelection: this.pieEventClick
                    }
                },
                dataLabels: {
                enabled: false
                },
                colors: ['#6FC1EA', '#F3CA27', '#D6515D', '#39AD74', '#6C72C2', '#E98B38'],
                labels: ['Hidup', 'Akan Berakhir', 'Mati'],
                legend: {
                width: 160,
                itemMargin: {
                    vertical: 8
                },
                formatter: function (seriesName, opts) {
                    return [ seriesName, " <span class='font-700 float-right'>", opts.w.globals.series[opts.seriesIndex], "</span>"]
                }
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },
            seriesPGI: [],
            pieChartPGI: {
                chart: {
                    type: 'pie',
                    height: 300,
                    events: {
                        dataPointSelection: this.pieEventClick
                    }
                },
                dataLabels: {
                enabled: false
                },
                colors: ['#6FC1EA', '#F3CA27', '#D6515D', '#39AD74', '#6C72C2', '#E98B38'],
                labels: ['Hidup', 'Akan Berakhir', 'Mati'],
                legend: {
                width: 160,
                itemMargin: {
                    vertical: 8
                },
                formatter: function (seriesName, opts) {
                    return [ seriesName, " <span class='font-700 float-right'>", opts.w.globals.series[opts.seriesIndex], "</span>"]
                }
                },
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 250
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            }
            
        }
    },
    watch: {
        leaderboardExpand (val) {
            if (val === true) {
                this.loadLeaderboard ()
            } 
        },
        payloadDate: {
            handler (val) {
                this.payloadPK = { ...this.payloadPK, ...val }
                this.payloadSP = { ...this.payloadSP, ...val }
                this.payloadSK = { ...this.payloadSK, ...val }
                this.payloadSMAK = { ...this.payloadSMAK, ...val }
                this.payloadOB = { ...this.payloadOB, ...val }
                this.payloadOP = { ...this.payloadOP, ...val }
                this.payloadPP = { ...this.payloadPP, ...val }
                this.payloadSSS = { ...this.payloadSSS, ...val }
                this.payloadPMBK = { ...this.payloadPMBK, ...val }
                this.payloadPTP = { ...this.payloadPTP, ...val }
                this.payloadPGI = { ...this.payloadPGI, ...val }
                this.payloadKC = { ...this.payloadKC, ...val }
            },
            deep: true
        }
    },
    computed: {
        paramsDate () {
            return !this.payloadDate.date_start && !this.payloadDate.date_end ? '' : `&start=${this.payloadDate.date_start}&end=${this.payloadDate.date_end}`
        }
    },
    async mounted () {
        let _ = this
        if ( !_.$store.state.user ) {
            _.$router.push('login')
            _.$toast.warning('Silahkan login terlebih dahulu')
        }

        _.$nextTick(() => {
            if ( localStorage.getItem('has_update_profile') == '0' ) {
                _.$refs.showModalUpdate.click()
            }
        })
        await _.loadNotifBG ()
        await _.loadNotifBGUangMuka ()
        await _.loadNotifBGPemeliharaan ()
        await _.loadNotifKontraktual ()
        await _.loadNotifAnggaran ()
        await _.loadKontrakCard ()
        await _.loadStatusOperasiMVACard ()
        await _.loadStatusOperasiTMCard ()
        await _.loadChartPK ()
        await _.loadChartSP ()
        await _.loadChartSMAK ()
        await _.loadChartSK ()
        await _.loadChartOB ()
        await _.loadChartOP ()
        await _.loadChartPP ()
        await _.loadChartSSS ()
        await _.loadLeaderboard()
        await _.loadChartPMBK ()
        await _.loadChartPTP ()
        await _.loadChartPGI ()
        await _.loadEmbed()

        this.fixedColTable = window.innerWidth > 420
        window.addEventListener('resize', function () {
            this.fixedColTable = window.innerWidth > 420
        });
    },
    methods : {
        async loadNotifBG () {
            try {
                const datas = (await this.axios.get('/v2/dashboard/bg-pelaksanaan')).data
                for (const [key, val] of Object.entries(datas.data.data)) {
                    if (key === 'total') {
                        this.notifBg.total = val
                    } else {
                        this.notifBg.data[key] = val
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async loadNotifBGUangMuka () {
            try {
                const datas = (await this.axios.get('/v2/dashboard/bg-uang-muka')).data
                for (const [key, val] of Object.entries(datas.data.data)) {
                    if (key === 'total') {
                        this.notifBg.total += val
                    } else {
                        this.notifBgUangMuka.data[key] = val
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async loadNotifBGPemeliharaan () {
            try {
                const datas = (await this.axios.get('/v2/dashboard/bg-pemeliharaan')).data
                for (const [key, val] of Object.entries(datas.data.data)) {
                    if (key === 'total') {
                        this.notifBg.total += val
                    } else {
                        this.notifBgPemeliharaan.data[key] = val
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async loadNotifKontraktual () {
            try {
                const datas = (await this.axios.get('/v2/dashboard/kontraktual')).data
                for (const [key, val] of Object.entries(datas.data.data)) {
                    if (key === 'total') {
                        this.notifKontraktual.total = val
                    } else {
                        this.notifKontraktual.data[key] = val
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async loadNotifAnggaran () {
            try {
                const datas = (await this.axios.get('/v2/dashboard/penyesuaiaan-ai')).data
                for (const [key, val] of Object.entries(datas.data.data)) {
                    if (key === 'total') {
                        this.notifAnggaran.total = val
                    } else {
                        this.notifAnggaran.data[key] = val
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async loadStatusOperasiMVACard () {
            this.loadStateSPMVA = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/mva/card')).data
                this.SPCard.mva = datas.data.total
            } catch (error) {
                console.log(error.message)
            } finally {
                this.loadStateSPMVA = true
            }
        },
        async loadStatusOperasiTMCard () {
            this.loadStateSPKT = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/transmisi/card')).data
                this.SPCard.kt = datas.data.total
            } catch (error) {
                console.log(error.message)
            } finally {
                this.loadStateSPKT = true
            }
        },
        async loadKontrakCard () {
            this.loadStateKontrakCard = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/kontrak', { params: this.payloadKC })).data
                datas.data.forEach((e) => {
                    if (e.status === 'Nilai Kontrak') this.kontrakCard.nilaiKontrak = e.total
                    if (e.status === 'Nilai Terbayar') this.kontrakCard.nilaiTerbayar = e.total
                    if (e.status === 'MVA') this.kontrakCard.mva = e.total
                    if (e.status === 'Kapasitas Transmisi') this.kontrakCard.kapasitasTransmisi = e.total
                })

            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateKontrakCard = true
            }
        },
        async loadChartPie () {
            let _ = this
            _.loadState = true
            try {
                await _.loadPMBKChart()
                await _.loadPTPChart()
                await _.loadPGIChart()
            } catch (error) {
                console.log(error)
            } finally {
                this.loadState = false
            }
        },
        async loadEmbed () {
            try {
                const datas = (await this.axios.get('/embed/all')).data.data
                this.embed = datas
            } catch (error) {
                console.log(error)
            }
        },
        submitLbFilter () {
            window.$("#Modal_filter_leaderboard").modal('hide')
            this.payloadLb.limit = null
            this.loadLeaderboard ()
        },
        
        async loadLeaderboard () {
            this.leaderBoardLoadState = true
            let params = this.leaderboardExpand ? {} : this.payloadLb
            try {
                const datas = (await this.axios.get('/v2/dashboard/leaderboard', { params: params })).data
                const arr = datas.data
                this.tableData = arr.map((e, idx) => {
                    e.ranking = {
                        fullname: e.name,
                        photo: e.photo,
                        position: e.position
                    } 
                    e.number = idx + 1
                    return e
                })
            } catch (error) {
                console.log(error)
            } finally {
                this.leaderBoardLoadState = false
            }
        },
        resetChartPK () {
            this.payloadDate.date_start = null
            this.payloadDate.date_end = null

            this.$nextTick(() => {
                this.fetchFilterDate ()
            })
        },
        async loadChartPK () {
            this.loadStatePK = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/projects/kinerja', { params: this.payloadPK })).data
                this.seriesPK[0].data = datas.data.data
                this.barChartPK.colors = datas.data.colors
                this.barChartPK.dataLabels.style.colors = datas.data.colors
                this.barChartPK.xaxis.categories = datas.data.xAxis
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStatePK = true
            }
        },
        async fetchFilterDate () {
            const _ = this
            await _.loadStatusOperasiMVACard ()
            await _.loadStatusOperasiTMCard ()
            await _.loadChartPK ()
            await _.loadChartSP ()
            await _.loadChartSMAK ()
            await _.loadKontrakCard ()
            await _.loadChartSK ()
            await _.loadChartOB ()
            await _.loadChartOP ()
            await _.loadChartPP ()
            await _.loadChartSSS ()
            await _.loadChartPMBK ()
            await _.loadChartPTP ()
            await _.loadChartPGI ()
        },
        async loadChartSP () {
            this.loadStateSP = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/projects', { params: this.payloadSP })).data
                this.seriesSP = datas.data.series
                this.donutChartSP.colors = datas.data.chart.colors
                this.donutChartSP.labels = datas.data.chart.labels
                this.labelDonutSP = []
                datas.data.series.forEach((e,i) => {
                    this.labelDonutSP.push({ series: e, label: datas.data.chart.labels[i], color: datas.data.chart.colors[i]})
                })
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateSP = true
            }
        },
        async loadChartSMAK () {
            this.loadStateSMAK = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/projects/period', { params: this.payloadSMAK })).data
                this.seriesSMAK[0].data = []
                this.barChartSMAK.colors = []
                this.barChartSMAK.dataLabels.style.colors = []
                this.barChartSMAK.xaxis.categories = []
                datas.data.chart.forEach((e) => {
                    this.seriesSMAK[0].data.push(e.value)
                    this.barChartSMAK.colors.push(e.color)
                    this.barChartSMAK.dataLabels.style.colors.push(e.color)
                    this.barChartSMAK.xaxis.categories.push(e.category)
                })
                this.infoSMAK.belum_efektif = datas.data['Belum Efektif']
                this.infoSMAK.st_1 = datas.data['ST 1']
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateSMAK = true
            }
        },
        async loadChartSK () {
            this.loadStateSK = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/projects/status', { params: this.payloadSK })).data
                this.seriesSK[0].data = []
                this.barChartSK.colors = []
                this.barChartSK.dataLabels.style.colors = []
                this.barChartSK.xaxis.categories = []
                datas.data.chart.forEach((e) => {
                    this.seriesSK[0].data.push(e.value)
                    this.barChartSK.colors.push(e.color)
                    this.barChartSK.dataLabels.style.colors.push(e.color)
                    this.barChartSK.xaxis.categories.push(e.category)
                })
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateSK = true
            }
        },
        async loadChartOB () {
            this.loadStateOB = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/status-operasi/bay', { params: this.payloadOB })).data
                this.seriesOB[0].data = datas.data.data
                this.barChartOB.colors = datas.data.colors
                this.barChartOB.dataLabels.style.colors = datas.data.colors
                this.barChartOB.xaxis.categories = datas.data.xAxis
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateOB = true
            }
        },
        async loadChartOP () {
            this.loadStateOP = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/status-operasi', { params: this.payloadOP })).data
                this.seriesOP[0].data = datas.data.data
                this.barChartOP.colors = datas.data.colors
                this.barChartOP.dataLabels.style.colors = datas.data.colors
                this.barChartOP.xaxis.categories = datas.data.xAxis
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateOP = true
            }
        },
        async loadChartPP () {
            this.loadStatePP = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/projects/pioritas', { params: this.payloadPP })).data
                this.seriesPP[0].data = datas.data.data
                this.barChartPP.colors = datas.data.colors
                this.barChartPP.dataLabels.style.colors = datas.data.colors
                this.barChartPP.xaxis.categories = datas.data.xAxis
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStatePP = true
            }
        },
        async loadChartSSS () {
            this.loadStateSSS = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/status-serahterima', { params: this.payloadSSS })).data
                this.seriesSSS[0].data = datas.data.data
                this.barChartSSS.colors = datas.data.colors
                this.barChartSSS.dataLabels.style.colors = datas.data.colors
                this.barChartSSS.xaxis.categories = datas.data.xAxis
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateSSS = true
            }
        },
        async loadChartPMBK () {
            this.loadStatePMBK = false
            this.seriesPMBK = []
            try {
                const datas = (await this.axios.get('/dashboard/project_period', { params: this.payloadPMBK})).data.data
                let labels = []
                for (const [key, value] of Object.entries(datas)) {
                    this.seriesPMBK.push(value)
                    labels.push(key)
                }
                this.pieChartPMBK = { ...this.pieChartPMBK, labels: labels}
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStatePMBK = true
            }
        },
        async loadChartPTP () {
            this.loadStatePTP = false
            this.seriesPTP = []
            try {
                const datas = (await this.axios.get('/dashboard/project_type', { params: this.payloadPTP})).data.data
                let labels = []
                for (const [key, value] of Object.entries(datas)) {
                    this.seriesPTP.push(value)
                    labels.push(key)
                }
                this.pieChartPTP = { ...this.pieChartPTP, labels: labels}
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStatePTP = true
            }
        },
        async loadChartPGI () {
            this.loadStatePGI = false
            this.seriesPGI = []
            try {
                const datas = (await this.axios.get('/dashboard/project_gi', { params: this.payloadPGI})).data.data
                let labels = []
                for (const [key, value] of Object.entries(datas)) {
                    this.seriesPGI.push(value)
                    labels.push(key)
                }
                this.pieChartPGI = { ...this.pieChartPGI, labels: labels}
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStatePGI = true
            }
        },
        donutEventClick (event, ctx, conf) {
            console.log(conf)
            const categoriesClick = conf.w.config.labels[conf.dataPointIndex]
            switch (categoriesClick) {
                case 'ST II':
                    this.$router.push('/chart/semua-project?filter=Serah Terima II'+ this.paramsDate)
                    break;
                case 'Ongoing':
                    this.$router.push('/chart/semua-project?filter=On Going'+ this.paramsDate)
                    break;
            }
        },
        pieEventClick (event, ctx, conf) {
            console.log(conf)
            const categoriesClick = conf.w.config.labels[conf.dataPointIndex]
            switch (categoriesClick) {
                case 'Aktif':
                    this.$router.push('/chart/kontraktual/pmbk?filter=Aktif'+this.paramsDate)
                    break;
                case 'Akan Habis':
                    this.$router.push('/chart/kontraktual/pmbk?filter=Akan Habis'+this.paramsDate)
                    break;
                case 'Habis':
                    this.$router.push('/chart/kontraktual/pmbk?filter=Habis'+this.paramsDate)
                    break;
                case 'GI':
                    this.$router.push('/chart/lainya/ptp-sktet?filter=1'+this.paramsDate)
                    break;
                case 'GIS':
                    this.$router.push('/chart/lainya/ptp-sktet?filter=2'+this.paramsDate)
                    break;
                case 'GITET':
                    this.$router.push('/chart/lainya/ptp-sktet?filter=3'+this.paramsDate)
                    break;
                case 'GISTET':
                    this.$router.push('/chart/lainya/ptp-sktet?filter=4'+this.paramsDate)
                    break;
                case 'SUTT':
                    this.$router.push('/chart/lainya/ptp-sktet?filter=5'+this.paramsDate)
                    break;
                case 'SUTET':
                    this.$router.push('/chart/lainya/ptp-sktet?filter=6'+this.paramsDate)
                    break;
                case 'SKTT':
                    this.$router.push('/chart/lainya/ptp-sktet?filter=7'+this.paramsDate)
                    break;
                case 'SKTET':
                    this.$router.push('/chart/lainya/ptp-sktet?filter=8'+this.paramsDate)
                    break;
                case 'New':
                    this.$router.push('/chart/lainya/pgi?filter=1'+this.paramsDate)
                    break;
                case 'Extension':
                    this.$router.push('/chart/lainya/pgi?filter=2'+this.paramsDate)
                    break;
            }
        },
        barEventClickSO (event, ctx, conf) {
            if (this.paramsDate === '') {
                this.payloadDate.date_start = moment().startOf('year').format('YYYY-MM-DD')
                this.payloadDate.date_end = moment().format('YYYY-MM-DD')
            }
            const categoriesClick = conf.config.xaxis.categories[conf.dataPointIndex]
            switch (categoriesClick) {
                case 'Energize VS SLO (Per Bay)':
                    // this.$router.push('/laporan/kontrak/bay/energize?p=1'+this.paramsDate)
                    this.$router.push('/laporan/kontrak/bay/energize?p=1')
                    break;
                case 'SLO per BAY':
                    // this.$router.push('/laporan/kontrak/bay/slo?p=1'+this.paramsDate)
                    this.$router.push('/laporan/kontrak/bay/slo?p=1')
                    break;
                case 'Energize per project':
                    // this.$router.push('/laporan/kontrak/energize?p=1'+this.paramsDate)
                    this.$router.push('/laporan/kontrak/energize?p=1')
                    break;
                case 'SLO per project':
                    // this.$router.push('/laporan/kontrak/slo?p=1'+this.paramsDate)
                    this.$router.push('/laporan/kontrak/slo?p=1')
                    break;
                case 'STOP':
                    // this.$router.push('/laporan-stop?p=1&status=STOP'+this.paramsDate)
                    this.$router.push('/laporan-stop?p=1&status=STOP')
                    break;
                case 'STAP':
                    // this.$router.push('/laporan-stap?p=1&status=SAP'+this.paramsDate)
                    this.$router.push('/laporan-stap?p=1&status=SAP')
                    break;
                case 'STP':
                    // this.$router.push('/laporan-stp?p=1&status=STP'+this.paramsDate)
                    this.$router.push('/laporan-stp?p=1&status=STP')
                    break;
                default:
                    break;
            }

        },
        barEventClick (event, ctx, conf) {
            const categoriesClick = conf.config.xaxis.categories[conf.dataPointIndex]
            switch (categoriesClick) {
                case 'UPP 1':
                    this.$router.push('/chart/projek-kinerja?filter=UPP JJBB 1'+this.paramsDate)
                    break;
                case 'UPP 2':
                    this.$router.push('/chart/projek-kinerja?filter=UPP JJBB 2'+this.paramsDate)
                    break;
                case 'UPP 3':
                    this.$router.push('/chart/projek-kinerja?filter=UPP JJBB 3'+this.paramsDate)
                    break;
                case 'Belum Efektif':
                    this.$router.push('/chart/kontraktual/status-kontrak?filter=Belum Efektif'+ this.paramsDate)
                    break;
                case 'Ongoing':
                    this.$router.push('/chart/kontraktual/status-kontrak?filter=On Going'+ this.paramsDate)
                    break;
                case 'ST I':
                    this.$router.push('/chart/kontraktual/status-kontrak?filter=Serah Terima I'+ this.paramsDate)
                    break;
                case 'Hidup':
                    this.$router.push('/chart/kontraktual/pmbk?filter=Hidup'+ this.paramsDate)
                    break;
                case 'Akan Habis':
                    this.$router.push('/chart/kontraktual/pmbk?filter=Akan Habis'+ this.paramsDate)
                    break;
                case 'Berakhir':
                    this.$router.push('/chart/kontraktual/pmbk?filter=Berakhir'+ this.paramsDate)
                    break;
                default:
                    break;
            }

        },
        renderBottomArrow(val,opts) {
            setTimeout(function () {
                const chart = window.$(`#apexcharts${opts.w.globals.chartID}`);
                const index = opts.dataPointIndex;
                const dataLabels = chart.find('.apexcharts-data-labels');
                const colors = opts.w.globals.colors;

                const rect = window.$(dataLabels[index]).find('rect');
                const fill = colors[index]

                const x = rect.attr('x')
                const y = rect.attr('y')

                window.$(dataLabels[index]).append(`<svg x="${x - 98}" y="${y - 8}">
                    <polyline points="111,23 115,29 119,23" fill="${fill}" stroke-linejoin="round" stroke-width="2"></polyline>
                    </svg>`)
            }, 500)

            return val
        }
    }
}
</script>

<style scoped>
.wrap-leader-see-all {
    width: 100%;
    height: 75px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.wrap-table-leaderboard {
    width: 100%;
    display: flex;
    flex-direction: column;
}
</style>