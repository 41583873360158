<template>
  <div>
    <div v-if="loadStateDetail" 
        class="content-header">
        <div class="content-header-left mb-2">
            <div class="breadcrumbs-top">
                <h2 class="content-header-title mb-0">Detail Kontrak</h2>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                <i class="feather icon-home"></i>
                            </router-link>
                        </li>

                        <li class="breadcrumb-item">
                            <router-link to="/kontrak-mtu">Monitoring Kontrak MTU</router-link>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="/kontrak-mtu">Project List</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a>{{ detail && detail.no_kontrak }}</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a>Detail Kontrak</a>
                        </li>
                        <!-- <li class="breadcrumb-item active">Detail Progress</li> -->
                    </ol>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div v-if="loadStateDetail" class="card-body card-dashboard">
                                <h4 v-can="'kontrak_mtu/detail/informasi/kontrak'" class="text-primary"><strong>Informasi Kontrak</strong></h4>
                                <div class="wrapper-mtu-info-kontrak">
                                    <div class="row">
                                        <div class="col-12 col-md-6" v-can="'kontrak_mtu/detail/informasi/kontrak'">
                                            <div class="row">
                                                <div class="col-4">
                                                    <h5 class="text-black"><strong>Judul Kontrak</strong></h5>
                                                </div>
                                                <div class="col-8">
                                                    <p>{{ detail.judul_kontrak }}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-4">
                                                    <h5 class="text-black"><strong>Jenis Pekerjaan</strong></h5>
                                                </div>
                                                <div class="col-8">
                                                    <p>{{ detail.jenis_pekerjaan}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-4">
                                                    <h5 class="text-black"><strong>No. Kontrak</strong></h5>
                                                </div>
                                                <div class="col-8">
                                                    <p>{{ detail && detail.no_kontrak }}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-4">
                                                    <h5 class="text-black"><strong>No. PRK</strong></h5>
                                                </div>
                                                <div class="col-8">
                                                    <p>{{ detail | parseNoPrk }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="row">
                                                <div class="col-4">
                                                    <h5 class="text-black"><strong>Tanggal Kontrak</strong></h5>
                                                </div>
                                                <div class="col-8">
                                                    <p>{{ detail.col_tanggal_kontrak }}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-4">
                                                    <h5 class="text-black"><strong>Kontraktor</strong></h5>
                                                </div>
                                                <div class="col-8">
                                                    <p>{{ detail && detail.kontraktor && detail.kontraktor.name }}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-4">
                                                    <h5 class="text-black"><strong>Sumber Dana</strong></h5>
                                                </div>
                                                <div class="col-8">
                                                    <p>{{ detail.sumber_dana }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12 col-md-6">
                                        <div class="row">
                                            <div class="col-12 col-md-4">
                                                <h5 class="text-black"><strong>Progress Bayar</strong></h5>
                                            </div>
                                            <div class="col-12 col-md-5">
                                                <div class="d-flex flex-column" style="gap: 10px">
                                                    <h5 class="m-0 text-primary">{{ detail.progress_bayar + ' %' }}</h5>
                                                    <el-progress :percentage="parseInt(detail.progress_bayar) >= 100 ? 100 : parseInt(detail.progress_bayar)" color="#409eff" :show-text="false"></el-progress>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mx-auto text-center" style="width:100%" v-else>
                            <h2><i class="fa fa-spinner fa-spin"></i></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body card-dashboard">
                            <div class="row">
                                <div class="col-12" v-if="loadStateKendala">
                                    <div class="row">
                                        <div class="col-12" style="padding: 0">
                                            <div class="col-12 mb-2">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <h4 class="text-black m-0"><strong>Kendala</strong></h4>
                                                    <button type="button" v-can="'kontrak_mtu/detail/kendala/read'" @click="clearModalData" data-toggle="modal" data-target="#Modal_Kendala" class="btn btn-primary waves-effect waves-light btn-icon-small float-right">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <table-v2 :options="tableKendalaOption">
                                                    <template #header>
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 5%">No</th>
                                                                <th style="width: 20%">Tanggal</th>
                                                                <th>Kendala</th>
                                                                <th>Uraian</th>
                                                                <th>
                                                                    <div class="d-flex justify-content-end">Aksi</div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    </template>
                                                    <template v-slot:action="{data}">
                                                        <div class="d-flex justify-content-end">
                                                            <button v-if="data.isSolusion" @click="addSolusi(data)" data-toggle="modal" data-target="#Modal_Solusi" type="button" class="btn btn-primary waves-effect waves-light mr-1" >
                                                                <i class="fa fa-plus"></i> Solusi
                                                            </button>
                                                            <button v-can="'kontrak_mtu/detail/kendala/read'" type="button" data-toggle="modal" data-target="#Modal_DetailKendala" @click="detailModal = data" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                                                                <i class="fa fa-eye"></i>
                                                            </button>
                                                            <button v-can="'kontrak_mtu/detail/kendala/update'" @click="actionEdit('kendala', data)" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                                <i class="fa fa-pencil"></i>
                                                            </button>
                                                            <button v-can="'kontrak_mtu/detail/kendala/delete'" type="button" @click="destroy('kendala', data)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </template>
                                                </table-v2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mx-auto text-center" style="width:100%" v-else>
                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                </div>
                                <div class="col-12">
                                    <div class="py-2">
                                        <hr />
                                    </div>
                                </div>
                                <div v-if="loadStateSolusi" class="col-12">
                                    <div class="row">
                                        <div class="col-12 mb-2">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="text-black m-0"><strong>Solusi</strong></h4>
                                                <!-- <button @click="clearModalData" data-toggle="modal" data-target="#Modal_Solusi" class="btn btn-primary">
                                                    <i class="fa fa-plus"></i>
                                                </button> -->
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <table-v2 :options="tableSolusiOption">
                                                <template #header>
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 5%">No</th>
                                                            <th style="width: 20%">Tanggal</th>
                                                            <th>Solusi</th>
                                                            <th>Uraian</th>
                                                            <th>
                                                                <div class="d-flex justify-content-end">Aksi</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </template>
                                                <template v-slot:action="{data}">
                                                    <div class="d-flex justify-content-end">
                                                        <button v-can="'kontrak_mtu/detail/solusi/read'" type="button" data-toggle="modal" data-target="#Modal_Detailsolusi" @click="detailModal = data" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                                                            <i class="fa fa-eye"></i>
                                                        </button>
                                                        <button  v-can="'kontrak_mtu/detail/solusi/read'" @click="actionEdit('solusi', data)" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                        <button v-can="'kontrak_mtu/detail/solusi/read'" @click="destroy('solusi', data)" type="button" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </template>
                                            </table-v2>
                                        </div>
                                    </div>
                                </div>
                                <div class="mx-auto text-center" style="width:100%" v-else>
                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body card-dashboard">
                                <h4 class="text-primary"><strong>Informasi Tambahan</strong></h4>
                                <hr />
                                <div class="row mt-2">
                                    <div class="col-12" v-can="'kontrak_mtu/detail/informasi/nilai_kontrak'">
                                        <h4><strong>Nilai Kontrak Total Include PPN</strong></h4>
                                        <div v-if="loadStateNilaiKontrak" class="row mt-2">
                                            <div class="col-12 col-md-5">
                                            <div class="card" style="box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%) !important">
                                                <div class="card-content">
                                                    <div class="card-body card-dashboard">
                                                        <div class="row align-items-center">
                                                            <div class="col-3 col-sm-2">
                                                                <img style="width: 50px" src="/assets/img/icon/ic-money-2.png" alt="money">
                                                            </div>
                                                            <div class="col-9 col-sm-10">
                                                                <h4 class="text-primary m-0">
                                                                    <strong>{{ detailNilaiKontrak.total | currency }}</strong>
                                                                    <p class="m-0"><small>Terbilang : {{ detailNilaiKontrak.total | terbilang }}</small></p>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="row mb-2">
                                                        <div class="col-4">
                                                            <p class="m-0 text-black"><strong>Nilai Kontrak Terbayar</strong></p>
                                                            <!-- <p class="text-black">(Include PPN 10%)</p> -->
                                                        </div>
                                                        <div class="col-8">
                                                            <p class="d-inline">{{ detailNilaiKontrak.total_bayar | currency }} <em><strong class="text-black">({{detailNilaiKontrak.total_bayar | terbilang}})</strong></em></p>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-4">
                                                            <p class="m-0 text-black"><strong>% Progress Pembayaran</strong></p>
                                                            <!-- <p class="text-black">(Include PPN 10%)</p> -->
                                                        </div>
                                                        <div class="col-8">
                                                            <p class="text-primary"><strong>{{ detailNilaiKontrak.progress_bayar }}%</strong></p>
                                                            <el-progress :percentage="detailNilaiKontrak.progress_bayar" color="#409eff" :show-text="false"></el-progress>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <p class="m-0 text-black"><strong>Nilai Outstanding Pembayaran</strong></p>
                                                        </div>
                                                        <div class="col-8">
                                                            <p>{{ detailNilaiKontrak.total_outstanding | currency }} <em><strong class="text-black">({{ detailNilaiKontrak.total_outstanding | terbilang }})</strong></em></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3" v-can="'kontrak_mtu/detail/informasi/anggaran'">
                                        <h4><strong>Data Anggaran</strong></h4>
                                        <div class="row mt-2">
                                            <div v-if="loadStateDataAnggaran" class="col-12">
                                                <el-collapse class="v2-ipm-accordion" accordion>
                                                <el-collapse-item 
                                                    v-for="(val,idx) in detailDataAnggaran"
                                                    :key="idx"
                                                    class="v2-ipm-accordion-item" 
                                                    :name="idx"
                                                >
                                                    <template slot="title">
                                                        <div class="d-block">
                                                            {{ detail.keterangan_prk == 'Gabungan' ? detail.judul_kontrak : val.name }}
                                                            <p class="text-mutted m-0" style="font-weight: 400; line-height: initial">No. PRK {{ val.no_prk }}</p>
                                                        </div>
                                                    </template>
                                                    
                                                    <div class="row mt-2">
                                                        <div class="col-12">
                                                            <h4><strong>Data Anggaran</strong></h4>
                                                            <p><em>{{ val && val.anggaran && val.anggaran.no_ski || '-' }} - {{ val && val.anggaran && val.anggaran.tgl }}</em></p>
                                                        </div>
                                                        <div class="col-12 mt-1">
                                                            <div class="row">
                                                                <div class="col-12 col-md-4">
                                                                    <h5><strong>Nilai AI {{date_now}}</strong></h5>
                                                                    <h2 class="text-primary"><strong>{{ val && val.anggaran && val.anggaran.ai_thn | currency }}</strong></h2>
                                                                    <div class="card" style="background: #E32323">
                                                                        <div class="card-content">
                                                                            <div class="card-body">
                                                                                <div class="row">
                                                                                    <div class="col-12">
                                                                                        <div class="row">
                                                                                            <div class="col-6">
                                                                                                <p class="text-white m-0">
                                                                                                    <small>Kebutuhan AI {{date_now}}</small>
                                                                                                </p>
                                                                                            </div>
                                                                                            <div class="col-6">
                                                                                                <p class="text-white m-0">
                                                                                                    <small><strong>{{ val && val.anggaran && val.anggaran.kebutuhan_ai | currency }}</strong></small>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row">
                                                                                            <div class="col-6">
                                                                                                <p class="text-white m-0">
                                                                                                    <small>Selisih dari AI aktual</small>
                                                                                                </p>
                                                                                            </div>
                                                                                            <div class="col-6">
                                                                                                <p class="text-white m-0">
                                                                                                    <small><strong>{{ val && val.anggaran && val.anggaran.selisih_ai | currency }}</strong></small>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <h5><strong>Nilai AKI {{date_now}}</strong></h5>
                                                                    <h2 class="text-primary"><strong>{{ val && val.anggaran &&  val.anggaran.aki_thn | currency }}</strong></h2>
                                                                    <p><small>Realisasi <strong>{{ val && val.anggaran &&  val.anggaran.nilai_pose_kontrak | currency }}</strong></small></p>
                                                                </div>
                                                                <div class="col-12 col-sm-8" v-if="val.anggaran">
                                                                    <apexchart ref="chartAnggaran" type="bar" height="300" :options="val.anggaran.chartOpt" :series="val.anggaran.chartSeries"></apexchart>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-collapse-item>
                                                </el-collapse>
                                            </div>
                                        </div>
                                    </div>
                                    <Resume v-if="detail"
                                        :statusOptions="resumeProgressOptions"
                                        :kontrakMTU="detail"
                                        @loadStateResume="(res) => loadStateResume = res"
                                        @onResumeStatusChange="onResumeStatusChange()"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BankGaransi />
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body card-dashboard">
                                <h4 class="text-primary"><strong>Dokumen</strong></h4>
                                <hr />
                                <div class="row mt-2">
                                    <div v-if="loadStateAmandemen" class="col-12">
                                        <div class="row">
                                        <div class="col-12" v-can="'kontrak_mtu/detail/amandemen/read'">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="btn-responsive">
                                                        <div class="d-flex align-items-center" style="gap: 10px">
                                                            <h4 class="m-0"><strong>Dokumen Amandemen</strong></h4>
                                                            <button v-can="'kontrak_mtu/detail/amandemen/create'" @click="clearModalData" data-toggle="modal" data-target="#Modal_Amandemen" class="btn btn-primary">Tambah Amandemen</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-4 mb-1">
                                                    <div class="input-group form-search-rounded">
                                                        <input v-model="payloadAmandemen.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari...">
                                                        <span class="input-group-append">
                                                            <div class="input-group-text bg-transparent">
                                                                <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12" >
                                            <table-v2 :options="tableDokAmandemenOption" :no-number="true">
                                                <template #header>
                                                    <thead>
                                                        <tr>
                                                            <th>Nama</th>
                                                            <th>Nomor</th>
                                                            <th>File</th>
                                                            <th>Tanggal</th>
                                                            <th style="width: 500px">Perihal</th>
                                                            <th>
                                                                <div class="d-flex justify-content-end">Aksi</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </template>
                                                <template v-slot:action="{data}">
                                                    <div class="d-flex justify-content-end">
                                                        <button v-can="'kontrak_mtu/detail/amandemen/detail'" type="button"  @click="loadDetailAmandemen(data.id, true)" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                                                            <i class="fa fa-eye"></i>
                                                        </button>
                                                        <button v-can="'kontrak_mtu/detail/amandemen/update'" @click="actionEdit('amandemen', data)" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                        <button v-can="'kontrak_mtu/detail/amandemen/delete'" @click="destroy('amandemen', data)" type="button" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </template>
                                            </table-v2>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="mx-auto text-center" style="width:100%" v-else>
                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                    </div>
                                </div>
                                <div class="pt-2 pb-2" v-can="'kontrak_mtu/detail/amandemen/read'">
                                    <hr />
                                </div>
                                <div class="row mt-2">
                                    <div v-if="loadStateBAP" class="col-12">
                                        <div class="row">
                                            <div class="col-12" v-can="'kontrak_mtu/detail/bap/read'">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="btn-responsive">
                                                        <div class="d-flex align-items-center" style="gap: 10px">
                                                            <h4 class="m-0"><strong>Dokumen Berita Acara Pembayaran</strong></h4>
                                                            <button v-can="'kontrak_mtu/detail/bap/create'" @click="clearModalData" data-toggle="modal" data-target="#Modal_BAP" class="btn btn-primary">Tambah BAP</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-4 mb-1">
                                                    <div class="input-group form-search-rounded">
                                                        <input v-model="payloadBP.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari...">
                                                        <span class="input-group-append">
                                                            <div class="input-group-text bg-transparent">
                                                                <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <table-v2 :options="tableDokBAPOption" :no-number="true">
                                                <template #header>
                                                    <thead>
                                                        <tr>
                                                            <th>Nama</th>
                                                            <th>Nomor</th>
                                                            <th>Tanggal</th>
                                                            <th>Nilai BAP</th>
                                                            <th>BAP Berjalan</th>
                                                            <th>
                                                                <div class="d-flex justify-content-end">Aksi</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </template>
                                                <template v-slot:action="{data}">
                                                    <div class="d-flex justify-content-end">
                                                        <button v-can="'kontrak_mtu/detail/bap/detail'" type="button" data-toggle="modal" data-target="#Modal_ViewBAP" @click="loadDetailBAP(data.id, true)" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                                                            <i class="fa fa-eye"></i>
                                                        </button>
                                                        <button v-can="'kontrak_mtu/detail/bap/update'" @click="actionEdit('bap', data)" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                        <button v-can="'kontrak_mtu/detail/bap/delete'" @click="destroy('bap', data)" type="button" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </template>
                                            </table-v2>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="mx-auto text-center" style="width:100%" v-else>
                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                    </div>
                                </div>
                                <div class="pt-2 pb-2" v-can="'kontrak_mtu/detail/bap/read'">
                                    <hr />
                                </div>
                                <div class="row mt-2">
                                    <div v-if="loadStatePOSE" class="col-12">
                                        <div class="row">
                                            <div class="col-12" v-can="'kontrak_mtu/detail/pose/read'">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="btn-responsive">
                                                        <div class="d-flex align-items-center" style="gap: 10px">
                                                            <h4 class="m-0"><strong>Dokumen PO SE</strong></h4>
                                                            <button v-can="'kontrak_mtu/detail/pose/create'" @click="clearModalData" data-toggle="modal" data-target="#Modal_POSE" class="btn btn-primary">Tambah PO SE</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-4 mb-1">
                                                    <div class="input-group form-search-rounded">
                                                        <input v-model="payloadPOSE.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari...">
                                                        <span class="input-group-append">
                                                            <div class="input-group-text bg-transparent">
                                                                <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <table-v2 :options="tableDokPOSEOption" :no-number="true">
                                                <template #header>
                                                    <thead>
                                                        <tr>
                                                            <th>No. PO</th>
                                                            <th>No. SE</th>
                                                            <th>Tanggal</th>
                                                            <th>Nilai Terbayar</th>
                                                            <th>Dokumen BAP</th>
                                                            <th>
                                                                <div class="d-flex justify-content-end">Aksi</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </template>
                                                <template v-slot:action="{data}">
                                                    <div class="d-flex justify-content-end">
                                                        <button v-can="'kontrak_mtu/detail/pose/update'" @click="actionEdit('pose', data)" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                        <button v-can="'kontrak_mtu/detail/pose/delete'" @click="destroy('pose', data)" type="button" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </template>
                                            </table-v2>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="mx-auto text-center" style="width:100%" v-else>
                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                    </div>
                                </div>
                                <div class="pt-2 pb-2" v-can="'kontrak_mtu/detail/pose/read'">
                                    <hr />
                                </div>
                                <div class="row mt-2">
                                    <div v-if="loadStateKontraktual" class="col-12">
                                        <div class="row">
                                        <div class="col-12" v-can="'kontrak_mtu/detail/status_kontraktual/read'">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="btn-responsive">
                                                        <div class="d-flex align-items-center" style="gap: 10px">
                                                            <h4 class="m-0"><strong>Status Kontraktual</strong></h4>
                                                            <div v-if="detail && detail.col_status_kontraktual" :class="{'badge badge-warning badge-md' : true, 'badge-warning' : detail && detail.col_status_kontraktual === 'On Going', 'badge-primary' : detail && detail.col_status_kontraktual === 'Serah Terima I','badge-success' : detail && detail.col_status_kontraktual === 'Serah Terima II'}">{{ detail && detail.col_status_kontraktual }}</div>
                                                            <button v-can="'kontrak_mtu/detail/status_kontraktual/create'" data-toggle="modal" data-target="#Modal_StatusKontraktual" class="btn btn-primary" @click="resetFormKontraktual()">Tambah Status</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-4 mb-1">
                                                    <div class="input-group form-search-rounded">
                                                        <input v-model="payloadKontraktor.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari...">
                                                        <span class="input-group-append">
                                                            <div class="input-group-text bg-transparent">
                                                                <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <table-v2 :no-number="true" :options="tableStatusKontraktualOption">
                                                <template #header>
                                                    <thead>
                                                        <tr>
                                                            <th>Lokasi</th>
                                                            <th>Nama</th>
                                                            <th>Tanggal</th>
                                                            <th>Nomor</th>
                                                            <th>Status</th>
                                                            <th><i class="feather icon-download-cloud mr-1"></i> File</th>
                                                            <th>
                                                                <div class="d-flex justify-content-end">Aksi</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </template>
                                                <template v-slot:action="{data}">
                                                    <div class="d-flex justify-content-end">
                                                        <!-- <button type="button" data-toggle="modal" data-target="#Modal_KontraktorData" @click="detail = data" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                                                            <i class="fa fa-eye"></i>
                                                        </button> -->
                                                        <button v-can="'kontrak_mtu/detail/status_kontraktual/update'" @click="actionEdit('kontraktual', data)" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                    </div>
                                                </template>
                                            </table-v2>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="mx-auto text-center" style="width:100%" v-else>
                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                    </div>
                                </div>
                                <div class="pt-2 pb-2" v-can="'kontrak_mtu/detail/status_kontraktual/read'">
                                    <hr />
                                </div>
                                <div class="row mt-2">
                                    <div v-if="loadStateDokPendukung" class="col-12">
                                        <div class="row">
                                            <div class="col-12" v-can="'kontrak_mtu/detail/doc_pendukung/read'">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="btn-responsive">
                                                        <div class="d-flex align-items-center" style="gap: 10px">
                                                            <h4 class="m-0"><strong>Dokumen Pendukung</strong></h4>
                                                            <button v-can="'kontrak_mtu/detail/doc_pendukung/create'" type="button" @click="clearModalData" data-toggle="modal" data-target="#Modal_DokPendukung" class="btn btn-primary">Tambah Dokumen</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-4 mb-1">
                                                    <div class="input-group form-search-rounded">
                                                        <input v-model="payloadDokPendukung.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari...">
                                                        <span class="input-group-append">
                                                            <div class="input-group-text bg-transparent">
                                                                <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <table-v2 :options="tableDokPendukungOption" :no-number="true">
                                                <template #header>
                                                    <thead>
                                                        <tr>
                                                            <th>Lokasi</th>
                                                            <th>Nama</th>
                                                            <th><i class="feather icon-download-cloud mr-1"></i> File</th>
                                                            <th>
                                                                <div class="d-flex justify-content-end">Aksi</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </template>
                                                <template v-slot:action="{data}">
                                                    <div class="d-flex justify-content-end">
                                                        <button v-can="'kontrak_mtu/detail/doc_pendukung/update'" @click="actionEdit('dok_pendukung', data)" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                    </div>
                                                </template>
                                            </table-v2>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="mx-auto text-center" style="width:100%" v-else>
                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div id="Modal_DetailKendala" 
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" style="max-width:60%;" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Detail Kendala</h5>
                    <button type="button" @click="detailModal = null" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="d-block">
                        <h5><strong>Nama Kendala</strong></h5>
                        <p class="text-black">{{ detailModal && detailModal.name }}</p>
                    </div>
                    <div class="d-block">
                        <h5><strong>Uraian</strong></h5>
                        <p class="text-black" v-html="detailModal && detailModal.uraian"></p>
                    </div>
                    <div class="d-block" v-if="detailModal && detailModal.image_url">
                        <h5><strong>Foto</strong></h5>
                        <button class="foto-detail btn p-0" data-toggle="modal" data-target="#Modal_LightBox">
                            <img :src="detailModal && detailModal.image_url || 'x'" alt="foto">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="Modal_DetailSolusi" 
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Detail Solusi</h5>
                    <button type="button" @click="detailModal = null" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="d-block">
                        <h5><strong>Nama Solusi</strong></h5>
                        <p class="text-black">{{ detailModal && detailModal.name }}</p>
                    </div>
                    <div class="d-block">
                        <h5><strong>Periode Progress</strong></h5>
                        <p class="text-black">{{'-'}}</p>
                    </div>
                    <div class="d-block">
                        <h5><strong>uraian</strong></h5>
                        <p class="text-black">{{ detailModal && detailModal.uraian }}</p>
                    </div>
                    <div class="d-block">
                        <h5><strong>Foto</strong></h5>
                        <div class="foto-detail">
                            <img :src="detailModal && detailModal.image_url || 'x'" alt="foto">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="Modal_Kendala"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <ValidationObserver v-slot="{ invalid }" ref="listKendala">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title">{{ editState ? 'Edit Kendala' : 'Tambah Kendala' }}</h5>
                        <button type="button" @click="clearModalData" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="form-group">
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                <label for="">Nama Kendala</label>
                                <input type="text" v-model="formKendala.name" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                <small>{{ errors[0] }} </small>
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <ValidationProvider name="Uraian" rules="required" v-slot="{ errors }">
                                <label for="">Uraian</label>
                                <!-- <textarea class="form-control" v-model="formKendala.uraian" :class="(Boolean(errors[0]))? 'is-invalid' : ''"></textarea> -->
                                <quill-editor v-model="formKendala.uraian" :options="editorOption"/>
                                <small style="color : #EA5455;">{{ errors[0] }} </small>
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <label for="">Foto Kendala</label>
                            <vue-dropzone ref="dropZoneKendala" id="dropzone" 
                                :duplicateCheck="true" 
                                :options="dropzoneKendalaOptions" 
                                @vdropzone-files-added="(e) => { formKendala.image = e[0]; is_new_file = true}"
                                @vdropzone-removed-file="deleteFile"
                            ></vue-dropzone>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                        <button v-if="submitKendalaState" type="button" @click="submitKendala" :disabled="invalid" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_Solusi"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <ValidationObserver v-slot="{ invalid }" ref="listKendala">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title">{{ editState ? 'Edit Solusi' : 'Tambah Solusi' }}</h5>
                        <button type="button" @click="clearModalData" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="form-group">
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                <label for="">Name</label>
                                <input type="text" v-model="formSolusi.name" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                <small>{{ errors[0] }} </small>
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <ValidationProvider name="Uraian" rules="required" v-slot="{ errors }">
                                <label for="">Uraian</label>
                                <textarea class="form-control" v-model="formSolusi.uraian" :class="(Boolean(errors[0]))? 'is-invalid' : ''"></textarea>
                                <small style="color : #EA5455;">{{ errors[0] }} </small>
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <label for="">Foto Kendala</label>
                            <vue-dropzone ref="dropZoneSolusi" id="dropzone" 
                                :duplicateCheck="true" 
                                :options="dropzoneKendalaOptions" 
                                @vdropzone-files-added="(e) =>{ formSolusi.image = e[0];is_new_file = true }"
                                @vdropzone-removed-file="deleteFile"
                            ></vue-dropzone>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                        <button v-if="submitSolusiState" type="button" @click="submitSolusi" :disabled="invalid" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_DokPendukung"
        class="modal fade text-left"  
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Tambah Dokumen</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenPendukungForm">
                    <div class="modal-body">
                        <div class="form-group">
                            <ValidationProvider name="Lokasi" rules="required" v-slot="{ errors }">
                                <label for="">Pilih Lokasi</label>
                                <select v-model="formDP.id_kontrak_mtu_lokasi" class="form-control">
                                    <option value="">- Pilih Lokasi -</option>
                                    <option :value="val.id" v-for="(val, idx) in miscLocation" :key="idx">{{ val.name }}</option>
                                </select>
                                <small>{{ errors[0] }} </small>
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <ValidationProvider name="Nama Dokumen" rules="required" v-slot="{ errors }">
                                <label for="">Nama Dokumen</label>
                                <select v-model="formDP.name" class="form-control">
                                    <option value="">- Pilih Dokumen -</option>
                                    <option :value="val.name" v-for="(val, idx) in listNameDokumenPendukung" :key="idx">{{ val.name }}</option>
                                </select>
                                <small>{{ errors[0] }} </small>
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <label for="">Foto Kontrak</label>
                            <vue-dropzone ref="dropzoneDokPendukung" id="dropzone" 
                                :duplicateCheck="true" 
                                :options="dropzoneSKOptions" 
                                @vdropzone-files-added="(e) => { formDP.document = e[0]; is_new_file = true}"
                                @vdropzone-removed-file="deleteFile"
                            ></vue-dropzone>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                        <button v-if="submitDokPendukungState" @click="submitDokPendukung" :disabled="invalid" type="button" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_Resume"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" style="max-width:90%;" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Penginputan Progress</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenResume">
                    <div class="modal-body">
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Jenis Progress" rules="required" v-slot="{ errors }">
                                            <label for="">Jenis Progress</label>
                                            <select v-model="formResume.type" class="form-control">
                                                <option value="">- Pilih Jenis Progress -</option>
                                                <option :value="val.id" v-for="(val, idx) in jenisProgress" :key="idx">{{ val.name }}</option>
                                            </select>
                                            <small>{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Lokasi" rules="required" v-slot="{ errors }">
                                            <label for="">Pilih Lokasi</label>
                                            <select v-model="formResume.id_kontrak_mtu_lokasi" class="form-control">
                                                <option value="">- Pilih Lokasi -</option>
                                                <option :value="val.id" v-for="(val, idx) in miscLocation" :key="idx">{{ val.name }}</option>
                                            </select>
                                            <small>{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Progress" rules="required" v-slot="{ errors }">
                                            <label for="">Pilih Progress</label>
                                            <div :style="(Boolean(errors[0]))? 'border : 1px solid red;border-radius: 0.3rem' : ''"> 
                                                <multiselect 
                                                    :options="resumeProgressOptions" 
                                                    :multiple="false" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    v-model="formResume.progress_type"
                                                    :preserve-search="true" 
                                                    placeholder="Pilih Progress" 
                                                    label="name" 
                                                    track-by="id"
                                                    :class="(Boolean(errors[0]))? 'is-invalid' : ''" 
                                                    :preselect-first="false"
                                                    :searchable="true"
                                                    @select="onSelect($event)"
                                                    @remove="onRemove($event)"
                                                >
                                                    <template v-slot:noResult>
                                                        Tidak ada data.
                                                    </template>
                                                    <template v-slot:noOptions>
                                                        Tidak ada data.
                                                    </template>                                                  
                                                </multiselect>
                                            </div>
                                            <small>{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Nilai" rules="required" v-slot="{ errors }">
                                            <label for="">{{ formResume.type === 1 ? '% Progress Fisik' : 'Nilai' }}</label>
                                            <input type="text" disabled v-model="formResume.progress_value" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                            <small>{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div> -->
                            </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                        <button type="button" v-if="submitResumeState" @click="submitResume" :disabled="invalid" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_Amandemen" 
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" style="max-width:90%;" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">{{ editState ? 'Edit Amandemen' : 'Tambah Amandemen' }}</h5>
                    <button @click="clearModalData" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenResume">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <ValidationProvider name="Nama Amandemen" rules="required" v-slot="{ errors }">
                                        <label for="">Nama Amandemen</label>
                                        <input type="text" v-model="formAmandemen.name" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <ValidationProvider name="Nomor Amandemen" rules="required" v-slot="{ errors }">
                                        <label for="">Nomor Amandemen</label>
                                        <input type="text" v-model="formAmandemen.nomor" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="">Tanggal Dokumen</label>
                                <div class="position-relative has-icon-right mb-2">
                                    <ValidationProvider name="Tanggal Dokumen" rules="required" v-slot="{ errors }">
                                    <date-picker
                                        format="DD/MM/YYYY"
                                        type="date"
                                        v-model="formAmandemen.tanggal"
                                        :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                        value-type="YYYY-MM-DD"
                                        style="width: 100%;"
                                        >
                                    </date-picker>
                                    <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <label for="">Dokumen</label>
                                    <vue-dropzone ref="dropzoneAmandemen" id="dropzone" 
                                        :duplicateCheck="true" 
                                        :options="dropzoneAmandemenOptions" 
                                        @vdropzone-files-added="(e) => { formAmandemen.document = e[0]; is_new_file = true}"
                                        @vdropzone-removed-file="deleteFile"
                                    ></vue-dropzone>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <ValidationProvider name="Perihal Umum" rules="required" v-slot="{ errors }">
                                        <label for="">Perihal Umum</label>
                                        <div :style="(Boolean(errors[0]))? 'border : 1px solid red;border-radius: 0.3rem' : ''"> 
                                            <multiselect 
                                                :options="optPerihalUmum" 
                                                :multiple="true" 
                                                :close-on-select="false" 
                                                :clear-on-select="false" 
                                                v-model="formAmandemen.perihal"
                                                :preserve-search="true" 
                                                placeholder="Pilih Perihal Umum" 
                                                label="name" 
                                                track-by="id"
                                                :class="(Boolean(errors[0]))? 'is-invalid' : ''" 
                                                :preselect-first="false"
                                                :searchable="true"
                                                @select="onSelect($event)"
                                                @remove="onRemove($event)"
                                            >
                                                <template v-slot:noResult>
                                                    Tidak ada data.
                                                </template>
                                                <template v-slot:noOptions>
                                                    Tidak ada data.
                                                </template>                                                  
                                            </multiselect>
                                        </div>
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label for="">Lokasi</label>
                                            <div @click="tambahLokasiAmandemen" class="d-flex align-items-center" style="gap: 10px; cursor: pointer;">
                                                <p class="m-0 text-primary">Tambah Lokasi</p>
                                                <div class="d-flex justify-content-between align-items-center" style="background: #03566B; width: 20px; height: 20px;border-radius: 50%;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row mb-2" v-for="(lok, idx) in formAmandemen.lokasi" :key="idx">
                                            <transition name="fadeY" appear>
                                                <div class="col-12 wrap-row-deleted" style="height: 50%" v-if="lok.deleted">
                                                    <label class="text-white"> Di Hapus </label>
                                                    <button @click="lok.deleted = false" class="btn btn-primary text-white">Balikan</button>
                                                </div>
                                            </transition>
                                            <div  class="col">
                                                <div class="row fieldset-style pt-2">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <ValidationProvider :name="'Lokasi ' + idx + 1" rules="required" v-slot="{ errors }">
                                                                <label for="">Pilih Lokasi</label>
                                                                <select v-model="lok.id_kontrak_mtu_lokasi" 
                                                                    class="form-control">
                                                                    <option value="">- Pilih Lokasi -</option>
                                                                    <option 
                                                                        :value="val.id" 
                                                                        v-for="(val, idx) in miscLocation" 
                                                                        :key="idx" 
                                                                        :disabled="isMiscLocationDisabled(val)">
                                                                        {{ val.name }}
                                                                    </option>
                                                                </select>
                                                                <small>{{ errors[0] }} </small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <ValidationProvider :name="'Perihal ' + idx + 1" rules="required" v-slot="{ errors }">
                                                                <label for="">Perihal</label>
                                                                <div :style="(Boolean(errors[0]))? 'border : 1px solid red;border-radius: 0.3rem' : ''"> 
                                                                    <multiselect 
                                                                        :options="formAmandemen.perihal" 
                                                                        :multiple="true" 
                                                                        :close-on-select="false" 
                                                                        v-model="lok.perihal"
                                                                        :clear-on-select="false" 
                                                                        :preserve-search="true" 
                                                                        placeholder="Pilih Perihal Umum" 
                                                                        label="name" 
                                                                        track-by="id"
                                                                        :class="(Boolean(errors[0]))? 'is-invalid' : ''" 
                                                                        :preselect-first="false"
                                                                        :searchable="true"
                                                                        @select="onSelectPerihal($event, idx, lok.id_kontrak_mtu_lokasi)"
                                                                        @remove="onRemovePerihal($event, idx)"
                                                                    >
                                                                        <template v-slot:noResult>
                                                                            Tidak ada data.
                                                                        </template>
                                                                        <template v-slot:noOptions>
                                                                            Tidak ada data.
                                                                        </template>                                                  
                                                                    </multiselect>
                                                                </div>
                                                                <small>{{ errors[0] }} </small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mb-2" v-if="isShowByPerihal(2, lok.perihal)">
                                                        <div class="row">
                                                            <div v-for="(lok_nilai, idx_lok_nilai) in lok.nilai" :key="idx_lok_nilai" class="col-12">
                                                                <h5 class="mt-2 mb-2"><strong>{{ lok_nilai.name }}</strong></h5>
                                                                <div class="row">
                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">IDR</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                                <money class="form-control" v-model="formAmandemen.lokasi[idx].nilai[idx_lok_nilai].idr" v-bind="money"></money>
                                                                                <div class="form-control-position">
                                                                                    Rp
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">CNY</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                                <money class="form-control" v-model="lok_nilai.cny" v-bind="money"></money>
                                                                                <div class="form-control-position">
                                                                                    &#x5143;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">JPY</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                            <money class="form-control" v-model="lok_nilai.jpy" v-bind="money"></money>
                                                                                <div class="form-control-position">
                                                                                    &#xa5;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">EURO</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                                <money class="form-control" v-model="lok_nilai.eur" v-bind="money"></money>
                                                                                <div class="form-control-position">
                                                                                    &euro;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">USD</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                                <money class="form-control" v-model="lok_nilai.usd" v-bind="money"></money>
                                                                                <div class="form-control-position">
                                                                                    $
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12">
                                                                        <ValidationProvider :name="'Nilai Kontrak ' + lok.name + ' ' + idx_lok_nilai + 1 +' ' + idx + 1" rules="" v-slot="{ errors }">
                                                                            <input type="hidden" v-model="lok_nilai.nilai">
                                                                            <p style="color : #EA5455;">{{ Boolean(errors[0])? '*Nilai Kontrak tidak boleh Rp 0' : '' }}</p>
                                                                        </ValidationProvider>
                                                                    </div>

                                                                    <div class="col-12">
                                                                        <h5>Equivalent</h5>
                                                                        <h3><strong>{{ equivalentV2(lok_nilai, idx, idx_lok_nilai) | currency }}</strong></h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-12" v-if="isShowByPerihal(4, lok.perihal) && formAmandemen.lokasi[idx]?.loc_type.length > 0">
                                                                <div class="row d-flex align-items-center" 
                                                                    v-for="(zz, z) in formAmandemen.lokasi[idx].loc_type.length" 
                                                                    :key="z">
                                                                    <div class="col" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byId'">
                                                                        <div class="form-group">
                                                                            <ValidationProvider :name="'Lokasi'  + idx + 1" rules="required" v-slot="{ errors }">
                                                                                <label for="">Lokasi Baru</label>
                                                                                <select v-model="lok.id_new_kontrak[z]" class="form-control">
                                                                                    <option value="">- Pilih Lokasi -</option>
                                                                                    <option :value="val.id" v-for="(val, idx) in miscListKontrak" :key="idx">{{ val.judul_kontrak }}</option>
                                                                                </select>
                                                                                <small>{{ errors[0] }} </small>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byId'">
                                                                        <div class="form-group">
                                                                            <ValidationProvider :name="'Lokasi'  + idx + 1 + '- Material'" rules="required" v-slot="{ errors }">
                                                                                <label for="">Material</label>
                                                                                <!-- <input type="text" class="form-control" v-model="lok.konfigurasi[z]" disabled> -->
                                                                                <select v-model="lok.konfigurasi[z]" class="form-control">
                                                                                    <option 
                                                                                        :value="konfigurasi" 
                                                                                        v-for="(konfigurasi, ii) in getLocationMaterialOptions(idx)" 
                                                                                        :key="ii">
                                                                                        {{ konfigurasi }}
                                                                                    </option>
                                                                                </select>
                                                                                <small>{{ errors[0] }} </small>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byId'">
                                                                        <div class="form-group">
                                                                            <ValidationProvider :name="'Lokasi'  + idx + 1 + '- Jumlah'" :rules="{ required: true }" v-slot="{ errors }">
                                                                                <label for="">Jumlah</label>
                                                                                <input type="number" class="form-control" v-model="lok.jumlah[z]">
                                                                                <small>{{ errors[0] }} </small>
                                                                                <small class="text-danger" v-if="!isValidTotalOfMaterial(idx, z)">Jumlah Material {{ lok.konfigurasi[z] }} tidak boleh lebih dari {{ maxOfMaterial(idx, z) }}</small>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byId'">
                                                                        <div class="form-group">
                                                                            <ValidationProvider :name="'Lokasi'  + idx + 1 + '- Ready At Site'" rules="required" v-slot="{ errors }">
                                                                                <label for="">Ready At Site</label>
                                                                                <date-picker
                                                                                    v-model="lok.ready_at[z]"
                                                                                    format="DD/MM/YYYY"
                                                                                    type="date"
                                                                                    placeholder="Pilih Tanggal"
                                                                                    :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                                                                    value-type="YYYY-MM-DD"
                                                                                    style="width: 100%;"
                                                                                    >
                                                                                </date-picker>
                                                                                <small>{{ errors[0] }} </small>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto d-flex" style="gap: 8px;" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byId'">
                                                                        <button class="btn btn-danger" 
                                                                            v-if="lok.id_new_kontrak.length > 0"
                                                                            @click="deleteNewLocation(z, idx)">
                                                                            <i class="fa fa-trash"></i></button>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0 mb-3">
                                                                    <button class="btn btn-primary d-flex align-items-center" style="gap: 8px;"
                                                                        @click="addnewLocation(idx, 'byId')">
                                                                    <i class="fa fa-plus"></i> Lokasi Baru</button>
                                                                </div>
                                                            </div>
                                                            <div class="col-12" v-if="isShowByPerihal(3, lok.perihal) && formAmandemen.lokasi[idx]?.loc_type.length > 0">
                                                                <div class="row d-flex align-items-center" 
                                                                    v-for="(zz, z) in formAmandemen.lokasi[idx].loc_type.length" 
                                                                    :key="z">
                                                                    <div class="col" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byName'">
                                                                        <div class="form-group">
                                                                            <ValidationProvider :name="'Lokasi Baru' + idx + 1" rules="required" v-slot="{ errors }">
                                                                                <label for="">Lokasi Baru</label>
                                                                                <input type="text" v-model="lok.name[z]" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                                <small>{{ errors[0] }} </small>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byName'">
                                                                        <div class="form-group">
                                                                            <ValidationProvider :name="'Lokasi'  + idx + 1 + '- Material'" rules="required" v-slot="{ errors }">
                                                                                <label for="">Material</label>
                                                                                <!-- <input type="text" class="form-control" v-model="lok.konfigurasi[z]" disabled> -->
                                                                                <select v-model="lok.konfigurasi[z]" class="form-control">
                                                                                    <option 
                                                                                        :value="konfigurasi" 
                                                                                        v-for="(konfigurasi, ii) in getLocationMaterialOptions(idx)" 
                                                                                        :key="ii">
                                                                                        {{ konfigurasi }}
                                                                                    </option>
                                                                                </select>
                                                                                <small>{{ errors[0] }} </small>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byName'">
                                                                        <div class="form-group">
                                                                            <ValidationProvider :name="'Lokasi'  + idx + 1 + '- Jumlah'" :rules="{ required: true }" v-slot="{ errors }">
                                                                                <label for="">Jumlah</label>
                                                                                <input type="number" class="form-control" v-model="lok.jumlah[z]">
                                                                                <small>{{ errors[0] }} </small>
                                                                                <small class="text-danger" v-if="!isValidTotalOfMaterial(idx, z)">Jumlah Material {{ lok.konfigurasi[z] }} tidak boleh lebih dari {{ maxOfMaterial(idx, z) }}</small>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byName'">
                                                                        <div class="form-group">
                                                                            <ValidationProvider :name="'Lokasi'  + idx + 1 + '- Ready At Site'" rules="required" v-slot="{ errors }">
                                                                                <label for="">Ready At Site</label>
                                                                                <date-picker
                                                                                    v-model="lok.ready_at[z]"
                                                                                    format="DD/MM/YYYY"
                                                                                    type="date"
                                                                                    placeholder="Pilih Tanggal"
                                                                                    :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                                                                    value-type="YYYY-MM-DD"
                                                                                    style="width: 100%;"
                                                                                    >
                                                                                </date-picker>
                                                                                <small>{{ errors[0] }} </small>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto d-flex" style="gap: 8px;" v-if="formAmandemen.lokasi[idx].loc_type[z] === 'byName'">
                                                                        <button class="btn btn-danger" 
                                                                            v-if="lok.id_new_kontrak.length > 0"
                                                                            @click="deleteNewLocation(z, idx)">
                                                                            <i class="fa fa-trash"></i></button>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0 mb-3">
                                                                    <button class="btn btn-primary d-flex align-items-center" style="gap: 8px;"
                                                                        @click="addnewLocation(idx, 'byName')">
                                                                    <i class="fa fa-plus"></i> Lokasi Baru</button>
                                                                </div>
                                                            </div>

                                                            <div class="col-12" v-if="isShowByPerihal(1, lok.perihal)">
                                                                <label for="">Tanggal Akhir Kontrak</label>
                                                                <div class="position-relative has-icon-right mb-2">
                                                                    <ValidationProvider :name="'Tanggal Dokumen' + idx + 1" rules="required" v-slot="{ errors }">
                                                                    <date-picker
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        v-model="lok.tanggal"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        style="width: 100%;"
                                                                        >
                                                                    </date-picker>
                                                                    <small>{{ errors[0] }} </small>
                                                                    </ValidationProvider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12" v-if="isShowByPerihal(5, lok.perihal)">
                                                        <div class="form-group">
                                                            <ValidationProvider :name="'Tentang Perubahan '  + idx + 1" rules="required" v-slot="{ errors }">
                                                                <label for="">Tentang Perubahan</label>
                                                                <textarea v-model="lok.other" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''"></textarea>
                                                                <small style="color : #EA5455;">{{ errors[0] }} </small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-auto d-flex align-items-center">
                                                <button @click="deleteRowLokasiAmandemen(idx)" class="btn p-0">
                                                    <h4 class="m-0"><i class="feather icon-trash text-danger"></i></h4>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                        <button type="button" v-if="submitAmandemenState" @click="submitAmandemen" :disabled="invalid" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_BAP"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" style="max-width:80%;" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">{{ editState ? 'Edit BAP' : 'Tambah BAP' }}</h5>
                    <button @click="clearModalData" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <ValidationProvider name="Nama BAP" rules="required" v-slot="{ errors }">
                                        <label for="">Nama BAP</label>
                                        <input v-model="formBAP.name" type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <ValidationProvider name="Nomor" rules="required" v-slot="{ errors }">
                                        <label for="">Nomor</label>
                                        <input v-model="formBAP.nomor" type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="">Tanggal</label>
                                <div class="position-relative has-icon-right mb-2">
                                    <ValidationProvider name="Tanggal Kontrak" rules="required" v-slot="{ errors }">
                                    <date-picker
                                        format="DD/MM/YYYY"
                                        type="date"
                                        v-model="formBAP.date"
                                        :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                        value-type="YYYY-MM-DD"
                                        style="width: 100%;"
                                        >
                                    </date-picker>
                                    <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <label for="">Dokumen</label>
                                    <vue-dropzone ref="dropzoneBAP" id="dropzone" 
                                        :duplicateCheck="true" 
                                        :options="dropzoneSKOptions" 
                                        @vdropzone-files-added="(e) => { formBAP.document = e[0]; is_new_file = true}"
                                        @vdropzone-removed-file="deleteFile"
                                    ></vue-dropzone>
                                </div>
                            </div>
                            <div class="col-12">
                                <!-- <div class="row align-items-end">
                                    <div class="col-12 col-sm-6">
                                        <label for="">Pilih Lokasi</label>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label for="">Pilih Progress</label>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="row align-items-end">
                                            <div class="col"><label for="">Nilai</label></div>
                                            <div class="col"><label for="">PPN</label></div>
                                            <div class="col"><label for="">Nilai + PPN</label></div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12"></div>
                                </div> -->
                                <div class="row" style="position: relative" v-for="(s, idx) in formBAP.lokasi" :key="idx">
                                    <div class="col-12" v-if="idx != 0"><hr></div>
                                    <transition name="fadeY" appear>
                                        <div class="col-12 wrap-row-deleted" v-if="s.deleted">
                                            <label class="text-white"> Di Hapus </label>
                                            <button @click="s.deleted = false" class="btn btn-primary text-white">Balikan</button>
                                        </div>
                                    </transition>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Pilih Lokasi</label>
                                            <ValidationProvider name="Lokasi" rules="required" v-slot="{ errors }">
                                                <select v-model="s.id_kontrak_mtu_lokasi" @change="loadMiscProgressResume(s.id_kontrak_mtu_lokasi)" class="form-control">
                                                    <option value="">- Pilih Lokasi -</option>
                                                    <option :value="val.id" v-for="(val, idx) in miscLocation" :key="idx" :disabled="isDisabledLocationBAP(val)">{{ val.name }}</option>
                                                </select>
                                                <small>{{ errors[0] }} </small>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Pilih Progress</label>
                                            <ValidationProvider name="Progress" rules="required" v-slot="{ errors }">
                                                <!-- <label for="">Pilih Progressssssss</label> -->
                                                <div :style="(Boolean(errors[0]))? 'border : 1px solid red;border-radius: 0.3rem' : ''"> 
                                                    <multiselect 
                                                        :options="resumeProgressOptions" 
                                                        :multiple="true" 
                                                        v-model="s.progress_type"
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="Pilih Progress" 
                                                        label="name" 
                                                        track-by="id"
                                                        :class="(Boolean(errors[0]))? 'is-invalid' : ''" 
                                                        :preselect-first="false"
                                                        :searchable="true"
                                                        @input="onInputProgresCalculate(idx)"
                                                        @select="onSelect($event)"
                                                        @remove="onRemove($event)"
                                                    >
                                                        <template v-slot:noResult>
                                                            Tidak ada data.
                                                        </template>
                                                        <template v-slot:noOptions>
                                                            Tidak ada data.
                                                        </template>                                                  
                                                    </multiselect>
                                                </div>
                                                <small>{{ errors[0] }} </small>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-11">
                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="">Nilai</label>
                                                    <ValidationProvider name="Nilai" :rules="{ regex:/^[0-9]+,?[0-9]*/, required:true}" v-slot="{ errors }">
                                                        <!-- <input type="text" v-model="s.value" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''"> -->
                                                        <money class="form-control" v-model="s.value" v-bind="money" :class="(Boolean(errors[0]))? 'is-invalid' : ''"/>
                                                        <small>{{ errors[0] }} </small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="">PPN</label>
                                                    <ValidationProvider name="PPN" :rules="{ regex:/^[0-9]+,?[0-9]*/, required:true}" v-slot="{ errors }">
                                                        <!-- <input type="text" v-model="s.ppn" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''"> -->
                                                        <money class="form-control" v-model="s.ppn" v-bind="money" :class="(Boolean(errors[0]))? 'is-invalid' : ''"/>
                                                        <small>{{ errors[0] }} </small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="">Nilai + PPN</label>
                                                    <ValidationProvider name="Nilai" :rules="{ regex:/^[0-9]+,?[0-9]*/, required:true}" v-slot="{ errors }">
                                                        <!-- <input type="text" readonly :value="parseInt(s.value) + parseInt(s.ppn)" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''"> -->
                                                        <money class="form-control" readonly :value="s.value + s.ppn" :class="(Boolean(errors[0]))? 'is-invalid' : ''"/>
                                                        <small>{{ errors[0] }} </small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-1">
                                        <div style="gap: 10px; padding-top: 10px; height: 100%; padding-bottom: 1.5rem" class="d-flex align-items-center">
                                            <button @click="removeLokasiBAP(idx)" class="btn p-0"><h4 class="m-0"><i class="feather icon-trash text-danger"></i></h4></button>
                                            <button 
                                                v-if="idx === formBAP.lokasi.length - 1"
                                                @click="addLokasiBAP"
                                                class="btn p-0"
                                            >
                                                <h4 class="m-0">
                                                    <i class="feather icon-plus-circle text-primary"></i>
                                                </h4>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" @click="clearModalData" data-dismiss="modal">Batal</button>
                        <button v-if="submitBAPState" @click="submitBAP" type="button" :disabled="invalid" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_POSE" 
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">{{ editState ? 'Edit PO SE' : 'Tambah PO SE' }}</h5>
                    <button @click="clearModalData" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider name="Nomor PO" rules="required" v-slot="{ errors }">
                                        <label for="">Nomor PO</label>
                                        <input type="text" v-model="formPOSE.nomor_po" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider name="Nomor SE" rules="required" v-slot="{ errors }">
                                        <label for="">Nomor SE</label>
                                        <input type="text" v-model="formPOSE.nomor_se" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="position-relative has-icon-right mb-2">
                                    <ValidationProvider name="Tanggal Dokumen" rules="required" v-slot="{ errors }">
                                        <label for="">Tanggal</label>
                                        <date-picker
                                            format="DD/MM/YYYY"
                                            type="date"
                                            v-model="formPOSE.date"
                                            :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                            value-type="YYYY-MM-DD"
                                            style="width: 100%;"
                                            >
                                        </date-picker>
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider name="Lokasi" rules="required" v-slot="{ errors }">
                                        <label for="">Dokumen BAP</label>
                                        <select v-model="formPOSE.id_kontrak_mtu_bap" class="form-control">
                                            <option value="">- Pilih Lokasi -</option>
                                            <option :value="parseInt(val.id)" v-for="(val, idx) in tableDokBAPOption.list" :key="idx">{{ val.name }}</option>
                                        </select>
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12" v-if="formPOSE.id_kontrak_mtu_bap">
                                <div class="form-group">
                                    <ValidationProvider name="Lokasi BAP" rules="required" v-slot="{ errors }">
                                        <label for="">Lokasi BAP</label>
                                        <select v-model="formPOSE.id_kontrak_mtu_lokasi" class="form-control">
                                            <option value="">- Pilih Lokasi -</option>
                                            <option :value="parseInt(val.value)" v-for="(val, idx) in listLocPose" :key="idx">{{ val.text }}</option>
                                        </select>
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <label for="">Nilai Terbayar</label>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">IDR</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                <money class="form-control" v-model="currencyForm.idr" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    Rp
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">CNY</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                <money class="form-control" v-model="currencyForm.cny" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    &#x5143;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">JPY</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                            <money class="form-control" v-model="currencyForm.jpy" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    &#xa5;
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">EURO</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                <money class="form-control" v-model="currencyForm.eur" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    &euro;
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">USD</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                <money class="form-control" v-model="currencyForm.usd" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    $
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-2">
                                        <p>Equivalent</p>
                                        <h4><strong>{{ equivalent | currency }}</strong></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button @click="clearModalData" type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                        <button v-if="submitPOSEState" @click="submitPOSE" type="button" :disabled="invalid" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_POSE_EDIT"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">{{ editState ? 'Edit PO SE' : 'Tambah PO SE' }}</h5>
                    <button @click="clearModalData" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider name="Nomor PO" rules="required" v-slot="{ errors }">
                                        <label for="">Nomor PO</label>
                                        <input type="text" v-model="formPOSE.nomor_po" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider name="Nomor SE" rules="required" v-slot="{ errors }">
                                        <label for="">Nomor SE</label>
                                        <input type="text" v-model="formPOSE.nomor_se" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="position-relative has-icon-right mb-2">
                                    <ValidationProvider name="Tanggal Dokumen" rules="required" v-slot="{ errors }">
                                        <label for="">Tanggal</label>
                                        <date-picker
                                            format="DD/MM/YYYY"
                                            type="date"
                                            v-model="formPOSE.date"
                                            :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                            value-type="YYYY-MM-DD"
                                            style="width: 100%;"
                                            >
                                        </date-picker>
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider name="Lokasi" rules="required" v-slot="{ errors }">
                                        <label for="">Dokumen BAP</label>
                                        <select v-model="formPOSE.id_kontrak_mtu_bap" class="form-control">
                                            <option value="">- Pilih Lokasi -</option>
                                            <option :value="parseInt(val.id)" v-for="(val, idx) in tableDokBAPOption.list" :key="idx">{{ val.name }}</option>
                                        </select>
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12" v-if="formPOSE.id_kontrak_mtu_bap">
                                <div class="form-group">
                                    <ValidationProvider name="Lokasi BAP" rules="required" v-slot="{ errors }">
                                        <label for="">Lokasi BAP</label>
                                        <select v-model="formPOSE.id_kontrak_mtu_lokasi" class="form-control">
                                            <option value="">- Pilih Lokasi -</option>
                                            <option :value="parseInt(val.value)" v-for="(val, idx) in listLocPose" :key="idx">{{ val.text }}</option>
                                        </select>
                                        <small>{{ errors[0] }} </small>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <label for="">Nilai Terbayar</label>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">IDR</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                <money class="form-control" v-model="formPOSE.idr" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    Rp
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">CNY</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                <money class="form-control" v-model="formPOSE.cny" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    &#x5143;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">JPY</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                            <money class="form-control" v-model="formPOSE.jpy" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    &#xa5;
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">EURO</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                <money class="form-control" v-model="formPOSE.eur" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    &euro;
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">USD</label>
                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                <money class="form-control" v-model="formPOSE.usd" v-bind="money"></money>
                                                <div class="form-control-position">
                                                    $
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-2">
                                        <p>Equivalent</p>
                                        <h4><strong>{{ equivalentV3 | currency }}</strong></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button @click="clearModalData" type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                        <button v-if="submitPOSEState" @click="submitPOSE" type="button" :disabled="invalid" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_StatusKontraktual"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" style="max-width:80%;" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">{{ editState ? 'Edit Status' : 'Tambah Status' }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                    <div class="modal-body">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <div class="form-group">
                                        <ValidationProvider name="Lokasi" rules="required" v-slot="{ errors }">
                                            <label for="">Pilih Lokasi</label>
                                            <select v-model="formKontraktual.id_kontrak_mtu_lokasi" class="form-control">
                                                <option value="">- Pilih Lokasi -</option>
                                                <option :value="val.id" v-for="(val, idx) in miscLocation" :key="idx">{{ val.name }}</option>
                                            </select>
                                            <small>{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="form-group">
                                        <ValidationProvider name="Nama Dokumen" rules="required" v-slot="{ errors }">
                                            <label for="">Nama Dokumen</label>
                                            <select v-model="formKontraktual.name" class="form-control">
                                                <option value="">- Pilih Dokumen -</option>
                                                <option :value="val.name" v-for="(val, idx) in listNameDokumen" :key="idx">{{ val.name }}</option>
                                            </select>
                                            <small>{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="position-relative has-icon-right mb-2">
                                        <ValidationProvider name="Tanggal Dokumen" rules="required" v-slot="{ errors }">
                                            <label for="">Tanggal Dokumen</label>
                                            <date-picker
                                                format="DD/MM/YYYY"
                                                v-model="formKontraktual.tanggal"
                                                type="date"
                                                :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                                value-type="YYYY-MM-DD"
                                                style="width: 100%;"
                                                >
                                            </date-picker>
                                            <small>{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="form-group">
                                        <ValidationProvider name="Nilai" rules="required" v-slot="{ errors }">
                                            <label for="">Nomor Dokumen</label>
                                            <input v-model="formKontraktual.nomor" type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                            <small>{{ errors[0] }} </small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="form-group">
                                        <label for="">Dokumen</label>
                                        <vue-dropzone ref="dropzoneStatusKontrak" id="dropzone" 
                                            :duplicateCheck="true" 
                                            :options="dropzoneSKOptions" 
                                            @vdropzone-files-added="(e) => { formKontraktual.document = e[0]; is_new_file = true}"
                                            @vdropzone-removed-file="deleteFile"
                                        ></vue-dropzone>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
                        <button v-if="submitKontraktualState" @click="submitKontraktual" type="button" :disabled="invalid" class="btn btn-primary">
                            <span>Simpan</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>

    <div id="Modal_LightBox"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" style="max-width:90%;" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-lightbox">
                    <img :src="detailModal && detailModal.image_url || 'x'" alt="foto">
                </div>
            </div>
        </div>
    </div>

    <div id="Modal_ViewBAP"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Detail BAP</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-block">
                                <h5><strong>Nama BAP</strong></h5>
                                <p class="text-black">{{ detailModal && detailModal.name }}</p>
                            </div>
                            <div class="d-block">
                                <h5><strong>Nomor</strong></h5>
                                <p class="text-black">{{ detailModal && detailModal.nomor }}</p>
                            </div>
                            <div class="d-block">
                                <h5><strong>Tanggal</strong></h5>
                                <p class="text-black">{{ detailModal && detailModal.col_date }}</p>
                            </div>
                            <div class="d-block">
                                <h5><strong>Dokumen</strong></h5>
                                <button data-toggle="modal" data-target="#Modal_PDFViewer"  class="p-0 mb-2 btn text-black"><a class="text-primary">View Document</a></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 mb-2" v-for="(bap, idx) in detailModal && detailModal.values" :key="idx">
                                    <div class="wrap-detail-bap">
                                        <div class="d-block">
                                            <h5><strong>Lokasi</strong></h5>
                                            <p class="text-black">{{ bap.location && bap.location.name || '-' }}</p>
                                        </div>
                                        <div class="d-block">
                                            <h5><strong>Progress</strong></h5>
                                            <div class="badge badge-success mr-2 mb-2" v-for="(ptype, i) in bap.progress_type" :key="i">
                                                {{ ptype.name }}
                                            </div>
                                        </div>
                                        <div class="d-block">
                                            <h5><strong>Nilai</strong></h5>
                                            <p class="text-black m-0">{{ bap.value | currency }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="Modal_ViewAmandemen"
        class="modal fade text-left" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Detail Amandemen</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-block">
                                <h5><strong>Nama Amandemen</strong></h5>
                                <p class="text-black">{{ detailModal && detailModal.name }}</p>
                            </div>
                            <div class="d-block">
                                <h5><strong>Nomor Amandemen</strong></h5>
                                <p class="text-black">{{ detailModal && detailModal.nomor }}</p>
                            </div>
                            <div class="d-block">
                                <h5><strong>Tanggal Dokumen</strong></h5>
                                <p class="text-black">{{ detailModal && detailModal.col_tanggal }}</p>
                            </div>
                            <div class="d-block">
                                <h5><strong>Dokumen</strong></h5>
                                <button data-toggle="modal" data-target="#Modal_PDFViewer"  class="p-0 mb-2 btn text-black"><a class="text-primary">View Document</a></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 mb-2" v-for="(dt, idx) in dataDetailAmandemen && dataDetailAmandemen.locations" :key="idx">
                                    <div class="wrap-detail-bap">
                                        <div class="d-block">
                                            <h5><strong>Lokasi</strong></h5>
                                            <p class="text-black" v-if="dt && dt.location && dt.location.relokasi">{{ dt && dt.location && dt.location.relokasi || '-' }}</p>
                                            <p class="text-black" v-if="dt && dt.location && dt.location.name">{{ dt && dt.location && dt.location.name || '-' }}</p>
                                        </div>
                                        <div class="d-block">
                                            <h4><strong>Perihal</strong></h4>
                                        </div>
                                        <div class="d-block" v-for="(p, pidx) in dt && dt.perihal" :key="pidx">
                                            <h5><strong>{{ p && p.name }}</strong></h5>
                                            <p class="text-black m-0" v-if="p.id === 1">{{ moment(dt.tanggal).isValid() ? moment(dt.tanggal).format("DD MMMM YYYY") : '-' }} </p>

                                            <!-- <div v-if="p.id === 3">
                                                <ul>
                                                    <li v-for="(i, nidx) in dt.name.length" :key="nidx">
                                                        <p class="text-black mb-0">Lokasi Baru: {{ dt.name[nidx] }}</p>
                                                        <p class="text-black mb-0">Material: {{ dt.konfigurasi[nidx] }}</p>
                                                        <p class="text-black mb-0">Jumlah: {{ dt.jumlah[nidx] }}</p>
                                                        <p class="text-black mb-0">Ready At Site: {{ moment(dt.ready_at[nidx]).format("DD MMMM YYYY") }}</p>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div v-if="p.id === 4">
                                                <ul>
                                                    <li v-for="(i, nidx) in dt.name.length" :key="nidx">
                                                        <p class="text-black mb-0">Lokasi Baru: {{ dt.id_kontrak[nidx] }}</p>
                                                        <p class="text-black mb-0">Material: {{ dt.konfigurasi[nidx] }}</p>
                                                        <p class="text-black mb-0">Jumlah: {{ dt.jumlah[nidx] }}</p>
                                                        <p class="text-black mb-0">Ready At Site: {{ moment(dt.ready_at[nidx]).format("DD MMMM YYYY") }}</p>
                                                    </li>
                                                </ul>
                                            </div> -->

                                            <p class="text-black m-0" v-if="p.id === 5">{{ dt.other }}</p>
                                            <div class="text-black m-0" v-if="p.id === 2">
                                                <p class="text-black text-sm" v-for="(n, nidx) in dt.nilai" :key="nidx">
                                                    {{ n.name }} : {{ n.nilai | currency }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="Modal_PDFViewer"
        class="modal fade text-left" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel160" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width:95%; max-height: 95%;" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">View File</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-if="detailModal && detailModal.document_url" style="height: 80vh;">
                    <embed :src="detailModal && detailModal.document_url+'?#zoom=125'" type="application/pdf" width="100%" height="100%"/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import TableV2 from '@/components/TableV2.vue'
import vueDropzone from 'vue2-dropzone'
import DatePicker from 'vue2-datepicker';
import {Money} from 'v-money'
import moment from 'moment'
import ld from 'lodash'
import BankGaransi from '@/views/mtu/bank_garansi/Index.vue'
import Resume from '@/views/mtu/resume/Index.vue'

export default {
    name: 'MtuDetail',
    components: { TableV2, vueDropzone, DatePicker, Money, BankGaransi, Resume },
    data () {
        return {
            moment : moment,
            role_id: localStorage.getItem('role_id'),
            date_now: new Date().getFullYear(),
            is_new_file: false,
            id_mtu: null,
            pdfFile: '',
            loadStateDetail: false,
            detail: null,
            detailDataAnggaran: [],
            detailNilaiKontrak: {},
            detailModal: {},
            dataDetailAmandemen: {},
            editState: false,
            id_kendala_active: null,
            id_solusi_active: null,
            id_sk_active: null,
            id_dp_active: null,
            id_bap_active: null,
            id_pose_active: null,
            id_resume_active: null,
            id_amandemen_active: null,

            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false 
            },

            tableKendalaOption : {
                list: [],
                columns: ['col_date', 'name', 'uraian']
            },
            tableSolusiOption : {
                list: [],
                columns: ['col_date', 'name', 'uraian']
            },
            tableResumeOption : {
                list: [],
                columns: ['name_resume', 'no_prk', 'nilai_kontrak', 'col_ready_at', 'status', 'progress_bayar']
            },
            tableDokPendukungOption : {
                list: [],
                columns: ['lokasi', 'name', 'dok_pendukung']
            },
            tableDokAmandemenOption : {
                list: [],
                columns: ['name', 'nomor', 'document', 'col_tanggal', 'col_perihal']
            },
            tableDokBAPOption : {
                list: [],
                columns: ['name', 'nomor', 'col_date', 'col_value', 'col_value_paid']
            },
            tableDokPOSEOption : {
                list: [],
                columns: ['nomor_po', 'nomor_se', 'col_date', 'col_value', 'bap_name']
            },
            tableStatusKontraktualOption : {
                list: [],
                columns: ['lokasi', 'name', 'col_tanggal', 'nomor', 'status', 'document_kontraktual']
            },

            amandemenPerihalUmum: [],
            optPerihalUmum: [],

            optionsProgress: [],

            miscLocation: [],
            defaultMiscLocation: [],
            miscListKontrak: [],
            jenisProgress: [
                {
                    id: 1,
                    name: 'Progress Fisik'
                }
            ],
            listNameDokumen: [
                {
                    name: 'BA ST1'
                },
                {
                    name: 'BA ST2'
                },
            ],
            listNameDokumenPendukung: [
                {
                    name: 'Test Report'
                },
                {
                    name: 'Manual Book'
                },
                {
                    name: 'As Build Drawing'
                },
            ],
            listLocPose: [],
            loadStateKendala: false,
            loadStateSolusi: false,
            loadStateKontraktual: false,
            loadStateBAP: false,
            loadStatePOSE: false,
            loadStateDokPendukung: false,
            loadStateResume: false,
            loadStateAmandemen: false,
            loadStateNilaiKontrak: false,
            loadStateDataAnggaran: false,

            submitKendalaState: true,
            submitSolusiState: true,
            submitBAPState: true,
            submitPOSEState: true,
            submitKontraktualState: true,
            submitDokPendukungState: true,
            submitResumeState: true,
            submitAmandemenState: true,

            formResume: {
                id_kontrak_mtu_lokasi: '',
                type: '',
                progress_type: [],
                progress_value: ''
            },
            formAmandemenLokasiNilai : [],
            formAmandemen: {
                id_kontrak_mtu: null,
                name: null,
                nomor: null,
                tanggal: null,
                document: null,
                perihal: [],
                lokasi: [
                    {
                        id_kontrak_mtu_lokasi: null,
                        id_new_kontrak: null,
                        name: null,
                        tanggal: null,
                        other: null,
                        perihal: [],
                        nilai: []
                    }
                ],
            },
            formKendala: {
                id_kontrak_mtu: '',
                name: '',
                uraian: '',
                image: null
            },
            formSolusi: {
                id_kontrak_mtu: '',
                id_kontrak_mtu_kendala: '',
                name: '',
                uraian: '',
                image: null
            },
            formKontraktual: {
                id_kontrak_mtu: '',
                id_kontrak_mtu_lokasi: '',
                name: '',
                nomor: '',
                tanggal: '',
                document: null
            },
            formBAP: {
                id_kontrak_mtu: '',
                name: '',
                nomor: '',
                date: '',
                document: null,
                lokasi: [
                    {
                        id_kontrak_mtu_lokasi: '',
                        progress_type: [],
                        value: '',
                        deleted: false,
                        added: false,
                    }
                ]
            },
            formPOSE: {
                id_kontrak_mtu: '',
                id_kontrak_mtu_bap: '',
                id_kontrak_mtu_lokasi: '',
                nomor_po: '',
                nomor_se: '',
                date: '',
                idr: '',
                nilai: ''
            },
            formDP : {
                id_kontrak_mtu: '',
                id_kontrak_mtu_lokasi: '',
                name: '',
                document: ''
            },
            payloadKontraktor : {
                search: '',
                id_kontrak_mtu: null
            },
            payloadAmandemen : {
                search: '',
                id_kontrak_mtu: null
            },
            payloadResume : {
                search: '',
                id_kontrak_mtu: null
            },
            payloadDokPendukung : {
                search: '',
                id_kontrak_mtu: null
            },
            payloadBP : {
                search: '',
                id_kontrak_mtu: null
            },
            payloadPOSE : {
                search: '',
                id_kontrak_mtu: null
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],                        
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                    ]
                }
            },
            dropzoneOptions: {
                url: this.$baseUrl + '/kontrak-mtu',
                acceptedFiles : 'image/*',
                thumbnailWidth: 150,
                autoProcessQueue: false,
                uploadMultiple: false,
                addRemoveLinks: true,
                paramName: 'image'
            },
            dropzoneSKOptions: {
                url: this.$baseUrl + '/kontrak-mtu',
                acceptedFiles : '.docx, .pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                thumbnailWidth: 150,
                autoProcessQueue: false,
                uploadMultiple: false,
                addRemoveLinks: true,
                paramName: 'image'
            },
            dropzoneAmandemenOptions : {
                url: this.$baseUrl + '/kontrak-mtu',
                acceptedFiles : '.docx, .pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                thumbnailWidth: 150,
                autoProcessQueue: false,
                uploadMultiple: false,
                addRemoveLinks: true,
                paramName: 'image'
            },
            dropzoneKendalaOptions: {
                url: this.$baseUrl + '/kontrak-mtu',
                acceptedFiles : 'image/*',
                thumbnailWidth: 150,
                autoProcessQueue: false,
                uploadMultiple: true,
                addRemoveLinks: true,
                paramName: 'image'
            },

            currencyForm : {
                jpy : 0,
                cny : 0,
                eur : 0,
                usd : 0,
                idr: 0,
            },
            cur : {
                jpy : 0,
                cny : 0,
                eur : 0,
                usd : 0
            },

            id_loc:null,
            resumeProgressOptions: [],
        }
    },
    watch: {
        'formResume.progress_type': ld.debounce(function () {
            if (this.formResume.type && this.formResume.id_kontrak_mtu_lokasi) {
                this.calculateResumeProgress ()
            }
        }, 300),
        'formResume.type': ld.debounce(function () {
            if (this.formResume.progress_type && this.formResume.id_kontrak_mtu_lokasi) {
                this.calculateResumeProgress ()
            }
        }, 300),
        'formResume.id_kontrak_mtu_lokasi': ld.debounce(function () {
            if (this.formResume.progress_type && this.formResume.type) {
                this.calculateResumeProgress ()
            }
        }, 300),
        'payloadAmandemen.search' : ld.debounce(function () {
            this.loadListAmandemen ()
        }, 300),
        'payloadKontraktor.search' : ld.debounce(function () {
            this.loadListKontraktual ()
        }, 300),
        'payloadBP.search' : ld.debounce(function () {
            this.loadListBAP ()
        }, 300),
        'payloadPOSE.search' : ld.debounce(function () {
            this.loadListPOSE ()
        }, 300),
        'payloadDokPendukung.search' : ld.debounce(function () {
            this.loadListDokPendukung ()
        }, 300),
        'formPOSE.id_kontrak_mtu_bap' (val) {
            if (val) {
                let val_paid = this.tableDokBAPOption.list[this.tableDokBAPOption.list.findIndex((e) => parseInt(e.id) === parseInt(val))]
                this.currencyForm.idr = val_paid.col_value_paid
                this.fetchLocPose()
            }
        },
        'currencyForm.idr' (val) {
            if (this.id_pose_active == null) {
                this.formPOSE.idr = val    
            }
        },
        equivalent (val) {
            console.log(val)
            this.formPOSE.nilai = val
        },
        equivalentV3 (val) {
            console.log(val, "ini")
            this.formPOSE.nilai = val
        }
    },
    computed: {
        equivalent () {
            const cur = ( parseFloat((this.currencyForm.idr ? this.currencyForm.idr : 0)) + parseFloat((this.currencyForm.cny ? this.currencyForm.cny : 0) * this.cur.cny) + parseFloat((this.currencyForm.jpy ? this.currencyForm.jpy : 0) * this.cur.jpy) + parseFloat((this.currencyForm.eur ? this.currencyForm.eur : 0) * this.cur.eur) + parseFloat((this.currencyForm.usd ? this.currencyForm.usd : 0) * this.cur.usd) )
            return cur
        },

        equivalentV3() {
            const cur = ( parseFloat((this.formPOSE.idr ? this.formPOSE.idr : 0)) + parseFloat((this.formPOSE.cny ? this.formPOSE.cny : 0) * this.cur.cny) + parseFloat((this.formPOSE.jpy ? this.formPOSE.jpy : 0) * this.cur.jpy) + parseFloat((this.formPOSE.eur ? this.formPOSE.eur : 0) * this.cur.eur) + parseFloat((this.formPOSE.usd ? this.formPOSE.usd : 0) * this.cur.usd) )
            return cur
        }
    },
    filters : {
        parseNoPrk (val) {
            if (val) {
                let type = typeof val.col_prk
                if (type === 'String') {
                    return val.col_prk
                } else {
                    return val.col_prk.toString ()
                }
            } else {
                return '-'
            }
        },
        ParseProgressValue (val) {
            if (val) {
                let res = []
                val.forEach((e) => {
                    e.progress_type.forEach((i) => {
                        res.push(i.name)
                    })
                })
                return res.toString()
            } else {
                return '-'
            }
        }
    },
    mounted () {
      this.id_mtu = this.$route.params.id
      this.loadCurrency ()
      this.loadDetail ()
      this.loadNilaiKontrak()
      this.loadDataAnggaran()
    //   this.loadListResume()
      this.loadListAmandemen()
      this.loadListKendala()
      this.loadListSolusi()
      this.loadListKontraktual()
      this.loadListBAP()
      this.loadListPOSE()
      this.loadListDokPendukung()
      this.loadMiscLocation()
      this.loadPerihalUmum()
    },
    methods : {
        parsedFormAmandemen(rawData) {
            let data = {
                ...rawData
            };

            let parsedLocations = [];

            data.lokasi.forEach(lokasi => {
                let dataLokasi = {
                    id_kontrak_mtu_lokasi: lokasi.id_kontrak_mtu_lokasi,
                    tanggal: lokasi.tanggal,
                    other: lokasi.other,
                    perihal: lokasi.perihal,
                    loc_type: lokasi.loc_type,
                    nilai: lokasi.nilai,
                    locations: [],
                    // added: lokasi.added,
                    // deleted: lokasi.deleted,
                    id: lokasi.id,
                };

                if (this.editState && this.id_amandemen_active) {
                    dataLokasi.added = lokasi.added;
                    dataLokasi.deleted = lokasi.deleted;
                }

                if (dataLokasi && dataLokasi.loc_type) {
                    for (let i = 0; i < dataLokasi.loc_type.length; i++) {
                        const locType = dataLokasi.loc_type[i];
                        let locations = {};

                        if (locType == 'byId') {
                            locations.type = 'id_new_kontrak';
                            locations.id_new_kontrak = lokasi.id_new_kontrak[i];   
                        } else if (locType == 'byName') {
                            locations.type = 'name';
                            locations.name = lokasi.name[i];   
                        }
                        locations.ready_at = lokasi.ready_at[i];
                        locations.konfigurasi = lokasi.konfigurasi[i];
                        locations.jumlah = lokasi.jumlah[i];

                        dataLokasi.locations.push(locations);
                    }   
                }

                parsedLocations.push(dataLokasi);
            });

            data.lokasi = parsedLocations;

            return data;
        },
        async parseNilaiTypeAmandemen (idx_lokasi = 0) {
            let _ = this
            // const val = this?.detail?.jenis_pekerjaan ?? ''

            let nilai_data;
            if (_.id_loc != null) {
                await _.axios.get('v2/kontrak-mtu/amandemen/' + this.id_loc + '/values')
                .then(resp=> {
                    nilai_data = resp.data.data;
                })
                .catch(err=> {
                    console.log(err)
                })   
            }

            // if (val === 'Konduktor' || val === 'Tower') {
            //     let SPB = nilai_data.filter(function (el) {return el.name == "Nilai SPB"});
                
            //     if (SPB) {
            //         this.formAmandemen.lokasi[idx_lokasi].nilai = [
            //             {name:'Nilai SPB',idr:SPB[0].idr,cny:SPB[0].cny,jpy:SPB[0].jpy,eur:SPB[0].eur,usd:SPB[0].usd,nilai:SPB[0].nilai},
            //         ]
            //     }
            // } else if (val === 'GIS' || val === 'Trafo') {
            //     let PM = nilai_data.filter(function (el) {return el.name == "Nilai Pengadaan Material"});
            //     let IT = nilai_data.filter(function (el) {return el.name == "Nilai Inland Transport"});
            //     let NPT = nilai_data.filter(function (el) {return el.name == "Nilai Perakitan & Testing"});

            //     if (PM, IT, NPT) {
            //         this.formAmandemen.lokasi[idx_lokasi].nilai = [
            //             {name:'Nilai Pengadaan Material',idr:PM[0].idr,cny:PM[0].cny,jpy:PM[0].jpy,eur:PM[0].eur,usd:PM[0].usd,nilai:PM[0].nilai},
            //             {name:'Nilai Inland Transport',idr:IT[0].idr,cny:IT[0].cny,jpy:IT[0].jpy,eur:IT[0].eur,usd:IT[0].usd,nilai:IT[0].nilai},
            //             {name:'Nilai Perakitan & Testing',idr:NPT[0].idr,cny:NPT[0].cny,jpy:NPT[0].jpy,eur:NPT[0].eur,usd:NPT[0].usd,nilai:NPT[0].nilai},   
            //         ]   
            //     }
            // } else if (val === 'AIS') {
                let PM = nilai_data.filter(function (el) {return el.name == "Nilai Pengadaan Material"});
                let IT = nilai_data.filter(function (el) {return el.name == "Nilai Inland Transport"});
                let JS = nilai_data.filter(function (el) {return el.name == "Nilai Jasa Supervisi"});
                let NPT = nilai_data.filter(function (el) {return el.name == "Nilai Perakitan & Testing"});

                if (PM, IT, NPT, JS) {
                    this.formAmandemen.lokasi[idx_lokasi].nilai = [
                        {name:'Nilai Pengadaan Material',idr:PM[0].idr,cny:PM[0].cny,jpy:PM[0].jpy,eur:PM[0].eur,usd:PM[0].usd,nilai:PM[0].nilai},
                        {name:'Nilai Inland Transport',idr:IT[0].idr,cny:IT[0].cny,jpy:IT[0].jpy,eur:IT[0].eur,usd:IT[0].usd,nilai:IT[0].nilai},
                        {name:'Nilai Jasa Supervisi',idr:JS[0].idr,cny:JS[0].cny,jpy:JS[0].jpy,eur:JS[0].eur,usd:JS[0].usd,nilai:JS[0].nilai},
                        {name:'Nilai Perakitan & Testing',idr:NPT[0].idr,cny:NPT[0].cny,jpy:NPT[0].jpy,eur:NPT[0].eur,usd:NPT[0].usd,nilai:NPT[0].nilai},
                    ]
                }
            // }
        },
        async loadCurrency(){
            try {
                const datas = (await this.axios.get('/kontrak/currency')).data
                this.cur.jpy = datas.data.jpy
                this.cur.cny = datas.data.cny
                this.cur.eur = datas.data.eur
                this.cur.usd = datas.data.usd
            } catch (error) {
                alert("Can't get update Currency")
            }
        }, 
        async loadNilaiKontrak () {
            this.loadStateNilaiKontrak = false
            try {
                const datas = (await this.axios.get(`/v2/kontrak-mtu/${this.id_mtu}/values`)).data
                if (!datas.status) throw datas
                this.detailNilaiKontrak = datas.data
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateNilaiKontrak = true
            }
        },
        async loadDataAnggaran () {
            this.loadStateDataAnggaran = false
            var formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });
            try {
                const datas = (await this.axios.get(`/v2/kontrak-mtu/${this.id_mtu}/anggaran`)).data
                if (!datas.status) throw datas
                this.detailDataAnggaran = datas.data
                this.detailDataAnggaran.map((e) => {
                    if (e.anggaran) {
                        e.anggaran.chartSeries = [
                            {
                                name: 'AI',
                                data: [parseInt(e.anggaran.ai_thn), 0]
                            },
                            {
                                name: 'Realisasi',
                                data: [0, parseInt(e?.anggaran?.nilai_pose_kontrak ?? 0)]
                            },
                            {
                                name: 'Sisa AKI',
                                data: [0, (parseInt(e.anggaran.aki_thn) - parseInt(e.anggaran.nilai_pose_kontrak))]
                            }
                        ],
                        e.anggaran.chartOpt = {
                            chart: {
                                type: 'bar',
                                stacked: true,
                                height: 250,
                                toolbar: {
                                    show: false
                                },
                            },
                            grid: {
                            show: true,
                            strokeDashArray: 4
                            },
                            colors: ['#3099AC', '#F3CA27', '#8C9092'],
                            dataLabels: {
                                enabled: true,
                                offsetY: -40,
                                offsetX: 10,
                                background: {
                                    enabled: true,
                                    padding: '10',
                                    borderRadius: 6
                                },
                                formatter: function (val, opts) {
                                    var warningCount = 0;

                                    const chart = window.$(`#apexcharts${opts.w.globals.chartID}`);
                                    const series = opts.w.globals.series[0];
                                    const index = opts.dataPointIndex;
                                    
                                    const dataLabels = chart.find('.apexcharts-data-labels')

                                    if (index === 0 && series[0] < parseInt(e.anggaran.kebutuhan_ai)) {
                                    window.$(dataLabels[index]).addClass('chart-warning')
                                    } else {
                                    window.$(chart.find('.apexcharts-data-labels:not(.chart-warning)')).addClass('hide-data-labels')
                                    }

                                    setTimeout(() => {

                                    const rect = chart.find('.apexcharts-data-labels.chart-warning').find('rect')
                                    const x = rect.attr('x')
                                    const y = rect.attr('y')

                                    if (x !== undefined && y !== undefined && warningCount === 0) {
                                        warningCount += 1;
                                        window.$('.apexcharts-data-labels.chart-warning').append(`<svg x="${parseInt(x) + 14}" y="${parseInt(y) + 8}" cx="${x}" cy="${y}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.5335 9.73665L6.90348 1.21665C6.81131 1.04685 6.67503 0.905069 6.509 0.806276C6.34296 0.707482 6.15335 0.655334 5.96014 0.655334C5.76694 0.655334 5.57732 0.707482 5.41129 0.806276C5.24526 0.905069 5.10898 1.04685 5.01681 1.21665L0.390144 9.73665C0.298652 9.90047 0.251705 10.0854 0.253986 10.273C0.256266 10.4607 0.307693 10.6444 0.40314 10.806C0.498587 10.9675 0.634717 11.1012 0.797957 11.1938C0.961196 11.2863 1.14584 11.3344 1.33348 11.3333H10.5901C10.7759 11.3331 10.9584 11.2847 11.1198 11.1929C11.2812 11.1011 11.4161 10.9689 11.5111 10.8093C11.6062 10.6498 11.6582 10.4683 11.6621 10.2826C11.6661 10.0969 11.6217 9.9134 11.5335 9.74998V9.73665ZM5.53348 3.33331C5.53348 3.20954 5.58264 3.09085 5.67016 3.00333C5.75768 2.91581 5.87638 2.86665 6.00014 2.86665C6.12391 2.86665 6.24261 2.91581 6.33013 3.00333C6.41764 3.09085 6.46681 3.20954 6.46681 3.33331V7.33331C6.46681 7.45708 6.41764 7.57578 6.33013 7.6633C6.24261 7.75081 6.12391 7.79998 6.00014 7.79998C5.87638 7.79998 5.75768 7.75081 5.67016 7.6633C5.58264 7.57578 5.53348 7.45708 5.53348 7.33331V3.33331ZM6.00014 9.94998C5.88148 9.94998 5.76547 9.91479 5.6668 9.84886C5.56813 9.78293 5.49123 9.68923 5.44582 9.57959C5.4004 9.46995 5.38852 9.34931 5.41167 9.23293C5.43482 9.11654 5.49197 9.00963 5.57588 8.92572C5.65979 8.8418 5.7667 8.78466 5.88309 8.76151C5.99948 8.73836 6.12012 8.75024 6.22975 8.79565C6.33939 8.84106 6.4331 8.91797 6.49903 9.01664C6.56496 9.11531 6.60014 9.23131 6.60014 9.34998C6.60014 9.42877 6.58462 9.50679 6.55447 9.57959C6.52432 9.65238 6.48012 9.71853 6.42441 9.77424C6.36869 9.82996 6.30255 9.87415 6.22975 9.90431C6.15696 9.93446 6.07894 9.94998 6.00014 9.94998Z" fill="#E32323"/>
                                        </svg>`);
                                    }
                                    }, 500)

                                    
                                    window.$(chart.find('g[seriesName="SisaxAKI"]')).find('path').css('fill', '#8C9092')
                                    
                                    return val
                                },
                            },
                            xaxis: {
                            categories: ['AI', 'AKI'],
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            labels: {
                                rotate: 0
                            }
                            },
                            yaxis: {
                                labels: {
                                    formatter: (val) => {
                                        return formatter.format(val)
                                    },
                                },
                                min: 0,
                                max: function (max) {
                                    return max + (max * 0.2)
                                },
                                forceNiceScale: true,
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false
                                },
                                title: {
                                    text: 'Milyar',
                                    rotate: 0,
                                    offsetX: 15,
                                    offsetY: -140,
                                    style: {
                                    color: '#626262',
                                    fontSize: '11px',
                                    fontFamily: 'Myriad Pro',
                                    fontWeight: 600,
                                    cssClass: 'apexcharts-yaxis-title',
                                    },
                                },
                            },
                            plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 6,
                                columnWidth: '15%',
                                distributed: true,
                                dataLabels: {
                                position: 'top'
                                }
                            },
                            },
                            legend: {
                            show: false,
                            itemMargin: {
                                horizontal: 2
                            },
                            },
                            tooltip: {
                                custom: ({seriesIndex}) => {
                                    if (seriesIndex > 0) {
                                        return `
                                            <div class="wrap-tultip">
                                            <div class="content-tultip">
                                                <div class="bulatan" style="background: #F3CA27"></div>
                                                <span>Realisasi : <strong>${formatter.format(parseInt(e?.anggaran?.nilai_pose_kontrak ?? 0))}</strong></span>
                                            </div>
                                            <div class="content-tultip">
                                                <div class="bulatan" style="background: #8C9092"></div>
                                                <span>Sisa AKI : <strong>${formatter.format(((parseInt(e.anggaran.aki_thn) - parseInt(e.anggaran.nilai_pose_kontrak))) < 0 ? 0 : (parseInt(e.anggaran.aki_thn) - parseInt(e.anggaran.nilai_pose_kontrak)))}</strong></span>
                                            </div>
                                            </div>
                                        `
                                    } else {
                                        return `
                                        <div class="wrap-tultip">
                                        <div class="content-tultip">
                                            <div class="bulatan" style="background: #3099AC"></div>
                                            <span>AI : <strong>${formatter.format(parseInt(e.anggaran.ai_thn))}</strong></span>
                                        </div>
                                        </div>
                                    `
                                    }
                                }
                            },
                            responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                width: 250
                                },
                                legend: {
                                position: 'bottom'
                                }
                            }
                            }]
                        }
                    }
                    return e
                })
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateDataAnggaran = true
            }
        },
        async loadDetail () {
            this.loadStateDetail = false
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/' + this.id_mtu)).data
                if (!datas.status) throw 'Error'
                this.detail = datas.data
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateDetail = true
                this.parseNilaiTypeAmandemen ()
                this.loadMiscListKontrak ()
                // this.loadMiscProgressResume ()
                this.getProgressResume();
            }
        },
        async loadMiscLocation () {
            try {
                const datas = (await this.axios.get('/v2/misc/locations?parent=true', { params: { id_kontrak_mtu: this.id_mtu } })).data
                if (!datas.status) throw datas
                this.miscLocation = datas.data
                this.defaultMiscLocation = datas.data
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async loadMiscListKontrak () {
            try {
                const datas = (await this.axios.get('/v2/misc/kontrak', { params: { jenis_kontrak: this.detail.jenis_pekerjaan } })).data
                if (!datas.status) throw datas
                this.miscListKontrak = datas.data.map((e) => {
                    e.name = e.nama_pekerjaan
                    return e
                })
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async loadMiscProgressResume (id) {
            try {
                const datas = (await this.axios.get('v2/kontrak-mtu/bap/type-progress?id_kontrak_mtu_lokasi=' + id)).data
                if (!datas.status) throw datas
                this.optionsProgress = datas.data ? datas.data : [];
                console.log(this.optionsProgress);
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async loadPerihalUmum () {
            try {
                const datas = (await this.axios.get('/v2/misc/perihal')).data
                if (!datas.status) throw datas
                this.optPerihalUmum = datas.data
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async loadDetailResume (data) {
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/resume/'+data.id, { params: { type: data.key } })).data
                if (!datas.status) throw datas
                for (const [key, value] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.formResume, key)) {
                        if (key === 'progress_type') {
                            this.formResume[key] = JSON.parse(value).map((e) => {
                                e.id = parseInt(e.id)
                                return e
                            })
                            
                        } else {
                            this.formResume[key] = value
                        }
                    }
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async submitResume () {
            this.submitResumeState = false
            const formData = new FormData ()
            for (const [key, value] of Object.entries(this.formResume)) {
                if (key === 'progress_type') {
                    formData.append(`progress_type[0][id]`, value.id);
                    formData.append(`progress_type[0][name]`, value.name);
                } else {
                    formData.append(key, value)
                }
            }
            try {
                if (this.editState && this.id_resume_active) {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/resume', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_Resume').modal('hide')
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadNilaiKontrak ()
                } else {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/resume', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_Resume').modal('hide')
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadNilaiKontrak ()
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.submitResumeState = true
                this.editState = false
                this.is_new_file = false
            }
        },
        async loadListResume () {
            this.$root.$emit('getResume');
        },
        validTotalOfMaterial() {
            if (this.formAmandemen && this.formAmandemen.lokasi && this.formAmandemen.lokasi.length > 0) {
                for (let i = 0; i < this.formAmandemen.lokasi.length; i++) {
                    const lokasi = this.formAmandemen.lokasi[i];
                    if (lokasi.konfigurasi && lokasi.konfigurasi.length > 0) {
                        for (let z = 0; z < lokasi.konfigurasi.length; z++) {
                            if (!this.isValidTotalOfMaterial(i, z)) return false;
                        }
                    }
                }
            }
            return true;
        },
        async submitAmandemen () {
            this.submitAmandemenState = false
            this.editState ? this.formAmandemen.id_kontrak_mtu = null : this.formAmandemen.id_kontrak_mtu = this.id_mtu

            if (!this.validTotalOfMaterial()) {
                this.submitAmandemenState = true;
                return;
            }

            const formData = new FormData ()
            console.log(this.parsedFormAmandemen(this.formAmandemen))

            for (const [key, val] of Object.entries(this.parsedFormAmandemen(this.formAmandemen))) {
                if (key === 'perihal') {
                    val.forEach((e, i) => {
                        for (const [key2, val2] of Object.entries(e)) {
                            val2 !== null? formData.append(`${key}[${i}][${key2}]`, val2) : ''
                        }
                    })
                } else if(key === 'document') {
                    if (this.is_new_file && val) {
                        formData.append(key, this.formAmandemen[key])
                    }
                } else if (key === 'lokasi') {
                    val.forEach((e, i) => {
                        for (const [key2, val2] of Object.entries(e)) {
                            if (['perihal', 'nilai'].includes(key2)) {
                                if (val2) {
                                    val2.forEach((e2, i2) => {
                                        for (const [key3, val3] of Object.entries(e2)) {
                                            val3 !== null ? formData.append(`${key}[${i}][${key2}][${i2}][${key3}]`, val3) : ''
                                        }
                                    })
                                }
                            } else if(['locations'].includes(key2)) {
                                val2.forEach((e2, i2) => {
                                    if (e2) {
                                        for (const [key3, val3] of Object.entries(e2)) {
                                            formData.append(`${key}[${i}][${key2}][${i2}][${key3}]`, val3)
                                        }
                                    }
                                })
                            } else {
                                val2 !== null ? formData.append(`${key}[${i}][${key2}]`, val2) : ''
                            }
                        }
                    })
                } else {
                    val !== null ? formData.append(key, val) : ''
                }
            }

            // var xhr = new XMLHttpRequest;
            // xhr.open('POST', '/', true);
            // xhr.send(formData);
            // this.submitAmandemenState = true;
            // return;

            try {
                if (this.editState && this.id_amandemen_active) {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/amandemen/'+this.id_amandemen_active, formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_Amandemen').modal('hide')
                    this.loadListAmandemen ()
                    this.loadNilaiKontrak ()
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadMiscLocation()
                } else {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/amandemen', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_Amandemen').modal('hide')
                    this.loadListAmandemen ()
                    this.loadListResume ()
                    this.loadNilaiKontrak ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadMiscLocation()
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.submitAmandemenState = true
                this.clearModalData ()
            }
        },
        async loadListAmandemen () {
            this.loadStateAmandemen = false
            this.payloadAmandemen.id_kontrak_mtu = this.id_mtu
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/amandemen', { params: this.payloadAmandemen })).data
                if (!datas.status) throw datas
                this.tableDokAmandemenOption.list = datas.data.map((e) => {
                    let parsePerihal = []
                    JSON.parse(e.perihal).forEach((a) => {
                        parsePerihal.push(a.name)
                    })
                    e.perihal_parse = parsePerihal.toString()
                    return e
                })
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateAmandemen = true
            }
        },
        async loadDetailAmandemen (id, view = false) {
            this.$refs.dropzoneAmandemen.removeAllFiles(true)
            this.formAmandemen.document = null
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/amandemen/'+id)).data
                if (!datas.status) throw datas
                this.detailModal = datas.data
                this.dataDetailAmandemen = this.detailModal
                if (view) {
                    this.$nextTick(() => {
                        window.$('#Modal_ViewAmandemen').modal('show')
                    })
                }
                for (const [key, val] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.formAmandemen, key)) {
                        if (key === 'document' && val !== null) {
                           let docUrl = datas.data['document_url']
                            let ext = docUrl.split('.').pop()
                            let file = { size: 10240, name: datas.data['document'], type: `application/${ext}` };
                            this.$refs.dropzoneAmandemen.manuallyAddFile(file, docUrl);
                        } else if (key === 'perihal') {
                            this.formAmandemen[key] = JSON.parse(val).map((p) => {
                                p.id = parseInt(p.id)
                                return p
                            })
                        } else {
                            this.formAmandemen[key] = datas.data[key]
                        }
                    }
                }
                this.formAmandemen.lokasi = datas.data.locations.map((e) => {
                    delete e.id_kontrak_mtu_amandemen;
                    delete e.created_at;
                    delete e.updated_at;
                    
                    e.deleted = false;

                    e.added = false;

                    e.nilai = e.values.map((a) => {
                        delete a.slug
                        delete a.id_amandemen_lokasi
                        delete a.created_at
                        delete a.updated_at
                        return a
                    });

                    delete e.values;

                    e.perihal = JSON.parse(e.perihal).map((p) => {
                        p.id = parseInt(p.id)
                        return p
                    });

                    e.loc_type = [];
                    e.name = [];
                    e.id_new_kontrak = [];
                    e.ready_at = [];
                    e.konfigurasi = [];
                    e.jumlah = [];

                    for (let x = 0; x < e.relokasi.length; x++) {
                        const c = e.relokasi[x];
                        const type = c.id_kontrak ? 'byId' : 'byName';

                        e.loc_type.push(type);
                        e.name.push(c.name??null);
                        e.id_new_kontrak.push(c.id_kontrak??null);
                        e.ready_at.push(c.ready_at);
                        e.konfigurasi.push(c.konfigurasi);
                        e.jumlah.push(c.jumlah);
                    }

                    return e
                })
                
                this.miscLocation = JSON.parse(JSON.stringify(this.defaultMiscLocation));
                for (let i = 0; i < this.formAmandemen.lokasi.length; i++) {
                    const lokasi = this.formAmandemen.lokasi[i];

                    const indexMiscLocation = this.miscLocation
                        .map(x => x.id)
                        .indexOf(lokasi.id_kontrak_mtu_lokasi);

                    for (let z = 0; z < lokasi.konfigurasi.length; z++) {
                        const konfigurasi = lokasi.konfigurasi[z];
                        const jumlah = parseInt(lokasi.jumlah[z]);

                        const miscLocationKonfigurasi = JSON.parse(this.miscLocation[indexMiscLocation].konfigurasi);
                        const miscLocationJumlah = JSON.parse(this.miscLocation[indexMiscLocation].jumlah);

                        for (let x = 0; x < miscLocationKonfigurasi.length; x++) {
                            const miscKonf = miscLocationKonfigurasi[x];

                            if (miscKonf == konfigurasi)
                                miscLocationJumlah[x] += jumlah;
                        }

                        this.miscLocation[indexMiscLocation].jumlah = JSON.stringify(miscLocationJumlah);
                    }
                }

                this.formAmandemen.id_kontrak_mtu = null
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async loadListKendala () {
            this.loadStateKendala = false
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/kendala?id_kontrak_mtu=' + this.id_mtu)).data
                this.tableKendalaOption['list'] = datas.data
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateKendala = true
            }
        },
        async loadDetailKendala (id) {
            this.$refs.dropZoneKendala.removeAllFiles(true)
            this.formKendala.image = null
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/kendala/'+id)).data
                if (!datas.status) throw datas
                for (const [key, val] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.formKendala, key)) {
                        if (key === 'image' && val !== null) {
                            let imgUrl = datas.data['image_url']
                            let ext = imgUrl.split('.').pop()
                            let file = { size: 10240, name: datas.data['image'], type: `image/${ext}` };
                            this.$refs.dropZoneKendala.manuallyAddFile(file, imgUrl);
                        } else {
                            this.formKendala[key] = datas.data[key]
                        }
                    }
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async submitKendala () {
            this.submitKendalaState = false
            const formData = new FormData()
            for (const [key] of Object.entries(this.formKendala)) {
                if (key === 'id_kontrak_mtu') {
                    formData.append(key, this.id_mtu)
                } else if (key === 'image') {
                    if (this.is_new_file) {
                        formData.append(key, this.formKendala[key])
                    }
                } else {
                    formData.append(key, this.formKendala[key])
                }
            }
            try {
                if (this.editState && this.id_kendala_active) {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/kendala/'+this.id_kendala_active, formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_Kendala').modal('hide')
                    this.loadListKendala ()
                    
                } else {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/kendala', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_Kendala').modal('hide')
                    this.loadListKendala ()
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.submitKendalaState = true
                this.is_new_file = false
                this.editState = false
            }
        },
        async loadListSolusi () {
            this.loadStateSolusi = false
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/solusi?id_kontrak_mtu=' + this.id_mtu)).data
                this.tableSolusiOption['list'] = datas.data
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateSolusi = true
            }
        },
        async loadDetailSolusi (id) {
            this.$refs.dropZoneSolusi.removeAllFiles(true)
            this.formSolusi.image = null
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/solusi/'+id)).data
                if (!datas.status) throw datas
                for (const [key, val] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.formSolusi, key)) {
                        if (key === 'image' && val !== null) {
                            let imgUrl = datas.data['image_url']
                            let ext = imgUrl.split('.').pop()
                            let file = { size: 10240, name: datas.data['image'], type: `image/${ext}` };
                            this.$refs.dropZoneSolusi.manuallyAddFile(file, imgUrl);
                        } else {
                            this.formSolusi[key] = datas.data[key]
                        }
                    }
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async submitSolusi () {
            this.submitSolusiState = false
            const formData = new FormData()
            for (const [key] of Object.entries(this.formSolusi)) {
                if (key === 'id_kontrak_mtu') {
                    formData.append(key, this.id_mtu)
                } else if (key === 'image') {
                    if (this.is_new_file) {
                        formData.append(key, this.formSolusi[key])
                    }
                } else {
                    formData.append(key, this.formSolusi[key])
                }
            }
            try {
                if (this.editState && this.id_solusi_active) {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/solusi/'+this.id_solusi_active, formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_Solusi').modal('hide')
                    this.loadListSolusi ()
                    this.loadListKendala ()
                    
                } else {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/solusi', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_Solusi').modal('hide')
                    this.loadListSolusi ()
                    this.loadListKendala ()
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.submitSolusiState = true
                this.editState = false
                this.is_new_file = false
            }
        },
        async loadDetailSk(id) {
            this.$refs.dropzoneStatusKontrak.removeAllFiles(true)
            this.formKontraktual.document = null
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/kontraktual/'+id)).data
                if (!datas.status) throw datas
                for (const [key, val] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.formKontraktual, key)) {
                        if (key === 'document' && val !== null) {
                            let docUrl = datas.data['document_url']
                            let ext = docUrl.split('.').pop()
                            let file = { size: 10240, name: datas.data['document'], type: `application/${ext}` };
                            this.$refs.dropzoneStatusKontrak.manuallyAddFile(file, docUrl);
                        } else {
                            this.formKontraktual[key] = datas.data[key]
                        }
                    }
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async submitKontraktual () {
            this.submitKontraktualState = false
            
            const formData = new FormData ()
            for (const [key] of Object.entries(this.formKontraktual)) {
                if (key === 'id_kontrak_mtu') {
                    formData.append(key, this.id_mtu)
                } else if(key === 'document') {
                    if (this.is_new_file) {
                        formData.append(key, this.formKontraktual[key])
                    }
                } else {
                    formData.append(key, this.formKontraktual[key])
                }
            }
            try {
                if (this.editState && this.id_sk_active) {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/kontraktual/'+this.id_sk_active, formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_StatusKontraktual').modal('hide')
                    this.loadListKontraktual ()
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadNilaiKontrak ()
                } else {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/kontraktual', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_StatusKontraktual').modal('hide')
                    this.loadListKontraktual ()
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadNilaiKontrak ()
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.submitKontraktualState = true
                this.editState = false
                this.is_new_file = false
            }
        },
        async loadListKontraktual () {
            this.loadStateKontraktual = false
            this.payloadKontraktor.id_kontrak_mtu = this.id_mtu
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/kontraktual', { params: this.payloadKontraktor })).data
                if (!datas.status) throw datas
                this.tableStatusKontraktualOption.list = datas.data.map((e) => {
                    e.document_kontraktual = e.document
                    return e
                })
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateKontraktual = true
            }
        },
        async loadDetailDokPendukung (id) {
            this.$refs.dropzoneDokPendukung.removeAllFiles(true)
            this.formDP.document = null
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/documents/'+id)).data
                if (!datas.status) throw datas
                for (const [key, val] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.formDP, key)) {
                        if (key === 'document' && val !== null) {
                            let docUrl = datas.data['document_url']
                            let ext = docUrl.split('.').pop()
                            let file = { size: 10240, name: datas.data['document'], type: `application/${ext}` };
                            this.$refs.dropzoneDokPendukung.manuallyAddFile(file, docUrl);
                        } else {
                            this.formDP[key] = datas.data[key]
                        }
                    }
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async loadListDokPendukung () {
            this.loadStateDokPendukung = false
            this.payloadDokPendukung.id_kontrak_mtu = this.id_mtu
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/documents', { params: this.payloadDokPendukung })).data
                if (!datas.status) throw datas
                this.tableDokPendukungOption.list = datas.data.map((e) => {
                    e.dok_pendukung = e.document
                    return e
                })
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateDokPendukung = true
            }
        },
        async submitDokPendukung () {
            this.submitDokPendukungState = false

            const formData = new FormData ()
            for (const [key] of Object.entries(this.formDP)) {
                if (key === 'id_kontrak_mtu') {
                    formData.append(key, this.id_mtu)
                } else if(key === 'document') {
                    if (this.is_new_file) {
                        formData.append(key, this.formDP[key])
                    }
                } else {
                    formData.append(key, this.formDP[key])
                }
            }
            try {
                if (this.editState && this.id_dp_active) {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/documents/'+this.id_dp_active, formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_DokPendukung').modal('hide')
                    this.loadListDokPendukung ()
                } else {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/documents', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_DokPendukung').modal('hide')
                    this.loadListDokPendukung ()
                }
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.submitDokPendukungState = true
                this.editState = false
                this.is_new_file = false
            }
        },
        async loadDetailBAP (id, view = false) {
            this.$refs.dropzoneBAP.removeAllFiles(true)
            this.formBAP.document = null
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/bap/'+id)).data
                if (!datas.status) throw datas
                if (view) {
                    this.detailModal = datas.data
                }
                for (const [key, val] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.formDP, key)) {
                        if (key === 'document' && val !== null) {
                            let docUrl = datas.data['document_url']
                            let ext = docUrl.split('.').pop()
                            let file = { size: 10240, name: datas.data['document'], type: `application/${ext}` };
                            this.$refs.dropzoneBAP.manuallyAddFile(file, docUrl);
                        } else {
                            this.formBAP[key] = datas.data[key]
                        }
                    }
                }
                let lokasi = datas.data.values.map((e) => {
                    delete e.id_kontrak_mtu_bap
                    delete e.created_at
                    delete e.updated_at
                    e.progress_type = JSON.parse(e.progress_type).map((e) => {
                        e.id = parseInt(e.id)
                        return e
                    })
                    e.added = false
                    e.deleted = false
                    return e
                })
                this.formBAP.lokasi = lokasi
                this.formBAP.nomor = datas.data.nomor
                this.formBAP.date = datas.data.created_at
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        async submitBAP () {
            this.submitBAPState = false
            const formData = new FormData()
            for (const [key, val] of Object.entries(this.formBAP)) {
                if (key === 'lokasi') {
                    this.formBAP[key].forEach((e, i) => {
                        for (const [key2, val2] of Object.entries(e)) {
                            if (key2 === 'progress_type') {
                                this.formBAP[key][i][key2].forEach((e2, i2) => {
                                    for (const [key3, val3] of Object.entries(e2)) {
                                        formData.append(`${key}[${i}][${key2}][${i2}][${key3}]`, val3)
                                    }
                                })
                            } else if (['added', 'deleted'].includes(key2)) {
                                if (this.editState) {
                                    formData.append(`${key}[${i}][${key2}]`, val2)
                                }
                            } else {
                                if (key2 != 'col_value_include_ppn' && key2 != 'location') {
                                    formData.append(`${key}[${i}][${key2}]`, val2)
                                }
                            }
                        }
                    })
                } else if (key === 'id_kontrak_mtu') {
                    formData.append(key, this.id_mtu)
                } else if(key === 'document') {
                    if (this.is_new_file) {
                        formData.append(key, this.formBAP[key])
                    }
                } else {
                    formData.append(key, val)
                }
            }
            try {
                if (this.editState && this.id_bap_active) {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/bap/'+this.id_bap_active, formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_BAP').modal('hide')
                    this.loadListBAP ()
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadNilaiKontrak ()
                } else {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/bap', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_BAP').modal('hide')
                    this.loadListBAP ()
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadNilaiKontrak ()
                }
            } catch (error) {
                console.log(error)
                this.$toast.warning(error?.response?.data?.message ?? error.message)
            } finally {
                this.submitBAPState = true
                this.editState = false
                this.is_new_file = false
            }
        },
        async loadListBAP () {
            this.loadStateBAP = false
            this.payloadBP.id_kontrak_mtu = this.id_mtu
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/bap', { params: this.payloadBP })).data
                if (!datas.status) throw datas
                this.tableDokBAPOption.list = datas.data
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            } finally {
                this.loadStateBAP = true
            }
        },
        async submitPOSE () {
            this.submitPOSEState = false
            // const formData = new FormData ()
            // for (const [key, val] of Object.entries(this.formPOSE)) {
            //     if (key === 'id_kontrak_mtu') {
            //         formData.append(key, this.id_mtu)
            //     } else {
            //         formData.append(key, val)
            //     }
            // }
            try {
                if (this.editState && this.id_pose_active) {
                    const formData = new FormData ()
                    for (const [key, val] of Object.entries(this.formPOSE)) {
                        if (key === 'id_kontrak_mtu') {
                            formData.append(key, this.id_mtu)
                        } else {
                            formData.append(key, val)
                        }
                    }
                    const datas = (await this.axios.post('/v2/kontrak-mtu/pose/'+this.id_pose_active, formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_POSE_EDIT').modal('hide')
                    // window.$('#Modal_POSE').modal('hide')
                    this.loadListPOSE ()
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadNilaiKontrak ()
                    this.loadListBAP();
                } else {
                    this.formPOSE.cny =  this.currencyForm.cny
                    this.formPOSE.usd =  this.currencyForm.usd
                    this.formPOSE.eur =  this.currencyForm.eur
                    this.formPOSE.jpy =  this.currencyForm.jpy
                    const formData = new FormData ()
                    for (const [key, val] of Object.entries(this.formPOSE)) {
                        if (key === 'id_kontrak_mtu') {
                            formData.append(key, this.id_mtu)
                        } else {
                            formData.append(key, val)
                        }
                    }
                    const datas = (await this.axios.post('/v2/kontrak-mtu/pose', formData)).data
                    if (!datas.status) throw datas
                    window.$('#Modal_POSE').modal('hide')
                    this.loadListPOSE ()
                    this.loadListResume ()
                    this.loadDetail ()
                    this.loadDataAnggaran ()
                    this.loadNilaiKontrak ()
                    this.loadListBAP();
                }
            } catch (error) {
                console.log(error)
                this.$toast.warning(error?.response?.data?.message ?? error.message)
            } finally {
                this.submitPOSEState = true
                this.editState = false
                this.is_new_file = false
                window.$('#Modal_POSE').modal('hide')
                window.$('#Modal_POSE_EDIT').modal('hide')
            }
        },
        async loadListPOSE () {
            this.loadStatePOSE = false
            this.payloadPOSE.id_kontrak_mtu = this.id_mtu
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/pose', { params: this.payloadPOSE })).data
                if (!datas.status) throw datas
                this.tableDokPOSEOption.list = datas.data.map((e) => {
                    e.bap_name = e.bap.name
                    e.col_value = e.nilai
                    return e
                })
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)    
            } finally {
                this.loadStatePOSE = true
            }
        },
        async loadDetailPOSE (id) {
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/pose/'+id)).data
                if (!datas.status) throw datas
                for (const [key, value] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.formPOSE, key)) {
                        if (['nilai', 'idr'].includes(key)) {
                            this.formPOSE[key] = value
                            // this.currencyForm.idr = datas.data['nilai']
                        } else {
                            this.formPOSE[key] = value
                        }
                    }
                }
                console.log(this.formPOSE, "akas");
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        destroy (type, data) {
            this.$swal.fire({
              title: 'Hapus Data Ini?',
              text: "Anda mungkin tidak akan mengembalikannya lagi!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#44A8BD',
              cancelButtonColor: '#D95E6A',
              confirmButtonText: 'Ya, Hapus!',
              cancelButtonText: 'Batal'
              }).then(async (result) => {
                  if (result.value) {
                      try {
                        if (type === 'kendala') {
                            const datas = (await this.axios.delete('/v2/kontrak-mtu/kendala/'+data.id)).data
                            if (!datas.status) throw datas
                            this.loadListKendala ()
                            this.loadListSolusi ()
                        }
                        if (type === 'amandemen') {
                            const datas = (await this.axios.delete('/v2/kontrak-mtu/amandemen/'+data.id)).data
                            if (!datas.status) throw datas
                            this.loadListAmandemen ()
                            this.loadDataAnggaran()
                            this.loadListResume()
                        }
                        if (type === 'solusi') {
                            const datas = (await this.axios.delete('/v2/kontrak-mtu/solusi/'+data.id)).data
                            if (!datas.status) throw datas
                            this.loadListKendala ()
                            this.loadListSolusi ()
                        }
                         if (type === 'pose') {
                            const datas = (await this.axios.delete('/v2/kontrak-mtu/pose/'+data.id)).data
                            if (!datas.status) throw datas
                            this.loadListPOSE ()
                            this.loadDataAnggaran ()
                        }
                        if (type === 'bap') {
                            const datas = (await this.axios.delete('/v2/kontrak-mtu/bap/'+data.id)).data
                            if (!datas.status) throw datas
                            this.loadListBAP ()
                        }
                        this.loadDetail ();
                      } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                          console.log(error)
                      }
                  }
              })
        },
        async calculateResumeProgress () {
            const payload = this.formResume
            const formData = new FormData ()
            for (const [key, val] of Object.entries(payload)) {
                if (key === 'progress_type') {
                    payload[key].forEach((e, i) => {
                        for (const [key2, val2] of Object.entries(e)) {
                            formData.append(`${key}[${i}][${key2}]`, val2.toString())
                        }
                    })
                } else if (['progress_value'].includes(key)) {
                    //
                } else {
                    formData.append(key, val)
                }
            }
            try {
                const datas = (await this.axios.post('/v2/kontrak-mtu/resume/calculate-progress', formData)).data
                if (!datas.status) throw datas
                this.formResume.progress_value = datas.data.total
            } catch (error) {
                console.log("Can't Calculate", error )
            }
        },
        async onInputProgresCalculate(idx) {
            const payload = this.formBAP.lokasi[idx]
            const formData = new FormData ()
            for (const [key, val] of Object.entries(payload)) {
                if (key === 'progress_type') {
                    payload[key].forEach((e, i) => {
                        for (const [key2, val2] of Object.entries(e)) {
                            formData.append(`${key}[${i}][${key2}]`, val2.toString())
                        }
                    })
                } else if (['value', 'added', 'deleted'].includes(key)) {
                    //
                } else {
                    formData.append(key, val.toString())
                }
            }
            try {
                const datas = (await this.axios.post('/v2/kontrak-mtu/bap/calculate-value', formData)).data
                if (!datas.status) throw datas
                this.formBAP.lokasi[idx].value = datas.data.total
            } catch (error) {
                this.$toast.warning(error?.response?.data?.message ?? error.message)
                console.log(error)
            }
        },
        removeLokasiBAP (idx) {
            if (this.editState && this.formBAP?.lokasi[idx]?.added === false) {
                this.formBAP.lokasi[idx].deleted = true
            } else {
                this.formBAP.lokasi.splice(idx, 1)
            }
        },
        addLokasiBAP () {
            let mck = {
                id_kontrak_mtu_lokasi: '',
                progress_type: [],
                value: '',
                deleted: false,
                added: this.editState === true,
            }
            if (this.editState) {
                mck.added = true
                mck.deleted = false
            }
            this.formBAP.lokasi.push(mck)
        },
        onSelect (opt) {
            console.log(opt)
        },
        onRemove (opt) {
            console.log(opt)
        },
        actionEdit(type, data) {
            this.editState = true
            if (type === 'kendala') {
                window.$('#Modal_Kendala').modal('show')
                this.id_kendala_active = data.id
                this.loadDetailKendala (data.id)
            }
            if (type === 'amandemen') {
                window.$('#Modal_Amandemen').modal('show')
                this.id_amandemen_active = data.id
                this.loadDetailAmandemen (data.id)
            }
            if (type === 'resume') {
                window.$('#Modal_Resume').modal('show')
                this.id_resume_active = data.id
                this.loadDetailResume ({ id: data.id, key: 1 })
            }
            if (type === 'solusi') {
                window.$('#Modal_Solusi').modal('show')
                this.id_solusi_active = data.id
                this.loadDetailSolusi (data.id)
            }
            if (type === 'kontraktual') {
                window.$('#Modal_StatusKontraktual').modal('show')
                this.id_sk_active = data.id
                this.loadDetailSk (data.id)
            }
            if (type === 'bap') {
                window.$('#Modal_BAP').modal('show')
                this.id_bap_active = data.id
                this.loadDetailBAP (data.id, false)
            }
            if (type === 'pose') {
                window.$('#Modal_POSE_EDIT').modal('show')
                this.id_pose_active = data.id
                this.loadDetailPOSE (data.id)
            }
            if (type === 'dok_pendukung') {
                window.$('#Modal_DokPendukung').modal('show')
                this.id_dp_active = data.id
                this.loadDetailDokPendukung (data.id)
            }
        },
        async fetchLocPose () {
            this.listLocPose = []
            try {
                const datas = (await this.axios.get('/v2/misc/locations/bap', { params: { id_kontrak_mtu_bap: this.formPOSE.id_kontrak_mtu_bap } })).data
                if (!datas.status) throw datas
                console.log(datas)
                datas.data.forEach((e) => {
                    this.listLocPose.push(
                        {
                            value: e.id_kontrak_mtu_lokasi,
                            text: e?.location?.name ?? '-'
                        }
                    )
                })
            } catch (error) {
                console.log(error.message)
                this.$toast.warning(error?.response?.data?.message ?? error.message)
            }
        },
        clearModalData () {
            this.editState = false
            this.$refs.dropZoneKendala.removeAllFiles(true)
            this.$refs.dropZoneSolusi.removeAllFiles(true)
            this.$refs.dropzoneStatusKontrak.removeAllFiles(true)
            this.$refs.dropzoneDokPendukung.removeAllFiles(true)
            this.$refs.dropzoneBAP.removeAllFiles(true)
            this.$refs.dropzoneAmandemen.removeAllFiles(true)

            this.formAmandemen = {
                id_kontrak_mtu: null,
                name: null,
                nomor: null,
                tanggal: null,
                document: null,
                perihal: [],
                lokasi: [
                    {
                        id_kontrak_mtu_lokasi: null,
                        id_new_kontrak: null,
                        name: null,
                        tanggal: null,
                        other: null,
                        perihal: [],
                        nilai: null
                    }
                ],
            },
            this.formBAP = {
                id_kontrak_mtu: '',
                name: '',
                nomor: '',
                date: '',
                document: null,
                lokasi: [
                    {
                        id_kontrak_mtu_lokasi: '',
                        progress_type: [],
                        value: '',
                        deleted: false,
                        added: false,
                    }
                ]
            }
            this.formResume = {
                id_kontrak_mtu_lokasi: '',
                type: '',
                progress_type: [],
                progress_value: ''
            }
            for (const [key] of Object.entries(this.formKendala)) {
                this.formKendala[key] = null
            }
            for (const [key] of Object.entries(this.formSolusi)) {
                this.formSolusi[key] = null
            }
            for (const [key] of Object.entries(this.formPOSE)) {
                this.formPOSE[key] = null
            }
            for (const [key] of Object.entries(this.formKontraktual)) {
                this.formKontraktual[key] = null
            }
            for (const [key] of Object.entries(this.formDP)) {
                this.formDP[key] = null
            }

            this.parseNilaiTypeAmandemen (0)
            
        },
        addSolusi (data) {
            this.editState = false
            for (const [key] of Object.entries(this.formSolusi)) {
                this.formSolusi[key] = ''
            }
            this.formSolusi.id_kontrak_mtu_kendala = data.id
        },
        fileHandler (file){
            console.log(file)
        },
        deleteFile (file){
            console.log(file)          
        },
        equivalentV2 (data, idx1, idx2) {
            const curr = ( parseFloat((data.idr ? data.idr : 0)) + parseFloat((data.cny ? data.cny : 0) * this.cur.cny) + parseFloat((data.jpy ? data.jpy : 0) * this.cur.jpy) + parseFloat((data.eur ? data.eur : 0) * this.cur.eur) + parseFloat((data.usd ? data.usd : 0) * this.cur.usd) )
            this.formAmandemen.lokasi[idx1].nilai[idx2].nilai =  curr
            return curr
        },
        isShowByPerihal(id,data) {
            return data.findIndex(e => e.id === id) > -1
        },
        deleteRowLokasiAmandemen (idx) {
            if (this.editState && this.formAmandemen?.lokasi[idx]?.added === false) {
                this.formAmandemen.lokasi[idx].deleted = true
            } else {
                this.formAmandemen.lokasi.splice(idx, 1)
            }
        },
        tambahLokasiAmandemen () {
            let mck = {
                id_kontrak_mtu_lokasi: null,
                id_new_kontrak: null,
                name: null,
                tanggal: null,
                other: null,
                perihal: [],
                nilai: null
            }
            if (this.editState) {
                mck.added = true
                mck.deleted = false
            }
            this.formAmandemen.lokasi.push(mck)
        },
        addnewLocation(idx, loc_type) {
            const indexOfLocation = this.miscLocation.map(x => x.id)
                .indexOf(this.formAmandemen.lokasi[idx].id_kontrak_mtu_lokasi);

            const ready_at = this.miscLocation[indexOfLocation].ready_at;

            this.formAmandemen.lokasi[idx].loc_type.push(loc_type);
            this.formAmandemen.lokasi[idx].name.push(null);
            this.formAmandemen.lokasi[idx].id_new_kontrak.push(null);
            this.formAmandemen.lokasi[idx].ready_at.push(ready_at);
            this.formAmandemen.lokasi[idx].konfigurasi.push(null);
            this.formAmandemen.lokasi[idx].jumlah.push(null);
            this.$forceUpdate();
        },
        deleteNewLocation(z, idx) {
            this.formAmandemen.lokasi[idx].loc_type.splice(z, 1);
            this.formAmandemen.lokasi[idx].name.splice(z, 1);
            this.formAmandemen.lokasi[idx].id_new_kontrak.splice(z, 1);
            this.formAmandemen.lokasi[idx].ready_at.splice(z, 1);
            this.formAmandemen.lokasi[idx].konfigurasi.splice(z, 1);
            this.formAmandemen.lokasi[idx].jumlah.splice(z, 1);
        },
        resetLocation(idx) {
            this.formAmandemen.lokasi[idx].loc_type = [];
            this.formAmandemen.lokasi[idx].name = [];
            this.formAmandemen.lokasi[idx].id_new_kontrak = [];
            this.formAmandemen.lokasi[idx].ready_at = [];
            this.formAmandemen.lokasi[idx].konfigurasi = [];
            this.formAmandemen.lokasi[idx].jumlah = [];
        },
        getLocationMaterialOptions(idx) {
            const indexOfLocation = this.miscLocation.map(x => x.id)
                .indexOf(this.formAmandemen.lokasi[idx].id_kontrak_mtu_lokasi);

            return JSON.parse(this.miscLocation[indexOfLocation].konfigurasi);
        },
        maxOfMaterial(idx, z) {
            const indexOfLocation = this.miscLocation.map(x => x.id)
                .indexOf(this.formAmandemen.lokasi[idx].id_kontrak_mtu_lokasi);

            const konfigurasi = this.formAmandemen.lokasi[idx].konfigurasi[z];

            const indexOfKonfigurasi = JSON.parse(this.miscLocation[indexOfLocation].konfigurasi)
                .indexOf(konfigurasi);

            
            if (!this.editState)
                return JSON.parse(this.defaultMiscLocation[indexOfLocation].jumlah)[indexOfKonfigurasi];

            const defaultMaxJumlah = JSON.parse(this.miscLocation[indexOfLocation].jumlah)[indexOfKonfigurasi];
            return defaultMaxJumlah;
        },
        isValidTotalOfMaterial(idx, z) {
            const konfigurasi = this.formAmandemen.lokasi[idx].konfigurasi[z];
            const defaultMaxJumlah = this.maxOfMaterial(idx, z);

            let sumJumlah = 0;
            
            const zs = [];
            for (let i = 0; i < this.formAmandemen.lokasi[idx].konfigurasi.length; i++) {
                const element = this.formAmandemen.lokasi[idx].konfigurasi[i];
                if (element === konfigurasi) {
                    zs.push(i);
                }
            }

            for (let i = 0; i < zs.length; i++) {
                const element = zs[i];
                sumJumlah += parseInt(this.formAmandemen.lokasi[idx].jumlah[element]??0);
            }

            if (sumJumlah > defaultMaxJumlah) return false;
            return true;
        },
        onSelectPerihal(e, idx, id_lokasi) {
            this.id_loc = id_lokasi
            if (e.id === 2) {
                this.parseNilaiTypeAmandemen(idx)
            }

            if (e.id === 4) {
                if (this.formAmandemen.lokasi[idx].loc_type?.length > 0) {
                    const ids = [];
                    for (let i = 0; i < this.formAmandemen.lokasi[idx].loc_type.length; i++) {
                        if (this.formAmandemen.lokasi[idx].loc_type[i] === 'byId') ids.push(i);
                    }

                    for (let i = ids.length-1; i >= 0; i--) {
                        this.deleteNewLocation(ids[i], idx)
                    }
                } else {
                    this.resetLocation(idx)
                }

                this.addnewLocation(idx, 'byId');
            }

            if (e.id === 3) {
                if (this.formAmandemen.lokasi[idx].loc_type?.length > 0) {
                    const ids = [];
                    for (let i = 0; i < this.formAmandemen.lokasi[idx].loc_type.length; i++) {
                        if (this.formAmandemen.lokasi[idx].loc_type[i] === 'byName') ids.push(i);
                    }

                    for (let i = ids.length-1; i >= 0; i--) {
                        this.deleteNewLocation(ids[i], idx)
                    }
                } else {
                    this.resetLocation(idx)
                }
                
                this.addnewLocation(idx, 'byName');
            }
        },
        onRemovePerihal(e, idx) {
            if (e.id === 2) {
                this.formAmandemen.lokasi[idx].nilai = null
            }
        },
        isMiscLocationDisabled (val) {
            return this.formAmandemen.lokasi.findIndex((e) => parseInt(e.id_kontrak_mtu_lokasi) === parseInt(val.id)) > -1
        },
        isDisabledLocationBAP(val) {
            return this.formBAP.lokasi.findIndex((e) => parseInt(e.id_kontrak_mtu_lokasi) === parseInt(val.id)) > -1
        },
        resetFormKontraktual() {
            this.formKontraktual = {
                id_kontrak_mtu: '',
                id_kontrak_mtu_lokasi: '',
                name: '',
                nomor: '',
                tanggal: '',
                document: null
            };
            this.editState = false;
            this.$refs.dropzoneStatusKontrak.removeAllFiles(true);
        },
        getProgressResume() {
            let _ = this
            _.axios.get(`/v2/progress-mtu-status?jenis_pekerjaan=${this.detail.jenis_pekerjaan}`)
                .then(resp=>{
                    this.resumeProgressOptions = resp.data.data
                    this.loadListResume();
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async onResumeStatusChange() {
            this.loadDetail ()
            this.loadDataAnggaran ()
            this.loadNilaiKontrak ()
        },
    }
}
</script>

<style scoped>
.wrapper-mtu-info-kontrak {
    padding: 20px 0;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px dashed #D8D8D8
}
.foto-detail {
    width: 168px;
}
.foto-detail img {
    width: 100%;
    object-fit: cover;
}
.wrap-row-deleted {
    width: 100%;
    height: 40px;
    background: rgba(228, 0, 0, 0.5);
    position: absolute;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.fadeY-enter-active {
transition: all .3s ease;
}
.fadeY-leave-active {
    transition: all .5s;
}
.fadeY-enter, .fadeY-leave-to {
    transform: translateY(-10px);
    opacity: 0;
}

.fieldset-style {
    padding: 7px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0;
}
.modal-lightbox {
    padding: 5px;
    width: 100%
}
.modal-lightbox > img {
    width: 100%;
}
.wrap-detail-bap {
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
}
</style>