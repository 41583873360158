<template>
    <div>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Kontrak</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body card-dashboard">
                                <form-wizard color="#329fb7" title="" ref='fwizard' subtitle="" finishButtonText="Selesai" nextButtonText="Selanjutnya" backButtonText="Sebelumnya" stepSize="sm">
                                  <tab-content title="Nama Kontrak" icon="step-icon feather icon-home">
                                    <fieldset>
                                        <ValidationObserver ref="vFirst">
                                        <div class="row mt-2">
                                    
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Nomor Kontrak" rules="required" v-slot="{ errors }">
                                                    <label for="">Nomor Kontrak </label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.no_kontrak" placeholder="Contoh: 362.PJ/2/UIP/JJB/2016">
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Nomor PRK" rules="required" v-slot="{ errors }">
                                                    <label for="">Nomor PRK</label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.no_prk" placeholder="Nomor PRK">
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Judul Kontrak" rules="required" v-slot="{ errors }">
                                                    <label for="">Judul Kontrak</label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.judul_kontrak" placeholder="Masukan judul kontrak">
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Nama Pekerjaan" rules="required" v-slot="{ errors }">
                                                    <label for="">Nama Pekerjaan</label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.nama_pekerjaan" placeholder="Masukan nama pekerjaan">
                                                     <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <label for="">Tanggal Awal</label>
                                                <div class="position-relative has-icon-right mb-2">
                                                    <ValidationProvider name="Tanggal Awal" rules="required" v-slot="{ errors }">
                                                    <date-picker
                                                        v-model="model.tgl_awal"
                                                        format="DD/MM/YYYY"
                                                        type="date"
                                                        :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                                        value-type="YYYY-MM-DD"
                                                        placeholder="Masukan tanggal Awal"
                                                        style="width: 100%;"
                                                        >
                                                    </date-picker>
                                                    <small>{{ errors[0] }} </small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6" v-if="!$route.params.id">
                                                <div class="form-group" v-if="!model.tgl_spmk && !model.no_spmk">
                                                    <label for="">SPMK</label>
                                                    <button type="submit" class="btn btn-primary" data-toggle="modal" data-target="#Modal_UpdateSPMK">
                                                        Update SPMK
                                                    </button>
                                                </div>

                                                <div class="d-flex flex-wrap justify-content-between align-items-center mt-2" v-else>
                                                    <p class="mb-1">{{ model.tgl_spmk }} - SPM{{ model.no_spmk }}</p>
                                                    <button type="submit" class="btn btn-primary mb-1"
                                                        data-toggle="modal" data-target="#Modal_UpdateSPMK">
                                                        Update SPMK
                                                    </button>
                                                </div>
                                            </div>

                                            <div v-if="model.tgl_spmk != null && model.no_spmk != null && !$route.params.id" class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Durasi" rules="required|numeric|min_value:1" v-slot="{ errors }">
                                                    <label for="">Durasi</label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.durasi" @input="calculateDate" placeholder="Durasi dalam hari">
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div v-if="model.tgl_spmk != null && model.no_spmk != null && !$route.params.id" class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Tanggal Akhir</label>                                                    
                                                    <input type="text" class="form-control" v-model="tgl_akhir_text" readonly  placeholder="">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Modal -->
                                        <div class="modal fade text-left" id="Modal_UpdateSPMK" tabindex="-1" role="dialog"
                                            aria-labelledby="myModalLabel160" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header modal-primary white">
                                                        <h5 class="modal-title" id="myModalLabel160">SPMK</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="form-group">
                                                            <ValidationProvider name="Nomor SPMK" :rules="model.tgl_spmk != null ? 'required' : ''" v-slot="{ errors }">
                                                            <label for="">Nomor SPMK</label>
                                                            <input type="text" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.no_spmk" class="form-control" placeholder="Masukkan nomor SPMK"
                                                                aria-describedby="helpId">
                                                            <small>{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>

                                                        <div class="form-group">
                                                            <label for="">Tanggal SPMK</label>
                                                            <ValidationProvider name="Tanggal SPMK" :rules="model.no_spmk != null ? 'required' : ''" v-slot="{ errors }">
                                                            <div class="position-relative has-icon-right">
                                                                <date-picker
                                                                    v-model="model.tgl_spmk"
                                                                    format="DD/MM/YYYY"
                                                                    type="date"
                                                                    :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                                                    value-type="YYYY-MM-DD"
                                                                    placeholder="Tanggal SPMK"
                                                                    style="width: 100%;"
                                                                    >
                                                                </date-picker>
                                                            </div>
                                                            <small>{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                                                        <button type="button" class="btn btn-primary" data-dismiss="modal">Tambah</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                    </fieldset>
                                  </tab-content>
                                  <tab-content title="Informasi Kontrak" icon="step-icon feather icon-file-text">
                                    <fieldset>
                                        <ValidationObserver ref="vSecond">
                                        <div class="row mt-2 mb-3">
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Kontraktor" rules="required" v-slot="{ errors }">
                                                    <label for="">Kontraktor</label>
                                                    <select v-model="model.id_kontraktor" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                        <option value="">Pilih Kontraktor</option>
                                                        <option :value="kt.id" v-for="kt in optionsKontraktor" :key="`kt-${kt.id}`">{{ kt.name }}</option>
                                                    </select>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Jenis Pekerjaan" rules="required" v-slot="{ errors }">
                                                    <label for="">Jenis Pekerjaan</label>
                                                    <select v-model="model.id_pekerjaan" @change="model.lokasi = [], lokasiInp = [{name: '', latlng: '', pinned: false}]" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                        <option value="">Pilih Jenis Pekerjaan</option>
                                                        <option :value="pj.id" v-for="pj in optionsPekerjaan" :key="`pj-${pj.id}`">{{ pj.name }}</option>
                                                    </select>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="GI" :rules="(model.id_pekerjaan < 5)? 'required' : ''" v-slot="{ errors }">
                                                    <label for="">GI</label>
                                                    <select class="custom-select form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.gi" :disabled="!(model.id_pekerjaan<5)">
                                                        <option value=" ">Pilih GI</option>
                                                        <option value="1">New</option>
                                                        <option value="2">Extension</option>
                                                    </select>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Lingkup" rules="required" v-slot="{ errors }">
                                                    <label for="">Lingkup</label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.lingkup" placeholder="Lingkup">
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Kapasitas" v-slot="{ errors }">
                                                    <label for="">Kapasitas</label>
                                                    <div class="position-relative has-icon-right">
                                                        <input type="number" step=".01" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.kapasitas" :placeholder="`Contoh: 329465 ${( model.id_pekerjaan<5 ? 'MVA' : 'KMS'  )}`">
                                                        <div class="form-control-position" style="margin-right: 10px;" v-html="model.id_pekerjaan<5 ? 'MVA' : 'KMS'">
                                                            
                                                        </div>
                                                    </div>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Sumber Dana" rules="required" v-slot="{ errors }">
                                                    <label for="">Sumber Dana</label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.sumber_dana" placeholder="Contoh: BUMN">
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Project Code PMO" rules="" v-slot="{ errors }">
                                                    <label for="">Project Code PMO</label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.project_code_pmo" placeholder="Project Code PMO">
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Contract Code PMO" rules="" v-slot="{ errors }">
                                                    <label for="">Contract Code PMO</label>
                                                    <input type="text" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" v-model="model.contact_code_pmo" placeholder="Contract Code PMO">
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="UPP" rules="required" v-slot="{ errors }">
                                                    <label for="">UPP</label>
                                                    <select v-model="model.id_unit" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" @change="model.penugasan = null">
                                                        <option value="">Pilih UPP</option>
                                                        <option :value="un.id" v-for="un in optionsUnit.filter(un => un.id > 1)" :key="`un-${un.id}`">{{ un.name }}</option>
                                                    </select>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Pengawas" rules="required" v-slot="{ errors }">
                                                    <label for="">Pengawas</label>
                                                    <div :style="(Boolean(errors[0]))? 'border : 1px solid red;border-radius: 0.3rem' : ''"> 
                                                    <multiselect 
                                                        v-model="model.penugasan" 
                                                        :options="optionsPenugasan" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="Pilih Pengawas" 
                                                        label="name" 
                                                        track-by="id"
                                                        :class="(Boolean(errors[0]))? 'is-invalid' : ''" 
                                                        :searchable="true">
                                                        <template v-slot:noResult>
                                                            Tidak ada data.
                                                        </template>
                                                        <template v-slot:noOptions>
                                                            Tidak ada data.
                                                        </template>                                          
                                                    </multiselect>
                                                    </div>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                              <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="Project Lain Terkait" rules="" v-slot="{ errors }">
                                                    <label for="">Project Lain Terkait</label>
                                                    <div :style="(Boolean(errors[0]))? 'border : 1px solid red;border-radius: 0.3rem' : ''"> 
                                                    <multiselect 
                                                        v-model="model.keterkaitan" 
                                                        :options="optionsKontrak"
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="Pilih Kontrak" 
                                                        label="judul_kontrak" 
                                                        track-by="id"
                                                        :searchable="true">
                                                        <template v-slot:noResult>
                                                            Tidak ada data.
                                                        </template>
                                                        <template v-slot:noOptions>
                                                            Tidak ada data.
                                                        </template>                                          
                                                    </multiselect>
                                                    </div>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <ValidationProvider name="PIC Kontraktual" rules="required" v-slot="{ errors }">
                                                    <label for="">PIC Kontraktual</label>
                                                    <div :style="(Boolean(errors[0]))? 'border : 1px solid red;border-radius: 0.3rem' : ''"> 
                                                    <multiselect 
                                                        v-model="model.pic" 
                                                        :options="optionsPIC" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="Pilih PIC" 
                                                        label="name" 
                                                        track-by="id"
                                                        :class="(Boolean(errors[0]))? 'is-invalid' : ''" 
                                                        :preselect-first="false"
                                                        :searchable="true">
                                                        <template v-slot:noResult>
                                                            Tidak ada data.
                                                        </template>
                                                        <template v-slot:noOptions>
                                                            Tidak ada data.
                                                        </template>                                                  
                                                    </multiselect>
                                                    </div>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6 mb-1">
                                                <div class="form-check">
                                                    <ValidationProvider name="Supervised by Pusmanpro" rules="" v-slot="{ errors }">
                                                    <input class="form-check-input" type="checkbox" id="check1" v-model="model.is_supervised">
                                                    <label class="form-check-label ml-1">Supervised by Pusmanpro</label>
                                                    <small>{{ errors[0] }}</small>
                                                    </ValidationProvider>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="row align-items-center mb-1" v-for="(lok, ix) in lokasiInp" :key="`lok-${ix}`">
                                                    <div class="col-12 col-sm-7 col-md-6 col-lg-8">
                                                        <div class="form-group">
                                                            <label for="" v-if='model.id_pekerjaan <= 4'>Lokasi</label>
                                                            <label for="" v-else-if='model.id_pekerjaan <= 6'>Lokasi Tower</label>
                                                            <label for="" v-else-if='model.id_pekerjaan <= 8'>Lokasi JP</label>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <input v-if="model.id_pekerjaan > 4" type="text" class="mr-1 col-3 form-control" v-model="lokasiInp[ix].name" placeholder="Nama Lokasi">
                                                                    <input type="text" class="col form-control" v-model="lokasiInp[ix].latlng" placeholder="Lokasi project (Latitude,Longitude)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col- col-sm-5 col-md-3 col-lg-2">
                                                        <a href="javascript:;" @click="addMarker(ix)"
                                                            class="btn w-100 waves-effect waves-light" :class="(!lokasiInp[ix].pinned)? 'btn-success-trans': 'btn-warning-trans'">
                                                            <span v-if="!lokasiInp[ix].pinned">
                                                                Pin Lokasi
                                                            </span>
                                                            <span v-else><i class="fa fa-times"></i> Batal </span>
                                                        </a>
                                                    </div>

                                                    <div class="col-12 col-sm-5 col-md-3 col-lg-2" v-if="model.id_pekerjaan > 4 && ix == 0">
                                                        <a href="javascript:;" @click="addLokasi()"
                                                            class="btn btn-danger-trans w-100 waves-effect waves-light">
                                                            Tambah Lokasi
                                                        </a>
                                                    </div>

                                                    <div class="col-12 col-sm-5 col-md-3 col-lg-2" v-if="ix > 0">
                                                        <a href="javascript:;" @click="removeLokasi(ix)"
                                                            class="btn btn-outline-danger w-100 waves-effect waves-light">
                                                            Hapus Lokasi
                                                        </a>
                                                    </div>
                                                </div>
                                                <!-- <ValidationProvider name="Lokasi" rules="required" v-slot="{ errors }">
                                                    <input type="hidden" v-model="model.lokasi">
                                                    <p style="color : #EA5455;">{{ Boolean(errors[0])? '*Lokasi tidak boleh kosong, harap Pin Lokasi terlebih dahulu' : '' }}</p>
                                                </ValidationProvider> -->
                                                <div class="w-100" id="map" ref="map" style="height:300px"></div>
                                            </div>
                                        </div>
                                        </ValidationObserver>
                                    </fieldset>
                                  </tab-content>
                                   <tab-content title="Informasi Tambahan" icon="step-icon feather icon-layers">
                                    <fieldset>
                                        <ValidationObserver ref="vThird">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="">Foto Kontrak</label>
                                                    <vue-dropzone ref="myVueDropzone" id="dropzone" 
                                                        :duplicateCheck="true" 
                                                        :options="dropzoneOptions" 
                                                        @vdropzone-files-added="fileHandler"
                                                        @vdropzone-removed-file="deleteFile"
                                                    ></vue-dropzone>
                                                    <button @click="removeFile" class="btn btn-danger"> Reset File </button>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="">Single Line Diagram</label>
                                                    <vue-dropzone ref="myVueDropzoneSLD" id="dropzone" 
                                                        :duplicateCheck="true" 
                                                        :options="dropzoneOptionsSLD" 
                                                        @vdropzone-files-added="fileHandlerSLD"
                                                        @vdropzone-removed-file="deleteFileSLD"
                                                    ></vue-dropzone>
                                                    <button @click="removeFileSLD" class="btn btn-danger"> Reset File </button>
                                                </div>
                                            </div>

                                            <div class="col-12" v-if="hideCurvaS !== 1">
                                                <div class="form-group">
                                                    <label for="filter_by">Curva S</label>
                                                    <p style="color : #EA5455;" class="mb-0"><i> *Extensi file harus format excel</i></p>
                                                    <vue-dropzone
                                                        ref="curvaSDropzone"
                                                        id="curva_s"
                                                        :duplicateCheck="true"
                                                        @vdropzone-files-added="curvaSFileHandler"
                                                        @vdropzone-removed-file="curvaSDeleteFile"
                                                        :options="kontraktual_dropzoneOptionCurvaS"
                                                    >
                                                    </vue-dropzone>
                                                <p v-if="curvaSError == true" style="color : #EA5455;">*File Curva S melebihi 1</p>
                                                </div>
                                            </div>
                                        </div>

                                        <DokumenKontrakForm v-if="!$route.params.id" :isSaving="saving"/>
                                        <small class="text-danger" v-if="showDocumentsError">Dokumen Kontrak harus diisi</small>

                                        <hr>

                                        <h5 v-if="!$route.params.id">Nilai Kontrak</h5>
                                        <div class="row mb-2" v-if="!$route.params.id">
                                            <div class="col-12 col-sm-2">
                                                <div class="form-group">
                                                    <label for="">IDR</label>
                                                    <div class="position-relative has-icon-left">
                                                        <money class="form-control" v-model="idr" v-bind="money"></money>
                                                        <div class="form-control-position">
                                                            Rp
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-2">
                                                <div class="form-group">
                                                    <label for="">CNY</label>
                                                    <div class="position-relative has-icon-left">
                                                        <money class="form-control" v-model="cny" v-bind="money"></money>
                                                        <div class="form-control-position">
                                                            &#x5143;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-2">
                                                <div class="form-group">
                                                    <label for="">JPY</label>
                                                    <div class="position-relative has-icon-left">
                                                       <money class="form-control" v-model="jpy" v-bind="money"></money>
                                                        <div class="form-control-position">
                                                            &#xa5;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-2">
                                                <div class="form-group">
                                                    <label for="">EURO</label>
                                                    <div class="position-relative has-icon-left">
                                                        <money class="form-control" v-model="eur" v-bind="money"></money>
                                                        <div class="form-control-position">
                                                            &euro;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-2">
                                                <div class="form-group">
                                                    <label for="">USD</label>
                                                    <div class="position-relative has-icon-left">
                                                        <money class="form-control" v-model="usd" v-bind="money"></money>
                                                        <div class="form-control-position">
                                                            $
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-2">
                                                <ValidationProvider name="Nilai Kontrak" rules="min_value:1" v-slot="{ errors }">
                                                    <input type="hidden" v-model="model.nilai">
                                                    <p style="color : #EA5455;">{{ Boolean(errors[0])? '*Nilai Kontrak tidak boleh Rp 0' : '' }}</p>
                                                </ValidationProvider>
                                            </div>
                                        </div>

                                        <p v-if="!$route.params.id">Equivalent</p>
                                        <h3 class="font-bold" v-if="!$route.params.id">{{ equivalent | currency }}</h3>
                                    </ValidationObserver>
                                    </fieldset>
                                    
                                   </tab-content>
                                    <template slot="footer" slot-scope="props">
                                        <div class="wizard-footer-right">
                                            <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()"  style="margin-right:30px;border-color : rgb(50,159,183) !important;;color : rgb(50,159,183) !important; background :#fff;min-width:90px;"><i class="fa fa-chevron-left" style="margin-right:10px;"></i> Sebelumnya</wizard-button>
                                            <wizard-button v-if="!props.isLastStep" @click.native="wizValidate()" v class="wizard-footer-right" :style="props.fillButtonStyle" style="min-width:90px;">Selanjutnya <i class="fa fa-chevron-right" style="margin-left:10px;"></i></wizard-button>
                                            <wizard-button v-if="props.isLastStep" :disabled="save_ldstate" @click.native="wizValidate()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" style="min-width:90px;"> <i v-if="save_ldstate" class="fa fa-spinner fa-spin"></i> Selesai <i class="fa fa-check" style="margin-left:10px;"></i></wizard-button>
                                        </div>
                                    </template>
                                </form-wizard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        

        <div
            class="modal fade text-left"
            id="Modal_PDFViewer"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel160"
            aria-hidden="true">
            <div
                class="modal-dialog modal-dialog-centered"
                style="max-width: 95%; max-height: 95%"
                role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">View File</h5>
                        <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="pdfFile" style="height: 80vh">
                        <iframe :src="pdfFile" frameborder="0" style="width: 100%;height: 100%;"></iframe>
                    </div>
                    <div class="modal-footer">
                        <button
                        type="button"
                        class="btn btn-outline-primary"
                        data-dismiss="modal"
                        >
                        Tutup
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import vue2Dropzone from 'vue2-dropzone'
import $ from 'jquery'
import {Money} from 'v-money'
import ld from 'lodash'
import moment from 'moment'
import DokumenKontrakForm from '@/views/kontrak/components/DokumenKontrakForm.vue'

export default {
    components: { DatePicker, vueDropzone: vue2Dropzone, Money, DokumenKontrakForm },
    data() {
        return {
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false 
            },
            hideCurvaS:0,
            curvaSError:false,
            save_ldstate:false,
            title: "Tambah Kontrak",
            breadcumb: [
                {
                    name: 'Monitoring Project', 
                    link: '/kontrak',
                    active: false
                },
                {
                    name: 'Project List', 
                    link: '/kontrak',
                    active: false
                },
                {
                    name: 'Tambah Kontrak', 
                    link: '',
                    active: true
                }            
            ],
            model: {
                id_kontraktor: "",
                id_pekerjaan: "",
                id_unit: "",
                no_kontrak: null,
                priority: "",
                judul_kontrak: null,
                nama_pekerjaan: null,
                durasi: 0,
                tgl_awal: '',
                tgl_akhir: '',
                sumber_dana: null,
                gi: " ",
                lingkup: null,
                is_supervised:null,
                project_code_pmo:null,
                contact_code_pmo:null,
                kapasitas: null,
                no_spmk: null,
                tgl_spmk: null,
                lokasi: [],
                image: [],
                nilai: 0,
                penugasan: null,
                pic: null,
                deletedFile: [],
                deletedFileDiagram: [],
                line_diagram: [],
                keterkaitan:[],
                curva_s:[],
                deletedFileCurvaS:[],
                documents: [],
            },
            lokasiInp: [{
                    name   : '',
                    latlng : '',
                    pinned : false
            }],
            optionsPenugasan : [
                { id: 1, name: 'Pegawai Satu', user_id : 0 },
            ],
            optionsPIC : [],
            optionsKontraktor: [],
            optionsPekerjaan: [],
            optionsUnit: [],
            optionsKontrak:[],
            dropzoneOptions: {
                url: this.$baseUrl + '/kontrak',
                acceptedFiles : 'image/*',
                thumbnailWidth: 150,
                autoProcessQueue: false,
                uploadMultiple: true,
                addRemoveLinks: true,
                paramName: 'image'
            },
            dropzoneOptionsSLD: {
                url: this.$baseUrl + '/kontrak',
                acceptedFiles : 'image/*',
                thumbnailWidth: 150,
                autoProcessQueue: false,
                uploadMultiple: false,
                addRemoveLinks: true,
                paramName: 'image'
            },
            idr: 0,
            cny: 0,
            jpy: 0,
            eur: 0,
            usd: 0,
            map: '',
            marker : [],
            tgl_akhir_text : '',
            cur : {
                jpy : 0,
                cny : 0,
                eur : 0,
                usd : 0
            },
            role_id: localStorage.getItem('role_id'),
            kontraktual_dropzoneOptionCurvaS: {
                url: this.$baseUrl + "/kontrak/progress",
                thumbnailWidth: 150,
                acceptedFiles: ".xlsx,.xls,.csv",
                autoProcessQueue: false,
                addRemoveLinks: true,
                uploadMultiple: true,
                headers: { Authorization: "Bearer " + this.$store.state.token },
                paramName: "image",
                maxFilesize: 10000,
                init: function () {
                // this.on("error", function(file, message) {
                //     alert(message, "hallo");
                //     this.removeFile(file);
                // });
                },
            },
            pdfFile: null,
            saving: false,
            showDocumentsError: false,
        }
    },
    methods: {
        calculateDate : ld.debounce(function(){
            let _ = this
             _.axios.get('set-date?start='+_.model.tgl_spmk+'&durasi='+_.model.durasi)
                .then(resp => {
                    _.model.tgl_akhir = resp.data
                    _.tgl_akhir_text = moment(_.model.tgl_akhir).format("DD, MMMM YYYY")
                })
                .catch(err => {
                    console.log(err.response)
                })
        },500),
        wizValidate() {
            let _ = this
            var tab = _.$refs.fwizard.activeTabIndex
            if(tab == 0){
                var vFirst = _.$refs.vFirst
                vFirst.validate().then(success => {
                    if(!success){
                        window.scrollTo(0,0)
                        console.log('Failed on Tab 1')
                        return;
                    }
                    _.$refs.fwizard.nextTab()
                })
            }
            else if(tab == 1){
                var vSecond = _.$refs.vSecond
                vSecond.validate().then(success => {
                    if(!success){
                        window.scrollTo(0,0)
                        console.log('Failed on Tab 2')
                        return;
                    }

                    _.$refs.fwizard.nextTab()
                })
            }
            else if(tab == 2){
                this.showDocumentsError = false;
                if ( this.model.documents && this.model.documents.length <= 0 ) {
                    this.showDocumentsError = true;
                    return;
                }

                this.saving = true
                setTimeout(() => {
                    var vThird = _.$refs.vThird
                    vThird.validate()
                        .then(success => {
                            this.saving = false
                            if(!success){
                                console.log('Failed on Tab 3')
                                return;
                            }
                            _.onComplete()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }, 100);
            }
        },
        fileHandler (file){
            let _ = this
            $.each(file ,function(index,val){
                _.model.image.push(val)
            })
        },
        fileHandlerSLD (file){
            let _ = this
            $.each(file ,function(index,val){
                _.model.line_diagram.push(val)
            })
        },
        removeFile() {
            let _ = this
            _.$refs.myVueDropzone.removeAllFiles(true)
            _.model.image = []
        },
        removeFileSLD() {
            let _ = this
            _.$refs.myVueDropzoneSLD.removeAllFiles(true)
            _.model.line_diagram = []
        },
        initMap() {
            let _ = this
            var myLatlng = {lat: -6.409493537558435, lng: 106.79552258660475}

            _.map = new window.google.maps.Map(
               _.$refs["map"], {zoom: 12, center: myLatlng})
        },
        async addMarker(index) {
            let _ = this
            var mylatlng = {
                lat : parseFloat(_.lokasiInp[index].latlng.split(",")[0]), 
                lng : parseFloat(_.lokasiInp[index].latlng.split(",")[1])
            }
            if(!_.lokasiInp[index].pinned){
                var geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({
                        'latLng': mylatlng
                    }, function(results, status) {
                        if (status == window.google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                let mark = new window.google.maps.Marker({
                                                map : _.map,
                                                animation: window.google.maps.Animation.DROP,
                                                position: {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                                            });
                                var infoWindow = new window.google.maps.InfoWindow({
                                    content: results[0].formatted_address,
                                });
                                _.marker['mark'+index] = mark
                                infoWindow.open(_.map, _.marker['mark'+index]);

                                _.model.lokasi.push({
                                    name: _.lokasiInp[index].name,
                                    address: results[0].formatted_address,
                                    gmap_url : 'https://www.google.co.id/maps/place/'+results[0].formatted_address.replace(/,/g,"").replace(/ /g,"+"),
                                    lat : results[0].geometry.location.lat(),
                                    lng : results[0].geometry.location.lng()
                                })
                                _.lokasiInp[index].pinned = true
                            }
                        }
                    });
           
            }
            else{
                _.cancelPin(index)
            }
            
        },
        getKontraks() {
            let _ = this
            let api = ''
            if (_.$route.params.id){
                api = '/kontrak/all-only?id=' + _.$route.params.id
            } else {
                api = '/kontrak/all-only'
            }

            _.axios.get(api)
                .then(resp => {
                    _.optionsKontrak = resp.data.data
                    if (_.$route.params.id){
                        _.get()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getKontraktor() {
            let _ = this
            _.axios.get('/kontraktor/all')
                .then(resp => {
                    _.optionsKontraktor = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getPekerjaan() {
            let _ = this
            _.axios.get('/work/all')
                .then(resp => {
                    _.optionsPekerjaan = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getPenugasan(id_unit) {
            window.deleteTextEmpty()
            let _ = this
            _.axios.get('/user/all?unit_id='+id_unit+'&role=1')
                .then(resp => {
                    _.optionsPenugasan = resp.data.data.map(function(elem) {
                        return {
                            id: elem.id,
                            name: elem.name,
                            id_user: elem.id
                        };
                    })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getPIC() {
            window.deleteTextEmpty()
            let _ = this
            _.axios.get('/user_pic?limit=200&status=1')
                .then(resp => {
                    _.optionsPIC = resp.data.data.rows.map(function(elem) {
                        return {
                            id: elem.user_id,
                            name: elem.name,
                            id_user: elem.user_id
                        };
                    })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getUnit() {
            let _ = this
            _.axios.get('/unit/all')
                .then(resp => {
                    _.optionsUnit = resp.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        sendingMultiple(files, xhr, formData) {
            let _ = this
            $.each(_.model, function(index, val) {
                formData.append(index, (index == 'penugasan' ? JSON.stringify(val) : val));
            });
        },
        onSuccess() {
            let _ = this
            _.$router.push('/kontrak')
            _.$toast.success('Data Kontrak Berhasil Disimpan')
        },
        curvaSFileHandler(file) {
            let _ = this;
            $.each(file ,function(index,val){
                _.model.curva_s.push(val)
            })
        },
        curvaSDeleteFile(file) {
            let _ = this;
            if (file.lastModified) {
                    _.model.curva_s = ld.filter(
                    _.model.curva_s,
                    function (o) {
                        return o.name != file.name;
                    }
                );
            } else {
                _.model.deletedFileCurvaS.push(file.name);
            }
        },
        
        onError() {
            let _ = this
            _.$router.push('/kontrak')
            _.$toast.warning('wakwaw')
        },
        onComplete() {
            let _ = this
            // _.model.tgl_akhir = moment().format("YYYY-MM-DD")
            _.model.no_spmk = _.model.no_spmk == null ? '' : _.model.no_spmk 
            _.model.tgl_spmk = _.model.tgl_spmk == null ? '' : _.model.tgl_spmk
            _.model.lokasi = _.model.lokasi.length == 0 ? '' : _.model.lokasi

            _.model.is_supervised = _.model.is_supervised == true ? 1 : 0;

            _.model.idr = _.idr
            _.model.cny = _.cny
            _.model.jpy = _.jpy
            _.model.eur = _.eur
            _.model.usd = _.usd

            if (_.model.curva_s.length > 1) {
                _.curvaSError = true;
                return;
            }

            this.save_ldstate = true            
            let keterkaitan  = [];
            if (_.model.keterkaitan.length > 0 ) {
                _.model.keterkaitan.forEach(element => {
                keterkaitan.push(element.id);
                });
                _.model.keterkaitan = keterkaitan;   
            }

            var formData = new FormData();
            $.each(_.model, function(index, val) {
                if (val != null) {
                    if(index == 'image'){
                        $.each(_.model.image, function(index, val) {
                        formData.append('image['+index+']',val)
                    })
                    }
                    else if(index == 'line_diagram'){
                        $.each(_.model.line_diagram, function(index, val) {
                        formData.append('line_diagram['+index+']',val)
                    })
                    }
                    else if(index == 'deletedFile'){
                        $.each(_.model.deletedFile, function(index, val) {
                            formData.append('deletedFile['+index+']',val)
                        })
                    }
                    else if(index == 'deletedFileDiagram'){
                        $.each(_.model.deletedFileDiagram, function(index, val) {
                            formData.append('deletedFileDiagram['+index+']',val)
                        })
                    }
                    else if (index == "curva_s") {
                        $.each(_.model.curva_s, function (index, val) {
                            formData.append("curva_s", val);
                        });
                    }
                    else if (index == "documents") {
                        $.each(_.model.documents, function (index, val) {
                            $.each(val, function (indexVal, valVal) {
                                if (indexVal == "files") {
                                    $.each(valVal, function (indexFile, valFile) {
                                        formData.append("documents["+ index +"]["+ indexVal +"]["+ indexFile +"]", valFile);
                                    });
                                } else {
                                    formData.append("documents["+ index +"]["+ indexVal +"]", valVal);
                                }
                            })
                        });
                    }
                    else{
                        formData.append(index, (index == 'penugasan' || index == 'lokasi' || index == 'pic' || index == 'keterkaitan' ? JSON.stringify(val) : val));                   
                    }
                }
            });       

            //for (var p of formData) {
            //     let name = p[0];
            //     let value = p[1];
            //     console.log(name, value)
            //}
          
            let api = ''
            if (_.$route.params.id){
                api = '/kontrak/update'
            } else {
                api = '/kontrak'
            }

            _.axios.post(api, formData,{
                headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(resp=> {
                    this.save_ldstate = false
                    console.log(resp)
                    _.$router.push('/kontrak')
                    _.$toast.success('Data Kontrak Berhasil Disimpan')
                })
                .catch(err=> {
                    console.log(err)
                    // _.$router.push('/kontrak')
                    _.$toast.warning('Data Kontrak Gagal Disimpan')
                })
            
        },
        addLokasi() {
            let _ = this
            _.lokasiInp = [
                ..._.lokasiInp,
                { latlng : "" , pinned : false}
            ]
        },
        cancelPin(ix) {
            let _ = this
            _.model.lokasi = _.model.lokasi.filter((lk, i) => {return i != ix} )
            _.marker['mark'+ix].setMap(null)
           delete _.marker['mark'+ix]
            _.lokasiInp[ix].pinned = false
        },
        removeLokasi(ix) {
            let _ = this
            try{
                 _.lokasiInp[ix].pinned = false
                _.model.lokasi = _.model.lokasi.filter((lk, i) => {return i != ix} )
                _.marker['mark'+ix].setMap(null)
                delete _.marker['mark'+ix]
            }
            catch(err){
                console.log(err)
            }
            
            _.lokasiInp = _.lokasiInp.filter((lk, i) => {return i != ix} )
        },
        getCurrency(){
            let _ = this
            _.axios.get('/kontrak/currency')
            .then(res => {
                _.cur.jpy = res.data.data.jpy
                _.cur.cny = res.data.data.cny
                _.cur.eur = res.data.data.eur
                _.cur.usd = res.data.data.usd
            })
        },  
        get(){
            let _ = this
            _.axios.get('/kontrak?id=' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data.rows
                    _.tgl_akhir_text = moment(_.model.tgl_akhir).format("DD, MMMM YYYY")
                    _.hideCurvaS = _.model.curva_s
                    let arr = []
                    for (let i = 0; i < _.model.penugasan.length; i++) {
                        arr.push({
                            id: _.model.penugasan[i].user.id,
                            name: _.model.penugasan[i].user.name,
                            id_user: _.model.penugasan[i].user.id
                        })
                    }
                    _.model.penugasan = arr

                    let keterkaitan = []
                    if (_.model.keterkaitan) {
                         JSON.parse(_.model.keterkaitan).forEach(element => {
                        let data = this.optionsKontrak.filter(function(creature) {
                            return creature.id == element;
                        })
                        keterkaitan.push({
                            id: data[0].id,
                            judul_kontrak:data[0].judul_kontrak,
                        })
                        });
                        _.model.keterkaitan = keterkaitan   
                    }else(
                        _.model.keterkaitan = []
                    )
                                        
                    let arrPIC = []
                    for (let i = 0; i < _.model.pics.length; i++) {
                        arrPIC.push({
                            id: _.model.pics[i].user.id,
                            name: _.model.pics[i].user.name,
                            id_user: _.model.pics[i].user.id
                        })
                    }
                    _.model.pic = arrPIC
                    
                    if (_.model.lokasi == '') {
                        _.model.lokasi = []
                    } else if (_.model.lokasi != null){
                        let temp_lokasi = _.model.lokasi
                        _.model.lokasi = []
                        _.lokasiInp = []
                        for (let i = 0; i < temp_lokasi.length; i++) {
                            _.lokasiInp.push({
                                name: temp_lokasi[i].name,
                                latlng: temp_lokasi[i].lat + ',' + temp_lokasi[i].lng,
                                pinned: false
                            })
                            _.addMarker(i)
                        }
                    } else {
                        _.model.lokasi = []
                    }                                                           

                    _.model.image.forEach(function(el) {
                        let file = { 
                            size: 10240, 
                            name: el.name,
                            type: "image/png" 
                        };
                        let url = el.url;
                        _.$refs.myVueDropzone.manuallyAddFile(file, url);
                    })

                    _.model.line_diagram.forEach(function(el) {
                        let file = { 
                            size: 10240, 
                            name: el.name,
                            type: "image/png" 
                        };
                        let url = el.url;
                        _.$refs.myVueDropzoneSLD.manuallyAddFile(file, url);
                    })

                    _.model.image = []
                    _.model.curva_s = []
                    _.model.line_diagram = []
                    _.model.deletedFile = []
                    _.model.deletedFileDiagram = []
                    _.model.is_supervised = _.model.is_supervised == 1 ? true : false
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        deleteFile (file){
            let _ = this
            if(file.lastModified){
                _.model.image = ld.filter(_.model.image, function(o){
                    return o.name != file.name;
                })
            }else{
                _.model.deletedFile.push(file.name)
            }            
        },
        deleteFileSLD (file){
            let _ = this
            if(file.lastModified){
                _.model.line_diagram = ld.filter(_.model.line_diagram, function(o){
                    return o.name != file.name;
                })
            }else{
                _.model.deletedFileDiagram.push(file.name)
            }            
        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
            });
        },
        pdfViewer(file) {
            this.pdfFile = null
            setTimeout(async () => {
                this.pdfFile = await this.toBase64(file);
                window.$("#Modal_PDFViewer").modal("show");
            }, 100);
        },
    },
    computed: {
        equivalent() {
            let _ = this
            let equi = ( parseFloat((_.idr ? _.idr : 0)) + parseFloat((_.cny ? _.cny : 0) * _.cur.cny) + parseFloat((_.jpy ? _.jpy : 0) * _.cur.jpy) + parseFloat((_.eur ? _.eur : 0) * _.cur.eur) + parseFloat((_.usd ? _.usd : 0) * _.cur.usd) )
            _.model.nilai = equi
            return equi
        }
    },
    mounted() {
        let _ = this
        _.getCurrency()
        _.getKontraktor()
        _.getPekerjaan()
        _.getUnit()
        _.getPenugasan()
        _.getPIC()
        _.initMap()
        _.getKontraks()

        // if (_.$route.params.id){
        //     _.get()
        // }

        _.$root.$on("setPDFFile", (data) => {
            _.pdfViewer(data)
        });

        _.$root.$on("updateDokumenKontrak", (data) => {
            _.model.documents = data
        });
    },
    watch: {
        'model.id_pekerjaan': function(newValue) {
            let _ = this
            if ( newValue < 5 ) {
                let lt = _.lokasiInp[0].latlng
                _.lokasiInp = [{
                    latlng : lt,
                    pinned : false
                }]
                for(let i = 0;i <= 30;i++){
                    _.marker['mark'+i].setMap(null)
                }
                _.model.lokasi = []
            } else {
                _.model.gi = ''
            }
            
        },
        'model.id_unit': function(newValue) {
            let _ = this
            _.getPenugasan(newValue)
            // _.model.penugasan = null
        }
    },
}
</script>