<template>
    <div>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body" v-show="$route.params.type == 'dashboard'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary">Edit Akses Home</h3>
                                    <div class="row mt-2">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">View Dashboard</label>
                                                <select v-model="model.dashboard" class="custom-select form-control">
                                                    <option value="dashboard/read">Iya</option>
                                                    <option value="" selected>Tidak</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="content-body" v-show="$route.params.type == 'home' || $route.params.type == 'monitoring'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary" style="text-transform: capitalize">Edit Akses {{ $route.params.type }}</h3>
                                    <div class="row mt-2" :class="showDetailOptions ? '' : 'mb-5'">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Kontrak</label>
                                                <multiselect 
                                                    v-model="model.kontrak" 
                                                    :options="optionsKontrak" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                        </div>
                                    </div>
                                    <section v-if="showDetailOptions">
                                        <h6><b>Detail Kontak</b></h6>
                                        <hr>
                                        <div class="row mt-2 mb-3">
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Informasi Kontrak</label>
                                                    <multiselect 
                                                        v-model="model.informasiKontrak" 
                                                        :options="optionsInformasiKontrak" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="false" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div class="form-group" id="akses">
                                                    <label for="">Dokumen Amandemen</label>
                                                    <multiselect 
                                                        v-model="model.amandemen" 
                                                        :options="optionsAmandemen" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="false" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div class="form-group" id="akses">
                                                    <label for="">Dokumen Berita Acara Pembayaran</label>
                                                    <multiselect 
                                                        v-model="model.bap" 
                                                        :options="optionsBap" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="false" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div class="form-group" id="akses">
                                                    <label for="">Status Kontraktual</label>
                                                    <multiselect 
                                                        v-model="model.statusKontraktual" 
                                                        :options="optionsKontraktual" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="false" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group" id="akses">
                                                    <label for="">Status Operasi</label>
                                                    <multiselect 
                                                        v-model="model.statusOperasi" 
                                                        :options="optionsOperasi" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Status Serah Terima</label>
                                                    <multiselect 
                                                        v-model="model.statusSerahTerima" 
                                                        :options="optionsSerahTerima" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Progress</label>
                                                    <multiselect 
                                                        v-model="model.progress" 
                                                        :options="optionsProgress" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Kendala</label>
                                                    <multiselect 
                                                        v-model="model.kendala" 
                                                        :options="optionsKendala" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Solusi</label>
                                                    <multiselect 
                                                        v-model="model.solusi" 
                                                        :options="optionsSolusi" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Scope</label>
                                                    <multiselect 
                                                        v-model="model.scope" 
                                                        :options="optionsScope" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">PO SE</label>
                                                    <multiselect 
                                                        v-model="model.pose" 
                                                        :options="optionsPose" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Kontrak MTU</label>
                                                    <multiselect 
                                                        v-model="model.mtuTerkait" 
                                                        :options="optionsMTUTerkait" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Project Lain Terkait</label>
                                                    <multiselect 
                                                        v-model="model.otherProject" 
                                                        :options="optionsOtherProject" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">BG Jaminan Uang Muka</label>
                                                    <multiselect 
                                                        v-model="model.bgUangMuka" 
                                                        :options="optionsBgUangMuka" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">BG Jaminan Pelaksanaan</label>
                                                    <multiselect 
                                                        v-model="model.bgPelaksanaan" 
                                                        :options="optionsBgPelaksanaan" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">BG Jaminan Pemeliharaan</label>
                                                    <multiselect 
                                                        v-model="model.bgPemeliharaan" 
                                                        :options="optionsBgPemeliharaan" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Dokumen Kontrak</label>
                                                    <multiselect 
                                                        v-model="model.dokumenKontrak" 
                                                        :options="optionsDokumenKontrak" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Dokumen Kajian Kelayakan</label>
                                                    <multiselect 
                                                        v-model="model.dokumenKajianKelayakan" 
                                                        :options="optionsDokumenKajianKelayakan" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Dokumen Lain Lain</label>
                                                    <multiselect 
                                                        v-model="model.dokumenLainLain" 
                                                        :options="optionsDokumenLainLain" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="content-body" v-show="$route.params.type == 'cctv'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary">Edit Akses CCTV</h3>
                                    <div class="row mt-2 mb-5">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">CCTV</label>
                                                    <multiselect 
                                                        v-model="model.cctv" 
                                                        :options="optionsCCTV" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center mb-2">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="content-body" v-show="$route.params.type == 'setting'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary">Edit Akses Setting</h3>
                                    <div class="row mt-2">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">CCTV</label>
                                                    <multiselect 
                                                        v-model="model.cctv" 
                                                        :options="optionsCCTV" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">View Live Monitoring</label>
                                                <select v-model="model.live_monitoring" class="custom-select form-control">
                                                    <option value="live_monitoring/read">Iya</option>
                                                    <option value="" selected>Tidak</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <h4><b>Master Data</b></h4> 
                                    <div class="row mt-2 mb-3">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Kontraktor</label>
                                                <multiselect 
                                                    v-model="model.kontraktor" 
                                                    :options="optionsKontraktor" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Jenis Pekerjaan</label>
                                                <multiselect 
                                                    v-model="model.jenis_pekerjaan" 
                                                    :options="optionsJenis_pekerjaan" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Jabatan</label>
                                                <multiselect 
                                                    v-model="model.jabatan" 
                                                    :options="optionsJabatan" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Unit</label>
                                                <multiselect 
                                                    v-model="model.unit" 
                                                    :options="optionsUnit" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Bidang</label>
                                                <multiselect 
                                                    v-model="model.bidang" 
                                                    :options="optionsBidang" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Sub-Bidang</label>
                                                <multiselect 
                                                    v-model="model.sub_bidang" 
                                                    :options="optionsSub_bidang" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div> 
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">User PIC</label>
                                                <multiselect 
                                                    v-model="model.user_pic" 
                                                    :options="optionsUserPIC" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Embed</label>
                                                <multiselect 
                                                    v-model="model.embed" 
                                                    :options="optionsEmbed" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div> 
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Amandemen Status</label>
                                                <multiselect 
                                                    v-model="model.amandemenStatus" 
                                                    :options="optionsAmandemenStatus" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div> 
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Bank Garansi Status</label>
                                                <multiselect 
                                                    v-model="model.bankGaransiStatus" 
                                                    :options="optionsBankGaransiStatus" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>  
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Anggaran Status</label>
                                                <multiselect 
                                                    v-model="model.anggaranStatus" 
                                                    :options="optionsAnggaranStatus" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>  
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Progress MTU Status</label>
                                                <multiselect 
                                                    v-model="model.progressMTUStatus" 
                                                    :options="optionsProgressMTUStatus" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>                                        
                                    </div> 
                                    <h4><b>User & Role Management</b></h4> 
                                    <div class="row mt-2 mb-3">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">User List</label>
                                                <multiselect 
                                                    v-model="model.user" 
                                                    :options="optionsUser" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Set Permission Role</label>
                                                <select v-model="model.permission" class="custom-select form-control">
                                                    <option value="permission/read">Iya</option>
                                                    <option value="" selected>Tidak</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>                                  

                                    <div class="d-flex flex-wrap justify-content-end align-items-center">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="content-body" v-show="$route.params.type == 'live_monitoring'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary">Edit Akses Live Monitoring</h3>
                                    <div class="row mt-2">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">View Live Monitoring</label>
                                                <select v-model="model.live_monitoring" class="custom-select form-control">
                                                    <option value="live_monitoring/read">Iya</option>
                                                    <option value="" selected>Tidak</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="content-body" v-show="$route.params.type == 'laporan'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary">Edit Akses Laporan</h3>                                    
                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Monitoring</label>
                                                <multiselect 
                                                    v-model="model.laporanMonitoring" 
                                                    :options="optionsMonitoring" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Project</label>
                                                <multiselect 
                                                    v-model="model.laporanProject" 
                                                    :options="optionsProject" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="true" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Progress</label>
                                                <multiselect 
                                                    v-model="model.laporanProgress" 
                                                    :options="optionsLaporanProgress" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Kendala</label>
                                                <multiselect 
                                                    v-model="model.laporanKendala" 
                                                    :options="optionsLaporanKendala" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="true" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Solusi</label>
                                                <multiselect 
                                                    v-model="model.laporanSolusi" 
                                                    :options="optionsLaporanSolusi" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Amandemen</label>
                                                <multiselect 
                                                    v-model="model.laporanAmandemen" 
                                                    :options="optionsLaporanAmandemen" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="true" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan BAP</label>
                                                <multiselect 
                                                    v-model="model.laporanBap" 
                                                    :options="optionsLaporanBap" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan MTU</label>
                                                <multiselect 
                                                    v-model="model.laporanMTU" 
                                                    :options="optionsLaporanMTU" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Proyek telah STP UIP JBB</label>
                                                <multiselect 
                                                    v-model="model.laporanTelahSTP" 
                                                    :options="optionsLapTelahSTP" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Proyek telah STAP UIP JBB</label>
                                                <multiselect 
                                                    v-model="model.laporanTelahSTAP" 
                                                    :options="optionsLapTelahSTAP" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Proyek telah STOP UIP JBB</label>
                                                <multiselect 
                                                    v-model="model.laporanTelahSTOP" 
                                                    :options="optionsLapTelahSTOP" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Proyek telah Energize UIP JBB</label>
                                                <multiselect 
                                                    v-model="model.laporanTelahEnergize" 
                                                    :options="optionsLapTelahEnergize" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>  
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan BAY telah STP UIP JBB</label>
                                                <multiselect 
                                                    v-model="model.laporanTelahBAYSTP" 
                                                    :options="optionsLapTelahBAYSTP" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan Proyek telah SLO UIP JBB</label>
                                                <multiselect 
                                                    v-model="model.laporanTelahSLO" 
                                                    :options="optionsLapTelahSLO" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>  
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan BAY telah SLO UIP JBB</label>
                                                <multiselect 
                                                    v-model="model.laporanTelahBAYSLO" 
                                                    :options="optionsLapTelahBAYSLO" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <div class="form-group" id="akses">
                                                <label for="">Generate Laporan PPT & PDF</label>
                                                <multiselect 
                                                    v-model="model.laporanPPTPDF" 
                                                    :options="optionsLapPPTPDF" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>  
                                    </div>


                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label for="">Laporan Bulanan</label>
                                                <multiselect 
                                                    v-model="model.laporanBulanan" 
                                                    :options="optionsBulanan" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-6">
                                        </div>
                                    </div>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="content-body" v-show="$route.params.type == 'kontrak-mtu'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary" style="text-transform: capitalize">Edit Akses Kontrak MTU</h3>
                                    <div class="row mt-2" :class="showDetailOptionsMTU ? '' : 'mb-5'">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="">Monitoring Kontrak MTU</label>
                                                <multiselect 
                                                    v-model="model.kontrakMTU" 
                                                    :options="optionsKontrakMTU" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="false" 
                                                    placeholder="" 
                                                    label="label" 
                                                    track-by="name" 
                                                    :preselect-first="false"
                                                    :searchable="false">                                                    
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                        </div>
                                    </div>
                                    <section v-if="showDetailOptionsMTU">
                                        <h6><b>Detail Monitoring Kontak MTU</b></h6>
                                        <hr>
                                        <div class="row mt-2 mb-3">
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Informasi Kontrak MTU</label>
                                                    <multiselect 
                                                        v-model="model.informasiKontrakMTU" 
                                                        :options="optionsInformasiKontrakMTU" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="false" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div class="form-group" id="akses">
                                                    <label for="">Dokumen Amandemen MTU</label>
                                                    <multiselect 
                                                        v-model="model.amandemenMTU" 
                                                        :options="optionsAmandemenMTU" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="false" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div class="form-group" id="akses">
                                                    <label for="">Dokumen Berita Acara Pembayaran MTU</label>
                                                    <multiselect 
                                                        v-model="model.bapMTU" 
                                                        :options="optionsBapMTU" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="false" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <div class="form-group" id="akses">
                                                    <label for="">Status Kontraktual MTU</label>
                                                    <multiselect 
                                                        v-model="model.statusKontraktualMTU" 
                                                        :options="optionsKontraktualMTU" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="false" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Kendala MTU</label>
                                                    <multiselect 
                                                        v-model="model.kendalaMTU" 
                                                        :options="optionsKendalaMTU" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Solusi MTU</label>
                                                    <multiselect 
                                                        v-model="model.solusiMTU" 
                                                        :options="optionsSolusiMTU" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">PO SE MTU</label>
                                                    <multiselect 
                                                        v-model="model.poseMTU" 
                                                        :options="optionsPoseMTU" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Resume MTU</label>
                                                    <multiselect 
                                                        v-model="model.resumeMTU" 
                                                        :options="optionsResumeMTU" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="">Dokumen Pendukung MTU</label>
                                                    <multiselect 
                                                        v-model="model.docPendukung" 
                                                        :options="optionsDocPendukung" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="content-body" v-show="$route.params.type == 'approval_progress'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary">Edit Akses Approval Progress</h3>
                                    <div class="row mt-2 mb-5">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Approval Progress</label>
                                                    <multiselect 
                                                        v-model="model.approvalProgress" 
                                                        :options="optionsApprovalProgress" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center mb-2">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="content-body" v-show="$route.params.type == 'upload_aki'">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary">Edit Akses Upload AI & AKI</h3>
                                    <div class="row mt-2 mb-5">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Upload AI & AKI</label>
                                                    <multiselect 
                                                        v-model="model.uploadAKI" 
                                                        :options="optionsUploadAKI" 
                                                        :multiple="true" 
                                                        :close-on-select="false" 
                                                        :clear-on-select="false" 
                                                        :preserve-search="true" 
                                                        placeholder="" 
                                                        label="label" 
                                                        track-by="name" 
                                                        :preselect-first="false"
                                                        :searchable="false">                                                    
                                                    </multiselect>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center mb-2">
                                        <button type="button" @click="save()" class="btn btn-primary btn-lg mb-1">
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </div>
</template>   

<script>

export default {
	data() {
		return {			
            title: "Role",
            breadcumb: [
                {
                    name: 'User & Role Management', 
                    link: '/role',
                    active: false
                },
                {
                    name: localStorage.getItem('type'),
                    link: '/role',
                    active: false
                },   
                {
                    name: 'Akses', 
                    link: '/akses',
                    active: false
                },
                {
                    name: 'Edit Akses', 
                    link: '',
                    active: true
                }                
            ],
            model: {
                kontrak             : [],
                informasiKontrak    : [],
                amandemen           : [],
                bap                 : [],           
                statusOperasi       : [],
                statusKontraktual   : [],
                statusSerahTerima   : [],
                progress            : [],
                kendala             : [],
                solusi              : [], 
                cctv                : [],    
                live_monitoring     : '',    
                edit_profile        : '',
                edit_password       : '',
                riwayat_project     : '',     
                laporanMonitoring   : [],
                laporanProject      : [], 
                laporanBulanan      : [],
                dashboard           : '',
                kontraktor          : [],
                jenis_pekerjaan     : [],
                jabatan             : [],
                unit                : [],
                bidang              : [],
                sub_bidang          : [],
                user                : [],
                permission          : '',
                user_pic            : [],
                embed               : [],
                laporanProgress     : [],
                laporanKendala      : [],
                laporanSolusi       : [],
                laporanAmandemen    : [],
                laporanBap          : [],
                scope               : [],
                pose                : [],

                // Phase 2
                laporanMTU          : [],
                laporanTelahSTP     : [],
                laporanTelahSTAP    : [],
                laporanTelahSTOP    : [],
                laporanTelahEnergize: [],
                laporanTelahBAYSTP  : [],
                laporanTelahSLO     : [],
                laporanTelahBAYSLO  : [],
                laporanPPTPDF       : [],
                kontrakMTU          : [],
                informasiKontrakMTU : [],
                amandemenMTU        : [],
                bapMTU              : [],
                kendalaMTU          : [],
                poseMTU             : [],
                solusiMTU           : [],
                resumeMTU           : [],
                docPendukung        : [],
                statusKontraktualMTU: [],
                mtuTerkait          : [],
                otherProject        : [],
                bgUangMuka          : [],
                bgPelaksanaan       : [],
                bgPemeliharaan      : [],
                dokumenKontrak      : [],
                dokumenKajianKelayakan      : [],
                dokumenLainLain      : [],
                approvalProgress    : [],
                uploadAKI    : [],
                amandemenStatus: [],
                bankGaransiStatus: [],
                anggaranStatus: [],
                progressMTUStatus: [],
            },
            optionsKontrak          : [
                { label: 'View', name: 'kontrak/read' },
                { label: 'Create', name: 'kontrak/create' },                
                { label: 'Delete', name: 'kontrak/delete' },
                { label: 'Show Detail', name: 'kontrak/detail' },
                { label: 'Update', name: 'kontrak/update' },
            ],
            optionsInformasiKontrak : [
                { label: 'Update Dokumen Permohonan/Rekomendasi', name: 'kontrak/detail/informasi/update_dokumen' },
                { label: 'Update SPMK', name: 'kontrak/detail/informasi/update_spmk' },
                { label: 'Read Dokumen Permohonan', name: 'kontrak/detail/informasi/read_permohonan' },
                { label: 'Read Dokumen Rekomendasi', name: 'kontrak/detail/informasi/read_rekomendasi' }
            ],
            optionsAmandemen        : [
                { label: 'View', name: 'kontrak/detail/amandemen/read' },
                { label: 'Show Detail', name: 'kontrak/detail/amandemen/detail' },
                { label: 'Create', name: 'kontrak/detail/amandemen/create' },
                { label: 'Update', name: 'kontrak/detail/amandemen/update' },        
                { label: 'Delete', name: 'kontrak/detail/amandemen/delete' },
            ],
            optionsBap              : [
                { label: 'View', name: 'kontrak/detail/bap/read' },
                { label: 'Create', name: 'kontrak/detail/bap/create' },
                { label: 'Update', name: 'kontrak/detail/bap/update' },        
                { label: 'Delete', name: 'kontrak/detail/bap/delete' },  
            ],
            optionsOperasi          : [
                { label: 'View', name: 'kontrak/detail/status_operasi/read' },
                { label: 'Show Detail', name: 'kontrak/detail/status_operasi/detail' },
                { label: 'Update Status RLB', name: 'kontrak/detail/status_operasi/update_rlb' },
                { label: 'Update Status Energize', name: 'kontrak/detail/status_operasi/update_energize' },
                { label: 'Update Status SLO', name: 'kontrak/detail/status_operasi/update_slo' }
            ],
            optionsKontraktual      : [
                { label: 'View', name: 'kontrak/detail/status_kontraktual/read' },
                { label: 'Show Detail', name: 'kontrak/detail/status_kontraktual/detail' },
                { label: 'Update Status BAST Tahap 1', name: 'kontrak/detail/status_kontraktual/update_bast_1' },
                { label: 'Update Status BAST Tahap 2', name: 'kontrak/detail/status_kontraktual/update_bast_2' },
                { label: 'Delete', name: 'kontrak/detail/status_kontraktual/delete' },
            ],
            optionsSerahTerima      : [
                { label: 'View', name: 'kontrak/detail/status_serah_terima/read' },
                { label: 'Show Detail', name: 'kontrak/detail/status_serah_terima/detail' },
                { label: 'Update Status Test Report', name: 'kontrak/detail/status_serah_terima/update_test' },
                { label: 'Update Status Manual Book', name: 'kontrak/detail/status_serah_terima/update_manual' },
                { label: 'Update Status As Build Drawing', name: 'kontrak/detail/status_serah_terima/update_build' },
                // { label: 'Update Status SLO', name: 'kontrak/detail/status_serah_terima/update_slo' },
                { label: 'Update Status Surat Persetujuan STOP', name: 'kontrak/detail/status_serah_terima/update_surat_stop' },
                { label: 'Update Status Berita Acara STOP', name: 'kontrak/detail/status_serah_terima/update_bap_stop' },
                { label: 'Update Status Daftar Inventarisasi Aset', name: 'kontrak/detail/status_serah_terima/update_daftar_inventaris' },
                { label: 'Update Status Berita Acara Pending', name: 'kontrak/detail/status_serah_terima/update_bap_pending' },
                { label: 'Update Status Berita Acara STAP', name: 'kontrak/detail/status_serah_terima/update_bap_stap' },
                { label: 'Update Status PCR / Executive Summary', name: 'kontrak/detail/status_serah_terima/update_pcr' },
                { label: 'Update Status Dokumen Tanah', name: 'kontrak/detail/status_serah_terima/update_dok_tanah' },
                { label: 'Update Status Dokumen Perizinan', name: 'kontrak/detail/status_serah_terima/update_dok_perizinan' },
                { label: 'Update Status Dokumen Lingkungan', name: 'kontrak/detail/status_serah_terima/update_dok_lingkungan' },
                { label: 'Update Status Berita Acara Penyelesaian Pending', name: 'kontrak/detail/status_serah_terima/update_bap_selesai_pending' },
                { label: 'Update Status Berita Acara STP', name: 'kontrak/detail/status_serah_terima/update_stp' },
            ],
            optionsSolusi           : [
                { label: 'View', name: 'kontrak/detail/solusi/read' },
                { label: 'Edit Solusi', name: 'kontrak/detail/solusi/update' },
                { label: 'Tambah Solusi', name: 'kontrak/detail/solusi/create' },
                { label: 'Hapus Solusi', name: 'kontrak/detail/solusi/delete' }
            ],
            optionsKendala          : [
                { label: 'View', name: 'kontrak/detail/kendala/read' },
                { label: 'Edit Kendala', name: 'kontrak/detail/kendala/update' },
                { label: 'Tambah Kendala', name: 'kontrak/detail/kendala/create' },
                { label: 'Hapus Kendala', name: 'kontrak/detail/kendala/delete' }
            ],
            optionsProgress         : [
                { label: 'View', name: 'kontrak/detail/progress/read' },                
                { label: 'Tambah Progress', name: 'kontrak/detail/progress/create' },
                { label: 'Edit Progress', name: 'kontrak/detail/progress/update' },     
                { label: 'Generate Progress', name: 'kontrak/detail/progress/generate' },           
                { label: 'Hapus Progress', name: 'kontrak/detail/progress/delete' }
            ],
            optionsScope            : [
                { label: 'Create', name: 'kontrak/detail/scope/create' },
                { label: 'View', name: 'kontrak/detail/scope/read' },         
                { label: 'Update', name: 'kontrak/detail/scope/update' },
                { label: 'Delete', name: 'kontrak/detail/scope/delete' },
                { label: 'Detail', name: 'kontrak/detail/scope/detail' }
            ],
            optionsCCTV             : [
                { label: 'Create', name: 'cctv/create' },
                { label: 'View', name: 'cctv/read' },         
                { label: 'Update', name: 'cctv/update' },                       
                { label: 'Delete', name: 'cctv/delete' },
                { label: 'Show Detail', name: 'cctv/detail' }        
            ],
            optionsMonitoring       : [
                { label: 'View', name: 'laporan/monitoring/read' },
                { label: 'Generate Laporan', name: 'laporan/monitoring/generate' },
            ],
            optionsProject          : [
                { label: 'View', name: 'laporan/project/read' },
                { label: 'Generate Laporan', name: 'laporan/project/generate' },
            ], 
            optionsLaporanProgress : [
                { label: 'View', name : 'laporan/progress/read' },
                { label: 'Generate Laporan', name : 'laporan/progress/generate' },
            ],
            optionsLaporanKendala : [
                { label: 'View', name : 'laporan/kendala/read' },
                { label: 'Generate Laporan', name : 'laporan/kendala/generate' },
            ],
            optionsLaporanSolusi : [
                { label: 'View', name : 'laporan/solusi/read' },
                { label: 'Generate Laporan', name : 'laporan/solusi/generate' },
            ],
            optionsLaporanAmandemen : [
                { label: 'View', name : 'laporan/amandemen/read' },
                { label: 'Generate Laporan', name : 'laporan/amandemen/generate' },
            ],
            optionsLaporanBap : [
                { label: 'View', name : 'laporan/bap/read' },
                { label: 'Generate Laporan', name : 'laporan/bap/generate' },
            ],                                                            
            optionsBulanan          : [
                { label: 'View', name: 'laporan/bulanan/read' },
                { label: 'Cetak Excel', name: 'laporan/bulanan/cetak' },
                { label: 'Download', name: 'laporan/bulanan/download' },
            ], 
            optionsKontraktor       : [
                { label: 'Create', name: 'kontraktor/create' },
                { label: 'View', name: 'kontraktor/read' },         
                { label: 'Update', name: 'kontraktor/update' },                       
                { label: 'Delete', name: 'kontraktor/delete' },
                { label: 'Show Detail', name: 'kontraktor/detail' } 
            ],
            optionsJenis_pekerjaan  : [
                { label: 'Create', name: 'jenis_pekerjaan/create' },
                { label: 'View', name: 'jenis_pekerjaan/read' },         
                { label: 'Update', name: 'jenis_pekerjaan/update' },                       
                { label: 'Delete', name: 'jenis_pekerjaan/delete' },
                { label: 'Show Detail', name: 'jenis_pekerjaan/detail' } 
            ],
            optionsJabatan          : [
                { label: 'Create', name: 'jabatan/create' },
                { label: 'View', name: 'jabatan/read' },         
                { label: 'Update', name: 'jabatan/update' },                       
                { label: 'Delete', name: 'jabatan/delete' },
                { label: 'Show Detail', name: 'jabatan/detail' } 
            ],
            optionsUnit             : [
                { label: 'Create', name: 'unit/create' },
                { label: 'View', name: 'unit/read' },         
                { label: 'Update', name: 'unit/update' },                       
                { label: 'Delete', name: 'unit/delete' },
                { label: 'Show Detail', name: 'unit/detail' } 
            ],
            optionsBidang           : [
                { label: 'Create', name: 'bidang/create' },
                { label: 'View', name: 'bidang/read' },         
                { label: 'Update', name: 'bidang/update' },                       
                { label: 'Delete', name: 'bidang/delete' },
                { label: 'Show Detail', name: 'bidang/detail' } 
            ],
            optionsSub_bidang       : [
                { label: 'Create', name: 'sub_bidang/create' },
                { label: 'View', name: 'sub_bidang/read' },         
                { label: 'Update', name: 'sub_bidang/update' },                       
                { label: 'Delete', name: 'sub_bidang/delete' },
                { label: 'Show Detail', name: 'sub_bidang/detail' } 
            ],
            optionsUser             : [
                { label: 'Create', name: 'user/create' },
                { label: 'View', name: 'user/read' },         
                { label: 'Update', name: 'user/update' },                       
                { label: 'Delete', name: 'user/delete' },
                { label: 'Show Detail', name: 'user/detail' },
                { label: 'Export', name: 'user/export' }
            ],
            optionsUserPIC          : [
                { label: 'Create', name: 'user_pic/create' },
                { label: 'View', name: 'user_pic/read' },         
                { label: 'Update', name: 'user_pic/update' },                       
                { label: 'Delete', name: 'user_pic/delete' },
                { label: 'Show Detail', name: 'user_pic/detail' }
            ],
            optionsEmbed            : [
                { label: 'Create', name: 'embed/create' },
                { label: 'View', name: 'embed/read' },         
                { label: 'Update', name: 'embed/update' },                       
                { label: 'Delete', name: 'embed/delete' },
                { label: 'Show Detail', name: 'embed/detail' }
            ],
            optionsPose            : [
                { label: 'Create', name: 'kontrak/detail/pose/create' },
                { label: 'View', name: 'kontrak/detail/pose/read' },         
                { label: 'Update', name: 'kontrak/detail/pose/update' },                       
                { label: 'Delete', name: 'kontrak/detail/pose/delete' }
            ],

            //Phase 2
            optionsLaporanMTU             : [
                { label: 'View', name : 'laporan/mtu_kontrak/read' },
                { label: 'Generate Laporan', name : 'laporan/mtu_kontrak/excel' },
            ],
            optionsLapTelahSTP             : [
                { label: 'View', name : 'laporan/stp/read' },
                { label: 'Generate Laporan', name : 'laporan/stp/excel' },
            ],

            optionsLapTelahSTAP            : [
                { label: 'View', name : 'laporan/stap/read' },
                { label: 'Generate Laporan', name : 'laporan/stap/excel' },
            ],

            optionsLapTelahSTOP             : [
                { label: 'View', name : 'laporan/stop/read' },
                { label: 'Generate Laporan', name : 'laporan/stop/excel' },
            ],

            optionsLapTelahEnergize         : [
                { label: 'View', name : 'laporan/energize/read' },
                { label: 'Generate Laporan', name : 'laporan/energize/excel' },
            ],

            optionsLapTelahBAYSTP           : [
                { label: 'View', name : 'laporan/bay_stp/read' },
                { label: 'Generate Laporan', name : 'laporan/bay_stp/excel' },
            ],

            optionsLapTelahSLO              : [
                { label: 'View', name : 'laporan/slo/read' },
                { label: 'Generate Laporan', name : 'laporan/slo/excel' },
            ],

            optionsLapTelahBAYSLO           : [
                { label: 'View', name : 'laporan/bay_slo/read' },
                { label: 'Generate Laporan', name : 'laporan/bay_slo/excel' },
            ],

            optionsLapPPTPDF                : [
                { label: 'View', name : 'laporan/ppt_pdf/read' },
                { label: 'Cetak PPT', name : 'laporan/ppt_pdf/ppt' },
                { label: 'Cetak PDF', name : 'laporan/ppt_pdf/pdf' },
            ],

            optionsKontrakMTU                : [
                { label: 'View', name : 'kontrak_mtu/read' },
                { label: 'Tambah', name : 'kontrak_mtu/create' },
                { label: 'Edit', name : 'kontrak_mtu/update' },
                { label: 'Hapus', name : 'kontrak_mtu/delete' },
                { label: 'Show Detail', name : 'kontrak_mtu/detail' },
                { label: 'Cetak Excel', name : 'kontrak_mtu/excel' },
            ],

            optionsInformasiKontrakMTU                : [
                { label: 'Detail Kontrak', name : 'kontrak_mtu/detail/informasi/kontrak' },
                { label: 'Nilai Kontrak', name : 'kontrak_mtu/detail/informasi/nilai_kontrak' },
                { label: 'Anggaran', name : 'kontrak_mtu/detail/informasi/anggaran' },
            ],

            optionsAmandemenMTU        : [
                { label: 'View', name: 'kontrak_mtu/detail/amandemen/read' },
                { label: 'Show Detail', name: 'kontrak_mtu/detail/amandemen/detail' },
                { label: 'Create', name: 'kontrak_mtu/detail/amandemen/create' },
                { label: 'Update', name: 'kontrak_mtu/detail/amandemen/update' },        
                { label: 'Hapus', name: 'kontrak_mtu/detail/amandemen/delete' },
            ],

            optionsBapMTU                  : [
                { label: 'View', name: 'kontrak_mtu/detail/bap/read' },
                { label: 'Create', name: 'kontrak_mtu/detail/bap/create' },
                { label: 'Update', name: 'kontrak_mtu/detail/bap/update' },        
                { label: 'Hapus', name: 'kontrak_mtu/detail/bap/delete' }, 
                { label: 'Show Detail', name: 'kontrak_mtu/detail/bap/detail' }, 
            ],

            optionsKontraktualMTU      : [
                { label: 'View', name: 'kontrak_mtu/detail/status_kontraktual/read' },
                { label: 'Create', name: 'kontrak_mtu/detail/status_kontraktual/create' },
                { label: 'Update', name: 'kontrak_mtu/detail/status_kontraktual/update' },
                // { label: 'Hapus', name: 'kontrak_mtu/detail/status_kontraktual/delete' },
            ],

            optionsKendalaMTU      : [
                { label: 'View', name: 'kontrak_mtu/detail/kendala/read' },
                { label: 'Create', name: 'kontrak_mtu/detail/kendala/create' },
                { label: 'Update', name: 'kontrak_mtu/detail/kendala/update' },
                { label: 'Hapus', name: 'kontrak_mtu/detail/kendala/delete' },
            ],

            optionsSolusiMTU      : [
                { label: 'View', name: 'kontrak_mtu/detail/solusi/read' },
                { label: 'Create', name: 'kontrak_mtu/detail/solusi/create' },
                { label: 'Update', name: 'kontrak_mtu/detail/solusi/update' },
                { label: 'Hapus', name: 'kontrak_mtu/detail/solusi/delete' },
            ],

            optionsPoseMTU      : [
                { label: 'View', name: 'kontrak_mtu/detail/pose/read' },
                { label: 'Create', name: 'kontrak_mtu/detail/pose/create' },
                { label: 'Update', name: 'kontrak_mtu/detail/pose/update' },
                { label: 'Hapus', name: 'kontrak_mtu/detail/pose/delete' },
            ],

            optionsResumeMTU      : [
                { label: 'View', name: 'kontrak_mtu/detail/resume/read' },
                { label: 'Create', name: 'kontrak_mtu/detail/resume/create' },
                { label: 'Update', name: 'kontrak_mtu/detail/resume/update' },
                // { label: 'Hapus', name: 'kontrak_mtu/detail/resume/delete' },
            ],

            optionsDocPendukung      : [
                { label: 'View', name: 'kontrak_mtu/detail/doc_pendukung/read' },
                { label: 'Create', name: 'kontrak_mtu/detail/doc_pendukung/create' },
                { label: 'Update', name: 'kontrak_mtu/detail/doc_pendukung/update' },
                // { label: 'Hapus', name: 'kontrak_mtu/detail/doc_pendukung/delete' },
            ],

            optionsMTUTerkait      : [
                { label: 'View', name: 'kontrak/detail/kontrak_mtu/read' },
                { label: 'Show Detail', name: 'kontrak/detail/kontrak_mtu/detail' },
            ],

            optionsOtherProject      : [
                { label: 'View', name: 'kontrak/other_project/read' },
            ],

            optionsBgUangMuka                : [
                { label: 'View', name : 'kontrak/detail/bg_uang_muka/read' },
                { label: 'Tambah', name : 'kontrak/detail/bg_uang_muka/create' },
                { label: 'Edit', name : 'kontrak/detail/bg_uang_muka/update' },
                { label: 'Hapus', name : 'kontrak/detail/bg_uang_muka/delete' },
                { label: 'Show Detail', name : 'kontrak/detail/bg_uang_muka/detail' },
                { label: 'Force Update', name : 'kontrak/detail/bg_uang_muka/force_update' },
            ],

            optionsBgPelaksanaan                : [
                { label: 'View', name : 'kontrak/detail/bg_pelaksanaan/read' },
                { label: 'Tambah', name : 'kontrak/detail/bg_pelaksanaan/create' },
                { label: 'Edit', name : 'kontrak/detail/bg_pelaksanaan/update' },
                { label: 'Hapus', name : 'kontrak/detail/bg_pelaksanaan/delete' },
                { label: 'Show Detail', name : 'kontrak/detail/bg_pelaksanaan/detail' },
                { label: 'Force Update', name : 'kontrak/detail/bg_pelaksanaan/force_update' },
            ],

            optionsBgPemeliharaan                : [
                { label: 'View', name : 'kontrak/detail/bg_pemeliharaan/read' },
                { label: 'Tambah', name : 'kontrak/detail/bg_pemeliharaan/create' },
                { label: 'Edit', name : 'kontrak/detail/bg_pemeliharaan/update' },
                { label: 'Hapus', name : 'kontrak/detail/bg_pemeliharaan/delete' },
                { label: 'Show Detail', name : 'kontrak/detail/bg_pemeliharaan/detail' },
                { label: 'Force Update', name : 'kontrak/detail/bg_pemeliharaan/force_update' },
            ],
            optionsDokumenKontrak                : [
                { label: 'View', name : 'kontrak/detail/dokumenKontrak/read' },
                { label: 'Tambah', name : 'kontrak/detail/dokumenKontrak/create' },
                { label: 'Edit', name : 'kontrak/detail/dokumenKontrak/update' },
                { label: 'Hapus', name : 'kontrak/detail/dokumenKontrak/delete' },
            ],

            optionsDokumenKajianKelayakan                : [
                { label: 'View', name : 'kontrak/detail/dokumenKajianKelayakan/read' },
                { label: 'Tambah', name : 'kontrak/detail/dokumenKajianKelayakan/create' },
                { label: 'Edit', name : 'kontrak/detail/dokumenKajianKelayakan/update' },
                { label: 'Hapus', name : 'kontrak/detail/dokumenKajianKelayakan/delete' },
            ],

            optionsDokumenLainLain                : [
                { label: 'View', name : 'kontrak/detail/dokumenLainLain/read' },
                { label: 'Tambah', name : 'kontrak/detail/dokumenLainLain/create' },
                { label: 'Edit', name : 'kontrak/detail/dokumenLainLain/update' },
                { label: 'Hapus', name : 'kontrak/detail/dokumenLainLain/delete' },
            ],

            optionsApprovalProgress                : [
                { label: 'View', name : 'approval_progress/read' },
                { label: 'Show Detail', name : 'approval_progress/detail' },
                { label: 'Cetak Excel', name : 'approval_progress/export_excell' },
            ],

            optionsUploadAKI                : [
                { label: 'View', name : 'upload/ai_aki/read' },
                { label: 'Upload AI & AKI', name : 'upload/ai_aki/upload' },
            ],

            optionsAmandemenStatus : [
                { label: 'Create', name: 'amandemen_status/create' },
                { label: 'View', name: 'amandemen_status/read' },         
                { label: 'Update', name: 'amandemen_status/update' },                       
                { label: 'Delete', name: 'amandemen_status/delete' },
                { label: 'Show Detail', name: 'amandemen_status/detail' } 
            ],

            optionsBankGaransiStatus : [
                { label: 'Create', name: 'bank_garansi_status/create' },
                { label: 'View', name: 'bank_garansi_status/read' },         
                { label: 'Update', name: 'bank_garansi_status/update' },                       
                { label: 'Delete', name: 'bank_garansi_status/delete' },
                { label: 'Show Detail', name: 'bank_garansi_status/detail' } 
            ],

            optionsAnggaranStatus : [
                { label: 'Create', name: 'anggaran_status/create' },
                { label: 'View', name: 'anggaran_status/read' },         
                { label: 'Update', name: 'anggaran_status/update' },                       
                { label: 'Delete', name: 'anggaran_status/delete' },
                { label: 'Show Detail', name: 'anggaran_status/detail' } 
            ],

            optionsProgressMTUStatus : [
                { label: 'Create', name: 'progress_mtu_status/create' },
                { label: 'View', name: 'progress_mtu_status/read' },         
                { label: 'Update', name: 'progress_mtu_status/update' },                       
                { label: 'Delete', name: 'progress_mtu_status/delete' },
                { label: 'Show Detail', name: 'progress_mtu_status/detail' } 
            ],

            convertPermissions: '',
            showDetailOptions: false,
            showDetailOptionsMTU: false,
            role_id: localStorage.getItem('role_id')
		}
    },
    methods: {
        get() {
            let _ = this
            let data = {
                role_id: localStorage.getItem('role')
            }
            _.axios.post('/role/role-permission', data)
                .then(resp=>{
                    let data = resp.data.data

                    data.forEach(el => {
                        if (el.name == "live_monitoring/read"){
                            _.model.live_monitoring = "live_monitoring/read"
                        } 

                        if (el.name == "permission/read"){
                            _.model.permission = "permission/read"
                        }

                        if (el.name == "dashboard/read"){
                            _.model.dashboard = "dashboard/read"
                        }

                        _.optionsCCTV.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.cctv.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsKontraktor.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.kontraktor.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsJenis_pekerjaan.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.jenis_pekerjaan.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsJabatan.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.jabatan.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsUnit.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.unit.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsBidang.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.bidang.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsSub_bidang.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.sub_bidang.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsUser.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.user.push({label: elm.label, name: elm.name})
                            }
                        });                        

                        _.optionsKontrak.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.kontrak.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsInformasiKontrak.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.informasiKontrak.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsMonitoring.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanMonitoring.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsProject.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanProject.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsBulanan.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanBulanan.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsOperasi.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.statusOperasi.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsAmandemen.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.amandemen.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsBap.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.bap.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsKontraktual.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.statusKontraktual.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsSerahTerima.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.statusSerahTerima.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsSolusi.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.solusi.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsKendala.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.kendala.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsProgress.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.progress.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsUserPIC.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.user_pic.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsEmbed.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.embed.push({label: elm.label, name: elm.name})
                            }
                        });

                        _.optionsLaporanProgress.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanProgress.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLaporanKendala.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanKendala.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLaporanSolusi.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanSolusi.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLaporanAmandemen.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanAmandemen.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLaporanBap.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanBap.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsScope.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.scope.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsPose.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.pose.push({label: elm.label, name: elm.name})
                            }
                        })

                        // Phase 2
                        _.optionsLaporanMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanMTU.push({label: elm.label, name: elm.name})
                            }
                        })
                        _.optionsLapTelahSTP.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanTelahSTP.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLapTelahSTAP.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanTelahSTAP.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLapTelahSTOP.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanTelahSTOP.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLapTelahEnergize.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanTelahEnergize.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLapTelahBAYSTP.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanTelahBAYSTP.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLapTelahSLO.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanTelahSLO.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLapTelahBAYSLO.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanTelahBAYSLO.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsLapPPTPDF.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.laporanPPTPDF.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsKontrakMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.kontrakMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsInformasiKontrakMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.informasiKontrakMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsAmandemenMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.amandemenMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsBapMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.bapMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsKontraktualMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.statusKontraktualMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsKendalaMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.kendalaMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsSolusiMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.solusiMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsPoseMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.poseMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsResumeMTU.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.resumeMTU.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsDocPendukung.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.docPendukung.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsMTUTerkait.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.mtuTerkait.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsOtherProject.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.otherProject.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsBgUangMuka.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.bgUangMuka.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsBgPelaksanaan.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.bgPelaksanaan.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsBgPemeliharaan.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.bgPemeliharaan.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsDokumenKontrak.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.dokumenKontrak.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsDokumenKajianKelayakan.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.dokumenKajianKelayakan.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsDokumenLainLain.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.dokumenLainLain.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsApprovalProgress.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.approvalProgress.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsUploadAKI.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.uploadAKI.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsAmandemenStatus.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.amandemenStatus.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsBankGaransiStatus.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.bankGaransiStatus.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsAnggaranStatus.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.anggaranStatus.push({label: elm.label, name: elm.name})
                            }
                        })

                        _.optionsProgressMTUStatus.forEach(elm => {
                            if (el.name == elm.name) {
                                _.model.progressMTUStatus.push({label: elm.label, name: elm.name})
                            }
                        })

                    });                   
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            let data = {
                role_id: localStorage.getItem('role'),
                permissions: _.convertPermissions
            }
            _.axios.post('/role/set-role-permission', data)
                .then(resp => {
                    if (resp.status) {
                        _.$router.push('/akses')
                    }else{
                        console.log("Failed")
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        convert(){
            this.convertPermissions = '' 
            this.showDetailOptions = false
            this.showDetailOptionsMTU = false
            
            if ( this.model.live_monitoring != ''){
                this.convertPermissions += this.model.live_monitoring + ","
            }
            if ( this.model.edit_profile != ''){
                this.convertPermissions += this.model.edit_profile + ","
            }
            if ( this.model.edit_password != ''){
                this.convertPermissions += this.model.edit_password + ","
            }
            if ( this.model.riwayat_project != ''){
                this.convertPermissions += this.model.riwayat_project + ","
            }
            if ( this.model.dashboard != ''){
                this.convertPermissions += this.model.dashboard + ","
            }
            if ( this.model.permission != ''){
                this.convertPermissions += this.model.permission + ","
            }
            
            this.model.kontrak.forEach(el => {
                this.convertPermissions += el.name + ','
                if (el.name == 'kontrak/detail') {
                    this.showDetailOptions = true
                }
            });
            this.model.informasiKontrak.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.statusOperasi.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.statusKontraktual.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.statusSerahTerima.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.progress.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.kendala.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.solusi.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanMonitoring.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanProject.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanBulanan.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanProgress.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanKendala.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanSolusi.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanAmandemen.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanBap.forEach(el => {
                this.convertPermissions += el.name + ','
            });            
            this.model.cctv.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.kontraktor.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.jenis_pekerjaan.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.jabatan.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.unit.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.bidang.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.sub_bidang.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.user.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.amandemen.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.bap.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.user_pic.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.embed.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.scope.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.pose.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            // Phase 2 
            this.model.laporanMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanTelahSTP.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanTelahSTAP.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanTelahSTOP.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanTelahEnergize.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanTelahBAYSTP.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanTelahSLO.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanTelahBAYSLO.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.laporanPPTPDF.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.kontrakMTU.forEach(el => {
                this.convertPermissions += el.name + ','
                if (el.name == 'kontrak_mtu/detail') {
                    this.showDetailOptionsMTU = true
                }
            });

            this.model.informasiKontrakMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.amandemenMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.bapMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            this.model.statusKontraktualMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.kendalaMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.solusiMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.poseMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.resumeMTU.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.docPendukung.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.mtuTerkait.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.otherProject.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.bgUangMuka.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.bgPelaksanaan.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.bgPemeliharaan.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.dokumenKontrak.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.dokumenKajianKelayakan.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.dokumenLainLain.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.approvalProgress.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.uploadAKI.forEach(el => {
                this.convertPermissions += el.name + ','
            });

            this.model.amandemenStatus.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            
            this.model.bankGaransiStatus.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            
            this.model.anggaranStatus.forEach(el => {
                this.convertPermissions += el.name + ','
            });
            
            this.model.progressMTUStatus.forEach(el => {
                this.convertPermissions += el.name + ','
            });
        }
    },
    mounted() {
        let _ = this
        _.get()
    },
    created(){
        if (localStorage.getItem('type') != 'kontraktor') {
            this.optionsProgress.push({ label: 'Approve Laporan '+ (localStorage.getItem('type') == 'pengawas' ? 'Kontraktor' : 'Pengawas'), name: 'kontrak/detail/progress/approve' })
        }
    },
    watch: {
        'model.kontrak': function(){      
            this.convert()  
        },
        'model.informasiKontrak': function(){      
            this.convert()  
        },
        'model.statusOperasi': function(){      
            this.convert()  
        },
        'model.statusKontraktual': function(){      
            this.convert()  
        },
        'model.statusSerahTerima': function(){      
            this.convert()  
        },
        'model.progress': function(){      
            this.convert()  
        },
        'model.kendala': function(){      
            this.convert()  
        },
        'model.solusi': function(){      
            this.convert()  
        },
        'model.cctv': function(){      
            this.convert()  
        },
        'model.live_monitoring': function(){      
            this.convert()  
        },
        'model.edit_profile': function(){      
            this.convert()  
        },
        'model.edit_password': function(){      
            this.convert()  
        },
        'model.riwayat_project': function(){      
            this.convert()  
        },
        'model.laporanMonitoring': function(){      
            this.convert()  
        },
        'model.laporanProject': function(){      
            this.convert()  
        },
        'model.laporanBulanan': function(){      
            this.convert()  
        },
        'model.laporanProgress' : function(){
            this.convert()
        },
        'model.laporanKendala' : function(){
            this.convert()
        },
        'model.laporanSolusi' : function(){
            this.convert()
        },
        'model.laporanAmandemen' : function(){
            this.convert()
        },
        'model.laporanBap' : function(){
            this.convert()
        },
        'model.dashboard' : function() {
            this.convert()
        },
        'model.kontraktor' : function() {
            this.convert()
        },
        'model.jenis_pekerjaan' : function() {
            this.convert()
        },
        'model.jabatan' : function() {
            this.convert()
        },
        'model.unit' : function() {
            this.convert()
        },
        'model.bidang' : function() {
            this.convert()
        },
        'model.sub_bidang' : function() {
            this.convert()
        },
        'model.user' : function() {
            this.convert()
        },
        'model.permission' : function() {
            this.convert()
        },
        'model.amandemen' : function() {
            this.convert()
        },
        'model.bap' : function() {
            this.convert()
        },
        'model.user_pic' : function() {
            this.convert()
        },
        'model.embed' : function() {
            this.convert()
        },
        'model.scope' : function() {
            this.convert()
        },
        'model.pose' : function() {
            this.convert()
        },

        // Phase 2
        'model.laporanMTU': function(){      
            this.convert()  
        },
        'model.laporanTelahSTP': function(){      
            this.convert()  
        },
        'model.laporanTelahSTAP': function(){      
            this.convert()  
        },
        'model.laporanTelahSTOP': function(){      
            this.convert()  
        },
        'model.laporanTelahEnergize': function(){      
            this.convert()  
        },
        'model.laporanTelahBAYSTP': function(){      
            this.convert()  
        },
        'model.laporanTelahSLO': function(){      
            this.convert()  
        },
        'model.laporanTelahBAYSLO': function(){      
            this.convert()  
        },
        'model.laporanPPTPDF': function(){      
            this.convert()  
        },
        'model.kontrakMTU': function(){      
            this.convert()  
        },
        'model.informasiKontrakMTU': function(){      
            this.convert()  
        },
        'model.amandemenMTU': function(){      
            this.convert()  
        },
        'model.bapMTU': function(){      
            this.convert()  
        },
        'model.statusKontraktualMTU': function(){      
            this.convert()  
        },
        'model.kendalaMTU': function(){      
            this.convert()  
        },
        'model.solusiMTU': function(){      
            this.convert()  
        },
        'model.poseMTU': function(){      
            this.convert()  
        },
        'model.resumeMTU': function(){      
            this.convert()  
        },
        'model.docPendukung': function(){      
            this.convert()  
        },
        'model.mtuTerkait': function(){      
            this.convert()  
        },
        'model.otherProject': function(){      
            this.convert()  
        },

        'model.bgUangMuka': function(){      
            this.convert()  
        },

        'model.bgPelaksanaan': function(){      
            this.convert()  
        },

        'model.bgPemeliharaan': function(){      
            this.convert()  
        },

        'model.dokumenKontrak': function(){      
            this.convert()  
        },

        'model.dokumenKajianKelayakan': function(){      
            this.convert()  
        },

        'model.dokumenLainLain': function(){      
            this.convert()  
        },

        'model.approvalProgress': function(){      
            this.convert()  
        },

        'model.uploadAKI': function(){      
            this.convert()  
        },

        'model.amandemenStatus': function(){      
            this.convert()  
        },

        'model.bankGaransiStatus': function(){      
            this.convert()  
        },

        'model.anggaranStatus': function(){      
            this.convert()  
        },

        'model.progressMTUStatus': function(){      
            this.convert()  
        },

    }
}
</script>         