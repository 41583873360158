<template>
  <div>
    <div class="content-header">
        <div class="content-header-left mb-2">
            <div class="breadcrumbs-top">
                <h2 class="content-header-title mb-0">Kapasitas MVA</h2>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                <i class="feather icon-home"></i>
                            </router-link>
                        </li>

                        <li class="breadcrumb-item active">Project List</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body card-dashboard">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row align-items-center justify-content-between">
                                            <div class="col mb-1">
                                                <div class="btn-responsive">
                                                   <div class="d-inline-flex align-items-center" style="gap: 15px">
                                                        <!-- <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target="#Modal_Filter_">
                                                            Filter
                                                        </button>
                                                        <h4 class="d-inline m-0">
                                                            <div class="badge badge-rounded-md badge-warning badge-md">
                                                                10% Pencapaian
                                                            </div>
                                                        </h4> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-4 mb-1">
                                                <div class="input-group form-search-rounded">
                                                    <input v-model="payloadList.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari disini..">
                                                    <span class="input-group-append">
                                                        <div class="input-group-text bg-transparent">
                                                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div v-if="loadStateList" class="col-12">
                                                <div class="wrap-table-leaderboard">
                                                    <el-table stripe empty-text="No Data"  :data="tableData" style="width: 100%" class="table-ipm-theme">
                                                        <el-table-column width="90" fixed>
                                                            <template #header>
                                                                <span class="text-primary text-center d-block"><strong>No</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <a style="display: block; text-align: center; cursor: normal">
                                                                    {{ e.row.no }}
                                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g filter="url(#filter0_d_939_5634)">
                                                                        <path d="M21.0711 20.0711C22.9464 18.1957 24 15.6522 24 13C24 10.3478 22.9464 7.8043 21.0711 5.92893C19.1957 4.05357 16.6522 3 14 3C11.3478 3 8.8043 4.05357 6.92893 5.92893C5.05357 7.8043 4 10.3478 4 13C4 15.6522 5.05357 18.1957 6.92893 20.0711C8.8043 21.9464 11.3478 23 14 23C16.6522 23 19.1957 21.9464 21.0711 20.0711Z" fill="white"/>
                                                                        </g>
                                                                        <path d="M14.1453 8.62457C12.8539 7.88664 11.4966 7.81762 10.1914 8.40676V8H9.60547V18H10.1914V14.3529C11.3939 13.7221 12.6568 13.7418 13.8547 14.4262C14.5731 14.8368 15.3118 15.0419 16.0508 15.0419C16.7895 15.0419 17.5285 14.8368 18.2469 14.4262L18.3945 14.3418V8.37395L17.9562 8.62457C16.71 9.33695 15.3922 9.33695 14.1453 8.62457Z" fill="#EC2F39"/>
                                                                        <defs>
                                                                        <filter id="filter0_d_939_5634" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                                        <feOffset dy="1"/>
                                                                        <feGaussianBlur stdDeviation="2"/>
                                                                        <feComposite in2="hardAlpha" operator="out"/>
                                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.12 0"/>
                                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_939_5634"/>
                                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_939_5634" result="shape"/>
                                                                        </filter>
                                                                        </defs>
                                                                    </svg>
                                                                </a>
                                                            </template>
                                                        </el-table-column>
                                                       
                                                        <el-table-column fixed width="350">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Nama Project</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <router-link :to="'#'" style="display: block;">
                                                                    <a style="display: block;" class="text-blue">{{ e.row.judul_kontrak }}</a>
                                                                </router-link>
                                                            </template>
                                                        </el-table-column>

                                                         <el-table-column width="250">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>No. Kontrak</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <span class="no-decoration" v-html="e.row.no_kontrak"></span>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column width="150">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Jenis Pekerjaan</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <a class="text-blue"><strong>{{ e.row.jenis_pekerjaan }}</strong></a>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="150">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Kapasitas MVA</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <a style="display: block; color: black !important;">{{ e.row.kapasitas | numFormat }}</a>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                            </div>
                                            <div v-else class="col-12">
                                                <div class="mx-auto text-center" style="width:100%">
                                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2" style="text-align: end">
                                        <paginate
                                            :page-count="total_page"
                                            :click-handler="paginateCb"
                                            :prev-text="'Prev'"
                                            :next-text="'Next'"
                                            container-class="pagination-v2">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div 
        class="modal fade text-left" 
        id="Modal_Filter" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Filter</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider name="UPP" rules="required" v-slot="{ errors }">
                                    <label for="">Status</label>
                                    <select v-model="payloadList.status_kontraktual" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <option :value="null">Pilih Status</option>
                                        <option v-for="(opt, idx) in optList" :key="idx" :value="opt.value">{{ opt.text }}</option>
                                    </select>
                                    <small>{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="reset" @click="resetFilter()" class="btn btn-outline-primary" data-dismiss="modal">Reset</button>
                        <button v-if="true" @click="loadList()" type="button" :disabled="invalid" class="btn btn-primary">
                            <span>Filter</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ld from 'lodash'
import paginate from 'vuejs-paginate'
// import moment from 'moment'

export default {
    name: 'PageKapasitasMVAAll',
    components: {paginate},
    data() {
        return {
            role_id: localStorage.getItem('role_id'),
            doc_url: null,
            loadStateList: false,
            loadStateExport: true,
            optList: [
                {
                    value: 'On Going',
                    text: 'On Going'
                },
                {
                    value: 'Serah Terima II',
                    text: 'Serah Terima II'
                }
            ],
            payloadList: {
                per_page: 10,
                search: '',
                date_start: '',
                date_end: '',
                page: 1,
            },
            total_page: 0,
            tableData: []
        }
    },
    watch: {
        'payloadList.search' : ld.debounce (function () {
            this.payloadList.page = 1
            this.loadList ()
        }, 300)
    },
    mounted () {
        // var start_date = moment().startOf('year').format('YYYY-MM-DD')
        // var end_date = moment().format('YYYY-MM-DD')

        // this.payloadList.date_start = this.$route.query?.start ?? start_date
        // this.payloadList.date_end = this.$route.query?.end ?? end_date
        // this.payloadList.status_kontraktual = this.$route?.query?.filter ?? null
        this.loadList ()
    },
    methods: {
        async loadList () {
            this.loadStateList = false
            try {
                const datas = (await this.axios.get('/v2/dashboard/mva', { params: this.payloadList })).data
                if (!datas.status) throw datas
                this.tableData = datas.data.data.map((e, key, i) => {
                    e.no = ((datas.data.current_page - 1) * datas.data.per_page + (key > 9 ? i : key) + 1) || key + 1 
                    return e
                })
                this.total_page = datas.data.total >= 10 ? Math.ceil(datas.data.total / 10) : 1
            } catch (error) {
                console.log(error)
            } finally {
                window.$('#Modal_Filter').modal('hide')
                this.loadStateList = true
            }
        },
        paginateCb (page) {
            this.payloadList.page = page
            this.loadList ()
        },
        resetFilter () {
            this.payloadList = { ...this.payloadList, status_kontraktual : null }
            this.loadList ()
        },
        async exportExcel () {
            this.loadStateExport = false
            try {
                const datas = (await this.axios.get('/v2/bg_pelaksanaan/generate-excel', { responseType: 'blob' })).data
                const url = window.URL.createObjectURL(new Blob([datas]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'bank-garansi-jaminan-pelaksanaan.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateExport = true
            }
        }
    }
}
</script>

<style scoped>
</style>