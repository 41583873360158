<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>                          
                    <button type="submit" class="btn btn-primary waves-effect waves-light" data-toggle="modal" data-target="#Modal_FilterKontrak" style="margin-left: 3px;">
                        <i class="fa fa-filter fa-l" aria-hidden="true"></i> Filter Laporan
                    </button>
                    &emsp;
                    <button v-can="'laporan/monitoring/generate'" @click="cetak()" class="btn btn-outline-primary waves-effect waves-light" 
                        v-if="table.dataTable.lists.data" 
                        :disabled="table.dataTable.lists.data.length == 0">
                        <i class="fa fa-file-excel-o fa-l" aria-hidden="true"></i> Cetak Excel
                    </button>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th @click="sortBy('no_kontrak')">No. Kontrak
                            <i class="fa sort" :class="currentSort == 'no_kontrak' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('judul_kontrak')">Judul Kontrak 
                            <i class="fa sort" :class="currentSort == 'judul_kontrak' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('tgl_awal')">Tanggal Awal 
                            <i class="fa sort" :class="currentSort == 'tgl_awal' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('tgl_akhir')">Tanggal Akhir 
                            <i class="fa sort" :class="currentSort == 'tgl_akhir' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('col_unit')">UPP 
                            <i class="fa sort" :class="currentSort == 'col_unit' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('col_jenis_pekerjaan')">Jenis Pekerjaan
                            <i class="fa sort" :class="currentSort == 'col_jenis_pekerjaan' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('col_status_kontraktual')">Status Pekerjaan 
                            <i class="fa sort" :class="currentSort == 'col_status_kontraktual' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>                        
                        <th @click="sortBy('col_progress_num')">Progress 
                            <i class="fa sort" :class="currentSort == 'col_progress_num' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('col_status_serahterima')">Status Serah Terima 
                            <i class="fa sort" :class="currentSort == 'col_status_serahterima' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('col_status_operasi')">Status Operasi 
                            <i class="fa sort" :class="currentSort == 'col_status_operasi' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>    
                        <th></th>
                    </tr>
                </thead>
			</template>          
		</List>
        <div class="modal fade text-left show" id="Modal_FilterKontrak" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Filter Kontrak</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-1" v-for="(f, idx) in filter" :key="idx">
                            <div class="col-8 col-sm-10">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="filter_by">Filter Berdasarkan</label>
                                            <select class="form-control" v-model="f.by" :disabled="f.by != '-'">
                                                <option v-for="(data, index) in filterOption" :key="index" :value="data.by">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-if="f.by == 'tgl_awal'">
                                        <div class="form-group">
                                            <label for="filter_by">Tanggal Awal</label>
                                            <div class="position-relative has-icon-left">                                                
                                                <date-picker
                                                    v-model="filterby.tgl_awal"
                                                    format="DD/MM/YYYY"
                                                    type="date"
                                                    value-type="YYYY-MM-DD"
                                                    placeholder="Tanggal awal kontrak (dd/mm/yyyy)"  
                                                    style="width: 100%"
                                                ></date-picker>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'tgl_akhir'">
                                        <div class="form-group">
                                            <label for="filter_by">Tanggal Akhir</label>
                                            <div class="position-relative has-icon-left">                                                
                                                <date-picker
                                                    v-model="filterby.tgl_akhir"
                                                    format="DD/MM/YYYY"
                                                    type="date"
                                                    value-type="YYYY-MM-DD"
                                                    placeholder="Tanggal akhir kontrak (dd/mm/yyyy)"   
                                                    style="width: 100%"
                                                ></date-picker>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'id_pekerjaan'">
                                        <div class="form-group">
                                            <label for="filter_by">Jenis Pekerjaan</label>                                                                                        
                                            <select class="form-control" v-model="filterby.id_pekerjaan">
                                                <option v-for="(work, index) in optionsPekerjaan" :key="index" :value="work.id">{{work.name}}</option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'id_unit'">
                                        <div class="form-group">
                                            <label for="filter_by">UPP</label>                                                                                        
                                            <select class="form-control" v-model="filterby.id_unit">
                                                <option v-for="(unit, index) in optionsUnit.filter(unit => unit.id > 1)" :key="index" :value="unit.id">{{unit.name}}</option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'status_pekerjaan'">
                                        <div class="form-group">
                                            <label for="filter_by">Status Pekerjaan</label>                                                                                        
                                            <select class="form-control" v-model="filterby.status_pekerjaan">
                                                <option v-for="(data, index) in optionsStatusPekerjaan" :key="index" :value="data.name">{{data.name}}</option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'status_serahterima'">
                                        <div class="form-group">
                                            <label for="filter_by">Status Serah Terima</label>                                                                                        
                                            <select class="form-control" v-model="filterby.status_serahterima">
                                                <option v-for="(data, index) in optionsStatusSerahTerima" :key="index" :value="data.name">{{data.name}}</option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'status_operasi'">
                                        <div class="form-group">
                                            <label for="filter_by">Status Operasi</label>                                                                                        
                                            <select class="form-control" v-model="filterby.status_operasi">
                                                <option v-for="(data, index) in optionsStatusOperasi" :key="index" :value="data.name">{{data.name}}</option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else>
                                        <div class="form-group">
                                            <label for="filter_by">-</label>
                                            <input type="text" class="form-control" disabled>                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 col-sm-2 d-flex align-items-center">
                                <button class="btn-transparent" @click="addFilter()" v-if="idx == filter.length - 1">
                                    <svg class="img-fluid mr-2" width="20" height="21" viewBox="0 0 20 21">
                                        <path fill="#17536A" fill-rule="nonzero" d="M10 .134c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zm0 18.75a8.75 8.75 0 1 1 0-17.5 8.75 8.75 0 0 1 0 17.5zm3.75-9.375h-3.125V6.384a.625.625 0 0 0-1.25 0v3.125H6.25a.625.625 0 0 0 0 1.25h3.125v3.125a.625.625 0 0 0 1.25 0v-3.125h3.125a.625.625 0 0 0 0-1.25z"></path>
                                    </svg>
                                </button>
                                <button class="btn-transparent" @click="deleteFilter(idx)" v-if="filter.length > 1">
                                    <svg class="img-fluid mr-2" width="20" height="21" viewBox="0 0 20 21">
                                        <path fill="#DA3E2C" fill-rule="nonzero" d="M17.314 3.314C13.547-.726 7.219-.946 3.18 2.82c-4.04 3.767-4.26 10.094-.494 14.134 3.767 4.039 10.095 4.26 14.134.493 4.039-3.766 4.26-10.094.494-14.133zM3.6 16.1A8.75 8.75 0 1 1 16.399 4.166 8.75 8.75 0 0 1 3.601 16.101zm9.414-3.651l-2.132-2.286 2.286-2.13a.625.625 0 0 0-.853-.915L10.031 9.25 7.9 6.965a.625.625 0 0 0-.915.852l2.132 2.286-2.286 2.13a.625.625 0 0 0 .853.915l2.285-2.131 2.131 2.285a.625.625 0 0 0 .915-.852z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="reset()">Reset</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="getList()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import List from '@/components/Listv2.vue';

export default {
	name: 'UserIndex',
	components: {
        List,
        DatePicker
	},
	data() {
		return {
			table: {
				title: "Generate Laporan Monitoring",
				breadcumb: "Laporan",
                dataTable: {
                    lists: [],
                    columns: [
                        'no_kontrak',
                        'judul_kontrak',
                        'col_tgl_awal',
                        'col_tgl_akhir',
                        'col_unit',
                        'col_jenis_pekerjaan',
                        'col_status_kontraktual_html',
                        'col_kontrak_progress',
                        'col_status_serahterima_html',
                        'col_status_operasi_html'
                    ]
                }
            },
            tanggal_awal : '',
            detail: [],
            model: {
                status: false
            },
            currentSort: 'id',
            currentSortDir: 'desc',
            filterby: {
                tgl_awal: '',
                tgl_akhir: '',
                id_pekerjaan: '',
                id_unit: '',
                status_pekerjaan: '',
                status_serahterima: '',
                status_operasi: '',
            },
            filter: [
                {
                    'by': '-'
                }
            ],
            filterOption: 
            [
                {
                    'name': '-',
                    'by': '-'
                },
                {
                    'name': 'Tanggal Awal',
                    'by': 'tgl_awal'
                },
                {
                    'name': 'Tanggal Akhir',
                    'by': 'tgl_akhir'
                },
                {
                    'name': 'Jenis Pekerjaan',
                    'by': 'id_pekerjaan'
                },
                {
                    'name': 'UPP',
                    'by': 'id_unit'
                },
                {
                    'name': 'Status Pekerjaan',
                    'by': 'status_pekerjaan'
                },
                {
                    'name': 'Status Serah Terima',
                    'by': 'status_serahterima'
                },
                {
                    'name': 'Status Operasi',
                    'by': 'status_operasi'
                }
            ],
            optionsPekerjaan: [],
            optionsUnit: [],
            optionsStatusPekerjaan: [
                {name: 'Belum Efektif'},
                {name: 'On Going'},
                {name: 'Serah Terima I'},
                {name: 'Serah Terima II'}
            ],
            optionsStatusSerahTerima: [                
                {name: 'On Going'},
                {name: 'Proses STOP'},
                {name: 'STOP'},
                {name: 'Proses STAP'},
                {name: 'STAP'},
                {name: 'Proses STP'},
                {name: 'STP'},
            ],
            optionsStatusOperasi: [
                {name: 'On Going'},
                {name: 'RLB'},
                {name: 'Energize'},
                {name: 'SLO'}
            ]            
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.getPekerjaan()
        _.getUnit()
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/kontrak/getall' + '?page=' + page + '&orderby=' + _.currentSort + '&sort=' + _.currentSortDir 
                    + '&date_start=' + _.filterby.tgl_awal
                    + '&date_end=' + _.filterby.tgl_akhir
                    + '&id_unit=' + _.filterby.id_unit
                    + '&id_pekerjaan=' + _.filterby.id_pekerjaan
                    + '&status_pekerjaan=' + _.filterby.status_pekerjaan
                    + '&status_serahterima=' + _.filterby.status_serahterima
                    + '&status_operasi=' + _.filterby.status_operasi
                )
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            _.axios.post('/kontrak', _.model)
                .then(resp => {
                    if (resp.status) {
                        _.getList()
                        _.model = {
                            status: false
                        }
                        _.$refs.observer.reset();
                    }else{
                        console.log("Failed")
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        sortBy(x) {
            if (x === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortDir = 'asc'
            }
            this.currentSort = x;
            this.getList()
        },
        addFilter(){
            let _ = this
            _.filter.push({
                name	: '-',
                by      : '-'
			})
        },
        deleteFilter(x) {
            let _ = this
            switch (_.filter[x].by) {
                case 'tgl_awal':
                    _.filterby.tgl_awal = ''
                    break;

                case 'tgl_akhir':
                    _.filterby.tgl_akhir = ''
                    break;

                case 'id_pekerjaan':
                    _.filterby.id_pekerjaan = ''
                    break;

                case 'id_unit':
                    _.filterby.id_unit = ''
                    break;
                
                case 'status_pekerjaan':
                    _.filterby.status_pekerjaan = ''
                    break;

                case 'status_serahterima':
                    _.filterby.status_serahterima = ''
                    break;

                case 'status_operasi':
                    _.filterby.status_operasi = ''
                    break;                
            
                default:
                    break;
            }            
            _.filter.splice(x, 1);            
        },
        getPekerjaan() {
            let _ = this
            _.axios.get('/work/all')
                .then(resp => {
                    _.optionsPekerjaan = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getUnit() {
            let _ = this
            _.axios.get('/unit/all')
                .then(resp => {
                    _.optionsUnit = resp.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        cetak() {
			let _ = this
            _.axios.get('/kontrak/export-monitoring' + '?orderby=' + _.currentSort + '&sort=' + _.currentSortDir 
                + '&date_start='        + _.filterby.tgl_awal
                + '&date_end='          + _.filterby.tgl_akhir
                + '&id_unit='           + _.filterby.id_unit
                + '&id_pekerjaan='      + _.filterby.id_pekerjaan
                + '&status_pekerjaan='  + _.filterby.status_pekerjaan
                + '&status_serahterima='+ _.filterby.status_serahterima
                + '&status_operasi='    + _.filterby.status_operasi                
                ,{responseType: 'arraybuffer'})
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'LaporanMonitoring_' + _.filterby.tgl_awal + '-' + _.filterby.tgl_akhir + '.xlsx'
                    link.click()
                })
                .catch(err => {
                    console.log(err.response)
                })
		},
        reset() {
            let _ = this 
            
            _.filterby.tgl_awal = ''
            _.filterby.tgl_akhir = ''
            _.filterby.id_pekerjaan = ''
            _.filterby.id_unit = ''
            _.filterby.status_pekerjaan = ''
            _.filterby.status_serahterima = ''
            _.filterby.status_operasi = ''
            _.filter = [
                {
                    'by': '-'
                }
            ]
        }
    }
}
</script>