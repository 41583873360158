<template>
<div class="card">
    <div class="card-content">
        <div class="card-body card-dashboard">
            <div class="row">
                <div class="col-md-12">
                    <div class="pt-2 pb-2 d-flex align-items-center justify-content-between">
                        <h4 class="d-inline m-0 card-title"><strong>Leaderboard</strong></h4>
                        <button data-toggle="modal" data-target="#Modal_filter_leaderboard" class="btn btn-primary d-inline">
                            Filter Leaderboard
                        </button>  
                    </div>
                    <div v-if="!leaderBoardLoadState" class="wrap-table-leaderboard">
                        <el-table stripe empty-text="No Data"  :data="tableData" style="width: 100%">
                            <el-table-column
                                :fixed="fixedColTable"
                                >
                                <template slot-scope="scope">
                                    <p class="text-center"><strong>{{ scope.row.number }}. </strong></p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :fixed="fixedColTable"
                                prop="ranking"
                                label="Ranking"
                                width="270">
                                <template #header>
                                    <p style="display: inline"><strong>Ranking</strong></p>
                                </template>
                                <template slot-scope="scope">
                                    <div class="row d-flex align-items-center">
                                        <div class="col-3">
                                            <el-avatar :size="40" :src="scope.row.ranking.photo || 'https://inspektorat.kotawaringinbaratkab.go.id/public/uploads/user/default-user-imge.jpeg'"></el-avatar>
                                        </div>
                                        <div class="col-9">
                                            <p class="text-primary"><strong>{{ scope.row.name }}</strong></p>
                                            <p class="text-mutted">{{ scope.row.ranking.position }}</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :fixed="fixedColTable"
                                prop="current_total"
                                label="Total Point"
                                width="100"
                                >
                                <template #header>
                                    <p style="display: inline"><strong>Total Point</strong></p>
                                </template>
                                <template slot-scope="scope">
                                    <div class="d-flex align-items-center" style="gap: 10px">
                                        <svg v-if="scope.row.status === 'up'" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="14" cy="13" r="6.25" fill="#3099AC"/>
                                            <g filter="url(#filter0_d_300_2518)">
                                            <path d="M24 13C24 10.3478 22.9464 7.8043 21.0711 5.92893C19.1957 4.05357 16.6522 3 14 3C11.3478 3 8.8043 4.05357 6.92893 5.92893C5.05357 7.8043 4 10.3478 4 13C4 15.6522 5.05357 18.1957 6.92893 20.0711C8.8043 21.9464 11.3478 23 14 23C16.6522 23 19.1957 21.9464 21.0711 20.0711C22.9464 18.1957 24 15.6522 24 13ZM14.625 17.375C14.625 17.5408 14.5592 17.6997 14.4419 17.8169C14.3247 17.9342 14.1658 18 14 18C13.8342 18 13.6753 17.9342 13.5581 17.8169C13.4408 17.6997 13.375 17.5408 13.375 17.375V10.1337L10.6925 12.8175C10.5751 12.9349 10.416 13.0008 10.25 13.0008C10.084 13.0008 9.92486 12.9349 9.8075 12.8175C9.69014 12.7001 9.62421 12.541 9.62421 12.375C9.62421 12.209 9.69014 12.0499 9.8075 11.9325L13.5575 8.1825C13.6156 8.1243 13.6845 8.07812 13.7605 8.04661C13.8364 8.0151 13.9178 7.99888 14 7.99888C14.0822 7.99888 14.1636 8.0151 14.2395 8.04661C14.3155 8.07812 14.3844 8.1243 14.4425 8.1825L18.1925 11.9325C18.3099 12.0499 18.3758 12.209 18.3758 12.375C18.3758 12.541 18.3099 12.7001 18.1925 12.8175C18.0751 12.9349 17.916 13.0008 17.75 13.0008C17.584 13.0008 17.4249 12.9349 17.3075 12.8175L14.625 10.1337V17.375Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <filter id="filter0_d_300_2518" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="1"/>
                                            <feGaussianBlur stdDeviation="2"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_300_2518"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_300_2518" result="shape"/>
                                            </filter>
                                            </defs>
                                        </svg>

                                        <svg v-if="scope.row.status === 'down'" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="6.25" transform="matrix(1 0 0 -1 14 13)" fill="#EC2F39"/>
                                            <g filter="url(#filter0_d_300_2533)">
                                            <path d="M24 13C24 15.6522 22.9464 18.1957 21.0711 20.0711C19.1957 21.9464 16.6522 23 14 23C11.3478 23 8.8043 21.9464 6.92893 20.0711C5.05357 18.1957 4 15.6522 4 13C4 10.3478 5.05357 7.8043 6.92893 5.92893C8.8043 4.05357 11.3478 3 14 3C16.6522 3 19.1957 4.05357 21.0711 5.92893C22.9464 7.8043 24 10.3478 24 13ZM14.625 8.625C14.625 8.45924 14.5592 8.30027 14.4419 8.18306C14.3247 8.06585 14.1658 8 14 8C13.8342 8 13.6753 8.06585 13.5581 8.18306C13.4408 8.30027 13.375 8.45924 13.375 8.625V15.8663L10.6925 13.1825C10.5751 13.0651 10.416 12.9992 10.25 12.9992C10.084 12.9992 9.92486 13.0651 9.8075 13.1825C9.69014 13.2999 9.62421 13.459 9.62421 13.625C9.62421 13.791 9.69014 13.9501 9.8075 14.0675L13.5575 17.8175C13.6156 17.8757 13.6845 17.9219 13.7605 17.9534C13.8364 17.9849 13.9178 18.0011 14 18.0011C14.0822 18.0011 14.1636 17.9849 14.2395 17.9534C14.3155 17.9219 14.3844 17.8757 14.4425 17.8175L18.1925 14.0675C18.3099 13.9501 18.3758 13.791 18.3758 13.625C18.3758 13.459 18.3099 13.2999 18.1925 13.1825C18.0751 13.0651 17.916 12.9992 17.75 12.9992C17.584 12.9992 17.4249 13.0651 17.3075 13.1825L14.625 15.8663V8.625Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <filter id="filter0_d_300_2533" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="1"/>
                                            <feGaussianBlur stdDeviation="2"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_300_2533"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_300_2533" result="shape"/>
                                            </filter>
                                            </defs>
                                        </svg>


                                        <svg v-if="scope.row.status === 'same'" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="6.25" transform="matrix(1 0 0 -1 14 13)" fill="#505050"/>
                                            <g filter="url(#filter0_d_300_2578)">
                                            <path d="M24 13C24 7.477 19.523 3 14 3C8.477 3 4 7.477 4 13C4 18.523 8.477 23 14 23C19.523 23 24 18.523 24 13ZM18.252 11.5H9.752C9.55309 11.5 9.36232 11.421 9.22167 11.2803C9.08102 11.1397 9.002 10.9489 9.002 10.75C9.002 10.5511 9.08102 10.3603 9.22167 10.2197C9.36232 10.079 9.55309 10 9.752 10H18.252C18.4509 10 18.6417 10.079 18.7823 10.2197C18.923 10.3603 19.002 10.5511 19.002 10.75C19.002 10.9489 18.923 11.1397 18.7823 11.2803C18.6417 11.421 18.4509 11.5 18.252 11.5ZM18.252 16H9.752C9.55309 16 9.36232 15.921 9.22167 15.7803C9.08102 15.6397 9.002 15.4489 9.002 15.25C9.002 15.0511 9.08102 14.8603 9.22167 14.7197C9.36232 14.579 9.55309 14.5 9.752 14.5H18.252C18.4509 14.5 18.6417 14.579 18.7823 14.7197C18.923 14.8603 19.002 15.0511 19.002 15.25C19.002 15.4489 18.923 15.6397 18.7823 15.7803C18.6417 15.921 18.4509 16 18.252 16Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <filter id="filter0_d_300_2578" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="1"/>
                                            <feGaussianBlur stdDeviation="2"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.1 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_300_2578"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_300_2578" result="shape"/>
                                            </filter>
                                            </defs>
                                        </svg>


                                        <a><strong>{{ scope.row.current_total }} </strong></a>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_masuk_ke_ipm"
                                label="Masuk Aplikasi"
                                >
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_masuk_ke_ipm }} </strong></a>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                width="160"
                                prop="total_update_status_kontraktual"
                                label="Input Status Kontraktual"
                                >
                                <template #header>
                                    <span style="display: inline"><strong>Input Progress</strong></span>
                                </template>
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_input_progress }} </strong></a>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                width="160"
                                prop="total_input_kendala"
                                label="Input Kendala"
                                >
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_input_kendala }} </strong></a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_input_solusi"
                                label="Input Solusi"
                                >
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_input_solusi }} </strong></a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_update_amandemen"
                                label="Update Amandemen"
                                >
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_amandemen }} </strong></a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_update_bank_garansi"
                                label="Update Bank Garansi"
                                >
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_bank_garansi }} </strong></a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_update_bap"
                                label="Update BAP"
                                >
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_bap }} </strong></a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_update_po_se"
                                label="Update PO SE"
                                >
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_po_se }} </strong></a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_input_status_operasi"
                                label="Update Status Operasi"
                                >
                                <template #header>
                                    <span style="display: inline"><strong>Update Status<br>Operasi</strong></span>
                                </template>
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_input_status_operasi }} </strong></a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_update_status_kontraktual"
                                label="Update Status Kontraktual"
                                >
                                <template #header>
                                    <span style="display: inline"><strong>Update Status<br>Kontraktual</strong></span>
                                </template>
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_status_kontraktual }} </strong></a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="total_update_status_serah_terima"
                                label="Update Status Serah Terima"
                                >
                                <template #header>
                                    <span style="display: inline"><strong>Update Status <br>Serah
                                        Terima</strong></span>
                                </template>
                                <template slot-scope="scope">
                                    <a style="display: block; text-align: center"><strong>{{ scope.row.total_update_status_serah_terima }} </strong></a>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="mx-auto text-center" style="width:400px" v-else>
                        <h2><i class="fa fa-spinner fa-spin"></i></h2>
                    </div>
                </div>
                <div class="modal fade text-left" id="Modal_filter_leaderboard" aye="mdaman" tabindex="-1" role="dialog"
                    aria-labelledby="myModalLabel160" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title">Filter</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body">
                                <div class="row">
                                    <div class="col col-md-6">
                                        <div class="form-group">
                                            <label for="">Bulan</label>
                                            <select class="form-control" v-model="payloadLb.month">
                                                <option :value="val.value" v-for="(val, idx) in months" :key="idx">{{ val.text }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col col-md-6">
                                        <div class="form-group">
                                            <label for="">Minggu</label>
                                            <select class="form-control" v-model="payloadLb.week">
                                                <option :value="idx" v-for="idx of 4" :key="idx">{{ idx }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button @click="resetFilter()" type="button" class="btn btn-outline-primary" data-dismiss="modal">Reset</button>
                                <button @click="submitLbFilter" type="button" class="btn btn-primary">
                                    <span>Filter</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'LeaderBoardIndex',
    data () {
        return {
            tableData: [],
            leaderBoardLoadState: true,
            payloadLb : {
                month: null,
                week: null,
                limit: null
            },
            months: [
                {
                    text: 'Januari',
                    value: '01'
                },
                {
                    text: 'Februari',
                    value: '02'
                },
                {
                    text: 'Maret',
                    value: '03'
                },
                {
                    text: 'April',
                    value: '04'
                },
                {
                    text: 'Mei',
                    value: '05'
                },
                {
                    text: 'Juni',
                    value: '06'
                },
                {
                    text: 'Juli',
                    value: '07'
                },
                {
                    text: 'Agustus',
                    value: '08'
                },
                {
                    text: 'Oktober',
                    value: '09'
                },
                {
                    text: 'September',
                    value: '10'
                },
                {
                    text: 'November',
                    value: '11'
                },
                {
                    text: 'Desember',
                    value: '12'
                },
            ],
            fixedColTable: true,
        }
    },
    watch: {

    },
    mounted () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        this.loadLeaderboard ()
        this.fixedColTable = window.innerWidth > 420
        window.addEventListener('resize', function () {
            this.fixedColTable = window.innerWidth > 420
        });
    },
    methods: {
        resetFilter () {
            this.leaderboardExpand = true
            this.payloadLb.week = ''
            this.payloadLb.month = ''
            this.loadLeaderboard ()
        },
        submitLbFilter () {
            window.$("#Modal_filter_leaderboard").modal('hide')
            this.payloadLb.limit = null
            this.loadLeaderboard ()
        },
        async loadLeaderboard () {
            this.leaderBoardLoadState = true
            let params = this.leaderboardExpand ? {} : this.payloadLb
            try {
                const datas = (await this.axios.get('/v2/dashboard/leaderboard', { params: params })).data
                const arr = datas.data
                this.tableData = arr.map((e, idx) => {
                    e.ranking = {
                        fullname: e.name,
                        photo: e.photo,
                        position: e.position
                    } 
                    e.number = idx + 1
                    return e
                })
            } catch (error) {
                console.log(error)
            } finally {
                this.leaderBoardLoadState = false
            }
        },
    }
}
</script>

<style>

</style>